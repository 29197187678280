import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { toast } from "@/components/ui/use-toast";
import ScriptApiInstance from "business_logic/script/ScriptApi";
import { Dialog } from "business_logic/script/scriptModels";
import { Loader2, Save } from "lucide-react";
import CustomTable, { Column } from "presentation/components/customTable/customTable";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { handleDialogChange } from "../../../components/customTable/components/handleChange";

import useValidateScript from "presentation/pages/createScript/hooks/useValidateScript";
import DevLogger from "services/Logger";
import useEditScriptStore from "../useEditScriptStore";

const EditDialogQuiz = () => {
    const { seriesId, episodeId } = useParams();
    const [isProcessing, setIsProcessing] = useState(false);
    const { dialogs, setDialogs, upsertDialog } = useEditScriptStore();
    const { validateMultipleQuizChoices, validateTranslationQuizChoices } = useValidateScript();

    const [tabSelected, setTabSelected] = useState("wordQuiz");
    const handleTextChange = ({ field, index, event }) => {
        handleDialogChange({ dialogs, setDialogs, field, index, event });
    };

    const handleSave = useCallback(async () => {
        let validated = false;

        // Validate quiz choices
        if (tabSelected === "wordQuiz") {
            validated = await validateMultipleQuizChoices(dialogs);
        }
        if (tabSelected === "translationQuiz") {
            validated = await validateTranslationQuizChoices(dialogs);
        }
        if (!validated) return;

        // Patch Request
        setIsProcessing(true);

        try {
            if (tabSelected === "wordQuiz") {
                const payload: Partial<Dialog>[] = dialogs.map((dialog) => ({
                    id: dialog.id,
                    dialogTurn: dialog.dialogTurn,
                    baseScript: dialog.baseScript,
                    isQuiz: dialog.isQuiz,
                    targetScript: dialog.targetScript,
                    ...(dialog.highlightedTranslation !== null && dialog.highlightedTranslation !== undefined && dialog.isQuiz && { highlightedTranslation: dialog.highlightedTranslation }),
                    ...(dialog.multipleChoices !== null && dialog.multipleChoices !== undefined && dialog.isQuiz && { multipleChoices: dialog.multipleChoices }),
                    ...(dialog.multipleChoicesId !== null && dialog.multipleChoicesId !== undefined && dialog.isQuiz && { multipleChoicesId: dialog.multipleChoicesId }),
                    ...(dialog.quizWord !== null && dialog.quizWord !== undefined && dialog.isQuiz && { quizWord: dialog.quizWord }),
                    ...(dialog.toeicWord !== null && dialog.toeicWord !== undefined && dialog.isQuiz && { toeicWord: dialog.toeicWord }),
                    ...(dialog.quizWordId !== null && dialog.quizWordId !== undefined && dialog.isQuiz && { quizWordId: dialog.quizWordId }),
                    ...(dialog.quizExplanationEn !== null && dialog.quizExplanationEn !== undefined && dialog.isQuiz && { quizExplanationEn: dialog.quizExplanationEn }),
                    ...(dialog.quizExplanationKo !== null && dialog.quizExplanationKo !== undefined && dialog.isQuiz && { quizExplanationKo: dialog.quizExplanationKo }),
                }));
                await ScriptApiInstance.patchDialogQuiz(episodeId, payload);
            }
            if (tabSelected === "translationQuiz") {
                const payload: Partial<Dialog>[] = dialogs.map((dialog) => ({
                    id: dialog.id,
                    targetScript: dialog.targetScript,
                    ...(dialog.translationQuizAnswer && { translationQuiz: true }),
                    ...(dialog.translationQuizAnswer && { translationQuizAnswer: dialog.translationQuizAnswer }),
                    ...(dialog.translationQuizChoices && { translationQuizChoices: dialog.translationQuizChoices }),
                }));
                DevLogger.log(payload);
                await ScriptApiInstance.patchTranslationQuiz(episodeId, payload);

                toast({
                    title: "Success",
                    description: "Dialog Quiz have been updated successfully",
                });
            }
        } catch (error) {
            toast({
                title: "Failure",
                description: error.message,
                variant: "destructive"
            });
        } finally {
            setIsProcessing(false);
        }
    }, [dialogs]);

    const fibQuizcolumns: Column<Dialog>[] = useMemo(() => [
        { header: "턴", field: "dialogTurn", width: "2.5vw", editable: false },
        { header: "캐릭터", field: "characterName", width: "6vw", editable: false },
        { header: "대사", field: "targetScript", width: "14vw", editable: false },
        { header: "퀴즈단어", field: "quizWord", width: "8vw", editable: false },
        { header: "MC 1", field: `multipleChoices-0`, width: "8vw", editable: true },
        { header: "MC 2", field: `multipleChoices-1`, width: "8vw", editable: true },
        { header: "MC 3", field: `multipleChoices-2`, width: "8vw", editable: true },
        { header: "재생성", renderType: 'createMultipleChoicesButton', width: '4vw', editable: (dialog: Dialog) => !!dialog.quizWord },
        { header: "해설", field: `quizExplanationKo`, width: "26vw", editable: (dialog: Dialog) => !!dialog.quizWord },
        { header: "재생성", renderType: 'createQuizExplanationButton', width: "4vw", editable: (dialog: Dialog) => !!dialog.quizWord },
    ], [dialogs]);

    const translationQuizColumns: Column<Dialog>[] = useMemo(() => [
        { header: "턴", field: "dialogTurn", width: "2.5vw", editable: false },
        { header: "캐릭터", field: "characterName", width: "6vw", editable: false },
        { header: "대사", field: "targetScript", width: "24vw", editable: false },
        { header: "정답", field: `translationQuizAnswer`, width: "24vw", editable: true },
        { header: "오답", field: `translationQuizChoices-0`, width: "24vw", editable: true },
        { header: "재생성", renderType: 'createTranslationQuizChoicesButton', width: '4vw', editable: true },
    ], [dialogs]);

    return (
        <>
            <section>
                <Tabs defaultValue="wordQuiz" className="h-full">
                    <TabsList>
                        <TabsTrigger onClick={() => setTabSelected("wordQuiz")} value="wordQuiz">Fill in the blank Quiz</TabsTrigger>
                        <TabsTrigger onClick={() => setTabSelected("translationQuiz")} value="translationQuiz">Translation Quiz</TabsTrigger>
                    </TabsList>
                    <TabsContent key="wordQuiz" value="wordQuiz">
                        <CustomTable<Dialog>
                            data={dialogs}
                            columns={fibQuizcolumns}
                            rowHeight="96px"
                            handleTextChange={handleTextChange}
                            customProps={{ seriesId: seriesId, episodeId: episodeId, upsertDialog: upsertDialog }}
                        />
                    </TabsContent>
                    <TabsContent key="translationQuiz" value="translationQuiz">
                        <CustomTable<Dialog>
                            data={dialogs}
                            columns={translationQuizColumns}
                            rowHeight="96px"
                            handleTextChange={handleTextChange}
                            customProps={{ seriesId: seriesId, episodeId: episodeId, upsertDialog: upsertDialog }}
                        />
                    </TabsContent>
                </Tabs >
            </section>

            <section>
                <div className="absolute bottom-9 left-1/2 translate-x-[-60%] text-lg mx-auto">
                    <Button disabled={isProcessing} onClick={handleSave}>
                        {isProcessing ? (
                            <> <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Please wait </>
                        ) : (
                            <> <Save size={20} className="mr-2" /> Save </>
                        )}
                    </Button>
                </div>
            </section>
        </>

    );
};

export default EditDialogQuiz;
