import { DialogTurn } from "business_logic/script/scriptModels";
import { useState } from "react";
import DialogBox from "./components/DialogBox";
import MultipleChoices from "./components/MultipleChoices";
import ProgressBar from "./components/ProgressBar";
import "./episodePlays.scss";

export default function EpisodePlay({ dialogTurns }: { dialogTurns: DialogTurn[] }) {
    const [currentDialogTurn, _] = useState(0);
    const [selectedMultipleChoiceIndex, setSelectedMultipleChoiceIndex] = useState(-1);
    // const nextDialogTurn = () => { setCurrentDialogTurn(currentDialogTurn + 1); }
    // const previousDialogTurn = () => { setCurrentDialogTurn(currentDialogTurn - 1); }

    const handleMultipleChoiceClick = (multipleChoiceIndex: number) => {
        setSelectedMultipleChoiceIndex(multipleChoiceIndex);
    }

    return (
        <div className="episode-play ">
            {dialogTurns.map((dialogTurn, index) => {
                const isQuiz = dialogTurn.multipleChoiceQuiz != null;
                const isNextButtonDisabled = isQuiz && selectedMultipleChoiceIndex === -1;
                return (
                    <div className="background-container" style={{ backgroundImage: `url(${dialogTurn.backgroundImageUrl})` }} >
                        <div className="character-container justify-between flex-col" style={{ backgroundImage: `url(${dialogTurn.characterImageUrl})` }}>

                            <ProgressBar currentDialogTurn={currentDialogTurn} totalDialogTurns={dialogTurns.length} />
                            <div className="flex-col gap-4">
                                <DialogBox dialogTurn={dialogTurn} />
                                {isQuiz && <MultipleChoices choices={dialogTurn.multipleChoiceQuiz.choices} handleClick={handleMultipleChoiceClick} selectedMultipleChoiceIndex={selectedMultipleChoiceIndex} />}
                                <button className="button button-next-dialog-turn" disabled={isNextButtonDisabled}>다음</button>
                            </div>
                        </div>
                    </div>
                );
            })}

        </div >
    );

}