import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import { CreateEpisodeRequest, Episode, EpisodeDTO } from "business_logic/episode/episodeModels";
import { Series } from "business_logic/series/seriesModels";
import SelectSeriesButtons from "presentation/components/dropdowns/SeriesSelectButtons";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import "styles/modals.scss";
import "styles/global.scss";
import "styles/buttons.scss";
import { useToast } from "@/components/ui/use-toast";
import { Loader2 } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";

interface Props {
  providedSeries?: Series;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export default function CreateEpisodeDialog({ providedSeries, open, onClose, onSuccess = () => { } }: Props) {
  const [series, setSeries] = useState<Series>(providedSeries ? providedSeries : null!);
  const { toast } = useToast();
  const [isProcessing, setIsProcessing] = useState(false);

  const createEpisode = useMutation(
    async (request: CreateEpisodeRequest) => { await EpisodeApiInstance.createEpisode(request); },
    {
      onSuccess: () => {
        toast({
          title: "Success",
          description: "에피소드가 성공적으로 추가됐습니다"
        })
        onSuccess();
        onClose();
      },
      onError: (error: any) => {
        toast({
          title: "Failure",
          description: error.message,
          variant: "destructive"
        })
      },
      onSettled: () => {
        setIsProcessing(false);
      }
    }
  );

  const handleSubmit = (event) => {

    event.preventDefault();
    setIsProcessing(true);

    const formData = new FormData(event.target);
    const createEpisodeRequest: CreateEpisodeRequest = {
      seriesId: series.id,
      index: Number(formData.get("index")),
      title: formData.get("title") as string,
      description: formData.get("description") as string,
      intro: formData.get("intro") as string,
      startBackgroundImage: formData.get("startBackgroundImg") as string,
      introBgm: formData.get("introBgm") as string,
    };
    createEpisode.mutate({ ...createEpisodeRequest });
  };



  return (
    <Dialog open={open} onOpenChange={onClose} >
      <DialogContent className="sm:max-w-[600px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Create new episode</DialogTitle>
            <DialogDescription>
              Select series and fill in the details to create a new episode.
            </DialogDescription>
          </DialogHeader>
          <SelectSeriesButtons selectedSeries={series} setSelectedSeries={setSeries} />
          {series &&
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 align-center gap-4">
                <Label htmlFor="index" className="text-right"> Index </Label>
                <Input name="index" type="number" className="col-span-3" required />
              </div>
              <div className="grid grid-cols-4 align-center gap-4">
                <Label htmlFor="title" className="text-right">Title</Label>
                <Input name="title" className="col-span-3" required />
              </div>
              <div className="grid grid-cols-4 align-center gap-4">
                <Label htmlFor="startBackgroundImg" className="text-right">Background image</Label>
                <Input name="startBackgroundImg" className="col-span-3" />
              </div>
              <div className="grid grid-cols-4 align-center gap-4">
                <Label htmlFor="introBgm" className="text-right">Background music</Label>
                <Input name="introBgm" className="col-span-3" />
              </div>
              <div className="grid grid-cols-4 align-center gap-4">
                <Label htmlFor="description" className="text-right">Description</Label>
                <Textarea name="description" className="col-span-3" />
              </div>
              <div className="grid grid-cols-4 align-center gap-4">
                <Label htmlFor="intro" className="text-right">Preview Text</Label>
                <Textarea name="intro" className="col-span-3" />
              </div>
            </div>
          }

          <DialogFooter>
            <Button type="submit" disabled={isProcessing}>
              {isProcessing ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Please wait
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
