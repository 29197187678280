import { AudioSettings } from "business_logic/script/scriptModels";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./audioSettingSliders.scss";

interface AudioSettingsSlidersProps {
  audioSettings: AudioSettings;
  setAudioSettings: (settings: AudioSettings) => void;
}

export const AudioSettingSliders = ({ audioSettings, setAudioSettings }: AudioSettingsSlidersProps) => {
  const setSettingsValue = (key: string, value: number) => {
    setAudioSettings({
      ...audioSettings,
      [key]: value,
    });
  };

  const sliderData = [
    {
      header: "Stability",
      label: "voiceStability",
      description: ["More Variable", "More Stable"],
      value: audioSettings!.voiceStability!,
    },
    {
      header: "Similarity",
      label: "voiceSimilarity",
      description: ["Low", "High"],
      value: audioSettings!.voiceSimilarity!,
    },
    {
      header: "Style Exaggeration",
      label: "voiceStyle",
      description: ["None", "Exaggerated"],
      value: audioSettings!.voiceStyle!,
    },
  ];

  return (
    <div className="component-audio-setting-sliders flex-row">
      {sliderData.map(({ header, label, description, value }) => (
        <div className="slider-item flex-col max-width" key={label}>
          <h5 className="item-header">
            {header} - {Math.round(value * 100)}%
          </h5>
          <div className="flex-col max-width">
            <div className="flex-row max-width justify-between">
              <p className="item-label">{description[0]}</p>
              <p className="item-label">{description[1]}</p>
            </div>
          </div>
          <Slider className="slider"
            trackStyle={{ backgroundColor: "black" }}
            handleStyle={{
              borderColor: 'black',
              backgroundColor: 'black',
            }}
            min={0}
            max={1}
            step={0.01}
            value={value}
            onChange={(value) => setSettingsValue(label, value as number)} />
        </div>
      ))}
    </div>
  );
};
