import ActivityFlow from "./ActivityFlow";
import SessionDrawer from "./SessionDrawer";

export default function ActivityFlowTab() {
    return (
        <div className="grid grid-cols-6 gap-8">
            <div className="col-span-1">
                <SessionDrawer />
            </div>
            <div className="col-span-5">
                <ActivityFlow />
            </div>
        </div>
    );
}