import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { Label } from "@radix-ui/react-label";
import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import { PatchEpisodeRequest } from "business_logic/episode/episodeModels";
import useEditContent from "presentation/pages/editContent/useEditContent";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import StringParser from "services/StringParser";
import useEditScriptStore from "../useEditScriptStore";

export default function EditEpisodePreview() {
  const { episode } = useEditScriptStore();

  const updateEpisode = useCallback(async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const payload: PatchEpisodeRequest = {
      intro: (formData.get("intro") as string).trim(),
      startBackgroundImage: (formData.get("startBackgroundImageUrl") as string).trim(),
      introBgm: (formData.get("introBgmUrl") as string).trim(),
    }

    try {
      await EpisodeApiInstance.updateEpisode({
        episodeId: episode.id,
        episode: payload
      })
      toast({
        title: "Success",
        description: "Episode has been updated successfully",
      })
      // fetchEpisode(episodeId);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update episode",
        variant: "destructive"
      })
    }

  }, [episode])

  if (episode)
    return (
      <Card className="w-2/4">
        <CardHeader>
          <CardTitle className="flex-row align-end gap-4">Episode Preview</CardTitle>
        </CardHeader>
        <form onSubmit={updateEpisode}>
          <CardContent>
            <div className="space-y-4">
              <div className="grid grid-rows-4 gap-4">
                <div className="grid-row-2">
                  <Label htmlFor="intro">Preview Text</Label>
                  <Textarea id="intro" name="intro" defaultValue={episode.intro} />
                </div>
                <div className="grid-row-1">
                  <Label htmlFor="startBackgroundImageUrl">Background Image</Label>
                  <Input id="startBackgroundImageUrl" name="startBackgroundImageUrl" defaultValue={StringParser.extractFilename(episode.startBackgroundImageUrl)} />
                </div>
                <div className="grid-row-1">
                  <Label htmlFor="introBgmUrl">Intro BGM</Label>
                  <Input id="introBgmUrl" name="introBgmUrl" defaultValue={StringParser.extractFilename(episode.introBgmUrl)} />
                </div>
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex justify-end space-x-2">
            <Button variant="outline" type="submit">Save</Button>
          </CardFooter>
        </form>
      </Card >
    )
}
