import "reactjs-popup/dist/index.css";
import "styles/buttons.scss";
import "styles/global.scss";
import "styles/popup.scss";
import "./word.scss";
import WordTable from "./wordTable/WordTable";

export default function WordPage() {


  return (
    <div className="page page-word">
      <h1 className="page-header flex-row justify-between h1">단어 정보</h1>
      <hr />
      <WordTable />
    </div>
  );
}
