import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import ScriptApiInstance from "business_logic/script/ScriptApi";
import { Dialog, ImageDirection } from "business_logic/script/scriptModels";
import { Loader2, Save } from "lucide-react";
import CustomTable, { Column } from "presentation/components/customTable/customTable";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { handleDialogChange } from "../../../components/customTable/components/handleChange";
import useEditScriptStore from "../useEditScriptStore";

const EditDialogImage = () => {
  const { episodeId } = useParams();
  const { dialogs, setDialogs } = useEditScriptStore();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleTextChange = useCallback(({ field, index, event }) => {
    handleDialogChange({ dialogs, setDialogs, field, index, event });
  }, [dialogs, setDialogs]);

  const handleSave = useCallback(async () => {
    const errors: string[] = [];

    dialogs?.forEach((dialog) => {
      if (dialog.characterName !== "narration" && (!dialog.characterExpression || !dialog.characterClothes)) {
        errors.push("표정과 옷은 비어있으면 안 됩니다.");
      }
      if (dialog.backgroundImageUrl === "") {
        errors.push("배경 이미지는 비어있으면 안 됩니다.");
      }
      if (dialog.imageDirection.toUpperCase() !== "L" && dialog.imageDirection.toUpperCase() !== "R") {
        errors.push("좌우반전은 L 또는 R로 쓰세요.");
      }
      if (dialog.characterClothes.includes("_") || dialog.characterExpression.includes("_")) {
        errors.push("표정과 옷은 _ 없이 쓰세요.");
      }
    });

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }

    setIsProcessing(true);

    try {
      const payload: Partial<Dialog>[] = dialogs.map((dialog) => ({
        id: dialog.id,
        dialogTurn: dialog.dialogTurn,
        dialogType: dialog.dialogType,
        characterName: dialog.characterName,
        characterImage: `${dialog.characterName}_${dialog.characterExpression}_${dialog.characterClothes}`,
        imageDirection: dialog.imageDirection.toUpperCase() as ImageDirection,
        backgroundImage: dialog.backgroundImage,
        objectImage: dialog.objectImage
      }));
      await ScriptApiInstance.patchDialogImage(episodeId, payload)
      toast({
        title: "Success",
        description: "Dialog Images have been updated successfully",
      })
    }
    catch (error) {
      toast({
        title: "Failure",
        description: error.message,
        variant: "destructive"
      })
    } finally {
      setIsProcessing(false);
    }
  }, [dialogs]);

  const columns: Column<Dialog>[] = useMemo(() => [
    { header: "턴", field: "dialogTurn", width: "2.5vw", editable: false },
    { header: "캐릭터", field: "characterName", width: "8vw" },
    { header: "캐릭터 표정", field: "characterExpression", width: "8vw", editable: (dialog: Dialog) => dialog.characterName !== "narration", },
    { header: "캐릭터 옷", field: `characterClothes`, width: "8vw", editable: (dialog: Dialog) => dialog.characterName !== "narration", },
    { header: "사물 이미지", field: `objectImage`, width: "8vw", editable: (dialog: Dialog) => dialog.characterName === "narration", },
    { header: "대사", field: `targetScript`, width: "30vw", editable: false },
    { header: "배경 이미지", field: `backgroundImage`, width: "8vw" },
    { header: "좌우 반전", field: 'imageDirection', width: "4vw", }
  ], [dialogs]);

  return (
    <>
      <CustomTable<Dialog>
        data={dialogs}
        columns={columns}
        rowHeight="48px"
        handleTextChange={handleTextChange}
      />
      <div className="absolute bottom-9 left-1/2 translate-x-[-60%] text-lg mx-auto">
        <Button disabled={isProcessing} onClick={handleSave}>
          {isProcessing ? (
            <> <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Please wait </>
          ) : (
            <> <Save size={20} className="mr-2" /> Save </>
          )}
        </Button>
      </div>
    </>
  );
};

export default EditDialogImage;
