import { motion } from "framer-motion";
import { useMemo } from "react";

interface WordCardImagesProps {
  imageUrls: string[];
  selectedImageUrl: string;
  loading: boolean;
  setSelectedImageUrl?: (imageUrl: string) => void;
}

export default function WordCardImages({ imageUrls, selectedImageUrl, setSelectedImageUrl, loading }: WordCardImagesProps) {

  const imageGenerationInProgress = useMemo(() =>
    imageUrls.length > 0 && imageUrls[0]?.includes("generating"),
    [imageUrls]);

  const renderCards = () => {
    if (imageGenerationInProgress || loading) {
      return Array(4).fill(null).map((_, index) => (
        <motion.div
          key={index}
          className="empty-word-card-image"
          initial={{ opacity: 0.2 }}
          animate={{ opacity: 0.8 }}
          transition={{ repeat: Infinity, duration: 1, ease: "easeInOut", repeatType: "reverse" }}
        >
          {imageGenerationInProgress && <>Generating</>}
          {loading && <>Loading</>}
        </motion.div>
      ));
    }

    if (imageUrls.length === 0) {
      return Array(4).fill(null).map((_, index) => (
        <div key={index} className="empty-word-card-image">
          No Image
        </div>
      ));
    }

    return imageUrls.map((imageUrl) => (
      <img
        key={imageUrl}
        className={`word-card-image ${imageUrl === selectedImageUrl ? "selected" : ""}`}
        src={`${imageUrl}?t=${Date.now()}`}
        alt="word card"
        onClick={() => setSelectedImageUrl(imageUrl)}
      />
    ));
  };
  return (
    <div className="component-word-card-images">
      {renderCards()}
    </div>
  );
}
