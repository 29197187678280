import { UserLog } from "business_logic/analytics/analyticsModels";
import { format } from "date-fns/format";

export default function ActivityFlowItem({ item, timeDelta }: { item: UserLog, timeDelta: number }) {
    return (
        <div key={item.createdAt} className="flex flex-row gap-1.5 items-center mt-4 w-70">

            <section className="flex flex-col gap-4 align-center w-64">
                <div className="text-slate-800 flex-row align-center gap-2">
                    <div className="bg-[#f5f5f5] p-1 text-xs rounded-md">Event</div>
                    <div className="text-slate-800 text-xs">{item.logDetail}</div>
                </div>
                <div className="w-60 aspect-[9/19.5] bg-cover bg-center rounded-[2rem] border-[1px] border-slate-300 overflow-hidden shadow-xl"
                    style={{ backgroundImage: `url(${item.logImageUrl})` }}
                ></div>
                <div className="flex flex-col align-center gap-1">
                    <div className="text-slate-900 text-sm text-ellipsis text-center overflow-hidden whitespace-nowrap w-56">{item.logHeader}</div>
                    <div className="text-slate-800 text-xs">{format(new Date(item.createdAt), "hh:mm:ss a")}</div>
                </div>
            </section>

            <section className="flex flex-col gap-1 align-center">
                <hr className="border-dotted border-slate-500 h-full w-6" />
                <span className="text-xs font-medium text-slate-500">{`${(timeDelta / 1000).toFixed(1)}s`}</span>
            </section>

        </div>
    );
}