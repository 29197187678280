import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import AuthApiInstance from "business_logic/auth/AuthApi";

interface AuthContextType {
  user: any;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedUser = jwtDecode(token);
      setUser(decodedUser);
    }
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const response = await AuthApiInstance.signIn({ email, password });

      if (response) {
        // Store token only if authentication was successful
        localStorage.setItem("token", response);
        const decodedUser = jwtDecode(response);
        setUser(decodedUser);
      } else {
        throw new Error("Sign in failed: No token received");
      }
    } catch (error) {
      throw new Error("Sign in failed: " + error.message);
    }
  };

  const signOut = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  return <AuthContext.Provider value={{ user, signIn, signOut, isAuthenticated: !!user }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
