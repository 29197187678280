import { User, UserAttendance, UserAttendanceWithUserInfo, UserLog, UserReport } from "business_logic/analytics/analyticsModels";
import BaseApi from "business_logic/base/BaseApi";
import { format } from "date-fns";
import DevLogger from "services/Logger";
import { toZonedTime } from 'date-fns-tz'
import { OverviewStats, RetentionRates, ReturningRates } from "./dashboardModels";

class DashboardApi extends BaseApi {

  private static instance: DashboardApi;
  private constructor() {
    super();
  }
  public static getInstance() {
    if (!DashboardApi.instance) {
      DashboardApi.instance = new DashboardApi();
    }
    return DashboardApi.instance;
  }

  async fetchOverviewStats(): Promise<OverviewStats> {
    try {
      const response = await this.axiosInstance.get<any>(`dashboard/overview`);
      DevLogger.log('Analytics Api Response - fetchUserReport', response.data);
      return response.data as OverviewStats;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async fetchRetentionStats(): Promise<any> {
    try {
      const retentionData = await this.axiosInstance.get(`dashboard/retention/retention-rates`);
      const returningData = await this.axiosInstance.get(`dashboard/retention/returning-rates`);
      DevLogger.log('Analytics Api Response - fetchRetentionStats', retentionData.data);
      DevLogger.log('Analytics Api Response - fetchRetentionStats', returningData.data);
      return { retentionRates: retentionData.data, returnRates: returningData.data };

    } catch (error) {
      throw new Error(error.message);
    }
  }

  async fetchSeriesEngagementStats(): Promise<[]> {
    try {
      const response = await this.axiosInstance.get(`dashboard/engagement/series-engagement`);
      DevLogger.log('Analytics Api Response - fetchRetentionStats', response.data);
      return response.data
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

const DashboardApiInstance = DashboardApi.getInstance();
export default DashboardApiInstance;
