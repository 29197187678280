import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import CharacterApiInstance from "business_logic/character/CharacterApi";
import { PatchSeriesCharacterRequest, SeriesCharacter } from "business_logic/series/seriesModels";
import { useCallback } from "react";

export default function CharacterItem({ character }: { character: SeriesCharacter }) {

    const updateSeriesCharacter = useCallback(async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const request: PatchSeriesCharacterRequest = {
            seriesId: character.seriesId,
            name: formData.get("name") as string,
            voiceId: formData.get("voiceId") as string,
            description: formData.get("description") as string,
        }

        try {
            await CharacterApiInstance.updateSeriesCharacter({
                characterId: character.id,
                req: request
            })
            toast({
                title: "Success",
                description: "Character has been updated successfully",
            })
        } catch (error) {
            toast({
                title: "Failure",
                description: error.message,
                variant: "destructive"
            })
        }

    }, [character])

    return (
        <Card className="w-full">
            <CardHeader>
                <CardTitle className="flex-row align-end gap-4">
                    <Avatar >
                        <AvatarImage src={character.imageUrl} />
                        <AvatarFallback>?</AvatarFallback>
                    </Avatar>
                    {character.internalId}
                </CardTitle>
            </CardHeader>
            <form onSubmit={updateSeriesCharacter}>
                <CardContent>
                    <div className="space-y-4">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <Label htmlFor="name">Name</Label>
                                <Input id="name" name="name" defaultValue={character.name} />
                            </div>
                            <div className="col-span-2">
                                <Label htmlFor="voiceId">Elevenlabs ID</Label>
                                <Input id="voiceId" name="voiceId" defaultValue={character.voiceId} />
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="description">Description</Label>
                            <Input id="description" name="description" defaultValue={character.description} />
                        </div>
                    </div>
                </CardContent>
                <CardFooter className="flex justify-end space-x-2">
                    {/* <Button variant="delete" type = "button" onClick={() => {}}>Delete</Button> */}
                    <Button variant="outline" type="submit">Save</Button>
                </CardFooter>
            </form>
        </Card >
    )
}