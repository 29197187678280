import { Button } from '@/components/ui/button';
import { useState } from 'react';
import SessionSearch from './SessionSearch';
import SessionSearchResult from './SessionSearchResult';

export type SessionsView = 'sessionByDate' | 'sessionByUser';

export default function SessionDrawer() {
    const [sessionsView, setSessionsView] = useState<SessionsView>('sessionByDate');
    return (
        <div className="flex flex-col h-dvh border-r border-gray-200 overflow-hidden p-4">

            <div className="flex flex-row gap-2 mb-4 relative">
                <Button
                    className="rounded-full cursor-pointer"
                    variant={sessionsView === "sessionByDate" ? "default" : "outline"}
                    onClick={() => setSessionsView('sessionByDate')}
                >
                    By Date
                </Button>
                <Button
                    className="rounded-full cursor-pointer"
                    variant={sessionsView === "sessionByUser" ? "default" : "outline"}
                    onClick={() => setSessionsView('sessionByUser')}
                >
                    By User
                </Button>
            </div>

            <SessionSearch sessionsView={sessionsView} />
            <div className="h-full overflow-y-scroll scrollbar-hide">
                <SessionSearchResult sessionsView={sessionsView} />
            </div>
        </div>
    );
}