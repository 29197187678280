import { IDBase, TimeStampBase } from "business_logic/base/baseModels";
import logImageUrlMap from "./logImageUrlMap";
import ResourceUrlService from "services/ResourceUrl";
// import logImageUrlMap from "./logImageUrlMap";

export interface User extends IDBase, TimeStampBase {
  email?: string;
  name?: string;
  lastLogin?: string;
  fcmToken?: string;
  referralCode?: string;
  isTester?: boolean;
}

export interface UserAttendance extends IDBase, TimeStampBase {
  userId?: string;
  count?: number;
}

export interface UserAttendanceWithUserInfo extends UserAttendance {
  user?: User;
}

export interface UserEpisodePlay extends IDBase, TimeStampBase {
  userId?: string;
  seriesId?: string;
  episodeId?: string;

  quizTime?: number;
  playTime?: number;
  scoreLevel?: number;
  scoreRanking?: number;
  quizCount?: number;
  correctCount?: number;
  lastPlayedAt?: string;
  explanationCheckCount?: number;
}


// Custom Models

export interface UserReport {
  user: User,
  attendanceCount: number,
  episodePlays: number,
  episodeCompleted: number,
}

export class UserLog {
  id: string;
  userId: string;
  route: string;
  message: string;
  createdAt: string;
  logHeader?: string;
  logDetail?: string;
  logImageUrl?: string;

  private static readonly SERIES_NAMES = {
    '봉순': 'bongsoons',
    '탐정엠버': 'ember',
    '순애로운 회사생활': "plo",
    '이상한 나라의 앨리스': 'alice',
    'Love is near blind': 'lib',
    '냥냥 뉴스': 'cnn'
  };

  constructor(id: string, userId: string, route: string, message: string, createdAt: Date) {
    this.id = id;
    this.userId = userId;
    this.route = route;
    this.message = message;
    this.createdAt = createdAt.toISOString();
    this.setLogDetails(this.message, this.route);
  }

  static fromJson(data: any) {
    return new UserLog(data.id, data.userId, data.route, data.message, new Date(data.createdAt));
  }

  private setLogDetails(message: string, route: string) {

    const BASEURL = "https://vocatale-data.s3.ap-northeast-2.amazonaws.com/analytics/ui-snapshots/";
    const NOIMAGEURL = "https://vocatale-data.s3.ap-northeast-2.amazonaws.com/analytics/ui-snapshots/noURL.webp";

    if (!message || !route) {
      this.logHeader = "No log data";
      this.logDetail = "No log data";
      this.logImageUrl = logImageUrlMap["No log data"] + '.webp' || NOIMAGEURL;
      return;
    }

    const dialogTurnPattern = /^(.+?)\s+(\d+)화\s+(\d+)번\s+턴\s+(.+)$/

    if (this.route === 'home') {
      const pattern = /^홈 바텀 탭\s+(\d+)\s+클릭$/;
      const [_, message] = this.message.match(pattern);
      this.logHeader = '탭 클릭';
      this.logDetail = `탭 ${message} 클릭`;
      this.logImageUrl = `${BASEURL}tab_${message}.webp`;
    }
    else if (this.route === 'home/dashboard') {
      this.logHeader = '대시보드';
      this.logDetail = message.replace('대시보드', '')
      this.logImageUrl = `${BASEURL}home_tab_playing_series_tab_0.webp`;

      switch (true) {
        case this.logDetail.includes('진행중인 콘텐츠'):
          this.logImageUrl = `${BASEURL}home_tab_playing_series_tab_0.webp`;
          break;
        case this.logDetail.includes('전체 콘텐츠'):
          this.logImageUrl = `${BASEURL}home_tab_all_series_tab.webp`;
          break;
        case this.logDetail.includes('젬 아이콘 클릭'):
          this.logImageUrl = `${BASEURL}gem_store.webp`;
          break;
      }
    }
    else if (this.route === 'home/dashboard/all') {
      const seriesTitle = message.replace(/(?:전체 콘텐츠|포스터 클릭)/g, '').trim();
      const seriesCode = UserLog.SERIES_NAMES[seriesTitle];
      this.logHeader = '대시보드 - 전체 콘텐츠';
      this.logDetail = message.replace('전체 콘텐츠', '')
      this.logImageUrl = `${BASEURL}${seriesCode}_series_info.webp`;
    }
    else if (this.route === "home/dashboard/playing") {

      this.logHeader = '대시보드 - 진행중인 콘텐츠';

      const seriesInfoPattern = /^진행중인 콘텐츠\s+(.+?)\s+정보 아이콘 클릭$/
      const startSeriesPattern = /^진행중인 콘텐츠\s+(.+?)\s+시작 버튼 클릭$/
      const scrollTabPattern = /^대시보드 진행중탭\s+(`d+)번째 시리즈로 스크롤 이동$/
      const clickTabPattern = /^대시보드 진행중탭\s+(`d+)번째 시리즈 탭 클릭$/

      if (this.message.match(seriesInfoPattern)) {
        const [_, seriesTitle] = this.message.match(seriesInfoPattern);
        const seriesCode = UserLog.SERIES_NAMES[seriesTitle];
        this.logDetail = `${seriesCode} 정보 아이콘 클릭`;
        this.logImageUrl = `${BASEURL}${seriesCode}_series_info.webp`;
      }
      else if (this.message.match(startSeriesPattern)) {
        const [_, seriesTitle] = this.message.match(startSeriesPattern);
        const seriesCode = UserLog.SERIES_NAMES[seriesTitle];
        this.logDetail = `${seriesCode} 시작 버튼 클릭`;
        this.logImageUrl = `${BASEURL}${seriesCode}_1_intro_text.webp`;
      }
      else if (this.message.match(scrollTabPattern)) {
        const [_, tabNumber] = this.message.match(scrollTabPattern);
        this.logDetail = `${tabNumber}번째 시리즈로 스크롤 이동`;
        this.logImageUrl = `${BASEURL}home_tab_playing_series_tab_0.webp`;
      }
      else if (this.message.match(clickTabPattern)) {
        const [_, tabNumber] = this.message.match(clickTabPattern);
        this.logDetail = `${tabNumber}번째 시리즈 탭 클릭`;
        this.logImageUrl = `${BASEURL}home_tab_playing_series_tab_0.webp`;
      }
    }
    else if (this.route === 'series') {

      const previousPagePattern = /^시리즈\s+(.+?)\s+뒤로가기 클릭$/
      const seriesPlayPattern = /^시리즈\s+(.+?)\s+시작 버튼 클릭$/
      const episodeInfoPagePattern = /^시리즈\s+(.+?)\s+에피소드\s+(\d+)\s+정보 클릭$/
      const episodePlayPattern = /^시리즈\s+(.+?)\s+에피소드\s+(\d+)\s+플레이 클릭$/

      let seriesTitle, episodeIndex, seriesCode;

      switch (true) {
        case message.match(previousPagePattern) !== null:
          [, seriesTitle] = message.match(previousPagePattern);
          seriesCode = UserLog.SERIES_NAMES[seriesTitle];
          this.logHeader = `시리즈 정보 화면`;
          this.logDetail = `${seriesCode} 뒤로가기 클릭`;
          this.logImageUrl = `${BASEURL}home_tab_playing_series_tab_${seriesCode}.webp`;
          break;
        case message.match(seriesPlayPattern) !== null:
          [, seriesTitle] = message.match(seriesPlayPattern);
          seriesCode = UserLog.SERIES_NAMES[seriesTitle];
          this.logHeader = `시리즈 정보 화면`;
          this.logDetail = `${seriesCode} 시작 버튼 클릭`;
          this.logImageUrl = `${BASEURL}${seriesCode}_1_intro_text.webp`;
          break;
        case message.match(episodeInfoPagePattern) !== null:
          [, seriesTitle, episodeIndex] = message.match(episodeInfoPagePattern);
          seriesCode = UserLog.SERIES_NAMES[seriesTitle];
          this.logHeader = `${seriesCode} ${episodeIndex}화`;
          this.logDetail = `정보 클릭`;
          this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_info.webp`;
          break;
        case message.match(episodePlayPattern) !== null:
          [, seriesTitle, episodeIndex] = message.match(episodePlayPattern);
          seriesCode = UserLog.SERIES_NAMES[seriesTitle];
          this.logHeader = `${seriesCode} ${episodeIndex}화`;
          this.logDetail = `플레이 클릭`;
          this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_intro_text.webp`;
          break;
        default:
          this.logHeader = this.message;
          this.logDetail = this.message;
          this.logImageUrl = logImageUrlMap[this.message] + '.webp' || NOIMAGEURL;
      }
    }
    else if (route.includes('home/voca')) {
      const vocabPattern = /^단어장\s+(.+?)$/
      const [_, action] = this.message.match(vocabPattern);

      this.logHeader = `단어장 탭`;
      this.logDetail = action;

      switch (true) {
        case action.includes("뜻가리기 토글 클릭"):
          this.logImageUrl = `${BASEURL}vocab_tab_meaning_toggle.webp`;
          break;
        case action.includes('토익 탭 클릭'):
          this.logImageUrl = `${BASEURL}vocab_tab_toeic_tab.webp`;
          break;
        case action.includes('저장한 단어 탭 클릭'):
          this.logImageUrl = `${BASEURL}vocab_tab_bookmark_tab.webp`;
          break;
        case action.includes('오답 필터 클릭'):
          this.logImageUrl = `${BASEURL}vocab_tab_incorrect_toggle.webp`;
          break;
        case action.includes('출제율 필터 클릭'):
          this.logImageUrl = `${BASEURL}vocab_tab_frequency_toggle.webp`;
          break;
        case action.includes('저장탭'):
          this.logImageUrl = `${BASEURL}vocab_tab_bookmark_tab.webp`;
          break;
        case action.includes('토익탭'):
          this.logImageUrl = `${BASEURL}vocab_tab_toeic_tab.webp`;
          break;
        default:
          this.logImageUrl = `${BASEURL}vocab_tab.webp`;
      }
    }
    else if (this.route === 'home/profile') {
      const profilePattern = /^프로필\s+(.+?)$/
      const [_, action] = this.message.match(profilePattern);
      this.logHeader = '프로필';
      this.logDetail = action
      this.logImageUrl = `${BASEURL}profile_tab.webp`;

      if (action.includes('설정 아이콘 클릭')) {
        this.logImageUrl = `${BASEURL}profile_settings.webp`;
      }
      else if (action.includes('문의 버튼 클릭')) {
        this.logImageUrl = `${BASEURL}profile_inquiry.webp`;
      }
    }
    else if (this.route === 'episode_details') {
      const pattern = /^(.+?)\s+(\d+)화 에피소드\s+(.+?)$/
      const [_, seriesTitle, episodeIndex, action] = this.message.match(pattern);
      const seriesCode = UserLog.SERIES_NAMES[seriesTitle];

      this.logHeader = `${seriesCode} ${episodeIndex}화 정보`;
      this.logDetail = action;
      this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_info.webp`;

      if (action.includes('뒤로가기 클릭')) {
        this.logImageUrl = `${BASEURL}${seriesCode}_series_info.webp`;
      }
    }
    else if (this.route === 'episode/intro') {
      const pattern = /^(.+?)\s+(\d+)화 인트로\s+(.+?)$/
      const [_, seriesTitle, episodeIndex, action] = this.message.match(pattern);
      const seriesCode = UserLog.SERIES_NAMES[seriesTitle];

      this.logHeader = `${seriesCode} ${episodeIndex}화 인트로`;
      this.logDetail = action;
      this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_intro_text.webp`;

      if (action.includes('뒤로가기 클릭')) {
        this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_info.webp`;
      }
      else if (action.includes('단어 리스트 스크롤')) {
        this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_intro_vocab.webp`;
      }
      else if (action.includes('인트로 배경 클릭') || action.includes('단어카드 클릭') || action.includes('인트로 스피커 아이콘 클릭')) {
        this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_intro_text.webp`;
      }
      else if (action.includes('시작 버튼 클릭')) {
        this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_intro_text.webp`;
      }
    }
    else if (this.route === 'episode/settings') {
      const pattern = /^(.+?)\s+(\d+)화\s+(.+?)$/
      const [_, seriesTitle, episodeIndex, action] = this.message.match(pattern);
      const seriesCode = UserLog.SERIES_NAMES[seriesTitle];

      this.logHeader = `플레이 설정`;
      this.logDetail = action;
      this.logImageUrl = `${BASEURL}play_settings.webp`;
    }
    else if (this.route === 'episode_purchase_popup') {
      const pattern = /^(.+?)\s+에피소드\s+(\d+)화\s+(.+?)$/
      const [_, seriesTitle, episodeIndex, action] = this.message.match(pattern);
      const seriesCode = UserLog.SERIES_NAMES[seriesTitle];

      this.logHeader = `${seriesCode} ${episodeIndex}화 설정`;
      this.logDetail = action;
      this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_intro_text.webp`;
    }
    else if (this.route === 'episode_review') {
      const pattern = /^(.+?)\s+(\d+)화\s+(.+?)$/
      const [_, seriesTitle, episodeIndex, action] = this.message.match(pattern);
      const seriesCode = UserLog.SERIES_NAMES[seriesTitle];

      this.logHeader = `${seriesCode} ${episodeIndex}화 복습`;
      this.logDetail = action;
      this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_info.webp`;

      if (action.includes('음성 재생')) {
        this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_complete_recap.webp`;
      }
      else if (action.includes('복습 홈으로 돌아가기')) {
        this.logImageUrl = `${BASEURL}home_tab_playing_series_${seriesCode}.webp`;
      }
      else if (action.includes('다음 버튼 클릭')) {
        this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_complete_vocab.webp`;
      }
    }
    else if (this.route === 'episode/completed') {
      const pattern = /^(.+?)\s+(\d+)화\s+(.+?)$/
      const [_, seriesTitle, episodeIndex, action] = this.message.match(pattern);
      const seriesCode = UserLog.SERIES_NAMES[seriesTitle];
      this.logHeader = `${seriesCode} ${episodeIndex}화 완료`;
      this.logDetail = action;
      this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_complete_score.webp`;

    }
    else if (this.route === 'episode/result') {
      const pattern = /^(.+?)\s+(\d+)화\s+(.+?)$/
      const [_, seriesTitle, episodeIndex, action] = this.message.match(pattern);
      const seriesCode = UserLog.SERIES_NAMES[seriesTitle];
      this.logHeader = `${seriesCode} ${episodeIndex}화 완료`;
      this.logDetail = action;
      this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_complete_recap.webp`;

    }

    else if (this.message.match(dialogTurnPattern)) {
      let [_, seriesTitle, episodeIndex, dialogTurn, action] = this.message.match(dialogTurnPattern);
      const seriesCode = UserLog.SERIES_NAMES[seriesTitle];
      this.logHeader = `${seriesCode} ${episodeIndex}화 ${parseInt(dialogTurn) + 1}번 턴`;
      this.logDetail = action.replace('}', '');
      this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_${parseInt(dialogTurn) + 1}.webp`;

      // Fallback episodeIndex to 1 if no image exists for that episode
      // if (ResourceUrlService.checkImageExists(this.logImageUrl)) {
      //   episodeIndex = '1';
      // }

      switch (true) {
        case action.includes("뒤로가기"):
          this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_${parseInt(dialogTurn)}.webp`;
          break;
        case action.includes("선택지 확정 버튼 클릭"):
          this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_${parseInt(dialogTurn) + 1}_answer.webp`;
          break;
        case action.includes("다음 버튼 클릭"):
          this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_${parseInt(dialogTurn) + 2}.webp`;
          break;
        case action.includes('설정 아이콘 클릭'):
          this.logImageUrl = `${BASEURL}play_settings.webp`;
          break;
        // case action.toLowerCase().includes('단어 클릭'):
        //   this.logImageUrl = `${ BASEURL }${ seriesCode } _word_link.webp`;
        //   break;
        // case action.includes('단어 팝업 음성 재생 아이콘 클릭'):
        //   this.logImageUrl = `${ BASEURL }${ seriesCode } _word_link_audio.webp`;
        //   break;
        // case action.includes('단어 팝업 북마크 아이콘 클릭'):
        //   this.logImageUrl = `${ BASEURL }${ seriesCode } _word_link_bookmark.webp`;
        //   break;
        case action.includes('복습 시작 버튼 클릭'):
          this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex} _brushup_start.webp`;
          break;
        case action.includes('복습 스킵 버튼 클릭'):
          this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex} _complete_vocab.webp`;
          break;
        default:
          this.logImageUrl = `${BASEURL}${seriesCode}_${episodeIndex}_${parseInt(dialogTurn) + 1}.webp`;
      }
    }

    else {
      this.logHeader = this.message;
      this.logDetail = this.message;
      this.logImageUrl = logImageUrlMap[this.message] + '.webp' || NOIMAGEURL;
    }
  };

}
