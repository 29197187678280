import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ArrowLeft, ArrowRight, Search } from "lucide-react";
import CalendarForm from "presentation/components/calendar/calendar";
import { useCallback, useEffect, useState } from "react";
import useAnalyticsStore from "../useAnalyticsStore";
import { SessionsView } from "./SessionDrawer";
import { addDays } from "date-fns/addDays";

export default function SessionSearch({ sessionsView }: { sessionsView: SessionsView }) {

    const [userSearch, setUserSearch] = useState("");
    const { date, setDate, fetchAttendancesByDate, fetchAttendancesByUser } = useAnalyticsStore();

    const handleUserSearchChange = useCallback((e) => {
        setUserSearch(e.target.value);
    }, [userSearch])

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            if (sessionsView === 'sessionByDate') {
                fetchAttendancesByDate(date);
            } else if (sessionsView === 'sessionByUser') {
                fetchAttendancesByUser(userSearch);
            }
        }
    }, [sessionsView, date, userSearch]);

    useEffect(() => {
        fetchAttendancesByDate(date)
    }, [date, setDate])

    return (
        <>
            {sessionsView === 'sessionByDate' &&
                <div className="flex-row gap-2">
                    <Button className="px-2" variant="outline" onClick={() => setDate(addDays(date, -1))}><ArrowLeft size={12} /></Button>
                    <CalendarForm date={date} setDate={setDate} />
                    <Button className="px-2" variant="outline" onClick={() => setDate(addDays(date, +1))}><ArrowRight size={12} /></Button>
                </div>
            }

            {sessionsView === 'sessionByUser' &&
                <div className="flex-row gap-2">
                    <Input placeholder="Search username or email"
                        onChange={handleUserSearchChange}
                        onKeyDown={handleKeyDown} />
                    <Button onClick={() => fetchAttendancesByUser(userSearch)}><Search size={12} /></Button>
                </div>
            }
        </>
    );
};

