import ScriptApiInstance from "business_logic/script/ScriptApi";
import { EpisodeScriptInfo } from "business_logic/script/scriptModels";
import { useEditScriptStoreStore } from "business_logic/script/scriptStore";
import { Series } from "business_logic/series/seriesModels";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface useEpisodeTableLogicInterface {
  scripts: EpisodeScriptInfo[];
  setScripts: (scripts: EpisodeScriptInfo[]) => void;
  refetchScripts: () => void;
}

export function useScriptTableLogic({ scripts, setScripts, refetchScripts }: useEpisodeTableLogicInterface) {
  const navigate = useNavigate();
  const series = useEditScriptStoreStore((state) => state.series);
  const handleChange = useCallback(
    (field: string, newValue: any, episodeId: string) => {
      const updatedEpisode = scripts.map((episode) => (episode.id === episodeId ? { ...episode, [field]: newValue } : episode));
      setScripts(updatedEpisode);
    },
    [scripts, setScripts]
  );

  const updateScript = useCallback(
    async (rowData: any) => {
      const episodeId = rowData.id;
      const seriesId = series.id;
      const targetEpisodesBySeriescriptInfo = scripts.find((episode) => episode.id === episodeId);
      if (targetEpisodesBySeriescriptInfo) {
        // Set episode to update in state
        useEditScriptStoreStore.setState({ episodeScriptInfo: targetEpisodesBySeriescriptInfo });

        // Navigate to edit script page
        navigate(`/script/edit/${seriesId}/${episodeId}`);
      }
    },
    [scripts, navigate]
  );


  const validateScript = useCallback(
    async (rowData: any) => {
      const episodeId = rowData.id;
      const targetEpisode = scripts.find((episode) => episode.id === episodeId);

      if (targetEpisode) {
        navigate("/script/validate/" + episodeId);
      }
    },
    [scripts, navigate]
  );

  const softDeleteScript = useCallback(
    async (rowData: any) => {
      const episodeId = rowData.id;
      const targetEpisode = scripts.find((s) => s.id === episodeId);
      if (targetEpisode.status !== "draft") {
        alert("출시된 스크립트는 삭제할 수 없습니다.");
        return;
      }
      try {
        await ScriptApiInstance.deleteScript({ episodeId: targetEpisode.id! });
        alert("스크립트 삭제 완료");
        refetchScripts();
      } catch (error: any) {
        alert(error.message.detail);
      }
    },
    [scripts, refetchScripts]
  );
  const hardDeleteScript = useCallback(
    async (rowData: any) => {
      const episodeId = rowData.id;
      const targetEpisode = scripts.find((s) => s.id === episodeId);
      if (targetEpisode) {
        // Prompt user for confirmation
        const confirmDelete = window.confirm("삭제하면 되돌릴 수 없습니다. 정말 삭제하시겠습니까?");
        if (confirmDelete) {
          try {
            await ScriptApiInstance.deleteScript({ episodeId: targetEpisode.id!, hardDelete: true });
            alert("스크립트 삭제 완료");
            refetchScripts();
          } catch (error: any) {
            alert(error.message);
          }
        }
      }
    },
    [scripts, refetchScripts]
  );

  const restoreScript = useCallback(
    async (rowData: any) => {
      const episodeId = rowData.id;
      const targetEpisode = scripts.find((s) => s.id === episodeId);
      if (targetEpisode) {
        await ScriptApiInstance.restoreScript(targetEpisode.id!);
        alert("스크립트 복구 완료");
        refetchScripts();
      }
    },
    [scripts, refetchScripts]
  );

  return { handleChange, updateScript, validateScript, softDeleteScript, hardDeleteScript, restoreScript };
}
