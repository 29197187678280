import { Button } from "@/components/ui/button";
import { format } from "date-fns/format";
import { useMemo, useState } from "react";
import useAnalyticsStore from "../useAnalyticsStore";
import ActivityFlowItem from "./ActivityFlowItem";

const FIFTEEN_MINUTES = 15 * 60 * 1000;

export default function ActivityFlow() {
    const { user, userReport, userAttendanceSessionLogs } = useAnalyticsStore();
    const [sessionSelectedIndex, setSessionSelectedIndex] = useState(0);

    const sessions = useMemo(() => {
        if (!userAttendanceSessionLogs || userAttendanceSessionLogs.length === 0) return [];

        return userAttendanceSessionLogs.reduce((acc, log, index, array) => {
            if (index === 0 || new Date(log.createdAt).getTime() - new Date(array[index - 1].createdAt).getTime() > FIFTEEN_MINUTES) {
                acc.push([]);
            }
            acc[acc.length - 1].push(log);
            return acc;
        }, []);
    }, [userAttendanceSessionLogs]);

    const userReportSummary = useMemo(() => {
        if (!userReport) return {};

        return {
            "User": userReport.user.name,
            "Sign-up": format(userReport.user.createdAt, "yyyy-MM-dd"),
            "Last Sign-in": format(userReport.user.lastLogin, "yyyy-MM-dd"),
            "Total Attendances": userReport.attendanceCount,
            "Episode Plays": userReport.episodePlays,
        };
    }, [userReport]);

    if (user && userReport && userAttendanceSessionLogs)
        return (
            <div className="flex-col gap-8 p-4">

                <section>
                    <div className="grid grid-cols-6 gap-4 w-4/6">
                        {Object.entries(userReportSummary).map(([key, value]) => (
                            <section key={key} className="flex-col">
                                <div className="text-sm text-slate-400">{key}</div>
                                <div className="text-md font-medium text-slate-800 text-ellipsis overflow-hidden whitespace-nowrap">{value}</div>
                            </section>
                        ))}
                    </div>
                </section>

                <section className="flex-col gap-1 w-1">
                    <div className="text-sm text-slate-400">Sessions</div>
                    <div className="flex flex-row gap-2">
                        {sessions.map((_, index) => (
                            <Button key={index} className="" variant={`${sessionSelectedIndex == index ? "default" : "outline"}`} onClick={() => setSessionSelectedIndex(index)}>
                                {format(sessions[index][0].createdAt, "hh:mm:ss a")}
                            </Button>
                        ))}
                    </div>
                </section>

                {sessions[sessionSelectedIndex] && (
                    <section>
                        <div className="flex flex-row gap-2 overflow-x-auto">
                            {sessions[sessionSelectedIndex].map((log, index, array) => (
                                <ActivityFlowItem
                                    key={log.id}
                                    item={log}
                                    timeDelta={
                                        index < array.length - 1
                                            ? new Date(array[index + 1].createdAt).getTime() - new Date(log.createdAt).getTime()
                                            : undefined
                                    }
                                />
                            ))}
                        </div>
                    </section>
                )}

            </div>
        );

}