import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
    ChartConfig,
    ChartContainer
} from "@/components/ui/chart";
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts";

const chartConfig = {
    desktop: {
        label: "Desktop",
        color: "hsl(var(--chart-1))",
    },
} satisfies ChartConfig

export default function AcquisitionGraph({ chartData }: { chartData: any[] }) {
    return (
        <Card>
            <CardHeader>
                <CardTitle>Weekly Sign-ups</CardTitle>
                <CardDescription>Last 12 weeks</CardDescription>
            </CardHeader>
            <CardContent>
                <ChartContainer config={chartConfig}>
                    <BarChart accessibilityLayer data={chartData}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey="date"
                            tickLine={false}
                            tickMargin={4}
                            axisLine={false}
                            angle={-45}
                            fontSize={12}
                            textAnchor="end"
                            tickFormatter={(value) => value.slice(0, 5)}
                        />
                        {/* <ChartTooltip
                            cursor={false}
                            content={<ChartTooltipContent hideLabel />}
                        /> */}

                        <Bar dataKey="value" fill="var(--color-desktop)" radius={8}>
                            <LabelList
                                position="top"
                                offset={12}
                                className="fill-foreground"
                                fontSize={12}

                            />
                        </Bar>
                    </BarChart>
                </ChartContainer>
            </CardContent>
            {/* <CardFooter className="flex-col items-start gap-2 text-sm">
                <div className="flex gap-2 font-medium leading-none">
                    Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
                </div>
                <div className="leading-none text-muted-foreground">
                    Showing WAU trend for the last 12 weeks.
                </div>
            </CardFooter> */}
        </Card>
    )

}