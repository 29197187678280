import { Dialog } from "business_logic/script/scriptModels";
import { useCreateScriptStore } from "business_logic/script/scriptStore";
import CustomTable, { Column } from "presentation/components/customTable/customTable";
import IsError from "presentation/components/feedbacks/Error";
import IsLoading from "presentation/components/feedbacks/Loading";
import { useEffect } from "react";
import useCreateContent from "./hooks/useCreateContent";

const CreateScriptStep6 = ({ handleDataChange }) => {

  const createScriptStore = useCreateScriptStore();
  const { createQuizExplanation } = useCreateContent(createScriptStore);
  const { dialogs, upsertDialog, scriptingStep, addCompletedStep, isStepCompleted } = createScriptStore;


  useEffect(() => {
    if (!isStepCompleted()) {
      createQuizExplanation.mutateAsync();
      addCompletedStep(scriptingStep);
    }
  }, [createQuizExplanation]);

  const columns: Column<Dialog>[] = [
    { header: "턴", field: "dialogTurn", width: "2.5vw", editable: false },
    { header: "캐릭터", field: "characterName", width: "6vw", editable: false },
    { header: "대사", field: "targetScript", width: "18vw", editable: false },
    { header: "정답선택지", field: `quizWord`, width: "8vw", editable: false },
    { header: "오답선택지 1", field: `multipleChoices-0`, width: "8vw", editable: (dialog: Dialog) => !!dialog.quizWord },
    { header: "오답선택지 2", field: `multipleChoices-1`, width: "8vw", editable: (dialog: Dialog) => !!dialog.quizWord },
    { header: "오답선택지 3", field: `multipleChoices-2`, width: "8vw", editable: (dialog: Dialog) => !!dialog.quizWord },
    { header: "오답해설", field: `quizExplanationKo`, width: "28vw", editable: (dialog: Dialog) => !!dialog.quizWord },
    { header: "재생성", renderType: 'createQuizExplanationButton', width: '4vw', editable: (dialog: Dialog) => !!dialog.quizWord }
  ];

  if (createQuizExplanation.isLoading) return <IsLoading />;
  if (createQuizExplanation.isError) return <IsError />;
  if (isStepCompleted() || createQuizExplanation.isSuccess) return (
    <CustomTable<Dialog>
      data={dialogs}
      columns={columns}
      rowHeight="96px"
      handleTextChange={handleDataChange}
      customProps={{ upsertDialog }}
    />)
};

export default CreateScriptStep6;
