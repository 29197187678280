import { format } from "date-fns/format";
import useAnalyticsStore from "../useAnalyticsStore";
import { SessionsView } from "./SessionDrawer";
import { UserAttendanceWithUserInfo } from "business_logic/analytics/analyticsModels";
import { useSearchParams } from "react-router-dom";

export default function SessionSearchResult({ sessionsView }: { sessionsView: SessionsView }) {

    const { date, attendances, attendanceSessionSelected, setAttendanceSessionSelected, fetchUserReport, fetchAttendanceSessionLogs } = useAnalyticsStore();
    const [_, setSearchParams] = useSearchParams();

    const selectAttendanceSession = (item: UserAttendanceWithUserInfo) => {
        setAttendanceSessionSelected(item);
        setSearchParams(prev => {
            prev.set('attendanceSessionId', item.id.toString());
            return prev;
        }, { replace: true });
        fetchUserReport();
        fetchAttendanceSessionLogs();
    }

    if (attendances)
        return (
            <div className="mt-4">

                <section className="text-l font-semibold my-4">
                    {sessionsView === 'sessionByDate'
                        ? format(date, 'yyyy.MM.dd')
                        : 'Users Sessions'
                    }
                </section>

                <section>
                    {attendances.length === 0 && <div className="text-sm text-gray-500">No sessions found</div>}
                    <ul className="space-y-2">
                        {attendances.map((item, index) => (
                            <li key={index}
                                className={` flex justify-between pl-8 cursor-pointer hover:bg-gray-200 p-2 rounded-md ${attendanceSessionSelected && item.id === attendanceSessionSelected.id ? 'bg-gray-200' : ''}`}
                                onClick={() => selectAttendanceSession(item)}
                            >
                                <span className="text-sm text-ellipsis">{item.user.name}</span>
                                <span className="text-sm text-gray-500">d+{item.count}</span>
                            </li>
                        ))}
                    </ul>
                </section>

            </div>
        )
}