import "react-tabs/style/react-tabs.css";
import "reactjs-popup/dist/index.css";
import "styles/buttons.scss";
import "styles/global.scss";
import "styles/layouts.scss";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import UploadSeriesAssetTab from "./components/UploadSeriesAssetTab";
import UploadSoundsTab from "./components/UploadSoundsTab";
import UploadWordImageTab from "./components/UploadWordImagesTab";
import "./uploadAsset.scss";

export default function UploadAssetPage() {

  return (
    <div className="page page-upload-asset">
      <h1 className="h1">업로드</h1>
      <section className="section-tabs">
        <Tabs>
          <TabList>
            <Tab>시리즈 이미지</Tab>
            <Tab>토익단어 이미지</Tab>
            <Tab>배경음악</Tab>
          </TabList>
          <TabPanel>
            <UploadSeriesAssetTab />
          </TabPanel>
          <TabPanel>
            <UploadWordImageTab />
          </TabPanel>
          <TabPanel>
            <UploadSoundsTab />
          </TabPanel>
        </Tabs>
      </section>
    </div>

  );
}
