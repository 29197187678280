import React, { useState } from 'react';
import { AudioCreateButton, AudioPlayButton, CreateDialogTranslationButton, CreateMultipleChoicesButton, CreateQuizExplanationButton, CreateTranslationQuizChoicesButton } from './components/Buttons';
import "./customTable.scss";
import { Textarea } from '@/components/ui/textarea';

type RenderType = 'text' | 'button' | 'createAudioButton' | 'playAudioButton' | 'playMaskAudioButton' | 'image' | 'createQuizExplanationButton' | 'createDialogTranslationButton' | 'createMultipleChoicesButton' | 'createTranslationQuizChoicesButton';

export interface Column<T> {
    header: string;
    field?: keyof T | string;
    width?: string;
    editable?: boolean | ((item: T) => boolean);
    renderType?: RenderType;
}

export interface HandleChangeProps<T> {
    field: keyof T | string;
    index: number;
    event: React.ChangeEvent<HTMLTextAreaElement>;
}

export interface CustomTableProps<T> {
    data: T[];
    columns: Column<T>[];
    rowHeight: string;
    handleTextChange: ({ field, index, event }: HandleChangeProps<T>) => void;
    customProps?: any;
}

function CustomTable<T>({ data, columns, rowHeight, handleTextChange, customProps }: CustomTableProps<T>) {

    const getFieldValue = (item: T, field: keyof T | string): string => {
        if (typeof field === 'string' && field.includes('-')) {
            const [arrayField, index] = field.split('-');
            return String(((item[arrayField as keyof T] as unknown) as string[])?.[Number(index)] || '');
        }
        return String((item[field as keyof T] as unknown) || '');
    };

    const renderCell = (item: T, column: Column<T>, rowIndex: number, rowHeight: string) => {
        const value = getFieldValue(item, column.field);
        const isEditable = column.editable === undefined ? true : typeof column.editable === 'function' ? column.editable(item) : column.editable;

        switch (column.renderType) {
            case 'playAudioButton':
                return <AudioPlayButton key={String(column.header) + rowIndex} item={item} height={rowHeight} />;
            case 'playMaskAudioButton':
                return <AudioPlayButton key={String(column.header) + rowIndex} item={item} height={rowHeight} maskAudio={true} />;
            case 'createAudioButton':
                return <AudioCreateButton key={String(column.header) + rowIndex} item={item} height={rowHeight} seriesId={customProps.seriesId} episodeId={customProps.episodeId} audioSettings={customProps.audioSettings} updateItem={customProps.updateItem} />
            case 'createQuizExplanationButton':
                return <CreateQuizExplanationButton key={String(column.header) + rowIndex} item={item} height={rowHeight} updateItem={customProps.upsertDialog} disabled={!isEditable} />
            case 'createDialogTranslationButton':
                return <CreateDialogTranslationButton key={String(column.header) + rowIndex} item={item} height={rowHeight} updateItem={customProps.upsertDialog} disabled={!isEditable} translationSpeechStyle={customProps.translationSpeechStyle} />
            case 'createMultipleChoicesButton':
                return <CreateMultipleChoicesButton item={item} height={rowHeight} updateItem={customProps.upsertDialog} disabled={!isEditable} />
            case 'createTranslationQuizChoicesButton':
                return <CreateTranslationQuizChoicesButton item={item} height={rowHeight} updateItem={customProps.upsertDialog} disabled={!isEditable} />

            case 'image':
                return null; // Implement image rendering if needed
            default:
                return (
                    <Textarea
                        key={String(column.field) + rowIndex}
                        className="custom-table-textarea"
                        style={{
                            width: column.width,
                            height: rowHeight,
                            resize: 'none',
                            backgroundColor: isEditable ? 'white' : '#f2f2f2',
                        }}
                        value={value}
                        readOnly={!isEditable}
                        onChange={(event) => handleTextChange({ field: column.field, index: rowIndex, event })}
                    />
                );
        }
    };

    return (
        <table className="custom-table-layout" style={{ borderCollapse: 'collapse' }}>
            <thead className="header">
                <tr key="header">
                    {columns.map((column, index) => (
                        <th key={`header-${index}`} style={{ width: column.width, }}>{column.header}</th>
                    ))}
                </tr>
            </thead>
            <tbody className="body">
                {data.map((item, rowIndex) => (
                    <tr key={`row-${rowIndex}`}>
                        {columns.map((column, colIndex) => (
                            <td key={`cell-${rowIndex}-${colIndex}`}>
                                {renderCell(item, column, rowIndex, rowHeight)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default CustomTable;