import React, { useState, useEffect } from 'react';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogCancel, AlertDialogAction } from '@/components/ui/alert-dialog';
import { Progress } from '@/components/ui/progress';
import { useToast } from '@/components/ui/use-toast';
import { useCreateScriptStore } from 'business_logic/script/scriptStore';
import useCreateContent from '../hooks/useCreateContent';

const ProgressDialog = ({ open, onClose, onOpenChange }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [progressMessage, setProgressMessage] = useState('');
    const { toast } = useToast();
    const createScriptStore = useCreateScriptStore();
    const { createAll } = useCreateContent(createScriptStore);

    const handleConfirm = async () => {
        setIsProcessing(true);
        try {
            for await (const { progress, message } of createAll()) {
                setProgressValue(progress);
                setProgressMessage(message);
            }
            toast({
                title: 'Success',
                description: 'Script created successfully.',
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to create script: ' + error.message,
                variant: 'destructive',
            });
        } finally {
            setIsProcessing(false);
            createScriptStore.resetScript();
            onOpenChange();
        }
    };

    return (
        <AlertDialog open={open} >
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>스크립트 자동 생성</AlertDialogTitle>
                    <AlertDialogDescription>
                        남은 단계부터 자동 생성, 끝나고 스크립트가 자동 추가됩니다. 자동 생성하시겠습니까?
                    </AlertDialogDescription>
                </AlertDialogHeader>
                {isProcessing && (
                    <div className="automation-progress">
                        <Progress value={progressValue} className="w-full" />
                        <p className="text-left mt-2 text-xs text-slate-500">{progressMessage}</p>
                    </div>
                )}
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={onClose} disabled={isProcessing}>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={handleConfirm} disabled={isProcessing}>
                        {isProcessing ? 'Processing...' : 'Confirm'}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default ProgressDialog;