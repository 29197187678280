import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Play, Plus, ShieldCheck } from "lucide-react";
import { useNavigate } from "react-router-dom";
import EditDialogImage from "./tabs/EditDialogImage";
import EditDialogQuiz from "./tabs/EditDialogQuiz";
import EditDialogText from "./tabs/EditDialogText";
import EditDialogWordLink from "./tabs/EditDialogWordLink";
import EditEpisodePreview from "./tabs/EditEpisodePreview";
import EditEpisodeWordCards from "./tabs/editEpisodeWordCards/EditEpisodeWordCard";
import useEditScriptStore from "./useEditScriptStore";
import Loading from "presentation/components/feedbacks/Loading";
import { Combobox } from "@/components/ui/combobox";
import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import EpisodePlayDialog from "presentation/dialogs/EpisodePlayDialog";
import { useDialog } from "hooks/useDialog";

export default function EditScriptPage() {
  const navigate = useNavigate();
  const { seriesId, episodeId } = useParams();
  const [episodes, setEpisodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { dialogs, openDialog, closeDialog } = useDialog();

  const { series, fetchSeriesById, fetchEpisode, fetchDialogTurns, dialogTurns, episode } = useEditScriptStore();

  // Upon initial mount
  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);

      try {
        await fetchSeriesById(seriesId);
        await fetchEpisode(episodeId);
        await fetchDialogTurns();

        const fetchedEpisodes = await EpisodeApiInstance.getEpisodesBySeries({ seriesId });
        const seriesTitle = useEditScriptStore.getState().series?.title;
        const comboBoxEpisodes = fetchedEpisodes.map((ep) => ({
          value: ep.id,
          label: `${seriesTitle} ${ep.index}화`,
          index: ep.index
        }));
        setEpisodes(comboBoxEpisodes.sort((a, b) => a.index - b.index));
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, [seriesId, fetchSeriesById]);

  // Upon episode change
  useEffect(() => {

    const loadEpisodeData = async () => {
      setIsLoading(true);
      try {
        await fetchEpisode(episodeId);
        await fetchDialogTurns();
      } catch (error) {
        alert(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadEpisodeData();
  }, [episodeId, fetchEpisode, fetchDialogTurns]);


  const navigateToValidation = () => {
    const url = `/script/validate/${episodeId}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const navigateToCreateNewScript = () => {
    const url = `/script/create`;
    window.open(url, '_blank', 'noopener,noreferrer');
  }
  const handleEpisodeChange = (selectedEpisode) => {
    navigate(`/script/edit/series/${seriesId}/episode/${selectedEpisode.value}`);
  };
  const handleEpisodePlayClick = useCallback(() => { openDialog("episodePlay"); }, [episodeId]);


  if (isLoading) return <div><Loading /></div>;
  if (series && episode && !isLoading)

    return (
      <div className="relative h-full overflow-hidden border-r border-gray-200">
        <div className="mt-16 h-[calc(100%)] overflow-y-auto scrollbar-hide p-4 flex flex-col gap-4">
          <Tabs defaultValue="episodePreview" className="h-full">

            <header className="absolute top-0 left-0 right-0 flex justify-between items-center h-16 px-4 bg-white z-10 border-b border-gray-200 ">
              <div className="flex-row flex items-center gap-8">
                <Combobox
                  hintText="Select Episode"
                  items={episodes}
                  setItem={handleEpisodeChange}
                  value={episodeId}
                />
                <TabsList key={`tab-list-${episode.index}`} variant="underline">
                  <TabsTrigger variant='underline' value="episodePreview">Episode Preview</TabsTrigger>
                  {dialogTurns?.length > 0 && <>
                    <TabsTrigger variant='underline' value="images">Images</TabsTrigger>
                    <TabsTrigger variant='underline' value="dialogs">Dialogs</TabsTrigger>
                    <TabsTrigger variant='underline' value="quizWords">Quiz</TabsTrigger>
                    <TabsTrigger variant='underline' value="quizCards">Quiz Images</TabsTrigger>
                    <TabsTrigger variant='underline' value="wordLink">Word Link</TabsTrigger>
                  </>}
                </TabsList>
              </div>
              <div className="flex flex-row gap-4">
                {dialogTurns?.length === 0 ? (
                  <Button onClick={navigateToCreateNewScript}><Plus size={20} /> 스크립트 생성</Button>
                ) : (
                  <>
                    <Button className="gap-2" onClick={navigateToValidation}><ShieldCheck size={16} /> Validation</Button>
                    <Button className="gap-2" variant="vocatale" onClick={handleEpisodePlayClick}><Play size={16} /> Play Mode</Button>
                    <EpisodePlayDialog
                      key={`${episodeId}`}
                      open={dialogs["episodePlay"] || false}
                      onClose={() => closeDialog("episodePlay")}
                      initialDialogTurns={dialogTurns}
                      episodeId={episodeId}
                    />
                  </>
                )}
              </div>
            </header>

            <section key={seriesId} className="h-[calc(100%-4rem)] w-full overflow-y-scroll scrollbar-hide pt-0">
              <TabsContent value="episodePreview"> <EditEpisodePreview /></TabsContent>
              {dialogTurns &&
                <>
                  <TabsContent value="images"><EditDialogImage /></TabsContent>
                  <TabsContent value="dialogs"><EditDialogText /></TabsContent>
                  <TabsContent value="quizWords"><EditDialogQuiz /></TabsContent>
                  <TabsContent value="quizCards"><EditEpisodeWordCards /></TabsContent>
                  <TabsContent value="wordLink"><EditDialogWordLink /></TabsContent>
                </>}
            </section>
          </Tabs>
        </div>
      </div>
    );
}
