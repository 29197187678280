import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

// Define the props interface
interface SaveButtonProps {
  handleClick: () => void;
}

// React functional component with proper props
const SaveButton: React.FC<SaveButtonProps> = ({ handleClick }) => {
  return (
    <button className="script-button-action justify-center align-center text-center flex-row" onClick={handleClick}>
      <FontAwesomeIcon icon={faSave} />
      <div className="button-label margin-left-16">저장하기</div>
    </button>
  );
};

export default SaveButton;
