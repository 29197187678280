import { useCreateScriptStore } from "business_logic/script/scriptStore";
import { useEffect } from "react";
import SelectEpisode from "presentation/components/selectContent/SelectEpisode";
import SelectSeriesPoster from "presentation/components/selectContent/SelectSeriesPoster";

export default function CreateScriptStep1() {
  const { series, episode, setSeries, setEpisode } = useCreateScriptStore();

  useEffect(() => {
    setEpisode(null);
  }, [series]);

  return (
    <div className="flex-col max-height justify-between gap-32">
      {series && <SelectEpisode key={series.id} series={series} selectedEpisode={episode} setSelectedEpisode={setEpisode} emptyEpisodesOnly={true} />}
      <SelectSeriesPoster selectedSeries={series} setSelectedSeries={setSeries} />
    </div>
  );
};

