import SeriesApiInstance from "business_logic/series/SeriesApi";
import { Series } from "business_logic/series/seriesModels";
import { useQuery } from "react-query";
import IsError from "../feedbacks/Error";
import IsLoading from "../feedbacks/Loading";

import "./selectContent.scss";

interface Props {
    selectedSeries: Series;
    setSelectedSeries: (selectedSeries: Series) => void;
}

export default function SelectSeries({ selectedSeries, setSelectedSeries }: Props) {

    const fetchSeries = useQuery("activeSeries", async () => {
        return await SeriesApiInstance.getSeries("active") as Series[];
    });

    function handleSeriesSelect(series: Series) {
        setSelectedSeries(series);
    }

    if (fetchSeries.isLoading) return <IsLoading />;
    if (fetchSeries.isError) return <IsError />;
    if (fetchSeries.data) {
        return (
            <div className="select-series display-flex flex-start flex-col gap-8">
                <div className="h2">시리즈를 선택하세요</div>
                <div className="series-poster-list overflow-x-scroll flex-row gap-16">
                    {fetchSeries.data.map((series) => (
                        <div
                            className={`series-poster flex-col gap-8 align-center ${selectedSeries && selectedSeries.id === series.id ? "selected" : ""}`}
                            key={series.id}
                            onClick={() => handleSeriesSelect(series)}
                        >
                            <img src={series.posterImageUrl} alt={series.title} />
                            <div>{series.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}