import { Series } from "business_logic/series/seriesModels";
import UploadApiInstance from "business_logic/uploadAsset/UploadAssetApi";
import SaveButton from "presentation/components/buttons/SaveButton";
import SelectSeriesButtons from "presentation/components/dropdowns/SeriesSelectButtons";
import WorkInProgressOverlay from "presentation/components/workInProgressOverlay/WorkInProgressOverlay";
import { useState } from "react";
import FileUploadDropzone from "presentation/components/dropzone/Dropzone";
import "styles/buttons.scss";
import { Checkbox } from "@/components/ui/checkbox";
import { useToast } from "@/components/ui/use-toast";

export default function UploadSeriesAssetTab() {
  const [series, setSeries] = useState<Series>(null!);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [uploadLocation, setUploadLocation] = useState<string>("upload");
  const [upscale, setUpscale] = useState(false);
  const { toast } = useToast();

  const acceptedFileTypes = ["image/*"];
  const uploadLocations = {
    "upload": "업로드",
    "characters": "캐릭터",
    "characterProfile": "캐릭터 프로필",
    "backgrounds": "배경",
    "episodeThumbnail": "썸네일",
    "seriesPoster": "시리즈 포스터"
  }

  function handleUploadLocationButtonClick(location: string) {
    setUploadLocation(location);
  }

  function handleCheckboxChange(checked: boolean) {
    setUpscale(checked);
  };

  async function handleUpload() {
    try {
      setIsLoading(true);

      if (files.length > 0) {
        if (upscale) {
          await UploadApiInstance.uploadSeriesAsset(files, series.id, true, uploadLocation);
        }
        else {
          await UploadApiInstance.uploadSeriesAsset(files, series.id, false, uploadLocation);
        }
      }
      setFiles([]);
      toast({
        title: "Success",
        description: "업로드 성공"
      })
    } catch (error) {
      toast({
        title: "Failure",
        variant: "destructive",
        description: error.message
      })
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="upload-series-asset-tab max-width">
      <WorkInProgressOverlay isProcessing={isLoading} />
      <SelectSeriesButtons selectedSeries={series!} setSelectedSeries={setSeries} />
      {series && (
        <section className="sections flex-col gap-16">
          <section className="upload-location-section flex-col gap-8">
            <div className="text-2xl font-bold">Upload to: {`/${uploadLocation !== "upload" ? "contents/" : ""}${uploadLocation}`}</div>
            <div className="upload-location-buttons flex-row">
              {Object.entries(uploadLocations).map(([key, value]) => (
                <button key={key} className={`button-upload-location ${key === uploadLocation ? "active" : ""}`} onClick={() => handleUploadLocationButtonClick(key)} >{value}</button>
              ))}
            </div>
          </section>

          <section className="dropzone-section flex-col">
            <div className="flex-row justify-between align-center">
              <div className="text-2xl font-bold">Drop files below : {files.length}개 </div>
              <div className="flex items-center space-x-2">
                <Checkbox id="terms" checked={upscale} onCheckedChange={handleCheckboxChange} />
                <label htmlFor="terms"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Upscale Image
                </label>
              </div>
            </div>
            <hr className="max-width color-border-grey" />
            <FileUploadDropzone files={files} onFilesChange={setFiles} acceptedFileTypes={acceptedFileTypes} checkImagePixels={upscale} />
          </section>
        </section>
      )
      }
      <SaveButton handleClick={handleUpload} />
    </div >
  );
}
