import BaseApi from "business_logic/base/BaseApi";

class AuthApi extends BaseApi {
  async signIn({ email, password }: { email: string; password: string }): Promise<string> {
    const endpoint = `/auth/admin/signin`;
    try {
      // Receive access token upon successful login
      const response = await this.axiosInstance.post(endpoint, { email, password });
      return response.data;
    } catch (error: any) {
      throw Error(error.response.data.detail);
    }
  }

  async signUp({ email, password, code }: { email: string; password: string; code: string }): Promise<void> {
    const endpoint = `/auth/admin/signup`;
    try {
      await this.axiosInstance.post(endpoint, { email, password, code });
    } catch (error: any) {
      throw Error(error.response.data.detail);
    }
  }
}

const AuthApiInstance = new AuthApi();
export default AuthApiInstance;
