import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
    Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import WordApiInstance from "business_logic/word/WordApi";
import { CreateWordRequest } from "business_logic/word/wordModels";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import { useMutation } from "react-query";

export default function CreateWordDialog({ word, open, onClose, onSuccess = () => { } }) {

    const { toast } = useToast();
    const PARTS_OF_SPEECH = ["noun", "verb", "adjective", "adverb", "preposition", "conjunction", "interjection"];
    const [newWordRequest, setNewWordRequest] = useState<Partial<CreateWordRequest>>({
        word: word,
        definition: "",
        pos: "noun",
    });

    const createNewWord = useMutation(
        async () => { await WordApiInstance.createWord(newWordRequest); },
        {
            onSuccess: () => {
                toast({
                    title: "Success",
                    description: `Word ${newWordRequest.word} created successfully`
                })
                onSuccess();
            },
            onError: (error: any) => {
                toast({
                    title: "Failure",
                    description: error.message,
                    variant: "destructive"
                })
            },
            onSettled: () => {
                onClose();
            }
        }
    );

    function handleSubmit() {
        if (Object.values(newWordRequest!).some((value) => !value)) {
            toast({
                title: "Failure",
                description: "Please fill in all fields.",
                variant: "destructive"
            })
            return;
        }
        createNewWord.mutate();
    }

    function handleTextChange(event) {
        const { name, value, type } = event.target;
        const parsedValue = type === "number" ? parseInt(value, 10) : value;
        setNewWordRequest({ ...newWordRequest!, [name]: parsedValue });
    };

    function handleSelectChange(value) {
        setNewWordRequest(prevState => {
            const newState = { ...prevState, pos: value };
            return newState;
        });
    }

    const dictionaryLinkButton = () => {
        return (
            <Button
                variant="outline"
                onClick={() => window.open(`https://www.dictionary.com/browse/${word}`, "_blank")}
            >
                사전 검색
            </Button>
        );
    };

    return (
        <Dialog open={open} onOpenChange={onClose} >
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Create new word</DialogTitle>
                    <DialogDescription>
                        Please confirm the word definition you are about to add does not exist in the dictionary.
                    </DialogDescription>
                </DialogHeader>

                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 align-center gap-4">
                        <Label htmlFor="name" className="text-right"> Word </Label>
                        <Input name="word" className="col-span-3" value={newWordRequest.word} onChange={handleTextChange} />
                    </div>
                    <div className="grid grid-cols-4 align-center  gap-4">
                        <Label htmlFor="definition" className="text-right"> Meaning (english) </Label>
                        <Input name="definition" className="col-span-3" value={newWordRequest.definition} onChange={handleTextChange}
                        />
                        <div className="col-start-2 col-span-3">
                            {dictionaryLinkButton()}
                        </div>
                    </div>
                    <div className="grid grid-cols-4 align-center gap-4">
                        <Label htmlFor="definition" className="text-right"> Parts of Speech </Label>
                        <Select name="pos" value={newWordRequest.pos} onValueChange={handleSelectChange}>
                            <SelectTrigger className="col-span-3">
                                <SelectValue placeholder="Select pos" />
                            </SelectTrigger>
                            <SelectContent >
                                <SelectGroup>
                                    <SelectLabel >Select POS</SelectLabel>
                                    {PARTS_OF_SPEECH.map((item) => (
                                        <SelectItem key={item} value={item}>{item}</SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <DialogFooter>
                    {createNewWord.isLoading ?
                        <Button disabled>
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            Please wait
                        </Button> :
                        <Button onClick={handleSubmit} type="submit">Submit</Button>}
                </DialogFooter>

            </DialogContent>
        </Dialog>
    );
}
