import CharacterApiInstance from "business_logic/character/CharacterApi";
import { Dialog } from "business_logic/script/scriptModels";
import { SeriesCharacter } from "business_logic/series/seriesModels";
import WordApiInstance from "business_logic/word/WordApi";

export default function useValidateScript() {

    const errors = new Set<string>();

    function alertError(errors: Set<string>) {
        errors.size > 0 && alert(Array.from(errors).join("\n"));
    }

    function resetErrors() {
        errors.clear();
    }

    async function validateMultipleQuizChoices(dialogs: Dialog[]) {
        resetErrors();

        for (const dialog of dialogs) {
            if (dialog.quizWord) {
                if (!dialog.multipleChoices!.every((choice) => choice)) {
                    errors.add(`턴${dialog.dialogTurn} - Multiple choices fields cannot be empty.`);
                }
                if (errors.size === 0)
                    try {
                        await WordApiInstance.checkWordExists(dialog.multipleChoices);
                    } catch (error) {
                        errors.add(error);
                    }
            }
        }
        alertError(errors);
        return errors.size === 0 ? true : false;
    }

    async function validateTranslationQuizChoices(dialogs: Dialog[]) {
        resetErrors();

        for (const dialog of dialogs) {
            if (dialog.translationQuizAnswer) {
                dialog.translationQuizChoices.forEach((choice, index) => {
                    if (choice === "") {
                        errors.add(`턴${dialog.dialogTurn} - Fill in both choices or none.`);
                    }
                });
            }
            if (dialog.translationQuizAnswer === "") {
                dialog.translationQuizChoices.forEach((choice, index) => {
                    if (choice !== "") {
                        errors.add(`턴${dialog.dialogTurn} - Fill in both choices or none.`);
                    }
                });
            }
        }
        alertError(errors);
        return errors.size === 0 ? true : false;
    }

    async function validateCreateScriptStep(createScriptStore): Promise<boolean> {
        const errors = new Set<string>();
        const { series, episode, scriptingStep, rawScript, dialogs, addCompletedStep } = createScriptStore;

        switch (scriptingStep) {
            case 1:
                if (!series) {
                    errors.add("시리즈를 선택해주세요.");
                }
                if (!episode) {
                    errors.add("에피소드를 선택해주세요.");
                }
                break;
            case 2:
                if (!rawScript || rawScript === "") {
                    errors.add("스크립트를 입력해주세요.");
                }
                break;
            case 3:
                const wordsToCheck = [];

                if (!dialogs || dialogs.length === 0) {
                    errors.add("대사를 입력해주세요.");
                    break;
                } else {
                    const response = await CharacterApiInstance.getSeriesCharacters(series.id);
                    const validCharacterIds = response.map((character: SeriesCharacter) => character.internalId);

                    dialogs.forEach(dialog => {
                        if (!validCharacterIds.includes(dialog.characterName)) {
                            errors.add(`${dialog.characterName}는 등록 안된 캐릭터입니다.`);
                        }
                        if (dialog.characterName !== "narration" && dialog.objectImage !== "") {
                            errors.add("narration이 아닌 경우 사물 이미지를 넣을 수 없습니다.");
                        }
                        if (dialog.quizWord) {
                            if (!dialog.targetScript.toLowerCase().includes(dialog.quizWord.toLowerCase())) {
                                errors.add(`턴${dialog.dialogTurn} - ${dialog.quizWord}는 대사의 일부여야 합니다.`);
                            }
                            if (!dialog.quizWordBase) {
                                errors.add(`턴${dialog.dialogTurn} - 퀴즈단어원형이 있어야 합니다.`);
                            }
                            wordsToCheck.push(dialog.quizWordBase);
                        }
                        else if (!dialog.quizword) {
                            if (dialog.quizWordBase) {
                                errors.add(`턴${dialog.dialogTurn} - 퀴즈단어원형은 비어있어야 합니다.`);
                            }
                        }
                    });

                    if (errors.size === 0) {
                        try {
                            await WordApiInstance.checkWordExists(wordsToCheck);
                        } catch (error) {
                            errors.add(error.message);
                        }
                    }
                }
                break;
            case 4:
                dialogs.forEach(dialog => {
                    if (dialog.baseScript === "") {
                        errors.add(`턴${dialog.dialogTurn} - 대사 번역을 입력해주세요.`);
                    }
                    if (dialog.quizWord) {
                        if (!dialog.targetScript.includes(dialog.quizWord)) {
                            errors.add(`턴${dialog.dialogTurn} - '${dialog.quizWord}'는 대사의 일부여야 합니다.`);
                        }
                        if (!dialog.highlightedTranslation || !dialog.baseScript.includes(dialog.highlightedTranslation)) {
                            errors.add(`턴${dialog.dialogTurn} - '${dialog.highlightedTranslation}'는 번역 문장의 일부여야 합니다.`);
                        }
                    } else {
                        if (dialog.highlightedTranslation) {
                            errors.add(`턴${dialog.dialogTurn} - 빈칸단어 번역을 제거해주세요.`);
                        }
                    }
                });
                break;
            case 5:
                let multiple_choices = dialogs!
                    .filter((dialog) => dialog.quizWord)
                    .map((dialog) => dialog.multipleChoices)
                    .flat();

                try {
                    await WordApiInstance.checkWordExists(multiple_choices);
                } catch (error) {
                    errors.add(error.message);
                    break;
                }
                dialogs.forEach(dialog => {
                    if (dialog.quizWord) {
                        dialog.multipleChoices.forEach((choice, index) => {
                            if (!choice) {
                                errors.add(`턴${dialog.dialogTurn} - 퀴즈 선택지 3개를 모두 입력해주세요.`);
                            }
                        });
                    }
                }
                )
                break;
            case 6:
                dialogs.forEach(dialog => {
                    if (dialog.quizWord) {
                        if (!dialog.quizExplanationKo || !dialog.quizExplanationEn) {
                            errors.add(`턴${dialog.dialogTurn} - 해설을 입력해주세요.`);
                        }
                    } else {
                        if (dialog.quizExplanationKo || dialog.quizExplanationEn) {
                            errors.add(`턴${dialog.dialogTurn} - 해설을 제거해주세요.`);
                        }
                    }
                });
                break;
            case 7:
                break;
            default:
                break;
        }
        alertError(errors);
        addCompletedStep(scriptingStep);
        return errors.size === 0;
    };
    return { validateCreateScriptStep, validateMultipleQuizChoices, validateTranslationQuizChoices };
}