import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WordCardInfoItem } from "business_logic/word/wordModels";

interface WordCardDetailsProps {
  wordCardInfo: WordCardInfoItem;
  setNewFeatureWordInfoDefinition?: (definitionId: string) => void;
  handleImagePromptInputChange?: (wordCardInfoItem: WordCardInfoItem, imagePrompt: string) => void;
  createNewWordCardImage?: (wordCardInfoItem: WordCardInfoItem) => void;
  loading: boolean;
}

export default function WordCardDetails({ wordCardInfo, setNewFeatureWordInfoDefinition, handleImagePromptInputChange, createNewWordCardImage, loading }: WordCardDetailsProps) {

  const imageGenerationInProgress = wordCardInfo.wordCardImageUrls.length !== 0 && wordCardInfo.wordCardImageUrls[0].includes("generating") || false;

  const label = (label: string) => {
    return <div className="label">{label}</div>;
  };

  const item = (item: any) => {
    return <div className="item">{item}</div>;
  };

  const promptPostfix = "Manhwa art style, ultra high quality, various colors --ar 5:6 --v 6";

  return (
    <div className="component-word-card-detail flex-col justify-between">
      <div className="upper-area">
        <div className="detail-item script-container flex-row">
          {label("대사")}
          {item(wordCardInfo.targetScript)}
        </div>
        <div className="detail-item quiz-word-container flex-row">
          {label("퀴즈")}
          {item(wordCardInfo.quizWord)}
        </div>
        <div className="detail-item definitions-container flex-row">
          {label("뜻")}
          {item(
            wordCardInfo.wordDefinitions.map((definition) => {
              return (
                <button
                  key={definition.id}
                  className={`definition-container flex-row ${definition.id === wordCardInfo.featureWordInfo.wordDefinition.id ? "selected" : ""}`}
                  disabled={definition.id === wordCardInfo.featureWordInfo.wordDefinition.id}
                  onClick={async () => {
                    await setNewFeatureWordInfoDefinition(definition.id)
                  }}
                >
                  {definition.imageUrl ? <FontAwesomeIcon icon={faImage} /> : ""}
                  {definition.definition}
                </button>
              );
            })
          )}
        </div>
        <div className="detail-item prompt-container">
          {label("Prompt")}
          {item(<Textarea className="prompt-input max-width" placeholder={promptPostfix + " will be added automatically."}
            value={wordCardInfo.imagePrompt || ""} onChange={(e) => handleImagePromptInputChange(wordCardInfo, e.target.value)} />)}
        </div>
        {/* <div className="detail-item prompt-helper-container">
          {label("Postfix")}
          {item(<div className=" max-width">{promptPostfix}</div>)}
        </div> */}
      </div>
      <div className="lower-area">
        <div className="button-create-image">
          <Button variant="outline" className="w-full" onClick={() => createNewWordCardImage(wordCardInfo)} disabled={loading || imageGenerationInProgress}>{loading ? <div>이미지 생성</div> : <div>이미지 생성</div>}</Button>
        </div>
      </div>
    </div>
  );
}
