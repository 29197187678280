import { create } from "zustand"


type AlertDialogStore = {
    isOpen: boolean;
    setOpen: () => void;
    setClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
}

export const useAlertDialog = create<AlertDialogStore>(set => ({
    isOpen: false,
    setOpen: () => set({ isOpen: true }),
    setClose: () => set({ isOpen: false }),
    onConfirm: () => set({ isOpen: false }),
    onCancel: () => set({ isOpen: false })
}));
