import BaseSelect from "presentation/components/select/BaseSelect";
import { useEffect, useState } from "react";

interface Props {
    createScriptStore: any;
    lastScriptStep: number;
    handleClickNext: () => Promise<void>;
    handleClickPrevious: () => void;
    handleClickSubmit: () => void;
}

export default function NavigationBar({ createScriptStore, lastScriptStep, handleClickNext, handleClickPrevious, handleClickSubmit }: Props) {

    const { series, episode, currentScriptingStep, translationSpeechStyle, setTranslationSpeechStyle } = createScriptStore;
    const [isVisible, setIsVisible] = useState(false);

    const handleSelectChange = (value) => {
        setTranslationSpeechStyle(value);
    };

    useEffect(() => {
        const handleMouseMove = (event) => {
            const threshold = window.innerHeight - 50;
            setIsVisible(event.clientY > threshold);
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);


    return (
        <div className={`create-script-navigation-bar align-center ${isVisible ? 'visible' : ''}`}>
            {/* <div className="drawer-icon"><FontAwesomeIcon icon={faAnglesUp} /></div> */}
            <div className="flex-row gap-16 align-center">
                <div className="script-info-container">
                    {series && series.title} {episode && episode.index + '화'} - {episode && episode.title}
                </div>
                <div className="speech-style-selector flex-row">
                    <BaseSelect label="Speech Style" value={translationSpeechStyle} onValueChange={handleSelectChange} items={["honorific", "semi-formal", "casual"]} />
                </div>
            </div>
            <div className="buttons-container flex-row justify-between align-center">
                {currentScriptingStep > 1 && <button className={`button button-back ${currentScriptingStep === 0 && "hidden"}`} onClick={handleClickPrevious}>뒤로</button>}
                {currentScriptingStep !== lastScriptStep && <button className={`button button-next ${currentScriptingStep === lastScriptStep && "hidden"}`} onClick={handleClickNext}>다음</button>}
                {currentScriptingStep === lastScriptStep && <button className={`button button-submit`} onClick={handleClickSubmit}>저장하기</button>}
            </div>
        </div>
    )
}