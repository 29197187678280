import DashboardApiInstance from "business_logic/dashboard/DashboardApi";
import Loading from "presentation/components/feedbacks/Loading";
import { useState } from "react";
import { useQuery } from "react-query";
import { EngagementGraph } from "./EngagementGraph";

export default function EngagementTab() {
    const [chartData, setChartData] = useState<any[]>([]);

    const fetchSeriesEngagementStats = useQuery('fetchSeriesEngagementStats', async () => DashboardApiInstance.fetchSeriesEngagementStats(),
        {
            enabled: true,
            refetchInterval: 600000,
            onSuccess: (data) => {
                setChartData(data);
            }
        },
    );

    if (fetchSeriesEngagementStats.isLoading) return <div><Loading /></div>
    if (fetchSeriesEngagementStats.isError) return <div>Error</div>
    return (
        <div className="flex-col gap-4 h-[90vh]">
            <section className="w-full">
                <EngagementGraph chartData={chartData} />
            </section>
        </div >
    );
}