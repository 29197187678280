import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import ActivityFlowTab from "./activityFlowTab/ActivityFlowTab";

export default function AnalyticsPage() {
    return (
        <div className="relative h-full overflow-hidden border-r border-gray-200">
            <div className="mt-16 h-[calc(100%)] overflow-y-auto scrollbar-hide flex flex-col gap-4">
                <Tabs defaultValue="userActivity" className="h-full">
                    <section className="absolute top-0 left-0 right-0 flex justify-between items-center h-16 px-4 bg-white z-10 border-b border-gray-200 ">
                        <div className="flex-row flex items-center gap-24">
                            <div className="text-xl font-bold">Analytics</div>
                            <TabsList variant="underline">
                                <TabsTrigger className="text-s px-0" variant='underline' value="userActivity">User Attendance Sessions</TabsTrigger>
                            </TabsList>
                        </div>
                    </section>

                    <section key={"change"} className="h-[calc(100%-4rem)] w-full overflow-y-scroll scrollbar-hide pt-0">
                        <TabsContent key="userActivity" value="userActivity">
                            <ActivityFlowTab />
                        </TabsContent>
                    </section>
                </Tabs>
            </div>
        </div>
    );
}