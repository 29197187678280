import BaseApi from "business_logic/base/BaseApi";
import { DataStatus } from "business_logic/base/baseModels";
import { EpisodeStatusEnum } from "business_logic/episode/episodeModels";
import {
  CreateDialogAudioRequest,
  CreateDialogAudioResponse,
  CreateDialogTranslationRequest,
  CreateDialogTranslationResponse,
  CreateEpisodeScriptRequest,
  CreateMultipleChoicesRequest, CreateMultipleChoicesResponse,
  CreateQuizExplanationRequest,
  CreateQuizExplanationResponse,
  CreateTranslationQuizChoicesRequest,
  CreateTranslationQuizChoicesResponse,
  Dialog,
  DialogTurn,
  DialogWordLinkDetails,
  EpisodeScriptInfo,
  ScriptValidation,
  WordLink
} from "business_logic/script/scriptModels";
import DevLogger from "services/Logger";
import { useCreateScriptStore } from "./scriptStore";

class ScriptApi extends BaseApi {
  private static instance: ScriptApi;
  private constructor() {
    super();
  }
  public static getInstance() {
    if (!ScriptApi.instance) {
      ScriptApi.instance = new ScriptApi();
    }
    return ScriptApi.instance;
  }

  async fetchScriptSummary(seriesId: string, status: DataStatus = "active"): Promise<EpisodeScriptInfo[]> {
    try {
      const response = await this.axiosInstance.get(`/scripts/${seriesId}?status=${status}`);
      return response.data;
    } catch (error: any) {
      throw Error("Error fetching dialog translation");
    }
  }

  async deleteScript({ episodeId, hardDelete = false }: { episodeId: string; hardDelete?: boolean }) {
    const endpoint = `/scripts/delete/${episodeId}?hard_delete=${hardDelete}`;
    try {
      await this.axiosInstance.delete(endpoint);
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async restoreScript(episodeId: string) {
    const endpoint = `/scripts/restore/${episodeId}`;
    try {
      await this.axiosInstance.patch(endpoint);
    } catch (error: any) {
      throw Error("Error fetching dialog translation");
    }
  }

  async validateScript(episodeId: string): Promise<ScriptValidation> {
    try {
      const response = await this.axiosInstance.get(`/scripts/validate/${episodeId}`);
      DevLogger.log(response.data);
      return response.data as ScriptValidation;
    } catch (error: any) {
      throw Error("Error fetching dialog translation");
    }
  }

  async publishScript(episodeId: string, publishMode: EpisodeStatusEnum): Promise<void> {
    const endpoint = `/scripts/publish/${episodeId}`;
    try {
      await this.axiosInstance.patch(endpoint, null, { params: { publishMode: publishMode } });
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async createDialogTranslation(payload: CreateDialogTranslationRequest[], translationSpeechStyle: String = "honorific"): Promise<CreateDialogTranslationResponse[]> {
    try {
      DevLogger.log("Create Dialog Translation Request Payload", payload);
      const response = await this.axiosInstance.post("/scripts/create/dialog_translations", payload,
        { params: { speech_style: translationSpeechStyle } });
      return response.data as CreateDialogTranslationResponse[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async createMultipleChoice(payload: CreateMultipleChoicesRequest[]): Promise<CreateMultipleChoicesResponse[]> {
    try {
      DevLogger.log("Create Multiple Choice Request Payload", payload);
      const response = await this.axiosInstance.post("/scripts/create/multiple_choice", payload);
      return response.data as CreateMultipleChoicesResponse[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async createTranslationQuizChoices(payload: CreateTranslationQuizChoicesRequest[]): Promise<CreateTranslationQuizChoicesResponse[]> {
    try {
      DevLogger.log("Create Translation Quiz Choice Request Payload", payload);
      const response = await this.axiosInstance.post("/scripts/translation-quiz-choices", payload);
      return response.data as CreateTranslationQuizChoicesResponse[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async createQuizExplanation(payload: CreateQuizExplanationRequest[]): Promise<CreateQuizExplanationResponse[]> {
    try {
      DevLogger.log("Create Quiz Explanation Request Payload", payload);
      const response = await this.axiosInstance.post('/scripts/create/quiz_explanation', payload);
      return response.data as CreateQuizExplanationResponse[];
    } catch (error) {
      throw Error(error.message);
    }
  }
  async createDialogAudio({ seriesId, episodeId, payload }: { seriesId: string; episodeId: string; payload: CreateDialogAudioRequest[] }): Promise<CreateDialogAudioResponse[]> {
    try {
      DevLogger.log("Create Dialog Audio Request Payload", payload);
      const response = await this.axiosInstance.post(`/scripts/create/dialog_audio?series_id=${seriesId}&episode_id=${episodeId}`, payload);
      return response.data as CreateDialogAudioResponse[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async createEpisodeScript() {
    const endpoint = `/scripts/create`;
    try {
      // Initialize Payload
      const payload: CreateEpisodeScriptRequest = {
        seriesId: useCreateScriptStore.getState().series!.id!,
        episodeId: useCreateScriptStore.getState().episode!.id!,
        dialogs: useCreateScriptStore.getState().dialogs.map((dialog) => {
          // Create a new Dialog instance
          const newDialog = new Dialog({
            ...dialog,
            backgroundImage: dialog.backgroundImageUrl,
            characterImage: `${dialog.characterName}_${dialog.characterExpression}_${dialog.characterClothes}`,
            objectImage: dialog.objectImage && dialog.objectImage !== "" ? dialog.objectImage : undefined,
          });

          // Remove properties that shouldn't be in the final object
          delete (newDialog as any).backgroundImageUrl;
          delete (newDialog as any).characterExpression;
          delete (newDialog as any).characterClothes;
          return newDialog;
        }),
      };
      DevLogger.log("Create Episode Script Request Payload", payload);

      const response = await this.axiosInstance.post(endpoint, payload);

      return response.data;
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async fetchDialogTurns(episodeId: string): Promise<DialogTurn[]> {
    try {
      const response = await this.axiosInstance.get(`/scripts/dialog-turns/${episodeId}`)
      DevLogger.log("ScriptApi - fetchDialogTurns Response", response.data);
      return response.data as DialogTurn[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async patchDialogImage(episodeId: string, dialogs: Partial<Dialog>[]): Promise<void> {
    DevLogger.log("Patch Dialog Image Request Payload", dialogs);
    try {
      await this.axiosInstance.patch(`/scripts/dialog-image/${episodeId}`, dialogs);
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async patchDialogText(episodeId: string, payload: Partial<Dialog>[]): Promise<void> {
    DevLogger.log("Patch Dialog Text Request Payload", payload);
    try {
      await this.axiosInstance.patch(`/scripts/dialog-text/${episodeId}`, payload);
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async patchDialogQuiz(episodeId: string, dialogs: Partial<Dialog>[]): Promise<void> {
    DevLogger.log("Patch Dialog Quiz Request Payload", dialogs);
    try {
      await this.axiosInstance.patch(`/scripts/dialog-quiz/${episodeId}`, dialogs);
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async patchTranslationQuiz(episodeId: string, dialogs: Partial<Dialog>[]): Promise<void> {
    DevLogger.log("Patch Translation Quiz Request Payload", dialogs);
    try {
      await this.axiosInstance.patch(`/scripts/translation-quiz`, dialogs);
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async fetchDialogWordLink(episodeId: string) {
    try {
      const response = await this.axiosInstance.get(`/scripts/dialog-word-links/${episodeId}`);
      return response.data as DialogWordLinkDetails[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async patchWordLink(wordLink: WordLink[]) {
    DevLogger.log("Patch Dialog Word Link Request", wordLink);
    try {
      const response = await this.axiosInstance.patch("/scripts/word-links/", wordLink);
      return response;
    }
    catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }



}

const ScriptApiInstance = ScriptApi.getInstance();
export default ScriptApiInstance;
