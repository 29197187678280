import { Route, Routes } from "react-router-dom";

// Pages
// import HomePage from "presentation/pages/home/home";
import Sidebar from "presentation/components/sidebar/Sidebar";
import EpisodePage from "presentation/pages/episode/Episode";
import SeriesPage from "presentation/pages/series/Series";
import SeriesCharacterPage from "presentation/pages/seriesCharacter/SeriesCharacter";
import SignInPage from "presentation/pages/signIn/SignIn";
import WordPage from "presentation/pages/word/Word";

import NotFoundPage from "presentation/components/feedbacks/NotFoundPage";
import CreateScriptPage from "presentation/pages/createScript/CreateScript";
import EditScriptPage from "presentation/pages/editScript/EditScript";
import InquiryPage from "presentation/pages/inquiry/Inquiry";
import InquiryMessagePage from "presentation/pages/inquiryMessage/inquiryMessage";
import SelectScriptPage from "presentation/pages/selectScript/SelectScript";
import ValidateScriptPage from "presentation/pages/validateScript/ValidateScript";
import UploadAssetPage from "presentation/pages/uploadAsset/UploadAsset";
import EditContentPage from "presentation/pages/editContent/EditContentPage";
import AnalyticsPage from "presentation/pages/analytics/AnalyticsPage";
import HomePage from "presentation/pages/home/Home";
import DashboardPage from "presentation/pages/dashboard/Dashboard";

export const PublicRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SignInPage />} />
      <Route path="/signIn" element={<SignInPage />} />
      {/* <Route path="/signUp" element={<SignUpPage />} /> */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export const PrivateRouter = () => {
  return (
    <div className="page-layout flex-row">
      <Sidebar />
      <div className="content-layout">
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/home" element={<DashboardPage />} />
          <Route path="/analytics" element={<AnalyticsPage />} />
          <Route path="/analytics/:attendanceSessionId" element={<AnalyticsPage />} />
          <Route path="/contents" element={<EditContentPage />} />
          <Route path="/script/create" element={<CreateScriptPage />} />
          <Route path="/script/edit/series/:seriesId/episode/:episodeId" element={<EditScriptPage />} />
          <Route path="/script/validate/:episodeId" element={<ValidateScriptPage />} />
          <Route path="/script/edit" element={<SelectScriptPage />} />
          <Route path="/words" element={<WordPage />} />
          <Route path="/inquiries" element={<InquiryPage />} />
          <Route path="/inquiry/:inquiryId" element={<InquiryMessagePage />} />
          <Route path="/upload" element={<UploadAssetPage />} />

        </Routes>
      </div>
    </div>
  );
};
