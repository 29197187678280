import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import SeriesApiInstance from "business_logic/series/SeriesApi";
import { CreateSeriesRequest, Series } from "business_logic/series/seriesModels";

import { Loader2 } from "lucide-react";
import SelectSeriesButtons from "presentation/components/dropdowns/SeriesSelectButtons";
import { useState } from "react";
import { useMutation } from "react-query";
import "styles/buttons.scss";
import "styles/global.scss";
import "styles/modals.scss";

interface Props {
  providedSeries?: Series;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export default function CreateSeriesDialog({ providedSeries, open, onClose, onSuccess = () => { } }: Props) {
  const { toast } = useToast();
  const [isProcessing, setIsProcessing] = useState(false);

  const createSeries = useMutation(
    async (request: CreateSeriesRequest) => { await SeriesApiInstance.createSeries(request); },
    {
      onSuccess: () => {
        toast({
          title: "Success",
          description: "시리즈가 성공적으로 추가됐습니다"
        })
        onSuccess();
        onClose();
      },
      onError: (error: any) => {
        toast({
          title: "Failure",
          description: error.message,
          variant: "destructive"
        })
      },
      onSettled: () => {
        setIsProcessing(false);
      }
    }
  );

  const handleSubmit = (event) => {

    event.preventDefault();
    setIsProcessing(true);

    const formData = new FormData(event.target);

    const createSeriesRequest: CreateSeriesRequest = {
      title: formData.get("title") as string,
      internalId: formData.get("internalId") as string,
      description: formData.get("description") as string,
    }

    createSeries.mutate({ ...createSeriesRequest });
  };

  return (
    <Dialog open={open} onOpenChange={onClose} >
      <DialogContent className="sm:max-w-[600px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Create new series</DialogTitle>
            <DialogDescription>
              Fill in the details to create new series.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 align-center gap-4">
              <Label htmlFor="title" className="text-right"> Title </Label>
              <Input name="title" className="col-span-3" required placeholder="in Korean" />
            </div>
            <div className="grid grid-cols-4 align-center gap-4">
              <Label htmlFor="internalId" className="text-right">Internal ID</Label>
              <Input name="internalId" className="col-span-3" required placeholder="eg: ember, bongsoons, lib" />
            </div>
            <div className="grid grid-cols-4 align-center gap-4">
              <Label htmlFor="description" className="text-right">Description</Label>
              <Input name="description" className="col-span-3" required />
            </div>
          </div>

          <DialogFooter>
            <Button type="submit" disabled={isProcessing}>
              {isProcessing ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Please wait
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
