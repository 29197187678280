import { useEffect } from "react";
import { Dialog } from "business_logic/script/scriptModels";
import { useCreateScriptStore } from "business_logic/script/scriptStore";
import CustomTable, { Column } from "presentation/components/customTable/customTable";
import useCreateContent from "./hooks/useCreateContent";
import Loading from "presentation/components/feedbacks/Loading";
import Error from "presentation/components/feedbacks/Error";

export default function CreateScriptStep3({ handleDataChange }) {
  const createScriptStore = useCreateScriptStore();
  const { parseScript, getQuizWordBase } = useCreateContent(createScriptStore);
  const { dialogs, scriptingStep, addCompletedStep, isStepCompleted } = createScriptStore;

  useEffect(() => {
    const runStep3 = async () => {
      if (!isStepCompleted()) {
        await parseScript();
        await getQuizWordBase.mutateAsync();
        addCompletedStep(scriptingStep);
      }
    };

    runStep3();
  }, [scriptingStep]);

  const columns: Column<Dialog>[] = [
    { header: "턴", field: "dialogTurn", width: "2.5vw", editable: false },
    { header: "캐릭터", field: "characterName", width: "8vw", editable: true },
    { header: "캐릭터표정", field: "characterExpression", width: "8vw", editable: true },
    { header: "캐릭터옷", field: "characterClothes", width: "8vw", editable: true },
    { header: "배경이미지", field: "backgroundImageUrl", width: "8vw", editable: true },
    { header: "사물이미지", field: "objectImage", width: "8vw", editable: true },
    { header: "사물이미지", field: "imageDirection", width: "8vw", editable: true },
    { header: "대사", field: "targetScript", width: "24vw", editable: true },
    { header: "퀴즈단어", field: "quizWord", width: "8vw", editable: true },
    { header: "퀴즈단어 원형", field: "quizWordBase", width: "8vw", editable: true }
  ];

  if (getQuizWordBase.isLoading) return <Loading />;
  if (getQuizWordBase.isError) return <Error />;
  if (isStepCompleted() || getQuizWordBase.isSuccess) return (
    <CustomTable<Dialog>
      data={dialogs}
      columns={columns}
      rowHeight="64px"
      handleTextChange={handleDataChange}
    />
  );
};
