import { WordInfoRow } from "business_logic/word/wordModels";
import AudioButton from "presentation/components/buttons/AudioButton";
import { patchWord } from "./tableApi";
import DateFormatter from "services/DateFormatter";

export const tableColumns = [
  { field: "id", headerName: "아이디", cellClass: "non-editable-cell", width: 80, hide: true, },
  { field: "definitionId", headerName: "아이디", cellClass: "non-editable-cell", width: 100, hide: true },
  { field: "sentenceId", headerName: "아이디", cellClass: "non-editable-cell", width: 100, hide: true },
  { field: "word", headerName: "단어", cellClass: "non-editable-cell", width: 140 },
  { field: "definition", headerName: "뜻", cellClass: "editable-cell", cellEditorParams: { rows: 3 }, width: 200, editable: true },
  { field: "pos", headerName: "품사", cellClass: "editable-cell", width: 80, editable: true },
  { field: "sentence", headerName: "예문", cellClass: "editable-cell multiline", cellEditor: 'agLargeTextCellEditor', cellEditorParams: { rows: 3 }, width: 320, editable: true },
  { field: "translation", headerName: "예문 번역", cellClass: "editable-cell multiline", cellEditor: 'agLargeTextCellEditor', cellEditorParams: { rows: 3 }, width: 320, editable: true },
  { field: "createdAt", headerName: "생성일", cellClass: "non-editable-cell", width: 150, editable: true, valueFormatter: (params: any) => DateFormatter.formatShort(params.value) },
];

export const updateButtonColumn = (words: WordInfoRow[]) => {
  return {
    field: "modify",
    headerName: "업데이트",
    cellClass: "non-editable-cell",
    editable: false,
    width: 100,
    cellRenderer: (params: any) => (
      <button className="update-button" onClick={() => patchWord(params.data, words)}>
        수정
      </button>
    ),
    onCellValueChanged: undefined,
  };
};

export const wordAudioColumn = () => {
  return {
    field: "audioUrl",
    headerName: "단어 음성",
    cellClass: "non-editable-cell",
    editable: false,
    width: 100,
    cellRenderer: (params: any) => <AudioButton audioUrl={params.data.audioUrl} />,
    onCellValueChanged: undefined,
  };
};

export const sentenceAudioColumn = () => {
  return {
    field: "sentenceAudioUrl",
    headerName: "예문 음성",
    cellClass: "non-editable-cell",
    editable: false,
    width: 100,
    cellRenderer: (params: any) => <AudioButton audioUrl={params.data.sentenceAudioUrl} />,
    onCellValueChanged: undefined,
  };
};

export const addCellValueChangedHandler = (field: string, handleChange: Function) => (e: any) => handleChange(e.newValue, field, e.data.id);
