import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import AudioServiceInstance from "services/AudioService";
import "styles/buttons.scss";

interface AudioButtonProps {
  audioUrl: string;
}

const AudioButton: React.FC<AudioButtonProps> = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    const checkPlayingStatus = () => {
      const currentaudioUrl = AudioServiceInstance.getCurrentaudioUrl();
      setIsPlaying(currentaudioUrl === audioUrl);
    };
    const interval = setInterval(checkPlayingStatus, 100);
    return () => clearInterval(interval);
  }, [audioUrl]);

  const handleClick = () => {
    AudioServiceInstance.play(audioUrl);
  };

  return (
    <button className={`audio-button ${isPlaying ? "isPlaying" : ""}`} onClick={handleClick}>
      <FontAwesomeIcon icon={faPlay} className={`audio-icon ${isPlaying ? "isPlaying" : ""}`} />
    </button>
  );
};

export default AudioButton;
