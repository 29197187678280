import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "styles/global.scss";
import "styles/layouts.scss";
import "../components.scss";

export default function Error() {
  return (
    <div className="component-is-error flex-col">
      <div className="icon-warning margin-auto-all margin-bottom-16">
        <FontAwesomeIcon icon={faWarning} spin size="1x" />
      </div>
      <span> Error Fetching Data </span>
    </div>
  );
}
