import DashboardApiInstance from "business_logic/dashboard/DashboardApi";
import { AcquisitionStats, ActiveUserStats, OverviewStats } from "business_logic/dashboard/dashboardModels";
import { Check, LucideUser, MonitorCheck, Play, TvMinimalPlay, UserRound } from "lucide-react";
import Loading from "presentation/components/feedbacks/Loading";
import { useState } from "react";
import { useQuery } from "react-query";
import DevLogger from "services/Logger";
import StatSummaryCard, { StatSummaryCardProps } from "../components/StatSummaryCard";
import { GraphData, useDashboard } from "../useDashboard";
import AcquisitionGraph from "./AcquisitionGraph";
import ActiveUserGraph from "./ActiveUserGraph";

export default function OverviewTab() {

    const [statSummaryCards, setstatSummaryCards] = useState<StatSummaryCardProps[]>([]);
    const [activeUserGraphData, setactiveUserGraphData] = useState<GraphData[]>([]);
    const [acquisitionGraphData, setacquisitionGraphData] = useState<GraphData[]>([]);

    const { getThisWeekData, getLastWeekData, getKstDate, } = useDashboard();

    const fetchOverviewStats = useQuery('fetchOverviewStats', async () => DashboardApiInstance.fetchOverviewStats(),
        {
            enabled: true,
            refetchInterval: 600000,
            onSuccess: (data: OverviewStats) => {
                setstatSummaryCards([
                    {
                        label: 'Weekly Active Users',
                        value: getThisWeekData(data.activeUserStats, 'activeUsers'),
                        icon: LucideUser,
                        infoText: <>It was <span className="font-bold text-sm text-black">{getLastWeekData(data.activeUserStats, 'activeUsers')}</span> last week</>
                    },
                    {
                        label: 'New Acquisitions',
                        value: getThisWeekData(data.acquisitionStats, 'newUsers'),
                        icon: UserRound,
                        infoText: <>It was <span className="font-bold text-sm text-black">{getLastWeekData(data.acquisitionStats, 'newUsers')}</span> last week</>
                    },
                    {
                        label: 'Episode Plays',
                        value: getThisWeekData(data.episodePlayStats, 'episodesStarted'),
                        icon: Play,
                        infoText: <>It was <span className="font-bold text-sm text-black">{getLastWeekData(data.episodePlayStats, 'episodesStarted')}</span> last week</>
                    },
                    {
                        label: 'Episode Completion rate',
                        value: `${(getThisWeekData(data.episodePlayStats, 'episodesCompleted') / getThisWeekData(data.episodePlayStats, 'episodesStarted') * 100).toFixed(1)}%`,
                        icon: Check,
                        infoText: <>It was <span className="font-bold text-sm text-black">{`${(getLastWeekData(data.episodePlayStats, 'episodesCompleted') / getLastWeekData(data.episodePlayStats, 'episodesStarted') * 100).toFixed(1)}%`}</span> last week</>
                    },
                    {
                        label: 'Ads watched',
                        value: getThisWeekData(data.adViewStats, 'adViews'),
                        icon: MonitorCheck,
                        infoText: <>It was <span className="font-bold text-sm text-black">{getLastWeekData(data.adViewStats, 'adViews')}</span> last week</>
                    }
                ])
                setactiveUserGraphData(data.activeUserStats.map((stat: ActiveUserStats) => ({ date: getKstDate(stat.date), value: stat.activeUsers })));
                setacquisitionGraphData(data.acquisitionStats.map((stat: AcquisitionStats) => ({ date: getKstDate(stat.date), value: stat.newUsers })));
                DevLogger.log(activeUserGraphData);
            }
        },
    );

    if (fetchOverviewStats.isLoading) return <div><Loading /></div>
    if (fetchOverviewStats.isError) return <div>Error</div>
    return (
        <div className="flex-col gap-4">
            <section className="grid grid-cols-5 gap-4">
                {statSummaryCards.map((card, index) => (
                    <StatSummaryCard key={index} label={card.label} value={card.value} infoText={card.infoText} />)
                )}
            </section>
            <section className="grid grid-cols-2 gap-4">
                <ActiveUserGraph chartData={activeUserGraphData} />
                <AcquisitionGraph chartData={acquisitionGraphData} />
            </section>
        </div >
    );
}