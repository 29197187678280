import { GenericAgGridTable } from "presentation/components/agGridTable/AgGridTable";
import { useInquiryTableLogic } from "./useInquiryTableLogic";
import { UserInquiry } from "business_logic/inquiry/inquiryModels";
import DateFormatter from "services/DateFormatter";
import { useMemo } from "react";

export default function InativeInquiryTable({ inquiries, setInquiries }: { inquiries: UserInquiry[]; setInquiries: (inquiries: UserInquiry[]) => void }) {
  // Table Resources
  const { handleChange, replyInquiry } = useInquiryTableLogic({ inquiries, setInquiries });
  const columns = [
    { field: "id", headerName: "아이디", width: 150, cellClass: "non-editable-cell", editable: false, hide: true },
    { field: "userId", headerName: "유저 아이디", width: 300, cellClass: "non-editable-cell", editable: false, sort: "asc" },
    { field: "messageCount", headerName: "메시지 수", width: 100, cellClass: "non-editable-cell", editable: false },
    { field: "lastMessage", headerName: "최근 어드민 메시지", width: 350, cellClass: "non-editable-cell", editable: false },
    { field: "createdAt", headerName: "최초 문의일", cellClass: "non-editable-cell", editable: false, valueFormatter: (params: any) => DateFormatter.formatShort(params.value) },
    { field: "lastMessageCreatedAt", headerName: "최근 답변일", cellClass: "non-editable-cell", editable: false, valueFormatter: (params: any) => DateFormatter.formatRelative(params.value) },
  ];

  // Process Inquiries object to include additional fields
  const processedInquiries = useMemo(() => {
    return inquiries.map((inquiry) => ({
      ...inquiry,
      messageCount: inquiry.messages?.length || 0,
      lastMessage:
        inquiry.messages?.sort((a, b) => {
          const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
          return dateB - dateA;
        })[0]?.message || "",
      lastMessageCreatedAt:
        inquiry.messages?.sort((a, b) => {
          const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
          return dateB - dateA;
        })[0]?.createdAt || "",
    }));
  }, [inquiries]);

  return <GenericAgGridTable data={processedInquiries} columns={columns} onCellValueChanged={handleChange} onReply={replyInquiry} />;
}
