import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import WordApiInstance from "business_logic/word/WordApi";
import { WordInfoRow } from "business_logic/word/wordModels";
import { useDialog } from "hooks/useDialog";
import Error from "presentation/components/feedbacks/Error";
import Loading from "presentation/components/feedbacks/Loading";
import CreateWordDialog from "presentation/dialogs/CreateWordDialog";
import { useState } from "react";
import { useQuery } from "react-query";
import { addCellValueChangedHandler, sentenceAudioColumn, tableColumns, updateButtonColumn, wordAudioColumn } from "./tableColumns";


export default function WordTable() {
  const { dialogs, openDialog, closeDialog } = useDialog();
  const [words, setWords] = useState<WordInfoRow[]>();
  const [searchText, setSearchText] = useState<string>("");


  const { data, isLoading, isError, refetch } = useQuery(
    ['wordDefinitions', searchText],
    () => WordApiInstance.getWordInfo(searchText),
    {
      enabled: false,
      onSuccess: (data: any) => {
        setWords(data);
      },
      onError: () => {
        alert("Error fetching data");
      },
    }
  );

  function handleSearch() {
    searchText.trim() !== "" && refetch();
  };

  function handleEnterClick(event: React.KeyboardEvent<HTMLInputElement>) {
    event.key === "Enter" && handleSearch();
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value);
  };

  function handleChange(newValue: any, field: string, episodeId: string) {
    const updatedwords = words!.map((s) => (s.id === episodeId ? { ...s, [field]: newValue } : s));
    setWords(updatedwords);
  };


  var columns = tableColumns.map((column) => ({
    ...column,
    onCellValueChanged: column.editable ? addCellValueChangedHandler(column.field, handleChange) : undefined,
  }));
  columns.push(wordAudioColumn());
  columns.push(sentenceAudioColumn());
  columns.push(updateButtonColumn(words!));

  // Define Table Options
  const gridOptions = { rowHeight: 80 };


  if (isLoading) return <Loading />;
  if (isError) return <Error />;
  return (
    <>
      <div className="section-text-search flex-row margin-bottom-16 gap-8">
        <div className="grid grid-cols-5 gap-4 ">
          <Input className="col-span-4" placeholder="Search..." value={searchText} onChange={handleInputChange} onKeyDown={handleEnterClick} />
          <Button type="submit" onClick={handleSearch}>Search</Button>
        </div>
      </div>
      {data && (
        <>
          <div className="ag-theme-quartz">
            <AgGridReact rowData={data} columnDefs={columns} gridOptions={gridOptions} domLayout="autoHeight" />
          </div>

          <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2">
            <Button variant="outline" onClick={() => openDialog("createWord")}>Create new word</Button>
          </div>

          <CreateWordDialog word={searchText} open={dialogs["createWord"] || false} onClose={() => closeDialog("createWord")} onSuccess={refetch} />
        </>
      )
      }
    </>
  );
}
