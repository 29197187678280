import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import ScriptApiInstance from "business_logic/script/ScriptApi";
import { DialogWordLinkDetails, WordDefinition, WordLink } from "business_logic/script/scriptModels";
import Loading from "presentation/components/feedbacks/Loading";
import { useEffect, useState } from "react";
import useEditScriptStore from "../useEditScriptStore";

const EditDialogText = () => {
  const [selectedDialogTurn, setSelectedDialogTurn] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dialogs, setCurrentWordLink, getCurrentWordLink, deleteWordLink, fetchWordLinks } = useEditScriptStore();

  useEffect(() => {

    const loadData = async () => {
      setIsLoading(true);
      try {
        await fetchWordLinks();
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to fetch word links",
          variant: "destructive"
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [])

  const patchWordLink = async (
    wordStartIndex: number,
    options: { wordDefinitionId?: string; visible?: boolean }
  ) => {
    if (Object.keys(options).length === 0) {
      throw new Error("At least one of wordDefinitionId or visible must be provided");
    }

    const dialogWordLinks = dialogs
      .find((dialog) => dialog.dialogTurn === selectedDialogTurn)
      ?.wordLinkDetails.find((wordLinkDetail) => wordLinkDetail.startIndex === wordStartIndex);

    if (!dialogWordLinks) {
      throw new Error("Word link not found");
    }

    const patchWordLink: WordLink = {
      id: dialogWordLinks.id,
      ...options
    };

    await ScriptApiInstance.patchWordLink([patchWordLink]);
  };


  const dialogWordLinkSelection = (selectedDialogTurn: number) => {
    let dialog = dialogs?.find((dialog) => dialog.dialogTurn === selectedDialogTurn);
    return (
      <div className="container-base-script">
        <div className="text-xl font-bold mb-4">{dialog!.characterName.toUpperCase()} : {dialog!.targetScript} </div>
        {dialog?.wordLinkDetails
          ?.slice()
          .sort((a, b) => a.startIndex! - b.startIndex!)
          .map((wordLinkDetail) => wordLinkInfo(wordLinkDetail))}

      </div>
    );
  };

  const wordLinkInfo = (dialogWordLinkDetail: DialogWordLinkDetails) => {
    const wordStartIndex = dialogWordLinkDetail.startIndex;
    const isWordLinkDeleted = getCurrentWordLink!(selectedDialogTurn, wordStartIndex) === null;

    return (
      <div className="grid grid-cols-12 gap-4 items-center mb-2">
        <div className="col-span-2 text-bold">단어 : {dialogWordLinkDetail.word} </div>
        <Button
          className="col-span-1"
          variant={isWordLinkDeleted ? "destructive" : "delete"}
          onClick={async () => {
            deleteWordLink!(selectedDialogTurn, wordStartIndex);
            await patchWordLink(wordStartIndex, { visible: false });
          }}
        >
          Delete
        </Button>
        <div className="col-span-9 gap-4">
          {dialogWordLinkDetail.candidates?.map((candidate: WordDefinition) => {
            const isWordDefinitionSelected = getCurrentWordLink!(selectedDialogTurn, wordStartIndex)?.id === candidate.id;
            return (
              <Button
                variant={isWordDefinitionSelected ? "default" : "outline"}
                className="mr-2"
                onClick={async () => {
                  setCurrentWordLink!(selectedDialogTurn, wordStartIndex, candidate);
                  await patchWordLink(wordStartIndex, { wordDefinitionId: candidate.id });
                }}
              >
                {candidate.pos} : {candidate.definition}
              </Button>)
          })}
        </div>
      </div >
    );
  };

  if (isLoading) return <div><Loading /></div>;
  else {
    return (
      <div className="section section-word-link flex-col gap-8">
        <section className="flex-col gap-4">
          <div className="text-xl font-bold">Dialog Turns</div>
          <div className="flex flex-row gap-4">
            {dialogs?.map((dialog, index) => {
              const isDialogTurnSelected = index + 1 === selectedDialogTurn;
              return (
                <Button variant={isDialogTurnSelected ? "default" : "outline"}
                  onClick={() => {
                    setSelectedDialogTurn(index + 1);
                  }}
                >
                  {index + 1}
                </Button>
              )
            })}
          </div>
        </section>

        <div className="dialog-word-link-section">{dialogWordLinkSelection(selectedDialogTurn)}</div>
      </div >
    );
  }
};

export default EditDialogText;
