
import { UserInquiryMessage } from "business_logic/inquiry/inquiryModels";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import DateFormatter from "services/DateFormatter";
import IsError from "presentation/components/feedbacks/Error";
import IsLoading from "presentation/components/feedbacks/Loading";
import InquiryApiInstance from "business_logic/inquiry/InquiryApi";

import "./inquiryMessage.scss";

export default function InquiryMessagePage() {
  const [messages, setMessages] = useState<UserInquiryMessage[]>([]);
  const [reply, setReply] = useState<string>("");
  const { inquiryId } = useParams();

  const fetchInquiryMessages = useMutation("fetchInquiryMessages", async () => {
    return await InquiryApiInstance.getInquiryMessages(inquiryId!);
  }, {
    onSuccess: (data) => { setMessages(data); }
  });

  // Fetch Inquiry Messages upon component mount
  useEffect(() => {
    fetchInquiryMessages.mutate();
  }, [fetchInquiryMessages.mutate]);

  const handleSendMessage = async () => {
    if (reply.trim() !== "") {
      const new_inquiry_message: UserInquiryMessage = {
        userId: null,
        userInquiryId: inquiryId!,
        message: reply,
      };
      await InquiryApiInstance.createInquiryMessage(new_inquiry_message);
      setReply("");
      fetchInquiryMessages.mutate();
    }
  };

  const handleDeleteMessage = async (messageId: string) => {
    await InquiryApiInstance.deleteInquiryMessage(messageId);
    fetchInquiryMessages.mutate();
  };

  if (fetchInquiryMessages.isLoading) return <IsLoading />;
  if (fetchInquiryMessages.isError) return <IsError />;
  if (fetchInquiryMessages.isSuccess)
    return (
      <div className="page page-inquiry">
        <div className="page-header flex-col">
          <h1 className="h1">유저 문의</h1>
        </div>
        <hr />
        <div className="messages-container">
          {messages.map((message) => {
            const messageSender = message.userId === null ? "Admin" : "User";
            return (
              <div key={message.id} className={`flex-col message-container ${message.userId === null ? "admin" : "user"}`}>
                <div className="message-sender-container flex-row justify-between max-width">
                  <div className="message-sender text-bold">{messageSender}</div>
                  <div className="message-date text-light">{DateFormatter.formatRelative(message.createdAt!.toString())}</div>
                </div>

                <div className="message-content justify-between">
                  {message.message}
                  {message.userId === null && (
                    <FontAwesomeIcon
                      icon={faCircleXmark}
                      onClick={() => {
                        handleDeleteMessage(message.id!);
                      }}
                    ></FontAwesomeIcon>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="input-container">
          <textarea className="input" value={reply} onChange={(e) => setReply(e.target.value)} placeholder="Type your message here..." />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    );
}
