import { BookA, ChartNetwork, CirclePlus, Home, Pencil, Upload, UsersRound } from "lucide-react";
import { NavigateFunction } from "react-router-dom";
const iconStyle = { color: "#606060", fontSize: "24" };

const sidebarMenuItems = (navigate: NavigateFunction) => [
  {
    category: "dashboard",
    menuName: "dashboard",
    text: "홈",
    icon: <Home style={iconStyle} />,
    onClick: (menuName: string) => {
      navigate("/home");
    },
    to: "/home"
  },
  {
    category: "dashboard",
    menuName: "analytics",
    text: "유저 분석",
    icon: <ChartNetwork style={iconStyle} />,
    onClick: (menuName: string) => {
      navigate("/analytics");
    },
    to: "/analytics"
  },
  {
    category: "contents",
    menuName: "create_contents",
    text: "콘텐츠 생성",
    icon: <CirclePlus style={iconStyle} />,
    onClick: (menuName: string) => {
      navigate("/script/create");
    },
    to: "/script/create"
  },
  {
    category: "contents",
    menuName: "edit_contents",
    text: "콘텐츠 수정",
    icon: <Pencil style={iconStyle} />,
    onClick: (menuName: string) => {
      navigate("/contents");
    },
    to: "/contents"
  },
  {
    category: "contents",
    menuName: "upload_assets",
    text: "Asset 업로드",
    icon: <Upload style={iconStyle} />,
    onClick: (menuName: string) => {
      navigate("/upload");
    },
    to: "/upload"
  },
  {
    category: "users",
    menuName: "inquiries",
    text: "유저 문의",
    icon: <UsersRound style={iconStyle} />,
    onClick: (menuName: string) => {
      navigate("/inquiries");
    },
    to: "/inquiries"
  },
  {
    category: "others",
    menuName: "view_words",
    text: "단어 추가",
    icon: <BookA style={iconStyle} />,
    onClick: (menuName: string) => {
      navigate("/words");
    },
    to: "/words"
  },
];

export default sidebarMenuItems;
