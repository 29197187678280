import { Series } from "business_logic/series/seriesModels";
import SelectSeriesButtons from "presentation/components/dropdowns/SeriesSelectButtons";
import { useCallback, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "reactjs-popup/dist/index.css";
import "styles/buttons.scss";
import "styles/global.scss";
import "styles/popup.scss";
import ActiveScriptTable from "./tabs/ActiveScriptTable";
import InactiveScriptTable from "./tabs/InactiveScriptTable";
import { useNavigate } from "react-router-dom";

export default function SelectScriptPage() {
  const navigate = useNavigate();
  const [series, setSeries] = useState<Series>(null!);

  const navigateToCreateNewScript = () => {
    navigate("/script/create");
  };

  // Tabs
  const episodeTableTabs = useCallback((currentSeries: Series) => {
    return (
      <Tabs key={currentSeries.id}>
        <TabList>
          <Tab>활성 스크립트</Tab>
          <Tab>비활성 스크립트</Tab>
        </TabList>
        <TabPanel>
          <ActiveScriptTable key={`active-${currentSeries.id}`} series={currentSeries} />
          <button className="button-action" onClick={navigateToCreateNewScript}>
            새로운 스크립트 추가
          </button>
        </TabPanel>
        <TabPanel>
          <InactiveScriptTable key={`inactive-${currentSeries.id}`} series={currentSeries} />
        </TabPanel>
      </Tabs>
    );
  }, []);

  return (
    <div className="page page-episode">
      <div className="page-header flex-col">
        <h1 className="h1">스크립트 정보</h1>
        <SelectSeriesButtons selectedSeries={series!} setSelectedSeries={setSeries} />
      </div>
      {series && episodeTableTabs(series)}
    </div>
  );
}
