import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select";


interface BaseSelectProps {
    label: string;
    value: string;
    onValueChange: (value: any) => void;
    items: string[];
}

export default function BaseSelect({ label, value, onValueChange, items }: BaseSelectProps) {
    return (
        <Select name="speechStyle" value={value} onValueChange={onValueChange}>
            <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select pos" />
            </SelectTrigger>
            <SelectContent >
                <SelectGroup>
                    <SelectLabel >Select {label} </SelectLabel>
                    {items.map((item) => (
                        <SelectItem key={item} value={item}>{item}</SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    );
}