import BaseApi from "business_logic/base/BaseApi";
import { Dialog, FeatureWordInfo, GetQuizWordInfoRequest, GetQuizWordInfoResponse } from "business_logic/script/scriptModels";
import { CreateWordImageRequest, CreateWordRequest, FeatureWordInfoDTO, WordDefinition, WordInfoRow } from "business_logic/word/wordModels";
import DevLogger from "services/Logger";
import WordUtils from "./wordUtils";

class WordApi extends BaseApi {
  private static instance: WordApi;
  private constructor() {
    super();
  }

  public static getInstance() {
    if (!WordApi.instance) {
      WordApi.instance = new WordApi();
    }
    return WordApi.instance;
  }

  async createWord(newwordRequest: Partial<CreateWordRequest>) {
    try {
      DevLogger.log(newwordRequest);
      const response = await this.axiosInstance.put("/words", newwordRequest);
      return response.data;
    } catch (error: any) {
      throw Error(error.response.data.detail);
    }
  }

  async getWordInfo(word: string): Promise<WordInfoRow[]> {
    try {
      const response = await this.axiosInstance.get(`/words/${word}`);
      try {
        const words = WordUtils.parseWordInfoToWordInfoRow(response.data); // Flatten data to be used in table
        return words;
      } catch (error: any) {
        DevLogger.log(error);
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async updateWordInfo(word: WordInfoRow) {
    try {
      const req = WordUtils.parseWordInfoRowToWordInfo(word);
      DevLogger.log(req);
      const response = await this.axiosInstance.patch("/words", req, {
        params: {
          word_id: word.id,
          definition_id: word.definitionId,
          example_sentence_id: word.sentenceId,
        },
      });
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async updateWordDefinition(wordDefinition: WordDefinition[]) {
    DevLogger.log(wordDefinition);
    try {
      const response = await this.axiosInstance.patch("/words/definition", wordDefinition);
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async updateFeatureWordInfo(featureWordInfo: FeatureWordInfo) {
    try {
      DevLogger.log(featureWordInfo);
      const response = await this.axiosInstance.patch("/words/feature-word-info", featureWordInfo);
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async createWordImages(createWordImageRequests: CreateWordImageRequest[], background: boolean = false): Promise<any> {
    try {
      DevLogger.log("CreateWordImageRequests", createWordImageRequests);
      const processedRequests = createWordImageRequests.map(item => {
        if (item.imagePrompt) {
          const trimmedPrompt = item.imagePrompt.trim();
          item.imagePrompt = trimmedPrompt + (trimmedPrompt.endsWith('.') ? '' : '.');
        }
        return item;
      });

      DevLogger.log(processedRequests);
      const response = await this.axiosInstance.post("/words/image", processedRequests, {
        params: { background: background }
      });
      return response;

    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else {
        throw new Error('An error occurred while generating word images');
      }
    }
  }
  async getEpisodeWordCards(episode_id: string, dialog_turn?: number) {
    try {
      const response = await this.axiosInstance.get(`/words/cards/episode/${episode_id}`,
        { params: { dialog_turn: dialog_turn } }
      );
      DevLogger.log("Get Episode Word Cards", response.data);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async deleteWord({ wordId, hardDelete = false }: { wordId: string; hardDelete: boolean }) { }

  async checkWordExists(words: string[]): Promise<void> {
    try {
      await this.axiosInstance.post("/words/validate", words);
    } catch (error: any) {
      throw Error(error.response.data.detail);
    }
  }

  async getQuizWordInfo(dialogs: Dialog[]) {
    DevLogger.log("Word Api - Get Quiz Word Info");
    try {
      const payload: GetQuizWordInfoRequest[] = dialogs
        .filter((dialog) => dialog.quizWord !== "" && dialog.quizWord !== null)
        .map((dialog) => ({
          dialogTurn: dialog.dialogTurn!,
          quizWord: dialog.quizWord!
        }));
      DevLogger.log(payload);
      const response = await this.axiosInstance.post("/words/quiz-word-info", payload);
      return response.data as GetQuizWordInfoResponse[];
    } catch (error: any) {
      throw Error(error.message);
    }
  }

  async getFeatureWordInfoByWordDefinitionId(definitionId: string) {
    try {
      const response = await this.axiosInstance.get(`/words/feature-word-info/?definition_id=${definitionId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async createFeatureWordInfo(featureWordInfo: FeatureWordInfoDTO[]) {
    try {
      const response = await this.axiosInstance.put("/words/feature-word-info", featureWordInfo);
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

}

const WordApiInstance = WordApi.getInstance();
export default WordApiInstance;
