import axios, { AxiosInstance } from "axios";

class BaseApi {
  protected readonly axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: "/api",
      withCredentials: false,
      timeout: 300000
    });

    // Add authorization headers to all requests
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
}
export default BaseApi;
