import { Series } from "business_logic/series/seriesModels";
import { Episode } from "business_logic/episode/episodeModels";
import { create } from "zustand";
import { AudioSettings, Dialog, DialogTurn, DialogWordLinkDetails, EpisodeScriptInfo, WordDefinition } from "./scriptModels";
import DevLogger from "services/Logger";

interface CreateScriptState {
  scriptingStep: number;
  setScriptingStep: (scriptingStep: number) => void;
  series?: Series | null;
  setSeries: (series: Series) => void;
  episode?: Episode | null;
  setEpisode: (episode: Episode) => void;
  rawScript?: string;
  setRawScript: (rawScript: string) => void;
  dialogs?: Dialog[];
  upsertDialog: (dialog: any) => void;
  setDialogs: (dialogs: Dialog[] | Partial<Dialog>[]) => void;
  seriesCharacters: string[];
  setSeriesCharacters: (seriesCharacters: string[]) => void;
  translationSpeechStyle?: string;
  setTranslationSpeechStyle?: (translationSpeechStyle: string) => void;
  completedSteps?: number[];
  addCompletedStep: (step: number) => void;
  isStepCompleted: () => boolean;
  audioSettings?: Partial<AudioSettings>;
  setAudioSettings: (audioSettings: AudioSettings) => void;
  nextPage: () => void;
  previousPage: () => void;
  resetScript: () => void;
}

export const useCreateScriptStore = create<CreateScriptState>((set, get) => {
  return {
    scriptingStep: 1,
    series: null,
    episode: null,
    rawScript: "",
    seriesCharacters: [],
    translationSpeechStyle: "honorific",
    setTranslationSpeechStyle: (translationSpeechStyle: string) => set(() => {
      DevLogger.log(translationSpeechStyle);
      return { translationSpeechStyle };
    }),
    setSeriesCharacters: (seriesCharacters: string[]) => set((state) => ({ seriesCharacters: seriesCharacters })),
    audioSettings: { voiceStability: 0.5, voiceSimilarity: 0.75, voiceStyle: 0 },
    completedSteps: [],
    isStepCompleted: () => {
      const state = get();
      return state.completedSteps!.includes(state.scriptingStep);
    },
    setScriptingStep: (scriptingStep: number) => set(() => ({ scriptingStep })),
    setSeries: (series: Series) => set((state) => ({ series: series })),
    setEpisode: (episode: Episode) => set((state) => ({ episode: episode })),
    upsertDialog: (dialog: any) =>
      set((state) => {
        const existingDialogIndex = state.dialogs!.findIndex((d) => d.dialogTurn === dialog.dialogTurn);
        let updatedDialogs;
        if (existingDialogIndex >= 0) {
          updatedDialogs = state.dialogs!.slice();
          DevLogger.log("updatedDialogs", updatedDialogs)
          updatedDialogs[existingDialogIndex] = { ...updatedDialogs[existingDialogIndex], ...dialog };
        } else {
          updatedDialogs = [...state.dialogs!, dialog];
        }
        DevLogger.log("updatedDialogs", updatedDialogs)
        updatedDialogs.sort((a, b) => a.dialogTurn - b.dialogTurn);
        return { dialogs: updatedDialogs };
      }),
    setDialogs: (dialogs: Dialog[]) => set((state) => ({ dialogs: dialogs.sort((a, b) => a.dialogTurn - b.dialogTurn) })),
    setRawScript: (rawScript: string) => set((state) => ({ rawScript: rawScript })),
    nextPage: () => set((state) => ({ scriptingStep: state.scriptingStep + 1 })),
    previousPage: () => set((state) => ({ scriptingStep: state.scriptingStep > 0 ? state.scriptingStep - 1 : 1 })),
    setAudioSettings: (audioSettings: AudioSettings) => set((state) => ({ audioSettings: audioSettings })),
    addCompletedStep: (step: number) =>
      set((state) => ({
        completedSteps: state.completedSteps && !state.completedSteps.includes(step) ? [...state.completedSteps, step] : state.completedSteps,
      })),
    resetScript: () =>
      set(() => ({
        scriptingStep: 1,
        series: undefined,
        episode: undefined,
        rawScript: "",
        dialogs: [],
        audioSettings: { voiceStability: 0.5, voiceSimilarity: 0.75, voiceStyle: 0 },
        completedSteps: [],
      })),
  };
});

interface EditCreateScriptState {
  series?: Series | null;
  setSeries: (series: Series) => void;
  episode?: Episode | null;
  setEpisode: (episode: Episode) => void;
  episodeScriptInfo?: EpisodeScriptInfo | null;
  setEpisodeScriptInfo: (episodeScriptInfo: EpisodeScriptInfo) => void;
  dialogs?: Dialog[];
  dialogTurns: DialogTurn[];
  setDialogs: (dialogs: Dialog[]) => void;
  setDialogTurns?: (dialogTurns: DialogTurn[]) => void;
  upsertDialog: (dialog: Dialog) => void;
  resetDialogs: () => void;
  seriesCharacters: string[];
  setSeriesCharacters: (seriesCharacters: string[]) => void;
  audioSettings?: Partial<AudioSettings>;
  setAudioSettings: (audioSettings: AudioSettings) => void;
  setCurrentWordLink?: (dialogTurn: number, wordStartIndex: number, wordDefinition: WordDefinition) => void;
  setWordLinks?: (dialogTurn: number, wordLinkDetails: DialogWordLinkDetails[]) => void;
  getCurrentWordLink?: (dialogTurn: number, wordStartIndex: number) => WordDefinition;
  deleteWordLink?: (dialogTurn: number, wordStartIndex: number) => void;
}

export const useEditScriptStoreStore = create<EditCreateScriptState>((set) => {
  return {
    scriptingStep: 1,
    series: null,
    episode: null,
    rawScript: "",
    dialogs: [],
    dialogTurns: [],
    seriesCharacters: [],
    setSeriesCharacters: (seriesCharacters: string[]) => set((state) => ({ seriesCharacters: seriesCharacters })),
    audioSettings: { voiceStability: 0.5, voiceSimilarity: 0.75, voiceStyle: 0 },
    setSeries: (series: Series) => set((state) => ({ series: series })),
    setEpisode: (episode: Episode) => set((state) => ({ episode: episode })),
    setDialogs: (dialogs: Dialog[]) => set((state) => ({ dialogs: dialogs.sort((a, b) => a.dialogTurn - b.dialogTurn) })),
    upsertDialog: (dialog: any) =>
      set((state) => {
        const existingDialogIndex = state.dialogs!.findIndex((d) => d.dialogTurn === dialog.dialogTurn);
        let updatedDialogs;
        if (existingDialogIndex >= 0) {
          const updatedDialogs = state.dialogs!.slice();
          updatedDialogs[existingDialogIndex] = { ...updatedDialogs[existingDialogIndex], ...dialog };
          return { dialogs: updatedDialogs };
        } else {
          updatedDialogs = [...state.dialogs!, dialog];
        }
        // Sort dialogs by dialogTurn
        updatedDialogs.sort((a, b) => a.dialogTurn - b.dialogTurn);

        return { dialogs: updatedDialogs };
      }),
    setAudioSettings: (audioSettings: AudioSettings) => set((state) => ({ audioSettings: audioSettings })),
    resetDialogs: () => {
      set((state) => {
        return { dialogs: [] };
      });
    },
    setDialogTurns: (dialogTurns: DialogTurn[]) => set((state) => ({ dialogTurns: dialogTurns })),
    resetScript: () =>
      set(() => ({
        scriptingStep: 1,
        series: undefined,
        episode: undefined,
        rawScript: "",
        dialogs: [],
        audioSettings: { voiceStability: 0.5, voiceSimilarity: 0.75, voiceStyle: 0 },
        completedSteps: [],
      })),
    setWordLinks: (dialogTurn: number, wordLinkDetails: DialogWordLinkDetails[]) =>
      set(
        (state): EditCreateScriptState => ({
          ...state,
          dialogs: state.dialogs!.map((dialog): Dialog => {
            if (dialog.dialogTurn === dialogTurn) {
              return new Dialog({
                ...dialog,
                wordLinkDetails: wordLinkDetails,
              } as Dialog);
            }
            return dialog;
          }),
        })
      ),
    setCurrentWordLink: (dialogTurn: number, wordStartIndex: number, wordDefinition: WordDefinition) =>
      set(
        (state): EditCreateScriptState => ({
          ...state,
          dialogs: state.dialogs!.map((dialog): Dialog => {
            if (dialog.dialogTurn === dialogTurn) {
              return new Dialog({
                ...dialog,
                wordLinkDetails: dialog.wordLinkDetails!.map((wordLinkDetail) => {
                  if (wordLinkDetail.startIndex === wordStartIndex) {
                    DevLogger.log("wordLinkDetail", wordLinkDetail, wordDefinition);
                    return { ...wordLinkDetail, current: wordDefinition } as DialogWordLinkDetails;
                  }
                  return wordLinkDetail as DialogWordLinkDetails;
                }),
              } as Dialog);
            }
            return dialog;
          }),
        })
      ),
    getCurrentWordLink: (dialogTurn: number, wordStartIndex: number) => {
      const dialog: Dialog = useEditScriptStoreStore.getState().dialogs!.find((dialog) => dialog.dialogTurn === dialogTurn);
      return dialog?.wordLinkDetails?.find((wordLinkDetail) => wordLinkDetail.startIndex === wordStartIndex)?.current;
    },
    deleteWordLink: (dialogTurn: number, wordStartIndex: number) => {
      set(
        (state): EditCreateScriptState => ({
          ...state,
          dialogs: state.dialogs!.map((dialog): Dialog => {
            if (dialog.dialogTurn === dialogTurn) {
              return {
                ...dialog,
                wordLinkDetails: dialog.wordLinkDetails!.map((wordLinkDetail) => {
                  if (wordLinkDetail.startIndex === wordStartIndex) {
                    return { ...wordLinkDetail, current: null };
                  }
                  return wordLinkDetail;
                }),
              } as Dialog;
            }
            return dialog;
          }),
        })
      );
    },
    setEpisodeScriptInfo: (episodeScriptInfo: EpisodeScriptInfo) => set((state) => ({ episodeScriptInfo: episodeScriptInfo })),
  };
});
