// src/services/AudioService.ts

import DevLogger from "./Logger";

class AudioService {
  private static instance: AudioService;
  private currentAudio: HTMLAudioElement | null = null;
  private currentaudioUrl: string | null = null;
  private constructor() { }

  public static getInstance(): AudioService {
    if (!AudioService.instance) {
      AudioService.instance = new AudioService();
    }
    return AudioService.instance;
  }

  private handleAudioEnded = () => {
    this.currentaudioUrl = null;
    this.currentAudio = null;
  };

  public play(audioSrc: string): void {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.removeEventListener("ended", this.handleAudioEnded);
      this.currentAudio = null;
      this.currentaudioUrl = null;
    }

    const timpstamp = new Date().getTime();
    this.currentAudio = new Audio(audioSrc + "?version=" + timpstamp); // Ensure that the audio is played with a new timestamp to prevent caching.
    this.currentAudio.play();
    this.currentAudio.addEventListener("ended", this.handleAudioEnded);
    this.currentaudioUrl = audioSrc;
  }

  public getCurrentaudioUrl(): string | null {
    return this.currentaudioUrl;
  }
}

const AudioServiceInstance = AudioService.getInstance();
export default AudioServiceInstance;
