import ScriptApiInstance from "business_logic/script/ScriptApi";
import { CreateDialogAudioRequest, CreateDialogTranslationRequest, CreateMultipleChoicesRequest, CreateQuizExplanationRequest, Dialog, GetQuizWordInfoResponse } from "business_logic/script/scriptModels";
import { useCreateScriptStore } from "business_logic/script/scriptStore";
import WordApiInstance from "business_logic/word/WordApi";
import { useMutation } from "react-query";
import DevLogger from "services/Logger";
import DialogParser from "../components/DialogParser";
import { set } from "lodash";
import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import { CreateWordImageRequest } from "business_logic/word/wordModels";

export default function useCreateContent(scriptStore) {

    // useCreateContent is a hook used to create translation, quizes, audio and more.
    // But it also updates the createScriptStore state with the fetched data directly.
    // State management is handled here to ensure that all the steps can run sequentially in full automation.

    const { series, episode, dialogs, rawScript, upsertDialog, setDialogs, translationSpeechStyle } = scriptStore;

    const parseScript = () => {
        return new Promise<void>((resolve) => {
            const rawScriptLines: string[] = rawScript.split("\n").filter((line: string) => line.trim() !== "");
            const parsedScript: Partial<Dialog>[] = [];

            rawScriptLines.forEach((line: string, index: number) => {
                const dialogEntry: Partial<Dialog> = {
                    dialogTurn: parsedScript.length + 1,
                    dialogType: DialogParser.parseCharacterName(line) !== "" ? "dialog" : "narration",
                    targetScript: DialogParser.parseTargetScript(line),
                    backgroundImageUrl: "",
                    characterName: DialogParser.parseCharacterName(line),
                    characterExpression: "",
                    characterClothes: "",
                    imageDirection: DialogParser.parseImageDirection(index, DialogParser.parseCharacterName(line), parsedScript),
                    quizWord: DialogParser.parseQuizWord(line),
                    isQuiz: DialogParser.parseQuizWord(line) !== "",
                    objectImage: "",
                };
                parsedScript.push(dialogEntry);
            });

            parsedScript.forEach((item, index) => {
                item.imageDirection = DialogParser.parseImageDirection(index, item.characterName!, parsedScript);
            });
            setDialogs(parsedScript);
            resolve();
        });
    };

    const createDialogTranslation = useMutation(async () => {
        const createDialogTranslationRequest: CreateDialogTranslationRequest[] = dialogs.map((dialog) => {
            return {
                dialogTurn: dialog.dialogTurn,
                targetScript: dialog.targetScript,
                characterName: dialog.characterName,
                quizWord: dialog.quizWord,
            }
        });
        return await ScriptApiInstance.createDialogTranslation(createDialogTranslationRequest, translationSpeechStyle);
    }, {
        onSuccess: (response) => {
            response.forEach((translation) => {
                upsertDialog(translation);
            });
        }
    });

    const getQuizWordBase = useMutation(
        async () => {
            const currentDialogs = useCreateScriptStore.getState().dialogs;
            return await WordApiInstance.getQuizWordInfo(currentDialogs);
        },
        {
            onSuccess: (response: GetQuizWordInfoResponse[]) => {
                const newDialogInfos = response.map((quizWordInfo) => {
                    const definitionWithImage = quizWordInfo.quizWordInfo?.definitions?.filter(definition => definition.imageUrl)[0];
                    return {
                        dialogTurn: quizWordInfo.dialogTurn,
                        quizWord: quizWordInfo.quizWord ?? '',
                        quizWordBase: quizWordInfo.quizWordInfo?.word ?? '',
                        quizWordDefinition: definitionWithImage ?? null
                    };
                });
                newDialogInfos.forEach((dialogInfo: Partial<Dialog>) => {
                    upsertDialog(dialogInfo);
                });
            },
            onError: (error: Error) => console.error('Error fetching quiz word info:', error)
        }
    );

    const createMultipleChoice = useMutation(
        async () => {
            const createMultipleChoiceRequest: CreateMultipleChoicesRequest[] = dialogs
                .filter((dialog) => dialog.quizWord !== "")
                .map((dialog) => ({
                    dialogTurn: dialog.dialogTurn,
                    characterName: dialog.characterName,
                    targetScript: dialog.targetScript,
                    quizWord: dialog.quizWord,
                }));

            if (createMultipleChoiceRequest.length === 0) return;
            return await ScriptApiInstance.createMultipleChoice(createMultipleChoiceRequest);
        },
        {
            onSuccess: (response) => {
                if (response) {
                    response.forEach((multipleChoice: any) => {
                        upsertDialog(multipleChoice);
                    });
                }
            }
        }
    );

    const createQuizExplanation = useMutation(
        async () => {
            const payload: CreateQuizExplanationRequest[] = dialogs
                .filter((dialog) => dialog.quizWord !== "")
                .map((dialog) => ({
                    dialogTurn: dialog.dialogTurn!,
                    dialog: dialog.baseScript!,
                    characterName: dialog.characterName!,
                    quizWord: dialog.quizWord!,
                    multipleChoices: dialog.multipleChoices!,
                }));
            return await ScriptApiInstance.createQuizExplanation(payload);
        }, {
        onSuccess: (response) => {
            response.forEach((quizExplanation: any) => {
                upsertDialog(quizExplanation as Partial<Dialog>);
            });
        }
    }
    );

    const createDialogAudio = useMutation(async () => {
        const createDialogAudioRequest: CreateDialogAudioRequest[] = dialogs.map((dialog) => {
            return {
                dialogTurn: dialog.dialogTurn,
                characterName: dialog.characterName,
                quizWord: dialog.quizWord,
                targetScript: dialog.targetScript,
                audioSettings: useCreateScriptStore.getState().audioSettings,
            };
        });
        return await ScriptApiInstance.createDialogAudio({
            seriesId: series!.id!,
            episodeId: episode!.id!,
            payload: createDialogAudioRequest,
        })
    }, {
        onSuccess: (response) => {
            response.forEach((audioResponse: Partial<Dialog>) => {
                upsertDialog(audioResponse);
            }
            );
        }
    })

    const createWordImages = useMutation(async () => {

        const featureWordInfos = await EpisodeApiInstance.getEpisodeFeatureWords(episode!.id!);
        const imageLessFeatureWordInfos = featureWordInfos.filter(
            (featureWordInfo) => !featureWordInfo.wordDefinition.imageUrl);

        if (imageLessFeatureWordInfos.length !== 0) {
            const createNewWordImagesRequest: CreateWordImageRequest[] = imageLessFeatureWordInfos.map((featureWordInfo) => {
                return {
                    wordDefinitionId: featureWordInfo.wordDefinition.id,
                    word: featureWordInfo.wordInfo.word
                };
            });
            await WordApiInstance.createWordImages(createNewWordImagesRequest, true);
        }
    });

    const insertScript = useMutation(
        async () => { await ScriptApiInstance.createEpisodeScript(); }
    )

    async function* createAll() {
        const totalSteps = 5;
        let currentStep = 0;

        yield { progress: (currentStep / totalSteps) * 100, message: "Starting translation..." };
        await createDialogTranslation.mutateAsync();
        currentStep++;

        yield { progress: (currentStep / totalSteps) * 100, message: "Creating multiple choice questions..." };
        await createMultipleChoice.mutateAsync();
        currentStep++;

        yield { progress: (currentStep / totalSteps) * 100, message: "Creating quiz explanations..." };
        await createQuizExplanation.mutateAsync();
        currentStep++;

        yield { progress: (currentStep / totalSteps) * 100, message: "Creating dialog audio..." };
        await createDialogAudio.mutateAsync();
        currentStep++;

        yield { progress: (currentStep / totalSteps) * 100, message: "Inserting Script..." };
        await insertScript.mutateAsync();
        await createWordImages.mutateAsync();
        currentStep++;

        yield { progress: (currentStep / totalSteps) * 100, message: "Successful" };
    }

    return { parseScript, createDialogTranslation, getQuizWordBase, createMultipleChoice, createQuizExplanation, createDialogAudio, createWordImages, insertScript, createAll };

}