import UploadApiInstance from "business_logic/uploadAsset/UploadAssetApi";
import SaveButton from "presentation/components/buttons/SaveButton";
import FileUploadDropzone from "presentation/components/dropzone/Dropzone";
import WorkInProgressOverlay from "presentation/components/workInProgressOverlay/WorkInProgressOverlay";
import { useState } from "react";

export default function UploadSoundsTab() {
  const [bgms, setBgms] = useState<File[]>([]);
  const acceptedFileTypes = ["audio/*"];
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      bgms.length > 0 && (await UploadApiInstance.uploadMusic(bgms));
      setBgms([]);
      alert("업로드 완료");
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="upload-bgm-tab max-width">
      <WorkInProgressOverlay isProcessing={isLoading} />
      <div className="upload-containers flex-row">
        <FileUploadDropzone title="배경 음악"
          label="1분으로 자른 후 압축되어 업로드됩니다."
          files={bgms} onFilesChange={setBgms} acceptedFileTypes={acceptedFileTypes} />
      </div>

      <SaveButton handleClick={handleUpload} />
    </div>
  );
}
