const logImageUrlMap = { 
    "홈 바텀 탭 0 클릭" : "tab_0",
    "홈 바텀 탭 1 클릭" : "tab_1",
    "홈 바텀 탭 2 클릭" : "tab_2",

    "젬 아이콘 클릭" : "gem_store",
    "광고로 젬 충전 클릭" : "gem_charge",

    "진행중인 콘텐츠 탭 클릭" : "dashboard_playing",
    "전체 콘텐츠 탭 클릭" : "dashboard_all",

    "프로필 문의 버튼 클릭" : "profile_inquiry",
    "프로필 설정 아이콘 클릭" : "profile_setting",
    // "프로필 설정 아이콘 클릭" : "profile_noti",
    "프로필 달력 ${date.month}월달 이동" : "profile_calendar",

    "단어장 뜻가리기 토글 클릭" : "vocab_tab__meaning_toggle",
    "단어장 토익 탭 클릭" : "voca_toeic",
    "단어장 저장한 단어 탭 클릭" : "voca_saved",
    "단어장 정렬 드랍다운 $value 설정" : "voca_dropdown",
    "단어장 오답 필터 클릭" : "voca_incorrect_toggle",
    "단어장 출제율 필터 클릭" : "voca_highrate_toggle",
    // "단어장 저장탭 ${userSavedWord.wordInfo.word} 단어 클릭" : "saved_word_info",
    // "단어장 저장탭 ${userSavedWord.wordInfo.word} 단어 음성 재생 클릭"
    // "단어장 토익탭 ${widget.wordInfo.word} 단어 카드 클릭" : "toeic_word_info",
    // "단어장 토익탭 ${widget.wordInfo.word} 단어 뜻보기 클릭" : "toeic_word_show_definition",
    // "단어장 토익탭 ${widget.wordInfo.word} 단어 음성 재생 클릭" : "toeic_word_play",

}

export default logImageUrlMap;



// const logImageUrlMap = {
//     "홈 바텀 탭 0 클릭" : "home_bottom_0",
//     "홈 바텀 탭 1 클릭" : "home_bottom_1",
//     "홈 바텀 탭 2 클릭" : "home_bottom_2",
//     "전체 콘텐츠 봉순 포스터 클릭" : "bongsoon_series_info",
//     "전체 콘텐츠 탐정엠버 포스터 클릭" : "ember_series_info",
//     "전체 콘텐츠 Love is near blind 포스터 클릭" : "lib_series_info",
//     "전체 콘텐츠 순애로운 회사생활 포스터 클릭" : "plo_series_info",
//     "전체 콘텐츠 이상한 나라의 앨리스 포스터 클릭" : "alice_series_info",
//     "젬 아이콘 클릭" : "gem_store",
//     "진행중인 콘텐츠 탭 클릭" : "dashboard_playing",
//     "전체 콘텐츠 탭 클릭" : "dashboard_all",
//     "프로필 문의 버튼 클릭" : "profile_inquiry",
//     "프로필 설정 아이콘 클릭" : "profile_setting",
//     "프로필 설정 아이콘 클릭" : "profile_noti",
//     "프로필 달력 ${date.month}월달 이동" : "profile_calendar",
//     "단어장 뜻가리기 토글 클릭" : "voca_meaning_toggle",
//     "단어장 토익 탭 클릭" : "voca_toeic",
//     "단어장 저장한 단어 탭 클릭" : "voca_saved",
//     "단어장 정렬 드랍다운 $value 설정" : "voca_dropdown",
//     "단어장 오답 필터 클릭" : "voca_incorrect_toggle",
//     "단어장 출제율 필터 클릭" : "voca_highrate_toggle",
//     "단어장 저장탭 ${userSavedWord.wordInfo.word} 단어 클릭" : "saved_word_info",
//     "단어장 저장탭 ${userSavedWord.wordInfo.word} 단어 음성 재생 클릭",
//     "단어장 토익탭 ${widget.wordInfo.word} 단어 카드 클릭" : "toeic_word_info",
//     "단어장 토익탭 ${widget.wordInfo.word} 단어 뜻보기 클릭" : "toeic_word_show_definition",
//     "단어장 토익탭 ${widget.wordInfo.word} 단어 음성 재생 클릭",
//     "시리즈 봉순 시작 버튼 클릭" : "series_bongsoon_start"
//     "시리즈 탐정엠버 시작 버튼 클릭" : "series_ember_start"
//     "시리즈 Love is near blind 시작 버튼 클릭" : "series_lib_start",
//     "시리즈 순애로운 회사생활 시작 버튼 클릭" : "series_plo_start",
//     "시리즈 이상한 나라의 앨리스 시작 버튼 클릭" : "series_alice_start",

//     "봉순 에피소드 1 구매 클릭" : "bongsoon_1_play",
//     "봉순 에피소드 2 구매 클릭" : "bongsoon_2_play",
//     "봉순 에피소드 3 구매 클릭" : "bongsoon_3_play",
//     "봉순 에피소드 4 구매 클릭" : "bongsoon_4_play",
//     "봉순 에피소드 5 구매 클릭" : "bongsoon_5_play",
//     "봉순 에피소드 6 구매 클릭" : "bongsoon_6_play",
//     "봉순 에피소드 7 구매 클릭" : "bongsoon_7_play",
//     "봉순 에피소드 8 구매 클릭" : "bongsoon_8_play",
//     "봉순 에피소드 9 구매 클릭" : "bongsoon_9_play",
//     "봉순 에피소드 10 구매 클릭" : "bongsoon_10_play",
//     "봉순 에피소드 11 구매 클릭" : "bongsoon_11_play",
//     "봉순 에피소드 12 구매 클릭" : "bongsoon_12_play",
//     "봉순 에피소드 13 구매 클릭" : "bongsoon_13_play",
//     "봉순 에피소드 14 구매 클릭" : "bongsoon_14_play",
  
//     "탐정엠버 에피소드 0 구매 클릭" : "ember_0_play",
//     "탐정엠버 에피소드 1 구매 클릭" : "ember_1_play",
//     "탐정엠버 에피소드 2 구매 클릭" : "ember_2_play",
//     "탐정엠버 에피소드 3 구매 클릭" : "ember_3_play",
//     "탐정엠버 에피소드 4 구매 클릭" : "ember_4_play",
//     "탐정엠버 에피소드 5 구매 클릭" : "ember_5_play",
//     "탐정엠버 에피소드 6 구매 클릭" : "ember_6_play",
//     "탐정엠버 에피소드 7 구매 클릭" : "ember_7_play",
//     "탐정엠버 에피소드 8 구매 클릭" : "ember_8_play",
//     "탐정엠버 에피소드 9 구매 클릭" : "ember_9_play",
//     "탐정엠버 에피소드 10 구매 클릭" : "ember_10_play",
//     "탐정엠버 에피소드 11 구매 클릭" : "ember_11_play",
//     "탐정엠버 에피소드 12 구매 클릭" : "ember_12_play",
//     "탐정엠버 에피소드 13 구매 클릭" : "ember_13_play",
//     "탐정엠버 에피소드 14 구매 클릭" : "ember_14_play",

//     "Love is near blind 에피소드 1 구매 클릭" : "lib_1_play",
//     "Love is near blind 에피소드 2 구매 클릭" : "lib_2_play",
//     "Love is near blind 에피소드 3 구매 클릭" : "lib_3_play",
//     "Love is near blind 에피소드 4 구매 클릭" : "lib_4_play",
//     "Love is near blind 에피소드 5 구매 클릭" : "lib_5_play",
//     "Love is near blind 에피소드 6 구매 클릭" : "lib_6_play",
//     "Love is near blind 에피소드 7 구매 클릭" : "lib_7_play",
//     "Love is near blind 에피소드 8 구매 클릭" : "lib_8_play",
//     "Love is near blind 에피소드 9 구매 클릭" : "lib_9_play",
//     "Love is near blind 에피소드 10 구매 클릭" : "lib_10_play",
//     "Love is near blind 에피소드 11 구매 클릭" : "lib_11_play",
//     "Love is near blind 에피소드 12 구매 클릭" : "lib_12_play",
//     "Love is near blind 에피소드 13 구매 클릭" : "lib_13_play",
//     "Love is near blind 에피소드 14 구매 클릭" : "lib_14_play",
//     "Love is near blind 에피소드 15 구매 클릭" : "lib_15_play",
//     "Love is near blind 에피소드 16 구매 클릭" : "lib_16_play",
//     "Love is near blind 에피소드 17 구매 클릭" : "lib_17_play",
//     "Love is near blind 에피소드 18 구매 클릭" : "lib_18_play",
//     "Love is near blind 에피소드 19 구매 클릭" : "lib_19_play",
//     "Love is near blind 에피소드 20 구매 클릭" : "lib_20_play",

//     "순애로운 회사생활 에피소드 1 구매 클릭" : "plo_1_play",
//     "순애로운 회사생활 에피소드 2 구매 클릭" : "plo_2_play",
//     "순애로운 회사생활 에피소드 3 구매 클릭" : "plo_3_play",
//     "순애로운 회사생활 에피소드 4 구매 클릭" : "plo_4_play",
//     "순애로운 회사생활 에피소드 5 구매 클릭" : "plo_5_play",
//     "순애로운 회사생활 에피소드 6 구매 클릭" : "plo_6_play",
//     "순애로운 회사생활 에피소드 7 구매 클릭" : "plo_7_play",
//     "순애로운 회사생활 에피소드 8 구매 클릭" : "plo_8_play",
//     "순애로운 회사생활 에피소드 9 구매 클릭" : "plo_9_play",

//     "이상한 나라의 앨리스 에피소드 1 구매 클릭" : "alice_1_play",
//     "이상한 나라의 앨리스 에피소드 2 구매 클릭" : "alice_2_play",
//     "이상한 나라의 앨리스 에피소드 3 구매 클릭" : "alice_3_play",
//     "이상한 나라의 앨리스 에피소드 4 구매 클릭" : "alice_4_play",
//     "이상한 나라의 앨리스 에피소드 5 구매 클릭" : "alice_5_play",
//     "이상한 나라의 앨리스 에피소드 6 구매 클릭" : "alice_6_play",

//     "광고로 젬 충전 클릭" : "adv_gem_charge"

//     "시리즈 ${cubit!.state.series!.title} 에피소드 ${episodePreview.index} 플레이 클릭"------------
//     "시리즈 봉순 에피소드 1 플레이 클릭" : "bongsoon_1_play"
//     "시리즈 봉순 에피소드 2 플레이 클릭" : "bongsoon_2_play"
//     "시리즈 봉순 에피소드 3 플레이 클릭" : "bongsoon_3_play"
//     "시리즈 봉순 에피소드 4 플레이 클릭" : "bongsoon_4_play"
//     "시리즈 봉순 에피소드 5 플레이 클릭" : "bongsoon_5_play"
//     "시리즈 봉순 에피소드 6 플레이 클릭" : "bongsoon_6_play"
//     "시리즈 봉순 에피소드 7 플레이 클릭" : "bongsoon_7_play"
//     "시리즈 봉순 에피소드 8 플레이 클릭" : "bongsoon_8_play"
//     "시리즈 봉순 에피소드 9 플레이 클릭" : "bongsoon_9_play"
//     "시리즈 봉순 에피소드 10 플레이 클릭" : "bongsoon_10_play"
//     "시리즈 봉순 에피소드 11 플레이 클릭" : "bongsoon_11_play"
//     "시리즈 봉순 에피소드 12 플레이 클릭" : "bongsoon_12_play"
//     "시리즈 봉순 에피소드 13 플레이 클릭" : "bongsoon_13_play"
//     "시리즈 봉순 에피소드 14 플레이 클릭" : "bongsoon_14_play"

//     "시리즈 탐정엠버 에피소드 0 플레이 클릭" : "ember_0_play"
//     "시리즈 탐정엠버 에피소드 1 플레이 클릭" : "ember_1_play"
//     "시리즈 탐정엠버 에피소드 2 플레이 클릭" : "ember_2_play"
//     "시리즈 탐정엠버 에피소드 3 플레이 클릭" : "ember_3_play"
//     "시리즈 탐정엠버 에피소드 4 플레이 클릭" : "ember_4_play"
//     "시리즈 탐정엠버 에피소드 5 플레이 클릭" : "ember_5_play"
//     "시리즈 탐정엠버 에피소드 6 플레이 클릭" : "ember_6_play"
//     "시리즈 탐정엠버 에피소드 7 플레이 클릭" : "ember_7_play"
//     "시리즈 탐정엠버 에피소드 8 플레이 클릭" : "ember_8_play"
//     "시리즈 탐정엠버 에피소드 9 플레이 클릭" : "ember_9_play"
//     "시리즈 탐정엠버 에피소드 10 플레이 클릭" : "ember_10_play"
//     "시리즈 탐정엠버 에피소드 11 플레이 클릭" : "ember_11_play"
//     "시리즈 탐정엠버 에피소드 12 플레이 클릭" : "ember_12_play"
//     "시리즈 탐정엠버 에피소드 13 플레이 클릭" : "ember_13_play"
//     "시리즈 탐정엠버 에피소드 14 플레이 클릭" : "ember_14_play"

//     "시리즈 Love is near blind 에피소드 1 플레이 클릭" : "lib_1_play"
//     "시리즈 Love is near blind 에피소드 2 플레이 클릭" : "lib_2_play"
//     "시리즈 Love is near blind 에피소드 3 플레이 클릭" : "lib_3_play"
//     "시리즈 Love is near blind 에피소드 4 플레이 클릭" : "lib_4_play"
//     "시리즈 Love is near blind 에피소드 5 플레이 클릭" : "lib_5_play"
//     "시리즈 Love is near blind 에피소드 6 플레이 클릭" : "lib_6_play"
//     "시리즈 Love is near blind 에피소드 7 플레이 클릭" : "lib_7_play"
//     "시리즈 Love is near blind 에피소드 8 플레이 클릭" : "lib_8_play"
//     "시리즈 Love is near blind 에피소드 9 플레이 클릭" : "lib_9_play"
//     "시리즈 Love is near blind 에피소드 10 플레이 클릭" : "lib_10_play"
//     "시리즈 Love is near blind 에피소드 11 플레이 클릭" : "lib_11_play"
//     "시리즈 Love is near blind 에피소드 12 플레이 클릭" : "lib_12_play"
//     "시리즈 Love is near blind 에피소드 13 플레이 클릭" : "lib_13_play"
//     "시리즈 Love is near blind 에피소드 14 플레이 클릭" : "lib_14_play"
//     "시리즈 Love is near blind 에피소드 15 플레이 클릭" : "lib_15_play"
//     "시리즈 Love is near blind 에피소드 16 플레이 클릭" : "lib_16_play"
//     "시리즈 Love is near blind 에피소드 17 플레이 클릭" : "lib_17_play"
//     "시리즈 Love is near blind 에피소드 18 플레이 클릭" : "lib_18_play"
//     "시리즈 Love is near blind 에피소드 19 플레이 클릭" : "lib_19_play"
//     "시리즈 Love is near blind 에피소드 20 플레이 클릭" : "lib_20_play"

//     "시리즈 순애로운 회사생활 에피소드 1 플레이 클릭" : "plo_1_play"
//     "시리즈 순애로운 회사생활 에피소드 2 플레이 클릭" : "plo_2_play"
//     "시리즈 순애로운 회사생활 에피소드 3 플레이 클릭" : "plo_3_play"
//     "시리즈 순애로운 회사생활 에피소드 4 플레이 클릭" : "plo_4_play"
//     "시리즈 순애로운 회사생활 에피소드 5 플레이 클릭" : "plo_5_play"
//     "시리즈 순애로운 회사생활 에피소드 6 플레이 클릭" : "plo_6_play"
//     "시리즈 순애로운 회사생활 에피소드 7 플레이 클릭" : "plo_7_play"
//     "시리즈 순애로운 회사생활 에피소드 8 플레이 클릭" : "plo_8_play"
//     "시리즈 순애로운 회사생활 에피소드 9 플레이 클릭" : "plo_9_play"

//     "시리즈 이상한 나라의 앨리스 에피소드 1 플레이 클릭" : "alice_1_play"
//     "시리즈 이상한 나라의 앨리스 에피소드 2 플레이 클릭" : "alice_2_play"
//     "시리즈 이상한 나라의 앨리스 에피소드 3 플레이 클릭" : "alice_3_play"
//     "시리즈 이상한 나라의 앨리스 에피소드 4 플레이 클릭" : "alice_4_play"
//     "시리즈 이상한 나라의 앨리스 에피소드 5 플레이 클릭" : "alice_5_play"
//     "시리즈 이상한 나라의 앨리스 에피소드 6 플레이 클릭" : "alice_6_play"

//     "시리즈 ${cubit.state.series!.title} 에피소드 ${episodePreview.index} 정보 클릭"--------------
//     "시리즈 봉순 에피소드 1 정보 클릭" : "bongsoon_1_info"
//     "시리즈 봉순 에피소드 2 정보 클릭" : "bongsoon_2_info"
//     "시리즈 봉순 에피소드 3 정보 클릭" : "bongsoon_3_info"
//     "시리즈 봉순 에피소드 4 정보 클릭" : "bongsoon_4_info"
//     "시리즈 봉순 에피소드 5 정보 클릭" : "bongsoon_5_info"
//     "시리즈 봉순 에피소드 6 정보 클릭" : "bongsoon_6_info"
//     "시리즈 봉순 에피소드 7 정보 클릭" : "bongsoon_7_info"
//     "시리즈 봉순 에피소드 8 정보 클릭" : "bongsoon_8_info"
//     "시리즈 봉순 에피소드 9 정보 클릭" : "bongsoon_9_info"
//     "시리즈 봉순 에피소드 10 정보 클릭" : "bongsoon_10_info"
//     "시리즈 봉순 에피소드 11 정보 클릭" : "bongsoon_11_info"
//     "시리즈 봉순 에피소드 12 정보 클릭" : "bongsoon_12_info"
//     "시리즈 봉순 에피소드 13 정보 클릭" : "bongsoon_13_info"
//     "시리즈 봉순 에피소드 14 정보 클릭" : "bongsoon_14_info"

//     "시리즈 탐정엠버 에피소드 0 정보 클릭" : "ember_0_info"
//     "시리즈 탐정엠버 에피소드 1 정보 클릭" : "ember_1_info"
//     "시리즈 탐정엠버 에피소드 2 정보 클릭" : "ember_2_info"
//     "시리즈 탐정엠버 에피소드 3 정보 클릭" : "ember_3_info"
//     "시리즈 탐정엠버 에피소드 4 정보 클릭" : "ember_4_info"
//     "시리즈 탐정엠버 에피소드 5 정보 클릭" : "ember_5_info"
//     "시리즈 탐정엠버 에피소드 6 정보 클릭" : "ember_6_info"
//     "시리즈 탐정엠버 에피소드 7 정보 클릭" : "ember_7_info"
//     "시리즈 탐정엠버 에피소드 8 정보 클릭" : "ember_8_info"
//     "시리즈 탐정엠버 에피소드 9 정보 클릭" : "ember_9_info"
//     "시리즈 탐정엠버 에피소드 10 정보 클릭" : "ember_10_info"
//     "시리즈 탐정엠버 에피소드 11 정보 클릭" : "ember_11_info"
//     "시리즈 탐정엠버 에피소드 12 정보 클릭" : "ember_12_info"
//     "시리즈 탐정엠버 에피소드 13 정보 클릭" : "ember_13_info"
//     "시리즈 탐정엠버 에피소드 14 정보 클릭" : "ember_14_info"

//     "시리즈 Love is near blind 에피소드 1 정보 클릭" : "lib_1_info"
//     "시리즈 Love is near blind 에피소드 2 정보 클릭" : "lib_2_info"
//     "시리즈 Love is near blind 에피소드 3 정보 클릭" : "lib_3_info"
//     "시리즈 Love is near blind 에피소드 4 정보 클릭" : "lib_4_info"
//     "시리즈 Love is near blind 에피소드 5 정보 클릭" : "lib_5_info"
//     "시리즈 Love is near blind 에피소드 6 정보 클릭" : "lib_6_info"
//     "시리즈 Love is near blind 에피소드 7 정보 클릭" : "lib_7_info"
//     "시리즈 Love is near blind 에피소드 8 정보 클릭" : "lib_8_info"
//     "시리즈 Love is near blind 에피소드 9 정보 클릭" : "lib_9_info"
//     "시리즈 Love is near blind 에피소드 10 정보 클릭" : "lib_10_info"
//     "시리즈 Love is near blind 에피소드 11 정보 클릭" : "lib_11_info"
//     "시리즈 Love is near blind 에피소드 12 정보 클릭" : "lib_12_info"
//     "시리즈 Love is near blind 에피소드 13 정보 클릭" : "lib_13_info"
//     "시리즈 Love is near blind 에피소드 14 정보 클릭" : "lib_14_info"
//     "시리즈 Love is near blind 에피소드 15 정보 클릭" : "lib_15_info"
//     "시리즈 Love is near blind 에피소드 16 정보 클릭" : "lib_16_info"
//     "시리즈 Love is near blind 에피소드 17 정보 클릭" : "lib_17_info"
//     "시리즈 Love is near blind 에피소드 18 정보 클릭" : "lib_18_info"
//     "시리즈 Love is near blind 에피소드 19 정보 클릭" : "lib_19_info"
//     "시리즈 Love is near blind 에피소드 20 정보 클릭" : "lib_20_info"

//     "시리즈 순애로운 회사생활 에피소드 1 정보 클릭" : "plo_1_info"
//     "시리즈 순애로운 회사생활 에피소드 2 정보 클릭" : "plo_2_info"
//     "시리즈 순애로운 회사생활 에피소드 3 정보 클릭" : "plo_3_info"
//     "시리즈 순애로운 회사생활 에피소드 4 정보 클릭" : "plo_4_info"
//     "시리즈 순애로운 회사생활 에피소드 5 정보 클릭" : "plo_5_info"
//     "시리즈 순애로운 회사생활 에피소드 6 정보 클릭" : "plo_6_info"
//     "시리즈 순애로운 회사생활 에피소드 7 정보 클릭" : "plo_7_info"
//     "시리즈 순애로운 회사생활 에피소드 8 정보 클릭" : "plo_8_info"
//     "시리즈 순애로운 회사생활 에피소드 9 정보 클릭" : "plo_9_info"
//     "시리즈 이상한 나라의 앨리스 에피소드 1 정보 클릭" : "alice_1_info"
//     "시리즈 이상한 나라의 앨리스 에피소드 2 정보 클릭" : "alice_2_info"
//     "시리즈 이상한 나라의 앨리스 에피소드 3 정보 클릭" : "alice_3_info"
//     "시리즈 이상한 나라의 앨리스 에피소드 4 정보 클릭" : "alice_4_info"
//     "시리즈 이상한 나라의 앨리스 에피소드 5 정보 클릭" : "alice_5_info"
//     "시리즈 이상한 나라의 앨리스 에피소드 6 정보 클릭" : "alice_6_info"

//     "시리즈 ${cubit!.state.series!.title} 뒤로가기 클릭"-----------------
//     "시리즈 봉순 뒤로가기 클릭" : "series_bongsoon_back"
//     "시리즈 탐정엠버 뒤로가기 클릭" : "series_ember_back"
//     "시리즈 Love is near blind 뒤로가기 클릭" : "series_lib_back"
//     "시리즈 순애로운 회사생활 뒤로가기 클릭" : "series_plo_back"
//     "시리즈 이상한 나라의 앨리스 뒤로가기 클릭" : "series_alice_back"

//     "대시보드 진행중탭 0번째 시리즈 탭 클릭" : "playing_series_tab_0"
//     "대시보드 진행중탭 1번째 시리즈 탭 클릭" : "playing_series_tab_1"
//     "대시보드 진행중탭 2번째 시리즈 탭 클릭" : "playing_series_tab_2"
//     "대시보드 진행중탭 3번째 시리즈 탭 클릭" : "playing_series_tab_3"
//     "대시보드 진행중탭 4번째 시리즈 탭 클릭" : "playing_series_tab_4"

//     "대시보드 진행중탭 0번째 시리즈로 스크롤 이동" : "playing_series_scroll_0"
//     "대시보드 진행중탭 1번째 시리즈로 스크롤 이동" : "playing_series_scroll_1"
//     "대시보드 진행중탭 2번째 시리즈로 스크롤 이동" : "playing_series_scroll_2"
//     "대시보드 진행중탭 3번째 시리즈로 스크롤 이동" : "playing_series_scroll_3"
//     "대시보드 진행중탭 4번째 시리즈로 스크롤 이동" : "playing_series_scroll_4"

//     "${widget.seriesTitle} ${widget.episodePreview.index}화 에피소드 정보 페이지 단어 뜻보기 클릭"-------이상함

//     "${widget.seriesTitle} ${widget.episodePreview.index}화 에피소드 정보 페이지 뒤로가기 클릭"
//     "봉순 1화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 2화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 3화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 4화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 5화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 6화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 7화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 8화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 9화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 10화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 11화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 12화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 13화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"
//     "봉순 14화 에피소드 정보 페이지 뒤로가기 클릭" : "bongsoon_detail_back"

//     "탐정엠버 0화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 1화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 2화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 3화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 4화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 5화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 6화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 7화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 8화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 9화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 10화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 11화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 12화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 13화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"
//     "탐정엠버 14화 에피소드 정보 페이지 뒤로가기 클릭" : "ember_detail_back"

//     "Love is near blind 1화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 2화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 3화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 4화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 5화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 6화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 7화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 8화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 9화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 10화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 11화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 12화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 13화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 14화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 15화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 16화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 17화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 18화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 19화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"
//     "Love is near blind 20화 에피소드 정보 페이지 뒤로가기 클릭" : "lib_detail_back"

//     "순애로운 회사생활 1화 에피소드 정보 페이지 뒤로가기 클릭" : "plo_detail_back"
//     "순애로운 회사생활 2화 에피소드 정보 페이지 뒤로가기 클릭" : "plo_detail_back"
//     "순애로운 회사생활 3화 에피소드 정보 페이지 뒤로가기 클릭" : "plo_detail_back"
//     "순애로운 회사생활 4화 에피소드 정보 페이지 뒤로가기 클릭" : "plo_detail_back"
//     "순애로운 회사생활 5화 에피소드 정보 페이지 뒤로가기 클릭" : "plo_detail_back"
//     "순애로운 회사생활 6화 에피소드 정보 페이지 뒤로가기 클릭" : "plo_detail_back"
//     "순애로운 회사생활 7화 에피소드 정보 페이지 뒤로가기 클릭" : "plo_detail_back"
//     "순애로운 회사생활 8화 에피소드 정보 페이지 뒤로가기 클릭" : "plo_detail_back"
//     "순애로운 회사생활 9화 에피소드 정보 페이지 뒤로가기 클릭" : "plo_detail_back"

//     "이상한 나라의 앨리스 1화 에피소드 정보 페이지 뒤로가기 클릭" : "alice_detail_back"
//     "이상한 나라의 앨리스 2화 에피소드 정보 페이지 뒤로가기 클릭" : "alice_detail_back"
//     "이상한 나라의 앨리스 3화 에피소드 정보 페이지 뒤로가기 클릭" : "alice_detail_back"
//     "이상한 나라의 앨리스 4화 에피소드 정보 페이지 뒤로가기 클릭" : "alice_detail_back"
//     "이상한 나라의 앨리스 5화 에피소드 정보 페이지 뒤로가기 클릭" : "alice_detail_back"
//     "이상한 나라의 앨리스 6화 에피소드 정보 페이지 뒤로가기 클릭" : "alice_detail_back"


//     "${widget.seriesTitle} ${widget.episodeIndex}화 에피소드 정보 페이지 단어 뜻보기 클릭"------------이상함
//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 해설 복습 단어 음성 재생"------X
//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 해설 복습 예문 음성 재생"------X


//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 해설 복습 홈으로 돌아가기 클릭"
//     "봉순 1화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 2화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 3화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 4화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 5화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 6화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 7화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 8화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 9화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 10화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 11화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 12화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 13화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"
//     "봉순 14화 해설 복습 홈으로 돌아가기 클릭" : "bongsoon_series_info"

//     "탐정엠버 0화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 1화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 2화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 3화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 4화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 5화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 6화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 7화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 8화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 9화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 10화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 11화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 12화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 13화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"
//     "탐정엠버 14화 해설 복습 홈으로 돌아가기 클릭" : "ember_series_info"

//     "Love is near blind 1화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 2화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 3화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 4화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 5화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 6화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 7화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 8화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 9화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 10화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 11화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 12화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 13화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 14화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 15화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 16화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 17화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 18화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 19화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"
//     "Love is near blind 20화 해설 복습 홈으로 돌아가기 클릭" : "lib_series_info"

//     "순애로운 회사생활 1화 해설 복습 홈으로 돌아가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 2화 해설 복습 홈으로 돌아가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 3화 해설 복습 홈으로 돌아가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 4화 해설 복습 홈으로 돌아가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 5화 해설 복습 홈으로 돌아가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 6화 해설 복습 홈으로 돌아가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 7화 해설 복습 홈으로 돌아가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 8화 해설 복습 홈으로 돌아가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 9화 해설 복습 홈으로 돌아가기 클릭" : "plo_series_info"

//     "이상한 나라의 앨리스 1화 해설 복습 홈으로 돌아가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 2화 해설 복습 홈으로 돌아가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 3화 해설 복습 홈으로 돌아가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 4화 해설 복습 홈으로 돌아가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 5화 해설 복습 홈으로 돌아가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 6화 해설 복습 홈으로 돌아가기 클릭" : "alice_series_info"

//     "진행중인 콘텐츠 ${widget.userSeriesProgress.title} 시작 버튼 클릭"-----------
//     "진행중인 콘텐츠 봉순 시작 버튼 클릭" : "series_bongsoon_start"
//     "진행중인 콘텐츠 탐정엠버 시작 버튼 클릭" : "series_ember_start"
//     "진행중인 콘텐츠 Love is near blind 시작 버튼 클릭" : "series_lib_start"
//     "진행중인 콘텐츠 순애로운 회사생활 시작 버튼 클릭" : "series_plo_start"
//     "진행중인 콘텐츠 이상한 나라의 앨리스 시작 버튼 클릭" : "series_alice_start"

//     "진행중인 콘텐츠 ${widget.userSeriesProgress.title} 정보 아이콘 클릭"------------
//     "진행중인 콘텐츠 봉순 정보 아이콘 클릭" : "bongsoon_series_info"
//     "진행중인 콘텐츠 탐정엠버 정보 아이콘 클릭" : "ember_series_info"
//     "진행중인 콘텐츠 Love is near blind 정보 아이콘 클릭" : "lib_series_info"
//     "진행중인 콘텐츠 순애로운 회사생활 정보 아이콘 클릭" : "plo_series_info"
//     "진행중인 콘텐츠 이상한 나라의 앨리스 정보 아이콘 클릭" : "alice_series_info"

//     "대시보드 진행중탭 시즌 선택 클릭" : "playing_season"

//     "진행중인 콘텐츠 ${widget.userSeriesProgress.title} 에피소드 ${episodeInfo.index} 클릭"----------안됨.이상

//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 인트로 단어 리스트 스크롤"--------------------
//     "봉순 1화 인트로 단어 리스트 스크롤" : "bongsoon_1_intro_word_scroll"
//     "봉순 2화 인트로 단어 리스트 스크롤" : "bongsoon_2_intro_word_scroll"
//     "봉순 3화 인트로 단어 리스트 스크롤" : "bongsoon_3_intro_word_scroll"
//     "봉순 4화 인트로 단어 리스트 스크롤" : "bongsoon_4_intro_word_scroll"
//     "봉순 5화 인트로 단어 리스트 스크롤" : "bongsoon_5_intro_word_scroll"
//     "봉순 6화 인트로 단어 리스트 스크롤" : "bongsoon_6_intro_word_scroll"
//     "봉순 7화 인트로 단어 리스트 스크롤" : "bongsoon_7_intro_word_scroll"
//     "봉순 8화 인트로 단어 리스트 스크롤" : "bongsoon_8_intro_word_scroll"
//     "봉순 9화 인트로 단어 리스트 스크롤" : "bongsoon_9_intro_word_scroll"
//     "봉순 10화 인트로 단어 리스트 스크롤" : "bongsoon_10_intro_word_scroll"
//     "봉순 11화 인트로 단어 리스트 스크롤" : "bongsoon_11_intro_word_scroll"
//     "봉순 12화 인트로 단어 리스트 스크롤" : "bongsoon_12_intro_word_scroll"
//     "봉순 13화 인트로 단어 리스트 스크롤" : "bongsoon_13_intro_word_scroll"
//     "봉순 14화 인트로 단어 리스트 스크롤" : "bongsoon_14_intro_word_scroll"

//     "탐정엠버 0화 인트로 단어 리스트 스크롤" : "ember_0_intro_word_scroll"
//     "탐정엠버 1화 인트로 단어 리스트 스크롤" : "ember_1_intro_word_scroll"
//     "탐정엠버 2화 인트로 단어 리스트 스크롤" : "ember_2_intro_word_scroll"
//     "탐정엠버 3화 인트로 단어 리스트 스크롤" : "ember_3_intro_word_scroll"
//     "탐정엠버 4화 인트로 단어 리스트 스크롤" : "ember_4_intro_word_scroll"
//     "탐정엠버 5화 인트로 단어 리스트 스크롤" : "ember_5_intro_word_scroll"
//     "탐정엠버 6화 인트로 단어 리스트 스크롤" : "ember_6_intro_word_scroll"
//     "탐정엠버 7화 인트로 단어 리스트 스크롤" : "ember_7_intro_word_scroll"
//     "탐정엠버 8화 인트로 단어 리스트 스크롤" : "ember_8_intro_word_scroll"
//     "탐정엠버 9화 인트로 단어 리스트 스크롤" : "ember_9_intro_word_scroll"
//     "탐정엠버 10화 인트로 단어 리스트 스크롤" : "ember_10_intro_word_scroll"
//     "탐정엠버 11화 인트로 단어 리스트 스크롤" : "ember_11_intro_word_scroll"
//     "탐정엠버 12화 인트로 단어 리스트 스크롤" : "ember_12_intro_word_scroll"
//     "탐정엠버 13화 인트로 단어 리스트 스크롤" : "ember_13_intro_word_scroll"
//     "탐정엠버 14화 인트로 단어 리스트 스크롤" : "ember_14_intro_word_scroll"

//     "Love is near blind 1화 인트로 단어 리스트 스크롤" : "lib_1_intro_word_scroll"
//     "Love is near blind 2화 인트로 단어 리스트 스크롤" : "lib_2_intro_word_scroll"
//     "Love is near blind 3화 인트로 단어 리스트 스크롤" : "lib_3_intro_word_scroll"
//     "Love is near blind 4화 인트로 단어 리스트 스크롤" : "lib_4_intro_word_scroll"
//     "Love is near blind 5화 인트로 단어 리스트 스크롤" : "lib_5_intro_word_scroll"
//     "Love is near blind 6화 인트로 단어 리스트 스크롤" : "lib_6_intro_word_scroll"
//     "Love is near blind 7화 인트로 단어 리스트 스크롤" : "lib_7_intro_word_scroll"
//     "Love is near blind 8화 인트로 단어 리스트 스크롤" : "lib_8_intro_word_scroll"
//     "Love is near blind 9화 인트로 단어 리스트 스크롤" : "lib_9_intro_word_scroll"
//     "Love is near blind 10화 인트로 단어 리스트 스크롤" : "lib_10_intro_word_scroll"
//     "Love is near blind 11화 인트로 단어 리스트 스크롤" : "lib_11_intro_word_scroll"
//     "Love is near blind 12화 인트로 단어 리스트 스크롤" : "lib_12_intro_word_scroll"
//     "Love is near blind 13화 인트로 단어 리스트 스크롤" : "lib_13_intro_word_scroll"
//     "Love is near blind 14화 인트로 단어 리스트 스크롤" : "lib_14_intro_word_scroll"
//     "Love is near blind 15화 인트로 단어 리스트 스크롤" : "lib_15_intro_word_scroll"
//     "Love is near blind 16화 인트로 단어 리스트 스크롤" : "lib_16_intro_word_scroll"
//     "Love is near blind 17화 인트로 단어 리스트 스크롤" : "lib_17_intro_word_scroll"
//     "Love is near blind 18화 인트로 단어 리스트 스크롤" : "lib_18_intro_word_scroll"
//     "Love is near blind 19화 인트로 단어 리스트 스크롤" : "lib_19_intro_word_scroll"
//     "Love is near blind 20화 인트로 단어 리스트 스크롤" : "lib_20_intro_word_scroll"

//     "순애로운 회사생활 1화 인트로 단어 리스트 스크롤" : "plo_1_intro_word_scroll"
//     "순애로운 회사생활 2화 인트로 단어 리스트 스크롤" : "plo_2_intro_word_scroll"
//     "순애로운 회사생활 3화 인트로 단어 리스트 스크롤" : "plo_3_intro_word_scroll"
//     "순애로운 회사생활 4화 인트로 단어 리스트 스크롤" : "plo_4_intro_word_scroll"
//     "순애로운 회사생활 5화 인트로 단어 리스트 스크롤" : "plo_5_intro_word_scroll"
//     "순애로운 회사생활 6화 인트로 단어 리스트 스크롤" : "plo_6_intro_word_scroll"
//     "순애로운 회사생활 7화 인트로 단어 리스트 스크롤" : "plo_7_intro_word_scroll"
//     "순애로운 회사생활 8화 인트로 단어 리스트 스크롤" : "plo_8_intro_word_scroll"
//     "순애로운 회사생활 9화 인트로 단어 리스트 스크롤" : "plo_9_intro_word_scroll"

//     "이상한 나라의 앨리스 1화 인트로 단어 리스트 스크롤" : "alice_1_intro_word_scroll"
//     "이상한 나라의 앨리스 2화 인트로 단어 리스트 스크롤" : "alice_2_intro_word_scroll"
//     "이상한 나라의 앨리스 3화 인트로 단어 리스트 스크롤" : "alice_3_intro_word_scroll"
//     "이상한 나라의 앨리스 4화 인트로 단어 리스트 스크롤" : "alice_4_intro_word_scroll"
//     "이상한 나라의 앨리스 5화 인트로 단어 리스트 스크롤" : "alice_5_intro_word_scroll"
//     "이상한 나라의 앨리스 6화 인트로 단어 리스트 스크롤" : "alice_6_intro_word_scroll"

//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 인트로 배경 클릭"
//     "봉순 1화 인트로 배경 클릭" : "www.sdlfksmdlfksdlf.com/bongsoon_1_intro_bg_click.jpeg"
//     "봉순 2화 인트로 배경 클릭" : "bongsoon_2_intro_bg_click"
//     "봉순 3화 인트로 배경 클릭" : "bongsoon_3_intro_bg_click"
//     "봉순 4화 인트로 배경 클릭" : "bongsoon_4_intro_bg_click"
//     "봉순 5화 인트로 배경 클릭" : "bongsoon_5_intro_bg_click"
//     "봉순 6화 인트로 배경 클릭" : "bongsoon_6_intro_bg_click"
//     "봉순 7화 인트로 배경 클릭" : "bongsoon_7_intro_bg_click"
//     "봉순 8화 인트로 배경 클릭" : "bongsoon_8_intro_bg_click"
//     "봉순 9화 인트로 배경 클릭" : "bongsoon_9_intro_bg_click"
//     "봉순 10화 인트로 배경 클릭" : "bongsoon_10_intro_bg_click"
//     "봉순 11화 인트로 배경 클릭" : "bongsoon_11_intro_bg_click"
//     "봉순 12화 인트로 배경 클릭" : "bongsoon_12_intro_bg_click"
//     "봉순 13화 인트로 배경 클릭" : "bongsoon_13_intro_bg_click"
//     "봉순 14화 인트로 배경 클릭" : "bongsoon_14_intro_bg_click"

//     "탐정엠버 0화 인트로 배경 클릭" : "ember_0_intro_bg_click"
//     "탐정엠버 1화 인트로 배경 클릭" : "ember_1_intro_bg_click"
//     "탐정엠버 2화 인트로 배경 클릭" : "ember_2_intro_bg_click"
//     "탐정엠버 3화 인트로 배경 클릭" : "ember_3_intro_bg_click"
//     "탐정엠버 4화 인트로 배경 클릭" : "ember_4_intro_bg_click"
//     "탐정엠버 5화 인트로 배경 클릭" : "ember_5_intro_bg_click"
//     "탐정엠버 6화 인트로 배경 클릭" : "ember_6_intro_bg_click"
//     "탐정엠버 7화 인트로 배경 클릭" : "ember_7_intro_bg_click"
//     "탐정엠버 8화 인트로 배경 클릭" : "ember_8_intro_bg_click"
//     "탐정엠버 9화 인트로 배경 클릭" : "ember_9_intro_bg_click"
//     "탐정엠버 10화 인트로 배경 클릭" : "ember_10_intro_bg_click"
//     "탐정엠버 11화 인트로 배경 클릭" : "ember_11_intro_bg_click"
//     "탐정엠버 12화 인트로 배경 클릭" : "ember_12_intro_bg_click"
//     "탐정엠버 13화 인트로 배경 클릭" : "ember_13_intro_bg_click"
//     "탐정엠버 14화 인트로 배경 클릭" : "ember_14_intro_bg_click"

//     "Love is near blind 1화 인트로 배경 클릭" : "lib_1_intro_bg_click"
//     "Love is near blind 2화 인트로 배경 클릭" : "lib_2_intro_bg_click"
//     "Love is near blind 3화 인트로 배경 클릭" : "lib_3_intro_bg_click"
//     "Love is near blind 4화 인트로 배경 클릭" : "lib_4_intro_bg_click"
//     "Love is near blind 5화 인트로 배경 클릭" : "lib_5_intro_bg_click"
//     "Love is near blind 6화 인트로 배경 클릭" : "lib_6_intro_bg_click"
//     "Love is near blind 7화 인트로 배경 클릭" : "lib_7_intro_bg_click"
//     "Love is near blind 8화 인트로 배경 클릭" : "lib_8_intro_bg_click"
//     "Love is near blind 9화 인트로 배경 클릭" : "lib_9_intro_bg_click"
//     "Love is near blind 10화 인트로 배경 클릭" : "lib_10_intro_bg_click"
//     "Love is near blind 11화 인트로 배경 클릭" : "lib_11_intro_bg_click"
//     "Love is near blind 12화 인트로 배경 클릭" : "lib_12_intro_bg_click"
//     "Love is near blind 13화 인트로 배경 클릭" : "lib_13_intro_bg_click"
//     "Love is near blind 14화 인트로 배경 클릭" : "lib_14_intro_bg_click"
//     "Love is near blind 15화 인트로 배경 클릭" : "lib_15_intro_bg_click"
//     "Love is near blind 16화 인트로 배경 클릭" : "lib_16_intro_bg_click"
//     "Love is near blind 17화 인트로 배경 클릭" : "lib_17_intro_bg_click"
//     "Love is near blind 18화 인트로 배경 클릭" : "lib_18_intro_bg_click"
//     "Love is near blind 19화 인트로 배경 클릭" : "lib_19_intro_bg_click"
//     "Love is near blind 20화 인트로 배경 클릭" : "lib_20_intro_bg_click"

//     "순애로운 회사생활 1화 인트로 배경 클릭" : "plo_1_intro_bg_click"
//     "순애로운 회사생활 2화 인트로 배경 클릭" : "plo_2_intro_bg_click"
//     "순애로운 회사생활 3화 인트로 배경 클릭" : "plo_3_intro_bg_click"
//     "순애로운 회사생활 4화 인트로 배경 클릭" : "plo_4_intro_bg_click"
//     "순애로운 회사생활 5화 인트로 배경 클릭" : "plo_5_intro_bg_click"
//     "순애로운 회사생활 6화 인트로 배경 클릭" : "plo_6_intro_bg_click"
//     "순애로운 회사생활 7화 인트로 배경 클릭" : "plo_7_intro_bg_click"
//     "순애로운 회사생활 8화 인트로 배경 클릭" : "plo_8_intro_bg_click"
//     "순애로운 회사생활 9화 인트로 배경 클릭" : "plo_9_intro_bg_click"

//     "이상한 나라의 앨리스 1화 인트로 배경 클릭" : "alice_1_intro_bg_click"
//     "이상한 나라의 앨리스 2화 인트로 배경 클릭" : "alice_2_intro_bg_click"
//     "이상한 나라의 앨리스 3화 인트로 배경 클릭" : "alice_3_intro_bg_click"
//     "이상한 나라의 앨리스 4화 인트로 배경 클릭" : "alice_4_intro_bg_click"
//     "이상한 나라의 앨리스 5화 인트로 배경 클릭" : "alice_5_intro_bg_click"
//     "이상한 나라의 앨리스 6화 인트로 배경 클릭" : "alice_6_intro_bg_click"

//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 인트로 뒤로가기 클릭"
//     "봉순 1화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 2화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 3화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 4화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 5화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 6화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 7화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 8화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 9화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 10화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 11화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 12화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 13화 인트로 뒤로가기 클릭" : "bongsoon_series_info"
//     "봉순 14화 인트로 뒤로가기 클릭" : "bongsoon_series_info"

//     "탐정엠버 0화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 1화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 2화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 3화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 4화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 5화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 6화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 7화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 8화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 9화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 10화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 11화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 12화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 13화 인트로 뒤로가기 클릭" : "ember_series_info"
//     "탐정엠버 14화 인트로 뒤로가기 클릭" : "ember_series_info"

//     "Love is near blind 1화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 2화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 3화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 4화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 5화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 6화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 7화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 8화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 9화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 10화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 11화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 12화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 13화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 14화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 15화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 16화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 17화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 18화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 19화 인트로 뒤로가기 클릭" : "lib_series_info"
//     "Love is near blind 20화 인트로 뒤로가기 클릭" : "lib_series_info"

//     "순애로운 회사생활 1화 인트로 뒤로가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 2화 인트로 뒤로가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 3화 인트로 뒤로가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 4화 인트로 뒤로가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 5화 인트로 뒤로가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 6화 인트로 뒤로가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 7화 인트로 뒤로가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 8화 인트로 뒤로가기 클릭" : "plo_series_info"
//     "순애로운 회사생활 9화 인트로 뒤로가기 클릭" : "plo_series_info"

//     "이상한 나라의 앨리스 1화 인트로 뒤로가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 2화 인트로 뒤로가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 3화 인트로 뒤로가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 4화 인트로 뒤로가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 5화 인트로 뒤로가기 클릭" : "alice_series_info"
//     "이상한 나라의 앨리스 6화 인트로 뒤로가기 클릭" : "alice_series_info"

//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 인트로 시작 버튼 클릭"------2가지 경우
//     "${state.seriesTitle} ${state.episode!.index}화 완료 화면 다음 클릭"----------2가지 경우


//     "${state.seriesTitle} ${state.episode!.index}화 음성 자동 재생 토글 클릭"-----------
//     "봉순 1화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 2화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 3화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 4화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 5화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 6화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 7화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 8화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 9화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 10화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 11화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 12화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 13화 음성 자동 재생 토글 클릭" : "play_setting"
//     "봉순 14화 음성 자동 재생 토글 클릭" : "play_setting"

//     "탐정엠버 0화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 1화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 2화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 3화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 4화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 5화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 6화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 7화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 8화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 9화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 10화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 11화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 12화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 13화 음성 자동 재생 토글 클릭" : "play_setting"
//     "탐정엠버 14화 음성 자동 재생 토글 클릭" : "play_setting"

//     "Love is near blind 1화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 2화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 3화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 4화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 5화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 6화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 7화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 8화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 9화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 10화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 11화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 12화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 13화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 14화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 15화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 16화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 17화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 18화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 19화 음성 자동 재생 토글 클릭" : "play_setting"
//     "Love is near blind 20화 음성 자동 재생 토글 클릭" : "play_setting"

//     "순애로운 회사생활 1화 음성 자동 재생 토글 클릭" : "play_setting"
//     "순애로운 회사생활 2화 음성 자동 재생 토글 클릭" : "play_setting"
//     "순애로운 회사생활 3화 음성 자동 재생 토글 클릭" : "play_setting"
//     "순애로운 회사생활 4화 음성 자동 재생 토글 클릭" : "play_setting"
//     "순애로운 회사생활 5화 음성 자동 재생 토글 클릭" : "play_setting"
//     "순애로운 회사생활 6화 음성 자동 재생 토글 클릭" : "play_setting"
//     "순애로운 회사생활 7화 음성 자동 재생 토글 클릭" : "play_setting"
//     "순애로운 회사생활 8화 음성 자동 재생 토글 클릭" : "play_setting"
//     "순애로운 회사생활 9화 음성 자동 재생 토글 클릭" : "play_setting"

//     "이상한 나라의 앨리스 1화 음성 자동 재생 토글 클릭" : "play_setting"
//     "이상한 나라의 앨리스 2화 음성 자동 재생 토글 클릭" : "play_setting"
//     "이상한 나라의 앨리스 3화 음성 자동 재생 토글 클릭" : "play_setting"
//     "이상한 나라의 앨리스 4화 음성 자동 재생 토글 클릭" : "play_setting"
//     "이상한 나라의 앨리스 5화 음성 자동 재생 토글 클릭" : "play_setting"
//     "이상한 나라의 앨리스 6화 음성 자동 재생 토글 클릭" : "play_setting"

//     "${state.seriesTitle} ${state.episode!.index}화 음성 재생 속도 조절"------------
//     "봉순 1화 음성 재생 속도 조절" : "play_setting"
//     "봉순 2화 음성 재생 속도 조절" : "play_setting"
//     "봉순 3화 음성 재생 속도 조절" : "play_setting"
//     "봉순 4화 음성 재생 속도 조절" : "play_setting"
//     "봉순 5화 음성 재생 속도 조절" : "play_setting"
//     "봉순 6화 음성 재생 속도 조절" : "play_setting"
//     "봉순 7화 음성 재생 속도 조절" : "play_setting"
//     "봉순 8화 음성 재생 속도 조절" : "play_setting"
//     "봉순 9화 음성 재생 속도 조절" : "play_setting"
//     "봉순 10화 음성 재생 속도 조절" : "play_setting"
//     "봉순 11화 음성 재생 속도 조절" : "play_setting"
//     "봉순 12화 음성 재생 속도 조절" : "play_setting"
//     "봉순 13화 음성 재생 속도 조절" : "play_setting"
//     "봉순 14화 음성 재생 속도 조절" : "play_setting"

//     "탐정엠버 0화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 1화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 2화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 3화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 4화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 5화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 6화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 7화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 8화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 9화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 10화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 11화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 12화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 13화 음성 재생 속도 조절" : "play_setting"
//     "탐정엠버 14화 음성 재생 속도 조절" : "play_setting"

//     "Love is near blind 1화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 2화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 3화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 4화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 5화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 6화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 7화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 8화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 9화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 10화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 11화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 12화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 13화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 14화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 15화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 16화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 17화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 18화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 19화 음성 재생 속도 조절" : "play_setting"
//     "Love is near blind 20화 음성 재생 속도 조절" : "play_setting"

//     "순애로운 회사생활 1화 음성 재생 속도 조절" : "play_setting"
//     "순애로운 회사생활 2화 음성 재생 속도 조절" : "play_setting"
//     "순애로운 회사생활 3화 음성 재생 속도 조절" : "play_setting"
//     "순애로운 회사생활 4화 음성 재생 속도 조절" : "play_setting"
//     "순애로운 회사생활 5화 음성 재생 속도 조절" : "play_setting"
//     "순애로운 회사생활 6화 음성 재생 속도 조절" : "play_setting"
//     "순애로운 회사생활 7화 음성 재생 속도 조절" : "play_setting"
//     "순애로운 회사생활 8화 음성 재생 속도 조절" : "play_setting"
//     "순애로운 회사생활 9화 음성 재생 속도 조절" : "play_setting"

//     "이상한 나라의 앨리스 1화 음성 재생 속도 조절" : "play_setting"
//     "이상한 나라의 앨리스 2화 음성 재생 속도 조절" : "play_setting"
//     "이상한 나라의 앨리스 3화 음성 재생 속도 조절" : "play_setting"
//     "이상한 나라의 앨리스 4화 음성 재생 속도 조절" : "play_setting"
//     "이상한 나라의 앨리스 5화 음성 재생 속도 조절" : "play_setting"
//     "이상한 나라의 앨리스 6화 음성 재생 속도 조절" : "play_setting"



//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 인트로 ${e.word} 단어카드 클릭"--------------

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 설정 아이콘 클릭" : "play_settings"

//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 인트로 스피커 아이콘 클릭"---------------
//     "봉순 1화 인트로 스피커 아이콘 클릭" : "bongsoon_1_play"
//     "봉순 2화 인트로 스피커 아이콘 클릭" : "bongsoon_2_play"
//     "봉순 3화 인트로 스피커 아이콘 클릭" : "bongsoon_3_play"
//     "봉순 4화 인트로 스피커 아이콘 클릭" : "bongsoon_4_play"
//     "봉순 5화 인트로 스피커 아이콘 클릭" : "bongsoon_5_play"
//     "봉순 6화 인트로 스피커 아이콘 클릭" : "bongsoon_6_play"
//     "봉순 7화 인트로 스피커 아이콘 클릭" : "bongsoon_7_play"
//     "봉순 8화 인트로 스피커 아이콘 클릭" : "bongsoon_8_play"
//     "봉순 9화 인트로 스피커 아이콘 클릭" : "bongsoon_9_play"
//     "봉순 10화 인트로 스피커 아이콘 클릭" : "bongsoon_10_play"
//     "봉순 11화 인트로 스피커 아이콘 클릭" : "bongsoon_11_play"
//     "봉순 12화 인트로 스피커 아이콘 클릭" : "bongsoon_12_play"
//     "봉순 13화 인트로 스피커 아이콘 클릭" : "bongsoon_13_play"
//     "봉순 14화 인트로 스피커 아이콘 클릭" : "bongsoon_14_play"

//     "탐정엠버 0화 인트로 스피커 아이콘 클릭" : "ember_0_play"
//     "탐정엠버 1화 인트로 스피커 아이콘 클릭" : "ember_1_play"
//     "탐정엠버 2화 인트로 스피커 아이콘 클릭" : "ember_2_play"
//     "탐정엠버 3화 인트로 스피커 아이콘 클릭" : "ember_3_play"
//     "탐정엠버 4화 인트로 스피커 아이콘 클릭" : "ember_4_play"
//     "탐정엠버 5화 인트로 스피커 아이콘 클릭" : "ember_5_play"
//     "탐정엠버 6화 인트로 스피커 아이콘 클릭" : "ember_6_play"
//     "탐정엠버 7화 인트로 스피커 아이콘 클릭" : "ember_7_play"
//     "탐정엠버 8화 인트로 스피커 아이콘 클릭" : "ember_8_play"
//     "탐정엠버 9화 인트로 스피커 아이콘 클릭" : "ember_9_play"
//     "탐정엠버 10화 인트로 스피커 아이콘 클릭" : "ember_10_play"
//     "탐정엠버 11화 인트로 스피커 아이콘 클릭" : "ember_11_play"
//     "탐정엠버 12화 인트로 스피커 아이콘 클릭" : "ember_12_play"
//     "탐정엠버 13화 인트로 스피커 아이콘 클릭" : "ember_13_play"
//     "탐정엠버 14화 인트로 스피커 아이콘 클릭" : "ember_14_play"

//     "Love is near blind 1화 인트로 스피커 아이콘 클릭" : "lib_1_play"
//     "Love is near blind 2화 인트로 스피커 아이콘 클릭" : "lib_2_play"
//     "Love is near blind 3화 인트로 스피커 아이콘 클릭" : "lib_3_play"
//     "Love is near blind 4화 인트로 스피커 아이콘 클릭" : "lib_4_play"
//     "Love is near blind 5화 인트로 스피커 아이콘 클릭" : "lib_5_play"
//     "Love is near blind 6화 인트로 스피커 아이콘 클릭" : "lib_6_play"
//     "Love is near blind 7화 인트로 스피커 아이콘 클릭" : "lib_7_play"
//     "Love is near blind 8화 인트로 스피커 아이콘 클릭" : "lib_8_play"
//     "Love is near blind 9화 인트로 스피커 아이콘 클릭" : "lib_9_play"
//     "Love is near blind 10화 인트로 스피커 아이콘 클릭" : "lib_10_play"
//     "Love is near blind 11화 인트로 스피커 아이콘 클릭" : "lib_11_play"
//     "Love is near blind 12화 인트로 스피커 아이콘 클릭" : "lib_12_play"
//     "Love is near blind 13화 인트로 스피커 아이콘 클릭" : "lib_13_play"
//     "Love is near blind 14화 인트로 스피커 아이콘 클릭" : "lib_14_play"
//     "Love is near blind 15화 인트로 스피커 아이콘 클릭" : "lib_15_play"
//     "Love is near blind 16화 인트로 스피커 아이콘 클릭" : "lib_16_play"
//     "Love is near blind 17화 인트로 스피커 아이콘 클릭" : "lib_17_play"
//     "Love is near blind 18화 인트로 스피커 아이콘 클릭" : "lib_18_play"
//     "Love is near blind 19화 인트로 스피커 아이콘 클릭" : "lib_19_play"
//     "Love is near blind 20화 인트로 스피커 아이콘 클릭" : "lib_20_play"

//     "순애로운 회사생활 1화 인트로 스피커 아이콘 클릭" : "plo_1_play"
//     "순애로운 회사생활 2화 인트로 스피커 아이콘 클릭" : "plo_2_play"
//     "순애로운 회사생활 3화 인트로 스피커 아이콘 클릭" : "plo_3_play"
//     "순애로운 회사생활 4화 인트로 스피커 아이콘 클릭" : "plo_4_play"
//     "순애로운 회사생활 5화 인트로 스피커 아이콘 클릭" : "plo_5_play"
//     "순애로운 회사생활 6화 인트로 스피커 아이콘 클릭" : "plo_6_play"
//     "순애로운 회사생활 7화 인트로 스피커 아이콘 클릭" : "plo_7_play"
//     "순애로운 회사생활 8화 인트로 스피커 아이콘 클릭" : "plo_8_play"
//     "순애로운 회사생활 9화 인트로 스피커 아이콘 클릭" : "plo_9_play"

//     "이상한 나라의 앨리스 1화 인트로 스피커 아이콘 클릭" : "alice_1_play"
//     "이상한 나라의 앨리스 2화 인트로 스피커 아이콘 클릭" : "alice_2_play"
//     "이상한 나라의 앨리스 3화 인트로 스피커 아이콘 클릭" : "alice_3_play"
//     "이상한 나라의 앨리스 4화 인트로 스피커 아이콘 클릭" : "alice_4_play"
//     "이상한 나라의 앨리스 5화 인트로 스피커 아이콘 클릭" : "alice_5_play"
//     "이상한 나라의 앨리스 6화 인트로 스피커 아이콘 클릭" : "alice_6_play"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 복습 시작 버튼 클릭"--------------------
//     "봉순 1화 17번 턴 복습 시작 버튼 클릭" : "bongsoon_1_brushup_start"
//     "봉순 2화 15번 턴 복습 시작 버튼 클릭" : "bongsoon_2_brushup_start"
//     "봉순 3화 19번 턴 복습 시작 버튼 클릭" : "bongsoon_3_brushup_start"
//     "봉순 4화 14번 턴 복습 시작 버튼 클릭" : "bongsoon_4_brushup_start"
//     "봉순 5화 17번 턴 복습 시작 버튼 클릭" : "bongsoon_5_brushup_start"
//     "봉순 6화 16번 턴 복습 시작 버튼 클릭" : "bongsoon_6_brushup_start"
//     "봉순 7화 16번 턴 복습 시작 버튼 클릭" : "bongsoon_7_brushup_start"
//     "봉순 8화 15번 턴 복습 시작 버튼 클릭" : "bongsoon_8_brushup_start"
//     "봉순 9화 14번 턴 복습 시작 버튼 클릭" : "bongsoon_9_brushup_start"
//     "봉순 10화 17번 턴 복습 시작 버튼 클릭" : "bongsoon_10_brushup_start"
//     "봉순 11화 11번 턴 복습 시작 버튼 클릭" : "bongsoon_11_brushup_start"
//     "봉순 12화 13번 턴 복습 시작 버튼 클릭" : "bongsoon_12_brushup_start"
//     "봉순 13화 19번 턴 복습 시작 버튼 클릭" : "bongsoon_13_brushup_start"
//     "봉순 14화 16번 턴 복습 시작 버튼 클릭" : "bongsoon_14_brushup_start"

//     "탐정엠버 0화 12번 턴 복습 시작 버튼 클릭" : "ember_0_brushup_start"
//     "탐정엠버 1화 14번 턴 복습 시작 버튼 클릭" : "ember_1_brushup_start"
//     "탐정엠버 2화 17번 턴 복습 시작 버튼 클릭" : "ember_2_brushup_start"
//     "탐정엠버 3화 17번 턴 복습 시작 버튼 클릭" : "ember_3_brushup_start"
//     "탐정엠버 4화 12번 턴 복습 시작 버튼 클릭" : "ember_4_brushup_start"
//     "탐정엠버 5화 16번 턴 복습 시작 버튼 클릭" : "ember_5_brushup_start"
//     "탐정엠버 6화 13번 턴 복습 시작 버튼 클릭" : "ember_6_brushup_start"
//     "탐정엠버 7화 20번 턴 복습 시작 버튼 클릭" : "ember_7_brushup_start"
//     "탐정엠버 8화 16번 턴 복습 시작 버튼 클릭" : "ember_8_brushup_start"
//     "탐정엠버 9화 15번 턴 복습 시작 버튼 클릭" : "ember_9_brushup_start"
//     "탐정엠버 10화 17번 턴 복습 시작 버튼 클릭" : "ember_10_brushup_start"
//     "탐정엠버 11화 14번 턴 복습 시작 버튼 클릭" : "ember_11_brushup_start"
//     "탐정엠버 12화 24번 턴 복습 시작 버튼 클릭" : "ember_12_brushup_start"
//     "탐정엠버 13화 18번 턴 복습 시작 버튼 클릭" : "ember_13_brushup_start"
//     "탐정엠버 14화 18번 턴 복습 시작 버튼 클릭" : "ember_14_brushup_start"

//     "Love is near blind 1화 20번 턴 복습 시작 버튼 클릭" : "lib_1_brushup_start"
//     "Love is near blind 2화 20번 턴 복습 시작 버튼 클릭" : "lib_2_brushup_start"
//     "Love is near blind 3화 30번 턴 복습 시작 버튼 클릭" : "lib_3_brushup_start"
//     "Love is near blind 4화 25번 턴 복습 시작 버튼 클릭" : "lib_4_brushup_start"
//     "Love is near blind 5화 19번 턴 복습 시작 버튼 클릭" : "lib_5_brushup_start"
//     "Love is near blind 6화 20번 턴 복습 시작 버튼 클릭" : "lib_6_brushup_start"
//     "Love is near blind 7화 17번 턴 복습 시작 버튼 클릭" : "lib_7_brushup_start"
//     "Love is near blind 8화 31번 턴 복습 시작 버튼 클릭" : "lib_8_brushup_start"
//     "Love is near blind 9화 15번 턴 복습 시작 버튼 클릭" : "lib_9_brushup_start"
//     "Love is near blind 10화 18번 턴 복습 시작 버튼 클릭" : "lib_10_brushup_start"
//     "Love is near blind 11화 17번 턴 복습 시작 버튼 클릭" : "lib_11_brushup_start"
//     "Love is near blind 12화 17번 턴 복습 시작 버튼 클릭" : "lib_12_brushup_start"
//     "Love is near blind 13화 22번 턴 복습 시작 버튼 클릭" : "lib_13_brushup_start"
//     "Love is near blind 14화 19번 턴 복습 시작 버튼 클릭" : "lib_14_brushup_start"
//     "Love is near blind 15화 18번 턴 복습 시작 버튼 클릭" : "lib_15_brushup_start"
//     "Love is near blind 16화 24번 턴 복습 시작 버튼 클릭" : "lib_16_brushup_start"
//     "Love is near blind 17화 18번 턴 복습 시작 버튼 클릭" : "lib_17_brushup_start"
//     "Love is near blind 18화 18번 턴 복습 시작 버튼 클릭" : "lib_18_brushup_start"
//     "Love is near blind 19화 18번 턴 복습 시작 버튼 클릭" : "lib_19_brushup_start"
//     "Love is near blind 20화 23번 턴 복습 시작 버튼 클릭" : "lib_20_brushup_start"

//     "순애로운 회사생활 1화 14번 턴 복습 시작 버튼 클릭" : "plo_1_brushup_start"
//     "순애로운 회사생활 2화 17번 턴 복습 시작 버튼 클릭" : "plo_2_brushup_start"
//     "순애로운 회사생활 3화 15번 턴 복습 시작 버튼 클릭" : "plo_3_brushup_start"
//     "순애로운 회사생활 4화 18번 턴 복습 시작 버튼 클릭" : "plo_4_brushup_start"
//     "순애로운 회사생활 5화 13번 턴 복습 시작 버튼 클릭" : "plo_5_brushup_start"
//     "순애로운 회사생활 6화 19번 턴 복습 시작 버튼 클릭" : "plo_6_brushup_start"
//     "순애로운 회사생활 7화 19번 턴 복습 시작 버튼 클릭" : "plo_7_brushup_start"
//     "순애로운 회사생활 8화 17번 턴 복습 시작 버튼 클릭" : "plo_8_brushup_start"
//     "순애로운 회사생활 9화 13번 턴 복습 시작 버튼 클릭" : "plo_9_brushup_start"

//     "이상한 나라의 앨리스 1화 18번 턴 복습 시작 버튼 클릭" : "alice_1_brushup_start"
//     "이상한 나라의 앨리스 2화 18번 턴 복습 시작 버튼 클릭" : "alice_2_brushup_start"
//     "이상한 나라의 앨리스 3화 22번 턴 복습 시작 버튼 클릭" : "alice_3_brushup_start"
//     "이상한 나라의 앨리스 4화 20번 턴 복습 시작 버튼 클릭" : "alice_4_brushup_start"
//     "이상한 나라의 앨리스 5화 19번 턴 복습 시작 버튼 클릭" : "alice_5_brushup_start"
//     "이상한 나라의 앨리스 6화 19번 턴 복습 시작 버튼 클릭" : "alice_6_brushup_start"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 복습 스킵 버튼 클릭"------------------
//     "봉순 1화 17번 턴 복습 스킵 버튼 클릭" : "bongsoon_1_brushup_skip"
//     "봉순 2화 15번 턴 복습 스킵 버튼 클릭" : "bongsoon_2_brushup_skip"
//     "봉순 3화 19번 턴 복습 스킵 버튼 클릭" : "bongsoon_3_brushup_skip"
//     "봉순 4화 14번 턴 복습 스킵 버튼 클릭" : "bongsoon_4_brushup_skip"
//     "봉순 5화 17번 턴 복습 스킵 버튼 클릭" : "bongsoon_5_brushup_skip"
//     "봉순 6화 16번 턴 복습 스킵 버튼 클릭" : "bongsoon_6_brushup_skip"
//     "봉순 7화 16번 턴 복습 스킵 버튼 클릭" : "bongsoon_7_brushup_skip"
//     "봉순 8화 15번 턴 복습 스킵 버튼 클릭" : "bongsoon_8_brushup_skip"
//     "봉순 9화 14번 턴 복습 스킵 버튼 클릭" : "bongsoon_9_brushup_skip"
//     "봉순 10화 17번 턴 복습 스킵 버튼 클릭" : "bongsoon_10_brushup_skip"
//     "봉순 11화 11번 턴 복습 스킵 버튼 클릭" : "bongsoon_11_brushup_skip"
//     "봉순 12화 13번 턴 복습 스킵 버튼 클릭" : "bongsoon_12_brushup_skip"
//     "봉순 13화 19번 턴 복습 스킵 버튼 클릭" : "bongsoon_13_brushup_skip"
//     "봉순 14화 16번 턴 복습 스킵 버튼 클릭" : "bongsoon_14_brushup_skip"

//     "탐정엠버 0화 12번 턴 복습 스킵 버튼 클릭" : "ember_0_brushup_skip"
//     "탐정엠버 1화 14번 턴 복습 스킵 버튼 클릭" : "ember_1_brushup_skip"
//     "탐정엠버 2화 17번 턴 복습 스킵 버튼 클릭" : "ember_2_brushup_skip"
//     "탐정엠버 3화 17번 턴 복습 스킵 버튼 클릭" : "ember_3_brushup_skip"
//     "탐정엠버 4화 12번 턴 복습 스킵 버튼 클릭" : "ember_4_brushup_skip"
//     "탐정엠버 5화 16번 턴 복습 스킵 버튼 클릭" : "ember_5_brushup_skip"
//     "탐정엠버 6화 13번 턴 복습 스킵 버튼 클릭" : "ember_6_brushup_skip"
//     "탐정엠버 7화 20번 턴 복습 스킵 버튼 클릭" : "ember_7_brushup_skip"
//     "탐정엠버 8화 16번 턴 복습 스킵 버튼 클릭" : "ember_8_brushup_skip"
//     "탐정엠버 9화 15번 턴 복습 스킵 버튼 클릭" : "ember_9_brushup_skip"
//     "탐정엠버 10화 17번 턴 복습 스킵 버튼 클릭" : "ember_10_brushup_skip"
//     "탐정엠버 11화 14번 턴 복습 스킵 버튼 클릭" : "ember_11_brushup_skip"
//     "탐정엠버 12화 24번 턴 복습 스킵 버튼 클릭" : "ember_12_brushup_skip"
//     "탐정엠버 13화 18번 턴 복습 스킵 버튼 클릭" : "ember_13_brushup_skip"
//     "탐정엠버 14화 18번 턴 복습 스킵 버튼 클릭" : "ember_14_brushup_skip"

//     "Love is near blind 1화 20번 턴 복습 스킵 버튼 클릭" : "lib_1_brushup_skip"
//     "Love is near blind 2화 20번 턴 복습 스킵 버튼 클릭" : "lib_2_brushup_skip"
//     "Love is near blind 3화 30번 턴 복습 스킵 버튼 클릭" : "lib_3_brushup_skip"
//     "Love is near blind 4화 25번 턴 복습 스킵 버튼 클릭" : "lib_4_brushup_skip"
//     "Love is near blind 5화 19번 턴 복습 스킵 버튼 클릭" : "lib_5_brushup_skip"
//     "Love is near blind 6화 20번 턴 복습 스킵 버튼 클릭" : "lib_6_brushup_skip"
//     "Love is near blind 7화 17번 턴 복습 스킵 버튼 클릭" : "lib_7_brushup_skip"
//     "Love is near blind 8화 31번 턴 복습 스킵 버튼 클릭" : "lib_8_brushup_skip"
//     "Love is near blind 9화 15번 턴 복습 스킵 버튼 클릭" : "lib_9_brushup_skip"
//     "Love is near blind 10화 18번 턴 복습 스킵 버튼 클릭" : "lib_10_brushup_skip"
//     "Love is near blind 11화 17번 턴 복습 스킵 버튼 클릭" : "lib_11_brushup_skip"
//     "Love is near blind 12화 17번 턴 복습 스킵 버튼 클릭" : "lib_12_brushup_skip"
//     "Love is near blind 13화 22번 턴 복습 스킵 버튼 클릭" : "lib_13_brushup_skip"
//     "Love is near blind 14화 19번 턴 복습 스킵 버튼 클릭" : "lib_14_brushup_skip"
//     "Love is near blind 15화 18번 턴 복습 스킵 버튼 클릭" : "lib_15_brushup_skip"
//     "Love is near blind 16화 24번 턴 복습 스킵 버튼 클릭" : "lib_16_brushup_skip"
//     "Love is near blind 17화 18번 턴 복습 스킵 버튼 클릭" : "lib_17_brushup_skip"
//     "Love is near blind 18화 18번 턴 복습 스킵 버튼 클릭" : "lib_18_brushup_skip"
//     "Love is near blind 19화 18번 턴 복습 스킵 버튼 클릭" : "lib_19_brushup_skip"
//     "Love is near blind 20화 23번 턴 복습 스킵 버튼 클릭" : "lib_20_brushup_skip"

//     "순애로운 회사생활 1화 14번 턴 복습 스킵 버튼 클릭" : "plo_1_brushup_skip"
//     "순애로운 회사생활 2화 17번 턴 복습 스킵 버튼 클릭" : "plo_2_brushup_skip"
//     "순애로운 회사생활 3화 15번 턴 복습 스킵 버튼 클릭" : "plo_3_brushup_skip"
//     "순애로운 회사생활 4화 18번 턴 복습 스킵 버튼 클릭" : "plo_4_brushup_skip"
//     "순애로운 회사생활 5화 13번 턴 복습 스킵 버튼 클릭" : "plo_5_brushup_skip"
//     "순애로운 회사생활 6화 19번 턴 복습 스킵 버튼 클릭" : "plo_6_brushup_skip"
//     "순애로운 회사생활 7화 19번 턴 복습 스킵 버튼 클릭" : "plo_7_brushup_skip"
//     "순애로운 회사생활 8화 17번 턴 복습 스킵 버튼 클릭" : "plo_8_brushup_skip"
//     "순애로운 회사생활 9화 13번 턴 복습 스킵 버튼 클릭" : "plo_9_brushup_skip"

//     "이상한 나라의 앨리스 1화 18번 턴 스킵 시작 버튼 클릭" : "alice_1_brushup_skip"
//     "이상한 나라의 앨리스 2화 18번 턴 스킵 시작 버튼 클릭" : "alice_2_brushup_skip"
//     "이상한 나라의 앨리스 3화 22번 턴 스킵 시작 버튼 클릭" : "alice_3_brushup_skip"
//     "이상한 나라의 앨리스 4화 20번 턴 스킵 시작 버튼 클릭" : "alice_4_brushup_skip"
//     "이상한 나라의 앨리스 5화 19번 턴 스킵 시작 버튼 클릭" : "alice_5_brushup_skip"
//     "이상한 나라의 앨리스 6화 19번 턴 스킵 시작 버튼 클릭" : "alice_6_brushup_skip"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 나가기 버튼 클릭"--------------
//     "봉순 1화 0번 턴 나가기 버튼 클릭" : "bongsoon_exit"
//     "탐정엠버 0화 0번 턴 나가기 버튼 클릭" : "ember_exit"
//     "Love is near blind 1화 0번 턴 나가기 버튼 클릭" : "lib_exit"
//     "순애로운 회사생활 1화 0번 턴 나가기 버튼 클릭" : "plo_exit"
//     "이상한 나라의 앨리스 1화 0번 턴 나가기 버튼 클릭" : "alice_exit"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 나가기 팝업 확인 버튼 클릭"------------
//     "봉순 1화 0번 턴 나가기 팝업 확인 버튼 클릭" : "bongsoon_series_info"
//     "탐정엠버 0화 0번 턴 나가기 팝업 확인 버튼 클릭" : "ember_series_info"
//     "Love is near blind 1화 0번 턴 나가기 팝업 확인 버튼 클릭" : "lib_series_info"
//     "순애로운 회사생활 1화 0번 턴 나가기 팝업 확인 버튼 클릭" : "plo_series_info"
//     "이상한 나라의 앨리스 1화 0번 턴 나가기 팝업 확인 버튼 클릭" : "alice_series_info"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 나가기 팝업 다시하기 버튼 클릭"------------
//     "봉순 1화 0번 턴 나가기 팝업 다시하기 버튼 클릭" : "bongsoon_1_intro_bg_click"
//     "탐정엠버 0화 0번 턴 나가기 팝업 다시하기 버튼 클릭" : "ember_1_intro_bg_click"
//     "Love is near blind 1화 0번 턴 나가기 팝업 다시하기 버튼 클릭" : "lib_1_intro_bg_click"
//     "순애로운 회사생활 1화 0번 턴 나가기 팝업 다시하기 버튼 클릭" : "plo_1_intro_bg_click"
//     "이상한 나라의 앨리스 1화 0번 턴 나가기 팝업 다시하기 버튼 클릭" : "alice_1_intro_bg_click"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 신고 아이콘 클릭"----------------------
//     "봉순 1화 0번 턴 신고 아이콘 클릭" : "bongsoon_report"
//     "탐정엠버 0화 0번 턴 신고 아이콘 클릭" : "ember_report"
//     "Love is near blind 1화 2번 턴 신고 아이콘 클릭" : "lib_report"
//     "순애로운 회사생활 1화 0번 턴 신고 아이콘 클릭" : "plo_report"
//     "이상한 나라의 앨리스 1화 0번 턴 신고 아이콘 클릭" : "alice_report"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 대사 ${wordLink.word} 단어 팝업 자세히 아이콘 클릭"------------
//     "봉순 1화 0번 턴 대사 tomorrow 단어 팝업 자세히 아이콘 클릭" : "series_dialog_word_info"
//     "탐정엠버 0화 0번 턴 대사 tomorrow 단어 팝업 자세히 아이콘 클릭" : "series_dialog_word_info"
//     "Love is near blind 1화 0번 턴 대사 tomorrow 단어 팝업 자세히 아이콘 클릭" : "series_dialog_word_info"
//     "순애로운 회사생활 1화 0번 턴 대사 tomorrow 단어 팝업 자세히 아이콘 클릭" : "series_dialog_word_info"
//     "이상한 나라의 앨리스 1화 0번 턴 대사 tomorrow 단어 팝업 자세히 아이콘 클릭" : "series_dialog_word_info"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 힌트 아이콘 클릭"------------안해도됨

//     "${state.seriesTitle} ${state.episode!.index}화 완료 ${widget.wordInfo.word} 단어카드 클릭"-------------------
//     "봉순 1화 완료 major 단어카드 클릭" : "series_complete_word_card_click"
//     "탐정엠버 0화 완료 major 단어카드 클릭" : "series_complete_word_card_click"
//     "Love is near blind 1화 완료 major 단어카드 클릭" : "series_complete_word_card_click"
//     "순애로운 회사생활 1화 완료 major 단어카드 클릭" : "series_complete_word_card_click"
//     "이상한 나라의 앨리스 1화 완료 major 단어카드 클릭" : "series_complete_word_card_click"

//     "${state.seriesTitle} ${state.episode!.index}화 복습 다음 버튼 클릭"--------------------키값만 있음
//     "봉순 1화 복습 다음 버튼 클릭" :
//     "봉순 2화 복습 다음 버튼 클릭" :
//     "봉순 3화 복습 다음 버튼 클릭" :
//     "봉순 4화 복습 다음 버튼 클릭" :
//     "봉순 5화 복습 다음 버튼 클릭" :
//     "봉순 6화 복습 다음 버튼 클릭" :
//     "봉순 7화 복습 다음 버튼 클릭" :
//     "봉순 8화 복습 다음 버튼 클릭" :
//     "봉순 9화 복습 다음 버튼 클릭" :
//     "봉순 10화 복습 다음 버튼 클릭" :
//     "봉순 11화 복습 다음 버튼 클릭" :
//     "봉순 12화 복습 다음 버튼 클릭" :
//     "봉순 13화 복습 다음 버튼 클릭" :
//     "봉순 14화 복습 다음 버튼 클릭" :

//     "탐정엠버 0화 복습 다음 버튼 클릭" :
//     "탐정엠버 1화 복습 다음 버튼 클릭" :
//     "탐정엠버 2화 복습 다음 버튼 클릭" :
//     "탐정엠버 3화 복습 다음 버튼 클릭" :
//     "탐정엠버 4화 복습 다음 버튼 클릭" :
//     "탐정엠버 5화 복습 다음 버튼 클릭" :
//     "탐정엠버 6화 복습 다음 버튼 클릭" :
//     "탐정엠버 7화 복습 다음 버튼 클릭" :
//     "탐정엠버 8화 복습 다음 버튼 클릭" :
//     "탐정엠버 9화 복습 다음 버튼 클릭" :
//     "탐정엠버 10화 복습 다음 버튼 클릭" :
//     "탐정엠버 11화 복습 다음 버튼 클릭" :
//     "탐정엠버 12화 복습 다음 버튼 클릭" :
//     "탐정엠버 13화 복습 다음 버튼 클릭" :
//     "탐정엠버 14화 복습 다음 버튼 클릭" :

//     "Love is near blind 1화 복습 다음 버튼 클릭" :
//     "Love is near blind 2화 복습 다음 버튼 클릭" :
//     "Love is near blind 3화 복습 다음 버튼 클릭" :
//     "Love is near blind 4화 복습 다음 버튼 클릭" :
//     "Love is near blind 5화 복습 다음 버튼 클릭" :
//     "Love is near blind 6화 복습 다음 버튼 클릭" :
//     "Love is near blind 7화 복습 다음 버튼 클릭" :
//     "Love is near blind 8화 복습 다음 버튼 클릭" :
//     "Love is near blind 9화 복습 다음 버튼 클릭" :
//     "Love is near blind 10화 복습 다음 버튼 클릭" :
//     "Love is near blind 11화 복습 다음 버튼 클릭" :
//     "Love is near blind 12화 복습 다음 버튼 클릭" :
//     "Love is near blind 13화 복습 다음 버튼 클릭" :
//     "Love is near blind 14화 복습 다음 버튼 클릭" :
//     "Love is near blind 15화 복습 다음 버튼 클릭" :
//     "Love is near blind 16화 복습 다음 버튼 클릭" :
//     "Love is near blind 17화 복습 다음 버튼 클릭" :
//     "Love is near blind 18화 복습 다음 버튼 클릭" :
//     "Love is near blind 19화 복습 다음 버튼 클릭" :
//     "Love is near blind 20화 복습 다음 버튼 클릭" :

//     "순애로운 회사생활 1화 복습 다음 버튼 클릭" :
//     "순애로운 회사생활 2화 복습 다음 버튼 클릭" :
//     "순애로운 회사생활 3화 복습 다음 버튼 클릭" :
//     "순애로운 회사생활 4화 복습 다음 버튼 클릭" :
//     "순애로운 회사생활 5화 복습 다음 버튼 클릭" :
//     "순애로운 회사생활 6화 복습 다음 버튼 클릭" :
//     "순애로운 회사생활 7화 복습 다음 버튼 클릭" :
//     "순애로운 회사생활 8화 복습 다음 버튼 클릭" :
//     "순애로운 회사생활 9화 복습 다음 버튼 클릭" :

//     "이상한 나라의 앨리스 1화 복습 다음 버튼 클릭" :
//     "이상한 나라의 앨리스 2화 복습 다음 버튼 클릭" :
//     "이상한 나라의 앨리스 3화 복습 다음 버튼 클릭" :
//     "이상한 나라의 앨리스 4화 복습 다음 버튼 클릭" :
//     "이상한 나라의 앨리스 5화 복습 다음 버튼 클릭" :
//     "이상한 나라의 앨리스 6화 복습 다음 버튼 클릭" :

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 대사 ${wordLink.word} 단어 클릭"----------------
//     "봉순 1화 0번 턴 대사 tomorrow 단어 클릭" : "bongsoon_dialog_word_popup"
//     "탐정엠버 0화 0번 턴 대사 disappointing 단어 클릭" : "ember_dialog_word_popup"
//     "Love is near blind 1화 0번 턴 대사 welcome 단어 클릭" : "lib_dialog_word_popup"
//     "순애로운 회사생활 1화 0번 턴 대사 research 단어 클릭" : "plo_dialog_word_popup"
//     "이상한 나라의 앨리스 1화 0번 턴 대사 spend 단어 클릭" : "alice_dialog_word_popup"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 대사 ${wordLink.word} 단어 팝업 음성 재생 아이콘 클릭"---------------------
//     "봉순 1화 0번 턴 대사 tomorrow 단어 팝업 음성 재생 아이콘 클릭" : "bongsoon_dialog_word_popup"
//     "탐정엠버 0화 0번 턴 대사 disappointing 단어 팝업 음성 재생 아이콘 클릭" : "ember_dialog_word_popup"
//     "Love is near blind 1화 0번 턴 대사 welcome 단어 팝업 음성 재생 아이콘 클릭" : "lib_dialog_word_popup"
//     "순애로운 회사생활 1화 0번 턴 대사 research 단어 팝업 음성 재생 아이콘 클릭" : "plo_dialog_word_popup"
//     "이상한 나라의 앨리스 1화 0번 턴 대사 spend 단어 팝업 음성 재생 아이콘 클릭" : "alice_dialog_word_popup"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 대사 ${state.lookedUpWordLink?.word} 단어 팝업 북마크 아이콘 클릭"--------------
//     "봉순 1화 0번 턴 대사 tomorrow 단어 팝업 북마크 아이콘 클릭" : "bongsoon_dialog_word_popup"
//     "탐정엠버 0화 0번 턴 대사 disappointing 단어 팝업 북마크 아이콘 클릭" : "ember_dialog_word_popup"
//     "Love is near blind 1화 0번 턴 대사 welcome 단어 팝업 북마크 아이콘 클릭" : "lib_dialog_word_popup"
//     "순애로운 회사생활 1화 0번 턴 대사 research 단어 팝업 북마크 아이콘 클릭" : "plo_dialog_word_popup"
//     "이상한 나라의 앨리스 1화 0번 턴 대사 spend 단어 팝업 북마크 아이콘 클릭" : "alice_dialog_word_popup"

//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 해설 복습 해설 펼쳐보기 클릭"--------------
//     "봉순 1화 해설 복습 해설 펼쳐보기 클릭" : "review_commentary_expand"
//     "탐정엠버 0화 해설 복습 해설 펼쳐보기 클릭" : "review_commentary_expand"
//     "Love is near blind 1화 해설 복습 해설 펼쳐보기 클릭" : "review_commentary_expand"
//     "순애로운 회사생활 1화 해설 복습 해설 펼쳐보기 클릭" : "review_commentary_expand"
//     "이상한 나라의 앨리스 1화 해설 복습 해설 펼쳐보기 클릭" : "review_commentary_expand"


//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 ${episodeState.currentTurnIndex}번 턴 대화 음성 재생 버튼 클릭"------------
//     "봉순 1화 0번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_0"
//     "봉순 1화 1번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_1"
//     "봉순 1화 2번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_2"
//     "봉순 1화 3번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_3"
//     "봉순 1화 4번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_4"
//     "봉순 1화 5번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_5"
//     "봉순 1화 6번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_6"
//     "봉순 1화 7번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_7"
//     "봉순 1화 8번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_8"
//     "봉순 1화 9번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_9"
//     "봉순 1화 10번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_10"
//     "봉순 1화 11번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_11"
//     "봉순 1화 12번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_12"
//     "봉순 1화 13번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_13"
//     "봉순 1화 14번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_14"
//     "봉순 1화 15번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_15"
//     "봉순 1화 16번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_16"
//     "봉순 1화 17번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_1_17"

//     "봉순 2화 0번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_0",
//     "봉순 2화 1번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_1",
//     "봉순 2화 2번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_2",
//     "봉순 2화 3번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_3",
//     "봉순 2화 4번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_4",
//     "봉순 2화 5번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_5",
//     "봉순 2화 6번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_6",
//     "봉순 2화 7번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_7",
//     "봉순 2화 8번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_8",
//     "봉순 2화 9번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_9",
//     "봉순 2화 10번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_10",
//     "봉순 2화 11번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_11",
//     "봉순 2화 12번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_12",
//     "봉순 2화 13번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_13",
//     "봉순 2화 14번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_14",
//     "봉순 2화 15번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_2_15"

//     "봉순 3화 0번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_0",
//     "봉순 3화 1번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_1",
//     "봉순 3화 2번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_2",
//     "봉순 3화 3번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_3",
//     "봉순 3화 4번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_4",
//     "봉순 3화 5번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_5",
//     "봉순 3화 6번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_6",
//     "봉순 3화 7번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_7",
//     "봉순 3화 8번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_8",
//     "봉순 3화 9번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_9",
//     "봉순 3화 10번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_10",
//     "봉순 3화 11번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_11",
//     "봉순 3화 12번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_12",
//     "봉순 3화 13번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_13",
//     "봉순 3화 14번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_14",
//     "봉순 3화 15번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_15",
//     "봉순 3화 16번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_16",
//     "봉순 3화 17번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_17",
//     "봉순 3화 18번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_18",
//     "봉순 3화 19번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_3_19"

//     "봉순 4화 0번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_0",
//     "봉순 4화 1번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_1",
//     "봉순 4화 2번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_2",
//     "봉순 4화 3번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_3",
//     "봉순 4화 4번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_4",
//     "봉순 4화 5번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_5",
//     "봉순 4화 6번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_6",
//     "봉순 4화 7번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_7",
//     "봉순 4화 8번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_8",
//     "봉순 4화 9번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_9",
//     "봉순 4화 10번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_10",
//     "봉순 4화 11번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_11",
//     "봉순 4화 12번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_12",
//     "봉순 4화 13번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_13",
//     "봉순 4화 14번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_4_14"

//     "봉순 5화 0번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_0",
//     "봉순 5화 1번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_1",
//     "봉순 5화 2번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_2",
//     "봉순 5화 3번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_3",
//     "봉순 5화 4번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_4",
//     "봉순 5화 5번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_5",
//     "봉순 5화 6번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_6",
//     "봉순 5화 7번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_7",
//     "봉순 5화 8번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_8",
//     "봉순 5화 9번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_9",
//     "봉순 5화 10번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_10",
//     "봉순 5화 11번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_11",
//     "봉순 5화 12번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_12",
//     "봉순 5화 13번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_13",
//     "봉순 5화 14번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_14",
//     "봉순 5화 15번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_15",
//     "봉순 5화 16번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_16",
//     "봉순 5화 17번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_5_17"

//     "봉순 6화 0번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_0",
//     "봉순 6화 1번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_1",
//     "봉순 6화 2번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_2",
//     "봉순 6화 3번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_3",
//     "봉순 6화 4번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_4",
//     "봉순 6화 5번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_5",
//     "봉순 6화 6번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_6",
//     "봉순 6화 7번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_7",
//     "봉순 6화 8번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_8",
//     "봉순 6화 9번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_9",
//     "봉순 6화 10번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_10",
//     "봉순 6화 11번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_11",
//     "봉순 6화 12번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_12",
//     "봉순 6화 13번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_13",
//     "봉순 6화 14번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_14",
//     "봉순 6화 15번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_15",
//     "봉순 6화 16번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_6_16"

//     "봉순 7화 0번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_0",
//     "봉순 7화 1번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_1",
//     "봉순 7화 2번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_2",
//     "봉순 7화 3번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_3",
//     "봉순 7화 4번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_4",
//     "봉순 7화 5번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_5",
//     "봉순 7화 6번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_6",
//     "봉순 7화 7번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_7",
//     "봉순 7화 8번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_8",
//     "봉순 7화 9번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_9",
//     "봉순 7화 10번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_10",
//     "봉순 7화 11번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_11",
//     "봉순 7화 12번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_12",
//     "봉순 7화 13번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_13",
//     "봉순 7화 14번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_14",
//     "봉순 7화 15번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_15",
//     "봉순 7화 16번 턴 대화 음성 재생 버튼 클릭" : "bongsoon_7_16"



//     "탐정엠버 0화 0번 턴 대화 음성 재생 버튼 클릭"
//     "Love is near blind 1화 0번 턴 대화 음성 재생 버튼 클릭"
//     "순애로운 회사생활 1화 0번 턴 대화 음성 재생 버튼 클릭"
//     "이상한 나라의 앨리스 1화 0번 턴 대화 음성 재생 버튼 클릭"

//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 ${episodeState.currentTurnIndex}번 턴 뒤로가기 버튼 클릭"----------
//     "봉순 1화 1번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_0"
//     "봉순 1화 2번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_1"
//     "봉순 1화 3번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_2",
//     "봉순 1화 4번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_3",
//     "봉순 1화 5번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_4",
//     "봉순 1화 6번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_5",
//     "봉순 1화 7번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_6",
//     "봉순 1화 8번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_7",
//     "봉순 1화 9번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_8",
//     "봉순 1화 10번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_9",
//     "봉순 1화 11번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_10",
//     "봉순 1화 12번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_11",
//     "봉순 1화 13번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_12",
//     "봉순 1화 14번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_13",
//     "봉순 1화 15번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_14",
//     "봉순 1화 16번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_15",
//     "봉순 1화 17번 턴 뒤로가기 버튼 클릭" : "bongsoon_1_16",

//     "봉순 2화 1번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_0",
//     "봉순 2화 2번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_1",
//     "봉순 2화 3번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_2",
//     "봉순 2화 4번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_3",
//     "봉순 2화 5번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_4",
//     "봉순 2화 6번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_5",
//     "봉순 2화 7번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_6",
//     "봉순 2화 8번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_7",
//     "봉순 2화 9번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_8",
//     "봉순 2화 10번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_9",
//     "봉순 2화 11번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_10",
//     "봉순 2화 12번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_11",
//     "봉순 2화 13번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_12",
//     "봉순 2화 14번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_13",
//     "봉순 2화 15번 턴 뒤로가기 버튼 클릭" : "bongsoon_2_14"

//     "봉순 3화 1번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_0",
//     "봉순 3화 2번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_1",
//     "봉순 3화 3번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_2",
//     "봉순 3화 4번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_3",
//     "봉순 3화 5번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_4",
//     "봉순 3화 6번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_5",
//     "봉순 3화 7번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_6",
//     "봉순 3화 8번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_7",
//     "봉순 3화 9번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_8",
//     "봉순 3화 10번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_9",
//     "봉순 3화 11번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_10",
//     "봉순 3화 12번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_11",
//     "봉순 3화 13번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_12",
//     "봉순 3화 14번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_13",
//     "봉순 3화 15번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_14",
//     "봉순 3화 16번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_15",
//     "봉순 3화 17번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_16",
//     "봉순 3화 18번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_17",
//     "봉순 3화 19번 턴 뒤로가기 버튼 클릭" : "bongsoon_3_18"

//     "봉순 4화 1번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_0",
//     "봉순 4화 2번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_1",
//     "봉순 4화 3번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_2",
//     "봉순 4화 4번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_3",
//     "봉순 4화 5번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_4",
//     "봉순 4화 6번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_5",
//     "봉순 4화 7번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_6",
//     "봉순 4화 8번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_7",
//     "봉순 4화 9번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_8",
//     "봉순 4화 10번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_9",
//     "봉순 4화 11번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_10",
//     "봉순 4화 12번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_11",
//     "봉순 4화 13번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_12",
//     "봉순 4화 14번 턴 뒤로가기 버튼 클릭" : "bongsoon_4_13"

//     "봉순 5화 1번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_0",
//     "봉순 5화 2번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_1",
//     "봉순 5화 3번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_2",
//     "봉순 5화 4번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_3",
//     "봉순 5화 5번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_4",
//     "봉순 5화 6번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_5",
//     "봉순 5화 7번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_6",
//     "봉순 5화 8번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_7",
//     "봉순 5화 9번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_8",
//     "봉순 5화 10번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_9",
//     "봉순 5화 11번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_10",
//     "봉순 5화 12번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_11",
//     "봉순 5화 13번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_12",
//     "봉순 5화 14번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_13",
//     "봉순 5화 15번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_14",
//     "봉순 5화 16번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_15",
//     "봉순 5화 17번 턴 뒤로가기 버튼 클릭" : "bongsoon_5_16"

//     "봉순 6화 1번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_0",
//     "봉순 6화 2번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_1",
//     "봉순 6화 3번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_2",
//     "봉순 6화 4번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_3",
//     "봉순 6화 5번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_4",
//     "봉순 6화 6번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_5",
//     "봉순 6화 7번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_6",
//     "봉순 6화 8번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_7",
//     "봉순 6화 9번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_8",
//     "봉순 6화 10번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_9",
//     "봉순 6화 11번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_10",
//     "봉순 6화 12번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_11",
//     "봉순 6화 13번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_12",
//     "봉순 6화 14번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_13",
//     "봉순 6화 15번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_14",
//     "봉순 6화 16번 턴 뒤로가기 버튼 클릭" : "bongsoon_6_15",

//     "봉순 7화 1번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_0",
//     "봉순 7화 2번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_1",
//     "봉순 7화 3번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_2",
//     "봉순 7화 4번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_3",
//     "봉순 7화 5번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_4",
//     "봉순 7화 6번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_5",
//     "봉순 7화 7번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_6",
//     "봉순 7화 8번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_7",
//     "봉순 7화 9번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_8",
//     "봉순 7화 10번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_9",
//     "봉순 7화 11번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_10",
//     "봉순 7화 12번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_11",
//     "봉순 7화 13번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_12",
//     "봉순 7화 14번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_13",
//     "봉순 7화 15번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_14",
//     "봉순 7화 16번 턴 뒤로가기 버튼 클릭" : "bongsoon_7_15"






//     "탐정엠버 0화 1번 턴 뒤로가기 버튼 클릭"
//     "Love is near blind 1화 1번 턴 뒤로가기 버튼 클릭"
//     "순애로운 회사생활 1화 1번 턴 뒤로가기 버튼 클릭"
//     "이상한 나라의 앨리스 1화 1번 턴 뒤로가기 버튼 클릭"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 다음 버튼 클릭"--------
//     "봉순 1화 0번 턴 다음 버튼 클릭" : "bongsoon_1_1",
//     "봉순 1화 1번 턴 다음 버튼 클릭" : "bongsoon_1_2",
//     "봉순 1화 2번 턴 다음 버튼 클릭" : "bongsoon_1_3",
//     "봉순 1화 3번 턴 다음 버튼 클릭" : "bongsoon_1_4",
//     "봉순 1화 4번 턴 다음 버튼 클릭" : "bongsoon_1_5",
//     "봉순 1화 5번 턴 다음 버튼 클릭" : "bongsoon_1_6",
//     "봉순 1화 6번 턴 다음 버튼 클릭" : "bongsoon_1_7",
//     "봉순 1화 7번 턴 다음 버튼 클릭" : "bongsoon_1_8",
//     "봉순 1화 8번 턴 다음 버튼 클릭" : "bongsoon_1_9",
//     "봉순 1화 9번 턴 다음 버튼 클릭" : "bongsoon_1_10",
//     "봉순 1화 10번 턴 다음 버튼 클릭" : "bongsoon_1_11",
//     "봉순 1화 11번 턴 다음 버튼 클릭" : "bongsoon_1_12",
//     "봉순 1화 12번 턴 다음 버튼 클릭" : "bongsoon_1_13",
//     "봉순 1화 13번 턴 다음 버튼 클릭" : "bongsoon_1_14",
//     "봉순 1화 14번 턴 다음 버튼 클릭" : "bongsoon_1_15",
//     "봉순 1화 15번 턴 다음 버튼 클릭" : "bongsoon_1_16",
//     "봉순 1화 16번 턴 다음 버튼 클릭" : "bongsoon_1_17"
//     "봉순 1화 17번 턴 다음 버튼 클릭" : "bongsoon_1_ask_brushup"

//     "봉순 2화 0번 턴 다음 버튼 클릭" : "bongsoon_2_1",
//     "봉순 2화 1번 턴 다음 버튼 클릭" : "bongsoon_2_2",
//     "봉순 2화 2번 턴 다음 버튼 클릭" : "bongsoon_2_3",
//     "봉순 2화 3번 턴 다음 버튼 클릭" : "bongsoon_2_4",
//     "봉순 2화 4번 턴 다음 버튼 클릭" : "bongsoon_2_5",
//     "봉순 2화 5번 턴 다음 버튼 클릭" : "bongsoon_2_6",
//     "봉순 2화 6번 턴 다음 버튼 클릭" : "bongsoon_2_7",
//     "봉순 2화 7번 턴 다음 버튼 클릭" : "bongsoon_2_8",
//     "봉순 2화 8번 턴 다음 버튼 클릭" : "bongsoon_2_9",
//     "봉순 2화 9번 턴 다음 버튼 클릭" : "bongsoon_2_10",
//     "봉순 2화 10번 턴 다음 버튼 클릭" : "bongsoon_2_11",
//     "봉순 2화 11번 턴 다음 버튼 클릭" : "bongsoon_2_12",
//     "봉순 2화 12번 턴 다음 버튼 클릭" : "bongsoon_2_13",
//     "봉순 2화 13번 턴 다음 버튼 클릭" : "bongsoon_2_14",
//     "봉순 2화 14번 턴 다음 버튼 클릭" : "bongsoon_2_15",
//     "봉순 2화 15번 턴 다음 버튼 클릭" : "bongsoon_2_ask_brushup"

//     "봉순 3화 0번 턴 다음 버튼 클릭" : "bongsoon_3_1",
//     "봉순 3화 1번 턴 다음 버튼 클릭" : "bongsoon_3_2",
//     "봉순 3화 2번 턴 다음 버튼 클릭" : "bongsoon_3_3",
//     "봉순 3화 3번 턴 다음 버튼 클릭" : "bongsoon_3_4",
//     "봉순 3화 4번 턴 다음 버튼 클릭" : "bongsoon_3_5",
//     "봉순 3화 5번 턴 다음 버튼 클릭" : "bongsoon_3_6",
//     "봉순 3화 6번 턴 다음 버튼 클릭" : "bongsoon_3_7",
//     "봉순 3화 7번 턴 다음 버튼 클릭" : "bongsoon_3_8",
//     "봉순 3화 8번 턴 다음 버튼 클릭" : "bongsoon_3_9",
//     "봉순 3화 9번 턴 다음 버튼 클릭" : "bongsoon_3_10",
//     "봉순 3화 10번 턴 다음 버튼 클릭" : "bongsoon_3_11",
//     "봉순 3화 11번 턴 다음 버튼 클릭" : "bongsoon_3_12",
//     "봉순 3화 12번 턴 다음 버튼 클릭" : "bongsoon_3_13",
//     "봉순 3화 13번 턴 다음 버튼 클릭" : "bongsoon_3_14",
//     "봉순 3화 14번 턴 다음 버튼 클릭" : "bongsoon_3_15",
//     "봉순 3화 15번 턴 다음 버튼 클릭" : "bongsoon_3_16",
//     "봉순 3화 16번 턴 다음 버튼 클릭" : "bongsoon_3_17",
//     "봉순 3화 17번 턴 다음 버튼 클릭" : "bongsoon_3_18",
//     "봉순 3화 18번 턴 다음 버튼 클릭" : "bongsoon_3_19",
//     "봉순 3화 19번 턴 다음 버튼 클릭" : "bongsoon_3_ask_brushup"

//     "봉순 4화 0번 턴 다음 버튼 클릭" : "bongsoon_4_1",
//     "봉순 4화 1번 턴 다음 버튼 클릭" : "bongsoon_4_2",
//     "봉순 4화 2번 턴 다음 버튼 클릭" : "bongsoon_4_3",
//     "봉순 4화 3번 턴 다음 버튼 클릭" : "bongsoon_4_4",
//     "봉순 4화 4번 턴 다음 버튼 클릭" : "bongsoon_4_5",
//     "봉순 4화 5번 턴 다음 버튼 클릭" : "bongsoon_4_6",
//     "봉순 4화 6번 턴 다음 버튼 클릭" : "bongsoon_4_7",
//     "봉순 4화 7번 턴 다음 버튼 클릭" : "bongsoon_4_8",
//     "봉순 4화 8번 턴 다음 버튼 클릭" : "bongsoon_4_9",
//     "봉순 4화 9번 턴 다음 버튼 클릭" : "bongsoon_4_10",
//     "봉순 4화 10번 턴 다음 버튼 클릭" : "bongsoon_4_11",
//     "봉순 4화 11번 턴 다음 버튼 클릭" : "bongsoon_4_12",
//     "봉순 4화 12번 턴 다음 버튼 클릭" : "bongsoon_4_13",
//     "봉순 4화 13번 턴 다음 버튼 클릭" : "bongsoon_4_14",
//     "봉순 4화 14번 턴 다음 버튼 클릭" : "bongsoon_4_ask_brushup"

//     "봉순 5화 0번 턴 다음 버튼 클릭" : "bongsoon_5_1",
//     "봉순 5화 1번 턴 다음 버튼 클릭" : "bongsoon_5_2",
//     "봉순 5화 2번 턴 다음 버튼 클릭" : "bongsoon_5_3",
//     "봉순 5화 3번 턴 다음 버튼 클릭" : "bongsoon_5_4",
//     "봉순 5화 4번 턴 다음 버튼 클릭" : "bongsoon_5_5",
//     "봉순 5화 5번 턴 다음 버튼 클릭" : "bongsoon_5_6",
//     "봉순 5화 6번 턴 다음 버튼 클릭" : "bongsoon_5_7",
//     "봉순 5화 7번 턴 다음 버튼 클릭" : "bongsoon_5_8",
//     "봉순 5화 8번 턴 다음 버튼 클릭" : "bongsoon_5_9",
//     "봉순 5화 9번 턴 다음 버튼 클릭" : "bongsoon_5_10",
//     "봉순 5화 10번 턴 다음 버튼 클릭" : "bongsoon_5_11",
//     "봉순 5화 11번 턴 다음 버튼 클릭" : "bongsoon_5_12",
//     "봉순 5화 12번 턴 다음 버튼 클릭" : "bongsoon_5_13",
//     "봉순 5화 13번 턴 다음 버튼 클릭" : "bongsoon_5_14",
//     "봉순 5화 14번 턴 다음 버튼 클릭" : "bongsoon_5_15",
//     "봉순 5화 15번 턴 다음 버튼 클릭" : "bongsoon_5_16",
//     "봉순 5화 16번 턴 다음 버튼 클릭" : "bongsoon_5_17",
//     "봉순 5화 17번 턴 다음 버튼 클릭" : "bongsoon_5_ask_brushup"

//     "봉순 6화 0번 턴 다음 버튼 클릭" : "bongsoon_6_1",
//     "봉순 6화 1번 턴 다음 버튼 클릭" : "bongsoon_6_2",
//     "봉순 6화 2번 턴 다음 버튼 클릭" : "bongsoon_6_3",
//     "봉순 6화 3번 턴 다음 버튼 클릭" : "bongsoon_6_4",
//     "봉순 6화 4번 턴 다음 버튼 클릭" : "bongsoon_6_5",
//     "봉순 6화 5번 턴 다음 버튼 클릭" : "bongsoon_6_6",
//     "봉순 6화 6번 턴 다음 버튼 클릭" : "bongsoon_6_7",
//     "봉순 6화 7번 턴 다음 버튼 클릭" : "bongsoon_6_8",
//     "봉순 6화 8번 턴 다음 버튼 클릭" : "bongsoon_6_9",
//     "봉순 6화 9번 턴 다음 버튼 클릭" : "bongsoon_6_10",
//     "봉순 6화 10번 턴 다음 버튼 클릭" : "bongsoon_6_11",
//     "봉순 6화 11번 턴 다음 버튼 클릭" : "bongsoon_6_12",
//     "봉순 6화 12번 턴 다음 버튼 클릭" : "bongsoon_6_13",
//     "봉순 6화 13번 턴 다음 버튼 클릭" : "bongsoon_6_14",
//     "봉순 6화 14번 턴 다음 버튼 클릭" : "bongsoon_6_15",
//     "봉순 6화 15번 턴 다음 버튼 클릭" : "bongsoon_6_16",
//     "봉순 6화 16번 턴 다음 버튼 클릭" : "bongsoon_6_ask_brushup"

//     "봉순 7화 0번 턴 다음 버튼 클릭" : "bongsoon_7_1",
//     "봉순 7화 1번 턴 다음 버튼 클릭" : "bongsoon_7_2",
//     "봉순 7화 2번 턴 다음 버튼 클릭" : "bongsoon_7_3",
//     "봉순 7화 3번 턴 다음 버튼 클릭" : "bongsoon_7_4",
//     "봉순 7화 4번 턴 다음 버튼 클릭" : "bongsoon_7_5",
//     "봉순 7화 5번 턴 다음 버튼 클릭" : "bongsoon_7_6",
//     "봉순 7화 6번 턴 다음 버튼 클릭" : "bongsoon_7_7",
//     "봉순 7화 7번 턴 다음 버튼 클릭" : "bongsoon_7_8",
//     "봉순 7화 8번 턴 다음 버튼 클릭" : "bongsoon_7_9",
//     "봉순 7화 9번 턴 다음 버튼 클릭" : "bongsoon_7_10",
//     "봉순 7화 10번 턴 다음 버튼 클릭" : "bongsoon_7_11",
//     "봉순 7화 11번 턴 다음 버튼 클릭" : "bongsoon_7_12",
//     "봉순 7화 12번 턴 다음 버튼 클릭" : "bongsoon_7_13",
//     "봉순 7화 13번 턴 다음 버튼 클릭" : "bongsoon_7_14",
//     "봉순 7화 14번 턴 다음 버튼 클릭" : "bongsoon_7_15",
//     "봉순 7화 15번 턴 다음 버튼 클릭" : "bongsoon_7_16",
//     "봉순 7화 16번 턴 다음 버튼 클릭" : "bongsoon_7_ask_brushup"










//     "탐정엠버 0화 0번 턴 다음 버튼 클릭"
//     "Love is near blind 1화 0번 턴 다음 버튼 클릭"
//     "순애로운 회사생활 1화 0번 턴 다음 버튼 클릭"
//     "이상한 나라의 앨리스 1화 0번 턴 다음 버튼 클릭"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 ${choiceWord} 선택지 선택"----------
//     "봉순 1화 0번 턴 intention 선택지 선택"
//     "탐정엠버 0화 0번 턴 intention 선택지 선택"
//     "Love is near blind 1화 0번 턴 intention 선택지 선택"
//     "순애로운 회사생활 1화 0번 턴 intention 선택지 선택"
//     "이상한 나라의 앨리스 1화 0번 턴 intention 선택지 선택"


//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 선택지 확정 버튼 클릭 (결과: ${choice.isCorrect ? "정답" : "오답"})"----------
//     "봉순 1화 0번 턴 선택지 확정 버튼 클릭 (결과: 정답)"
//     "탐정엠버 0화 0번 턴 선택지 확정 버튼 클릭 (결과: 정답)"
//     "Love is near blind 1화 0번 턴 선택지 확정 버튼 클릭 (결과: 정답)"
//     "순애로운 회사생활 1화 0번 턴 선택지 확정 버튼 클릭 (결과: 정답)"
//     "이상한 나라의 앨리스 1화 0번 턴 선택지 확정 버튼 클릭 (결과: 정답)"

//     "봉순 1화 0번 턴 선택지 확정 버튼 클릭 (결과: 오답)"
//     "탐정엠버 0화 0번 턴 선택지 확정 버튼 클릭 (결과: 오답)"
//     "Love is near blind 1화 0번 턴 선택지 확정 버튼 클릭 (결과: 오답)"
//     "순애로운 회사생활 1화 0번 턴 선택지 확정 버튼 클릭 (결과: 오답)"
//     "이상한 나라의 앨리스 1화 0번 턴 선택지 확정 버튼 클릭 (결과: 오답)"

//     "${state.seriesTitle} ${state.episode!.index}화 ${state.currentTurnIndex}번 턴 결과 바텀싯 해설 스크롤"------------------------
//     "봉순 1화 0번 턴 결과 바텀싯 해설 스크롤"
//     "탐정엠버 0화 0번 턴 결과 바텀싯 해설 스크롤"
//     "Love is near blind 1화 0번 턴 결과 바텀싯 해설 스크롤"
//     "순애로운 회사생활 1화 0번 턴 결과 바텀싯 해설 스크롤"
//     "이상한 나라의 앨리스 1화 0번 턴 결과 바텀싯 해설 스크롤"

//     "${episodeState.seriesTitle} ${episodeState.episode!.index}화 ${episodeState.currentTurnIndex}번 턴 선택지 ${choiceWord} 자세히 보기 클릭"---------
//     "봉순 1화 0번 턴 선택지 intention 자세히 보기 클릭" : "choice_word_info"
//     "탐정엠버 0화 0번 턴 선택지 intention 자세히 보기 클릭" : "choice_word_info"
//     "Love is near blind 1화 0번 턴 선택지 intention 자세히 보기 클릭" : "choice_word_info"
//     "순애로운 회사생활 1화 0번 턴 선택지 intention 자세히 보기 클릭" : "choice_word_info"
//     "이상한 나라의 앨리스 1화 0번 턴 선택지 intention 자세히 보기 클릭" : "choice_word_info"







//     "Love is near blind 1화 인트로": "lib_19_intro",
//     "Love is near blind 19화 0번 턴": "lib_19_0",
//     "Love is near blind 19화 1번 턴": "lib_19_1",
//     "Love is near blind 19화 2번 턴": "lib_19_2",
//     "Love is near blind 19화 3번 턴": "lib_19_3",
//     "봉순 6화 14번 턴" : "bongsoon_6_14",
//     "봉순 6화 15번 턴" : "bongsoon_6_15",
//     "시리즈 봉순 뒤로가기 클릭" : "home_tab_1",
//     "음성 재생 속도 조절" : "episode_settings",
//     "음성 자동 재생 토글 클릭" : "episode_settings"
// }

// export default logImageUrlMap;