import { useCreateScriptStore } from "business_logic/script/scriptStore";
import "./createScript.scss";
import { useCallback } from "react";

export default function CreateScriptStep2() {
  const { rawScript, setRawScript } = useCreateScriptStore();

  const handleTextChange = useCallback((event: any) => {
    setRawScript(event.target.value);
  }, [rawScript]);

  const hintText = `1) 퀴즈 단어는 {중괄호}로 감싸주세요.\n2) 캐릭터 이름은 영문으로 :앞에 붙여주세요.\n\nExample : 
  Brook: Well, well, well. What do we have here? This is {very} disappointing. 
  Brook: The famous Ember Dash, shackled and vulnerable. How tragic! I {like} it!
  Ember's heart raced as she realized her hands were chained to the chair.`;

  return (
    <div className="create-script-step-1">
      <textarea className="raw-script-textarea max-width max-height" value={rawScript} onChange={handleTextChange} rows={10} cols={50} maxLength={2800} placeholder={hintText} />
    </div>
  );
};
