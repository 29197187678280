import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import ScriptApiInstance from "business_logic/script/ScriptApi";
import { DialogTurn } from "business_logic/script/scriptModels";
import { RefreshCcwIcon } from "lucide-react";
import EpisodePlay from "presentation/components/episodePlay/EpisodePlay";
import Loading from "presentation/components/feedbacks/Loading";
import { useState } from "react";
import { useQuery } from "react-query";

interface Props {
  initialDialogTurns: DialogTurn[];
  episodeId: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export default function EpisodePlayDialog({ initialDialogTurns, episodeId, open, onClose }: Props) {
  const [loading, setLoading] = useState(false);
  const [dialogTurns, setDialogTurns] = useState<DialogTurn[]>(initialDialogTurns);

  const { isLoading, refetch } = useQuery(
    ['fetchDialogTurns', episodeId],
    () => ScriptApiInstance.fetchDialogTurns(episodeId),
    {
      enabled: false,
      onSuccess: (data) => {
        setDialogTurns(data);
        setLoading(false);
      },
    }
  );

  const handleRefresh = async () => {
    setLoading(true);
    refetch();
  }

  return (
    <Dialog open={open} onOpenChange={onClose} >
      <DialogContent className="w-full max-w-[80vw] h-[90vh]">
        <DialogHeader>
          <DialogTitle className="text-2xl align-center gap-2 flex-row">Episode Play
            <RefreshCcwIcon className="cursor-pointer" onClick={handleRefresh} />
          </DialogTitle>
        </DialogHeader>
        {loading
          ? <Loading />
          : <div className="episode-play-mode flex-row h-full items-center">
            {dialogTurns.sort((a, b) => a.index - b.index).map((dialogTurn) =>
              <div className="container flex-col items-left">
                <div className="index mb-4">{dialogTurn.index}</div>
                <EpisodePlay dialogTurns={[dialogTurn]} />
              </div>
            )}
          </div>}
      </DialogContent>
    </Dialog>
  );
}
