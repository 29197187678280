import { Badge } from "@/components/ui/badge";
import { Series } from "business_logic/series/seriesModels";
import useEditContent from "../useEditContent";

export default function SeriesDrawerItem({ series }: { series: Series }) {

    const { seriesSelected, setSeriesSelected } = useEditContent();

    return (
        <div className={`p-2 hover:bg-gray-200 cursor-pointer border border-gray-200 rounded-md ${seriesSelected && series.id === seriesSelected.id ? 'bg-gray-200' : ''}`} onClick={() => setSeriesSelected(series)} >
            <div className="grid grid-cols-4 gap-4 items-center">
                <div className="p-2 col-span-3 content-start">
                    <h2 className="text-xl font-bold">{series.title}</h2>
                    <p className="text-sm text-gray-500 truncate">{series.description}</p>
                    <Badge variant={`${series.status === "draft" ? "outline" : "default"}`}>{series.status}</Badge>
                </div>
                <img className="w-full object-cover rounded-md " src={series.posterImageUrl} />
            </div>
        </div>

    );
}