import { User, UserAttendance, UserAttendanceWithUserInfo, UserLog, UserReport } from "business_logic/analytics/analyticsModels";
import BaseApi from "business_logic/base/BaseApi";
import { format } from "date-fns";
import DevLogger from "services/Logger";
import { toZonedTime } from 'date-fns-tz'

class AnalyticsApi extends BaseApi {

  private static instance: AnalyticsApi;
  private constructor() {
    super();
  }
  public static getInstance() {
    if (!AnalyticsApi.instance) {
      AnalyticsApi.instance = new AnalyticsApi();
    }
    return AnalyticsApi.instance;
  }

  async fetchAttendancesByDate(date: Date): Promise<UserAttendanceWithUserInfo[]> {
    DevLogger.log('Analytics Api - fetchAttendancesByDate');
    let formattedDate: string;

    try {
      formattedDate = format(date.toLocaleString(), 'yyyy-MM-dd');
    } catch (error) {
      const koreanDate = toZonedTime(date, 'Asia/Seoul');
      formattedDate = format(koreanDate, 'yyyy-MM-dd');
    }
    try {
      const response = await this.axiosInstance.get<UserAttendance[]>('analytics/attendances', {
        params: { date: formattedDate }
      });
      DevLogger.log('Analytics Api Response - fetchAttendancesByDate', response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async fetchAttendancesByUser(userSearch: string): Promise<UserAttendanceWithUserInfo[]> {
    DevLogger.log('Analytics Api - fetchAttendancesByUser');
    try {
      const response = await this.axiosInstance.get<UserAttendance[]>('analytics/attendances', {
        params: { userSearch: userSearch }
      });
      DevLogger.log('Analytics Api Response - fetchAttendancesByUser', response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async fetchAttendanceSessionLogs(userId: string, attendanceDate: Date): Promise<UserLog[]> {
    let formattedDate: string;

    try {
      formattedDate = format(attendanceDate.toLocaleString(), 'yyyy-MM-dd');
    } catch (error) {
      const koreanDate = toZonedTime(attendanceDate, 'Asia/Seoul');
      formattedDate = format(koreanDate, 'yyyy-MM-dd');
    }

    try {
      const response = await this.axiosInstance.get<UserLog[]>(`analytics/users/${userId}/attendances/${formattedDate}/logs`);
      DevLogger.log('Analytics Api Response - fetchUserSessionLog', response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async fetchUserReport(userId: string): Promise<UserReport> {
    try {
      const response = await this.axiosInstance.get<UserReport>(`analytics/users/${userId}/report`);
      DevLogger.log('Analytics Api Response - fetchUserReport', response.data);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

}

const AnalyticsApiInstance = AnalyticsApi.getInstance();
export default AnalyticsApiInstance;
