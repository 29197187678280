class StringParser {
  static parseImageAndAudioUrl(url: string) {
    const regex = /\/([^/]+)\.(mp3|webp|png|jpeg)$/;
    const match = url.match(regex);
    if (match !== null) {
      return match[1];
    } else {
      return url;
    }
  }

  static parseDateTimeToString(dateTime: string) {
    const date = new Date(dateTime);
    return date.toLocaleString();
  }


  static extractFilename = (url: string): string => {

    if (!url) {
      return "";
    }

    // Split the URL by '/'
    const parts = url.split("/");

    // Get the last part (filename with extension)
    const lastPart = parts[parts.length - 1];

    // If the last part starts with a dot, it's just an extension, so return empty string
    if (lastPart.startsWith(".")) {
      return "";
    }

    // Split the last part by '.' and get the first part (filename without extension)
    const filenameParts = lastPart.split(".");
    return filenameParts[0] || "";
  };

}

export default StringParser;
