class DateFormatter {
  private static readonly MINUTE = 60;
  private static readonly HOUR = DateFormatter.MINUTE * 60;
  private static readonly DAY = DateFormatter.HOUR * 24;

  static formatRelative(isoString: string): string {
    const date = new Date(isoString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (diffInSeconds < DateFormatter.MINUTE) {
      return "방금 전";
    } else if (diffInSeconds < DateFormatter.HOUR) {
      const minutes = Math.floor(diffInSeconds / DateFormatter.MINUTE);
      return `${minutes} 분${minutes > 1 ? "" : ""} 전`;
    } else if (diffInSeconds < DateFormatter.DAY) {
      const hours = Math.floor(diffInSeconds / DateFormatter.HOUR);
      return `${hours} 시간${hours > 1 ? "" : ""} 전`;
    } else {
      const days = Math.floor(diffInSeconds / DateFormatter.DAY);
      return `${days} 일${days > 1 ? "" : ""} 전`;
    }
  }

  static formatShort(isoString: string): string {
    const date = new Date(isoString);
    const year = date.getFullYear() % 100;
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}년 ${month}월 ${day}일`;
  }

  static formatISO(date: Date): string {
    return date.toISOString();
  }

  static parseISO(isoString: string): Date {
    return new Date(isoString);
  }

  static formatDate(isoString: string): string {
    return DateFormatter.formatShort(isoString);
  }
}

export default DateFormatter;
