import { Dialog } from "business_logic/script/scriptModels";
import React from "react";

export interface HandleChangeProps<T> {
  data: T[];
  upsertData: (data: T) => void;
  field: keyof T | string;
  index: number;
  event: React.ChangeEvent<HTMLTextAreaElement>;
}

export const handleChange = <T,>({ data, upsertData, field, index, event }: HandleChangeProps<T>) => {
  const newData = [...data];
  const fieldParts = typeof field === "string" && field.includes("-") ? field.split("-") : [field];

  if (fieldParts[0] === "multipleChoices" && typeof fieldParts[1] === "string") {
    const choiceIndex = parseInt(fieldParts[1]);
    const item = newData[index] as any;
    const newChoices = item.multipleChoices ? [...item.multipleChoices] : [];
    newChoices[choiceIndex] = event.target.value;
    newData[index] = {
      ...item,
      multipleChoices: newChoices,
    };
  } else {
    newData[index] = {
      ...newData[index],
      [field]: event.target.value,
    };
  }

  newData.forEach((data) => {
    upsertData(data);
  });
};


export interface HandleDialogChangeProps<T extends Dialog> {
  dialogs: T[];
  setDialogs: (dialogs: T[]) => void;
  field: keyof T | string;
  index: number;
  event: React.ChangeEvent<HTMLTextAreaElement>;
}

export const handleDialogChange = <T extends Dialog>({ dialogs, setDialogs, field, index, event }: HandleDialogChangeProps<T>) => {
  const newDialogs = dialogs.map((dialog, i) => {
    if (i !== index) return dialog;

    if (typeof field === 'string' && field.includes("-")) {
      const [arrayField, arrayIndex] = field.split("-");
      const newValues = [...(dialog[arrayField as keyof T] as unknown as any[] || [])];
      const newArray = [...newValues];
      newArray[parseInt(arrayIndex)] = event.target.value;

      return { ...dialog, [arrayField]: newArray };
    }
    return { ...dialog, [field]: event.target.value };
  });

  setDialogs(newDialogs);
};
