import { useAuth } from "contexts/AuthContext";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import "styles/global.scss";
import "styles/buttons.scss";
import "./signIn.scss";

export default function SignInPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { signIn } = useAuth();
  const { mutate } = useMutation(
    "signIn",
    async ({ email, password }: { email: string; password: string }) => {
      return await signIn(email, password);
    },
    {
      onError: (error: any) => {
        alert(error.message);
      },
      onSuccess: (data: any) => {
        alert("로그인 성공");
        navigate("/home");
      },
    }
  );

  const handleSignIn = async (event: React.FormEvent) => {
    event.preventDefault();
    mutate({ email, password });
  };

  return (
    <div className="page-sign-in justify-center align-center display-flex">
      <div className="container-sign-in flex-col">
        <h1 className="text-center">로그인</h1>
        <form className="margin-bottom-16">
          <span className="label">이메일</span>
          <input className="text-input-box" type="email" id="email" value={email} onChange={(event) => setEmail(event.target.value)} />
          <br />
          <br />
          <span className="label">비밀번호</span>
          <input className="text-input-box" type="password" id="password" value={password} onChange={(event) => setPassword(event.target.value)} />
          <br />
        </form>
        <div className="flex-col justify-center align-center">
          <button className="button-sign-in" onClick={handleSignIn}>
            로그인
          </button>
          {/* <button className="button-sign-up" onClick={() => navigate("/signup")}>
            회원가입
          </button> */}
        </div>
      </div>
    </div>
  );
}
