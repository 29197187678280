import DashboardApiInstance from "business_logic/dashboard/DashboardApi";
import { ArrowDown, ArrowUp } from "lucide-react";
import Loading from "presentation/components/feedbacks/Loading";
import { useState } from "react";
import { useQuery } from "react-query";
import StatSummaryCard, { StatSummaryCardProps } from "../components/StatSummaryCard";
import { useDashboard } from "../useDashboard";
import { RetentionGraph } from "./RetentionGraph";

export default function RetentionTab() {

    const [retentionSummaryCards, setRetentionSummaryCards] = useState<StatSummaryCardProps[]>([]);
    const [retentionChartData, setRetentionChartData] = useState<any[]>([]);
    const { parseRetentionRate } = useDashboard();

    const fetchRetentionStats = useQuery('fetchRetentionStats', async () => DashboardApiInstance.fetchRetentionStats(),
        {
            enabled: true,
            refetchInterval: 600000,
            onSuccess: ({ retentionRates, returnRates }) => {

                setRetentionChartData(retentionRates)

                const day1Data = returnRates.find(item => item.returnDay === 1);
                const day3Data = returnRates.find(item => item.returnDay === 3);
                const day7Data = returnRates.find(item => item.returnDay === 7);
                const day14Data = returnRates.find(item => item.returnDay === 14);
                const day30Data = returnRates.find(item => item.returnDay === 30);

                setRetentionSummaryCards([
                    {
                        label: 'Returning rate : tomorrow',
                        value: day1Data && parseRetentionRate(day1Data.returnRateToday.returningRate),
                        icon: ArrowUp,
                        infoText: <>It was <span className="font-bold text-sm text-black">{day1Data && parseRetentionRate(day1Data.returnRateComparison.returningRate)}</span> 2 weeks ago</>
                    },
                    {
                        label: 'Returning rate : 3 days',
                        value: day3Data && parseRetentionRate(day3Data.returnRateToday.returningRate),
                        icon: ArrowUp,
                        infoText: <>It was <span className="font-bold text-sm text-black">{day3Data && parseRetentionRate(day3Data.returnRateComparison.returningRate)}</span> 2 weeks ago</>
                    },
                    {
                        label: 'Returning rate : 1 week',
                        value: day7Data && parseRetentionRate(day7Data.returnRateToday.returningRate),
                        icon: ArrowUp,
                        infoText: <>It was <span className="font-bold text-sm text-black">{day7Data && parseRetentionRate(day7Data.returnRateComparison.returningRate)}</span> 2 weeks ago</>
                    },
                    {
                        label: 'Returning rate : 2 weeks',
                        value: day14Data && parseRetentionRate(day14Data.returnRateToday.returningRate),
                        icon: ArrowDown,
                        infoText: <>It was <span className="font-bold text-sm text-black">{day14Data && parseRetentionRate(day14Data.returnRateComparison.returningRate)}</span> 2 weeks ago</>
                    },
                    {
                        label: 'Returning rate : 1 month',
                        value: day30Data && parseRetentionRate(day30Data.returnRateToday.returningRate),
                        icon: ArrowDown,
                        infoText: <>It was <span className="font-bold text-sm text-black">{day30Data && parseRetentionRate(day30Data.returnRateComparison.returningRate)}</span> 2 weeks ago</>
                    },
                ])
            }
        },
    );

    if (fetchRetentionStats.isLoading) return <div><Loading /></div>
    if (fetchRetentionStats.isError) return <div>Error</div>
    return (
        <div className="flex-col gap-4 h-[90vh]">
            <section className="grid grid-cols-5 gap-4">
                {retentionSummaryCards.map((card, index) => (
                    <StatSummaryCard key={index} label={card.label} value={card.value} infoText={card.infoText} />)
                )}
            </section>
            <section className="w-full">
                <RetentionGraph data={retentionChartData} />
            </section>
        </div >
    );
}