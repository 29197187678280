import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AssetValidation from "./components/AssetValidation";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DialogTurn } from "business_logic/script/scriptModels";
import ScriptApiInstance from "business_logic/script/ScriptApi";

export default function ValidateScriptPage() {
  const { episodeId } = useParams();
  const [, setDialogTurns] = useState<DialogTurn[]>([]);

  useEffect(() => {

    const fetchDialogTurns = async () => {
      const turns = await ScriptApiInstance.fetchDialogTurns(episodeId);
      setDialogTurns(turns);
    };

    fetchDialogTurns();
  }, [episodeId]);

  return (
    <div className="page">
      <div className="page-header flex-col">
        <h1 className="h1">스크립트 정보</h1>
        <section className="section-tabs">
          <Tabs key={episodeId}>
            <TabList>
              <Tab>Asset Validation</Tab>
            </TabList>
            <TabPanel>
              <AssetValidation key={`active-${episodeId}`} />
            </TabPanel>
          </Tabs>
        </section>
      </div>
    </div>
  );
}
