import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { Label } from "@radix-ui/react-label";
import SeriesApiInstance from "business_logic/series/SeriesApi";
import { useCallback } from "react";
import useEditContent from "../useEditContent";

export default function SeriesInfo() {
    const { seriesSelected, fetchSeries } = useEditContent();

    const updateSeries = useCallback(async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        try {
            await SeriesApiInstance.updateSeries(
                seriesSelected.id, {
                title: formData.get("title") as string,
                description: formData.get("description") as string,
            })
            toast({
                title: "Success",
                description: "Series has been updated successfully",
            })
            fetchSeries();
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update series",
                variant: "destructive"
            })
        }
    }, [seriesSelected]);

    return (
        <Card>
            <CardHeader>
                <CardTitle>Series Information</CardTitle>
            </CardHeader>
            <form onSubmit={updateSeries}>
                <CardContent key={seriesSelected.id} className="flex-col gap-4">
                    <div>
                        <Label htmlFor="internalId" className="text-right"> Internal ID </Label>
                        <Input name="sdfsd" className="col-span-3" disabled={true} value={seriesSelected.internalId} />
                    </div>
                    <div>
                        <Label htmlFor="title" className="text-right"> Title </Label>
                        <Input name="title" className="col-span-3" defaultValue={seriesSelected.title} />
                    </div>
                    <div>
                        <Label htmlFor="description" className="text-right"> Description </Label>
                        <Textarea name="description" className="col-span-3" defaultValue={seriesSelected.description} />
                    </div>
                </CardContent>
                <CardFooter className="justify-end">
                    <Button variant="outline" onClick={() => updateSeries}>Save</Button>
                </CardFooter>
            </form>
        </Card>
    )
}