import WordApiInstance from "business_logic/word/WordApi";
import { WordInfoRow } from "business_logic/word/wordModels";

export const patchWord = async (rowData: any, words: WordInfoRow[]) => {
  const definitionId = rowData.definitionId;
  const updateWordInfo = words.find((word) => word.definitionId === definitionId);
  const shouldUpdate = window.confirm(`단어 ${rowData.word}를 업데이트 하시겠습니까?`);
  if (shouldUpdate) {
    await WordApiInstance.updateWordInfo(updateWordInfo!);
  }
};

export const deleteWord = async (rowData: any) => { };
