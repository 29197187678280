import { ScriptValidation } from "business_logic/script/scriptModels";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { EpisodeStatusEnum } from "business_logic/episode/episodeModels";
import ScriptApiInstance from "business_logic/script/ScriptApi";
import IsError from "presentation/components/feedbacks/Error";
import IsLoading from "presentation/components/feedbacks/Loading";
import ValidationTable from "./ValidationTable";

const tableHeaders = {
  series: ["isSeriesTitleValid", "isSeriesDescriptionValid", "isSeriesPosterValid"],
  character: ["name", "isCharacterProfileImageValid"],
  episode: ["episodeIndex", "isEpisodeTitleValid", "isEpisodeDescriptionValid", "isEpisodeIntroValid", "isEpisodePreviewImageValid", "isEpisodePreviewBgmValid", "isEpisodeThumbnailValid"],
  dialog: ["index", "isTargetScriptValid", "isCharacterImageValid", "isBackgroundImageValid", "isAudioValid", "isMaskedAudioValid", "isWordImageValid"],
};

export default function AssetValidation() {
  const navigate = useNavigate();
  const { episodeId } = useParams();
  const [isScriptValidated, setIsScriptValidated] = useState(false);
  const {
    data: scriptValidation,
    isLoading,
    isError,
  } = useQuery(["validateScript", episodeId], () => ScriptApiInstance.validateScript(episodeId!), {
    onSuccess: (data) => setIsScriptValidated(checkIsScriptValidated(data)),
  });

  const publishScriptMutation = useMutation((newStatus: EpisodeStatusEnum) => ScriptApiInstance.publishScript(episodeId!, newStatus), {
    onSuccess: () => {
      alert("출시되었습니다.");
      navigate("/script/edit");
    },
  });

  const checkIsScriptValidated = (data: ScriptValidation): boolean => {
    const allValidations = [...data.seriesValidation, ...data.dialogValidations, ...data.characterValidations, ...data.episodeValidation];
    return allValidations.every((validation) => Object.values(validation).every((value) => value !== false));
  };

  if (isLoading || publishScriptMutation.isLoading) return <IsLoading />;
  if (isError || publishScriptMutation.isError) return <IsError />;

  const handlePublish = (newStatus: EpisodeStatusEnum) => {
    publishScriptMutation.mutate(newStatus);
  };

  return (
    <div className="asset-validation-tab" style={{ maxHeight: "100vh" }}>
      {scriptValidation && (
        <>
          <div className="flex-row gap-32">
            <div className="flex-col">
              <ValidationTable title="에피소드 정보" headers={tableHeaders.episode} data={scriptValidation.episodeValidation} />
              <ValidationTable title="에피소드 스크립트" headers={tableHeaders.dialog} data={scriptValidation.dialogValidations} />
            </div>
            <div className="flex-col">
              <ValidationTable title="시리즈 정보" headers={tableHeaders.series} data={scriptValidation.seriesValidation} />
              <ValidationTable title="시리즈 캐릭터" headers={tableHeaders.character} data={scriptValidation.characterValidations} />
            </div>

          </div>
          {isScriptValidated && scriptValidation.episodeStatus !== "released" && (
            <button className="button-action" onClick={() => handlePublish(scriptValidation.episodeStatus === "draft" ? "testing" : "released")}>
              {scriptValidation.episodeStatus === "draft" ? "테스트 출시하기" : "최종 출시하기"}
            </button>
          )}
          {!isScriptValidated && (
            <button className="button-action" disabled>
              출시할 수 없습니다.
            </button>
          )}
        </>
      )}
    </div>
  );
}
