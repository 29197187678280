import SeriesApiInstance from "business_logic/series/SeriesApi";
import { Series } from "business_logic/series/seriesModels";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import IsError from "../feedbacks/Error";
import IsLoading from "../feedbacks/Loading";

import "./selectContent.scss";
import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import { Episode } from "business_logic/episode/episodeModels";
import { faAdd, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@/components/ui/button";
import CreateEpisodeDialog from "presentation/dialogs/CreateEpisodeDialog";
import { useDialog } from "hooks/useDialog";

interface Props {
    series: Series;
    selectedEpisode: Episode;
    emptyEpisodesOnly?: boolean;
    setSelectedEpisode: (episode: Episode) => void;
}

export default function SelectEpisode({ series, selectedEpisode, emptyEpisodesOnly, setSelectedEpisode }: Props) {
    const { dialogs, openDialog, closeDialog } = useDialog();
    const fetchEpisodes = useQuery("activeEpisode", async () => {
        return await EpisodeApiInstance.getEpisodesBySeries({ seriesId: series.id, status: "active", emptyEpisodesOnly: emptyEpisodesOnly }) as Episode[];
    });

    function handleEpisodeSelect(episode: Episode) {
        setSelectedEpisode(episode)
    }

    if (fetchEpisodes.isLoading) return <IsLoading />;
    if (fetchEpisodes.isError) return <IsError />;
    if (fetchEpisodes.data) {
        return (
            <div className="select-episode display-flex flex-start flex-col gap-8">
                <h2>에피소드를 선택하세요</h2>
                <div className="episode-button-list overflow-x-scroll flex-row gap-8">
                    {fetchEpisodes.data.map((episode) => (
                        <button
                            className={`episode-button button flex-col gap-32 align-center ${selectedEpisode && selectedEpisode.id === episode.id ? "selected" : ""}`}
                            key={episode.id}
                            onClick={() => handleEpisodeSelect(episode)}
                        >
                            <div> {episode.index} </div>
                        </button>
                    ))}
                    <Button onClick={() => openDialog("createEpisode")}><FontAwesomeIcon icon={faPlus} /></Button>
                    <CreateEpisodeDialog open={dialogs["createEpisode"] || false} onClose={() => closeDialog("createEpisode")} providedSeries={series} onSuccess={fetchEpisodes.refetch} />
                </div>

            </div>
        );
    }
}