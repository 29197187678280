import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import ScriptApiInstance from "business_logic/script/ScriptApi";
import { Dialog } from "business_logic/script/scriptModels";
import { Loader2, Save } from "lucide-react";
import { AudioSettingSliders } from "presentation/components/audioSettingSliders/AudioSettingSliders";
import CustomTable, { Column } from "presentation/components/customTable/customTable";
import { useMemo, useState } from "react";
import { handleDialogChange } from "../../../components/customTable/components/handleChange";
import "../editScript.scss";
import useEditScriptStore from "../useEditScriptStore";

export default function EditDialogText() {
  const [isProcessing, setIsProcessing] = useState(false);
  const { series, episode, dialogs, setDialogs, audioSettings, setAudioSettings, upsertDialog } = useEditScriptStore();

  const handleTextChange = ({ field, index, event }) => {
    handleDialogChange({ dialogs, setDialogs, field, index, event });
  };

  const handleSave = async () => {
    const errors: string[] = [];

    dialogs?.forEach((dialog, index) => {
      if (!dialog.baseScript || !dialog.targetScript) {
        errors.push("대사와 번역은 비어있을 수 없습니다.");
      }
      if (dialog.isQuiz) {
        if (!dialog.quizWord || !dialog.highlightedTranslation) {
          errors.push(`턴 ${index + 1} - 퀴즈 단어와 정답은 비어있을 수 없습니다.`);
        }
      }

      if (dialog.quizWord || dialog.highlightedTranslation)

        if (dialog.quizWord || dialog.highlightedTranslation) {
          if (!dialog.baseScript?.includes(dialog.highlightedTranslation)) {
            errors.push(`'${dialog.highlightedTranslation}'가 대사번역에 있는지 확인하세요.`);
          }
          if (!dialog.targetScript.toLowerCase().includes(dialog.quizWord.toLowerCase())) {
            errors.push(`'${dialog.quizWord}'가 대사에 있는지 확인하세요.`);
          }
        }
    });

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }

    try {
      setIsProcessing(true);
      const payload: Partial<Dialog>[] = dialogs.map((dialog) => ({
        id: dialog.id,
        dialogTurn: dialog.dialogTurn,
        baseScript: dialog.baseScript?.trim(),
        targetScript: dialog.targetScript?.trim(),
        isQuiz: dialog.isQuiz,
        ...(dialog.quizWord !== null && dialog.quizWord !== undefined && dialog.isQuiz && { quizWord: dialog.quizWord }),
        ...(dialog.toeicWord !== null && dialog.toeicWord !== undefined && dialog.isQuiz && { toeicWord: dialog.toeicWord }),
        ...(dialog.quizWordId !== null && dialog.quizWordId !== undefined && dialog.isQuiz && { quizWordId: dialog.quizWordId }),
        ...(dialog.highlightedTranslation !== null && dialog.highlightedTranslation !== undefined && dialog.isQuiz && { highlightedTranslation: dialog.highlightedTranslation.trim() }),
      }));

      await ScriptApiInstance.patchDialogText(episode.id, payload);
      toast({
        title: "Success",
        description: "Dialog Text has been updated successfully",
      })
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update dialog text",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const columns: Column<Dialog>[] = useMemo(() => [
    { header: "턴", field: "dialogTurn", width: "2.5vw", editable: false },
    { header: "캐릭터", field: "characterName", width: "8vw", editable: false },
    { header: "대사", field: "targetScript", width: "24vw", editable: true },
    { header: "번역", field: "baseScript", width: "24vw", editable: true },
    { header: "퀴즈단어", field: `quizWord`, width: "8vw", editable: true },
    // { header: "퀴즈원형", field: `toeicWord`, width: "8vw", editable: (dialog: Dialog) => !!dialog.quizWord },
    { header: "대사번역", field: `highlightedTranslation`, width: "8vw", editable: true },
    { header: "대사", renderType: 'playAudioButton' },
    { header: "마스크", renderType: 'playMaskAudioButton' },
    { header: "재생성", renderType: 'createAudioButton' }
  ], []);

  return (
    <>
      <AudioSettingSliders audioSettings={audioSettings} setAudioSettings={setAudioSettings} />
      <div className="flex-row">
        <CustomTable<Dialog>
          data={dialogs}
          columns={columns}
          rowHeight="64px"
          handleTextChange={handleTextChange}
          customProps={{ seriesId: series.id, episodeId: episode.id, upsertDialog: upsertDialog }}
        />
      </div>
      <div className="absolute bottom-9 left-1/2 translate-x-[-60%] text-lg mx-auto">
        <Button disabled={isProcessing} onClick={handleSave}>
          {isProcessing ? (
            <> <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Please wait </>
          ) : (
            <> <Save size={20} className="mr-2" /> Save </>
          )}
        </Button>
      </div>
    </>
  );
};