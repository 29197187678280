import CharacterApiInstance from "business_logic/character/CharacterApi";
import { SeriesCharacter } from "business_logic/series/seriesModels";
import useEditContent from "../useEditContent";
import CharacterItem from "./CharacterItem";
import { useEffect } from "react";

export default function CharacterTab() {
    const { seriesSelected, characters, fetchCharacters } = useEditContent();

    useEffect(() => {
        fetchCharacters();
    }, [seriesSelected])

    if (characters)
        return (
            <div>
                <div className="grid grid-cols-3 w-full gap-4">
                    {characters.map((character: SeriesCharacter) => (
                        <div className="w-full">
                            <CharacterItem key={character.internalId} character={character} />
                        </div>
                    ))}
                </div>
            </div>
        )
}