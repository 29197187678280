import { FeatureWordInfo } from "business_logic/script/scriptModels";
import WordApiInstance from "business_logic/word/WordApi";
import { CreateWordImageRequest, FeatureWordInfoDTO, WordCardInfoItem, WordDefinition } from "business_logic/word/wordModels";
import IsError from "presentation/components/feedbacks/Error";
import IsLoading from "presentation/components/feedbacks/Loading";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import WordCardDetails from "./components/WordCardDetails";
import WordCardImages from "./components/WordCardImages";
import "./editEpisodeWordCards.scss";
import "../../editScript.scss";
import DevLogger from "services/Logger";
import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import { SyncFeatureWordInfoRequest } from "business_logic/episode/episodeModels";

const EditEpisodeWordCards = () => {
  const { episodeId } = useParams();
  const [episodeWordCards, setEpisodeWordCards] = useState<WordCardInfoItem[]>([]);
  const [loadingStates, setLoadingStates] = useState<Record<number, boolean>>({});

  // Fetch episode word cards
  const fetchEpisodeWordCards = useMutation(
    () => WordApiInstance.getEpisodeWordCards(episodeId),
    {
      onSuccess: (data) => setEpisodeWordCards(data),
    }
  );

  useEffect(() => {
    fetchEpisodeWordCards.mutate();
    DevLogger.log("Episode Word Cards: ", episodeWordCards);
  }, []);


  // Set word definition image url
  const setWordDefinitionImageUrl = async (wordCardInfoItem: WordCardInfoItem, imageUrl: string) => {
    setEpisodeWordCards((prevCards) =>
      prevCards.map((card) =>
        card.dialogTurn === wordCardInfoItem.dialogTurn
          ? {
            ...card,
            newWordDefinitionImageUrl: imageUrl,
            featureWordInfo: {
              ...card.featureWordInfo,
              wordDefinition: {
                ...card.featureWordInfo.wordDefinition,
                imageUrl,
              },
            },
          }
          : card
      )
    );

    // Update word definition's image url
    const updateWordDefinition: WordDefinition = {
      id: wordCardInfoItem.featureWordInfo.wordDefinition.id,
      imageUrl: imageUrl
    }
    await WordApiInstance.updateWordDefinition(
      [updateWordDefinition]
    )
  };

  async function updateWordCardDefinition(wordCardInfoItem: WordCardInfoItem, definitionId: string) {

    setLoadingStates((prevStates) => ({
      ...prevStates,
      [wordCardInfoItem.dialogTurn]: true,
    }));

    // Sync Episode Word Info with Word Definition 
    const syncFeatureWordInfoRequest: SyncFeatureWordInfoRequest = {
      featureWordInfoId: wordCardInfoItem.featureWordInfo.id,
      wordDefinitionId: definitionId,
      dialogTurnId: wordCardInfoItem.dialogTurnId
    }
    await EpisodeApiInstance.syncFeatureWordInfo(syncFeatureWordInfoRequest);

    // Fetch New WordCardInfoItem
    const updatedWordCardInfoItem = await WordApiInstance.getEpisodeWordCards(episodeId, wordCardInfoItem.dialogTurn)

    // Update WordCardInfoItem
    setEpisodeWordCards((prevCards: WordCardInfoItem[]) =>
      prevCards.map((card: WordCardInfoItem) =>
        card.dialogTurn === wordCardInfoItem.dialogTurn
          ? { ...card, ...updatedWordCardInfoItem[0] }
          : card
      )
    );

    // Update state 
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [wordCardInfoItem.dialogTurn]: false,
    }));
  }


  // Generate new word card image
  const createNewWordCardImage = useCallback(async (wordCardInfoItem: WordCardInfoItem) => {

    // Update loading state
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [wordCardInfoItem.dialogTurn]: true,
    }));

    // Generate new word card image
    const CreateWordImageRequest: CreateWordImageRequest = {
      wordDefinitionId: wordCardInfoItem.featureWordInfo.wordDefinitionId,
      word: wordCardInfoItem.featureWordInfo.wordInfo.word,
      imagePrompt: wordCardInfoItem.imagePrompt || ""
    }
    await WordApiInstance.createWordImages([CreateWordImageRequest]);

    // Fetch New WordCardInfoItem
    const updatedWordCardInfoItem = await WordApiInstance.getEpisodeWordCards(episodeId, wordCardInfoItem.dialogTurn)

    DevLogger.log("Updated WordCardInfoItem: ", updatedWordCardInfoItem);

    // Update WordCardInfoItem
    setEpisodeWordCards((prevCards: WordCardInfoItem[]) =>
      prevCards.map((card: WordCardInfoItem) =>
        card.dialogTurn === wordCardInfoItem.dialogTurn
          ? { ...card, ...updatedWordCardInfoItem[0] }
          : card
      )
    );

    DevLogger.log("Updated WordCardInfoItems: ", episodeWordCards);

    // Update loading state
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [wordCardInfoItem.dialogTurn]: false,
    }));

  }, [episodeId]);

  const handleImagePromptInputChange = (wordCardInfoItem: WordCardInfoItem, imagePrompt?: string) => {
    setEpisodeWordCards((prevCards) =>
      prevCards.map((card) =>
        card.dialogTurn === wordCardInfoItem.dialogTurn
          ? { ...card, imagePrompt: imagePrompt }
          : card
      )
    );
  };

  if (fetchEpisodeWordCards.isLoading) return <IsLoading />;
  if (fetchEpisodeWordCards.isError) return <IsError />;
  return (
    <div className="tab-edit-episode-word-cards">
      <div className="word-card-info-item-list">
        {episodeWordCards.map((wordCard, index) => (
          <div key={index} className="word-card-info-item flex-row">
            <WordCardImages
              imageUrls={wordCard.wordCardImageUrls}
              selectedImageUrl={wordCard.featureWordInfo.wordDefinition.imageUrl}
              setSelectedImageUrl={(imageUrl) => setWordDefinitionImageUrl(wordCard, imageUrl)}

              loading={loadingStates[wordCard.dialogTurn]}
            />
            <WordCardDetails
              wordCardInfo={wordCard}
              handleImagePromptInputChange={handleImagePromptInputChange}
              setNewFeatureWordInfoDefinition={(definitionId) => updateWordCardDefinition(wordCard, definitionId)}
              createNewWordCardImage={() => createNewWordCardImage(wordCard)}
              loading={loadingStates[wordCard.dialogTurn]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};


export default EditEpisodeWordCards;
