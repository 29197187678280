import BaseApi from "business_logic/base/BaseApi";
import { uploadLocationMapping } from "./uploadAssetModels";

class UploadApi extends BaseApi {
  private static instance: UploadApi;
  private constructor() {
    super();
  }
  public static getInstance() {
    if (!UploadApi.instance) {
      UploadApi.instance = new UploadApi();
    }
    return UploadApi.instance;
  }

  async uploadWordImages(files: File[]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await this.axiosInstance.post("/upload/word-images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading files:", error);
      throw error;
    }
  }

  async uploadSeriesAsset(files: File[], seriesId: string, upscale: boolean = false, location: string = uploadLocationMapping["upload"]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await this.axiosInstance.post(`/upload/series-asset/${seriesId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          upscale: upscale,
          location: uploadLocationMapping[location]
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async uploadMusic(files: File[]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await this.axiosInstance.post("/upload/bgm", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

const UploadApiInstance = UploadApi.getInstance();
export default UploadApiInstance;
