import { create } from "zustand";

// interface DialogProps {
//     isOpen: boolean;
//     onOpen: () => void;
//     onClose: () => void;
//     data: any;
//     setData: (data: any) => void;
// }

// export const useDialog = create<DialogProps>((set) => ({
//     isOpen: false,
//     onOpen: () => set({ isOpen: true }),
//     onClose: () => set({ isOpen: false }),
//     data: {},
//     setData: (data: any) => set({ data: data })
// }));

interface DialogState {
    dialogs: Record<string, boolean>;
    data: Record<string, any>;
    openDialog: (dialogId: string) => void;
    closeDialog: (dialogId: string) => void;
    setDialogData: (dialogId: string, data: any) => void;
}

export const useDialog = create<DialogState>((set) => ({
    dialogs: {},
    data: {},
    openDialog: (dialogId: string) =>
        set((state) => ({
            dialogs: { ...state.dialogs, [dialogId]: true },
        })),
    closeDialog: (dialogId: string) =>
        set((state) => ({
            dialogs: { ...state.dialogs, [dialogId]: false },
        })),
    setDialogData: (dialogId: string, data: any) =>
        set((state) => ({
            data: { ...state.data, [dialogId]: data },
        })),
}));