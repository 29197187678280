import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import useEditContent from "../useEditContent";
import { useCallback } from "react";
import { PatchEpisodeRequest } from "business_logic/episode/episodeModels";
import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import { toast } from "@/components/ui/use-toast";
import { Textarea } from "@/components/ui/textarea";


const EpisodeCard = ({ episode }) => {
    const { seriesSelected, setEpisode } = useEditContent();
    const navigate = useNavigate();

    const handleCardClick = useCallback((e) => {
        const nonNavigationElements = ['input', 'button', 'label', 'select', 'textarea'];
        if (nonNavigationElements.includes(e.target.tagName.toLowerCase())) return;

        setEpisode(episode);
        navigate(`/script/edit/series/${seriesSelected.id}/episode/${episode.id}`);
    }, [navigate, episode, seriesSelected]);

    const updateEpisode = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();

        const formData = new FormData(event.target);
        const req: PatchEpisodeRequest = {
            title: formData.get("title") as string,
            description: formData.get("description") as string,
            intro: (formData.get("intro") as string).trim(),
            startBackgroundImage: (formData.get("startBackgroundImageUrl") as string).trim(),
            introBgm: (formData.get("introBgmUrl") as string).trim(),
        }

        try {
            await EpisodeApiInstance.updateEpisode({
                episodeId: episode.id,
                episode: req
            })
            toast({
                title: "Success",
                description: "Episode has been updated successfully",
            })
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update episode",
                variant: "destructive"
            })
        }

    }, [episode])

    return (
        <div onClick={handleCardClick} className="flex flex-col justify-between border border-gray-200 rounded-md p-4 hover:bg-gray-50 hover:cursor-pointer">
            <form onSubmit={updateEpisode}>
                <div className="grid grid-cols-5 gap-4">

                    <div className="col-span-4 flex flex-col gap-2">
                        <div className="whitespace-nowrap font-bold text-2xl">{episode.index}화</div>

                        <div className="flex-col gap-4">
                            <div className="grid grid-cols-3 gap-2">
                                <div className="col-span-1">
                                    <Label htmlFor="title">Title</Label>
                                    <Input id="title" name="title" defaultValue={episode.title} />
                                </div>
                                <div className="col-span-1">
                                    <Label htmlFor="startBackgroundImageUrl">Background Image</Label>
                                    <Input id="startBackgroundImageUrl" name="startBackgroundImageUrl" defaultValue={episode.startBackgroundImage} />
                                </div>
                                <div className="col-span-1">
                                    <Label htmlFor="introBgmUrl">BGM</Label>
                                    <Input id="introBgmUrl" name="introBgmUrl" defaultValue={episode.introBgm} />
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-2">
                                <div className="col-span-1">
                                    <Label htmlFor="description">Description</Label>
                                    <Textarea id="description" name="description" defaultValue={episode.description} />
                                </div>
                                <div className="col-span-2">
                                    <Label htmlFor="intro">Episode Intro Text</Label>
                                    <Textarea id="intro" name="intro" defaultValue={episode.intro} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <img src={episode.listThumbnailImageUrl} alt="episode" className="rounded-md w-full h-auto" />
                    </div>

                </div>

                <div className="flex flex-row justify-between mt-4">
                    <div className="gap-4 content-end">
                        <Badge className="mr-1" variant={`${episode.status === "draft" ? "outline" : "default"}`}>{episode.status}</Badge>
                        {episode.isFree && <Badge variant="outline">free</Badge>}
                    </div>
                    <div className="flex flex-row gap-2">
                        {/* <Button variant="delete" type="button">Delete</Button> */}
                        <Button variant="outline" type="submit">Save</Button>
                    </div>
                </div>
            </form>
        </div >
    );
}

export default EpisodeCard;