import Loading from "presentation/components/feedbacks/Loading";
import { useEffect } from "react";
import useEditContent from "../useEditContent";
import SeriesDrawerItem from "./SeriesDrawerItem";


export default function SeriesDrawer() {
    const { seriesList, fetchSeries } = useEditContent();

    useEffect(() => {
        fetchSeries();
    }, []);

    if (!seriesList) return <Loading />;
    if (seriesList)
        return (
            <div className="relative h-full overflow-hidden border-r border-gray-200">

                <section className="absolute top-0 left-0 right-0 flex justify-between items-center h-16 px-4 bg-white z-10 border-b border-gray-200 ">
                    <div className="text-xl font-bold">Series</div>
                </section>

                <section className="mt-16 h-[calc(100%-4rem)] overflow-y-auto scrollbar-hide p-4 flex flex-col gap-4">
                    {seriesList.map((series) => (
                        <SeriesDrawerItem key={series.title} series={series} />
                    ))}
                </section>

            </div>
        );
}