import { MultipleChoiceQuizChoice } from "business_logic/script/scriptModels";
import AudioServiceInstance from "services/AudioService";

export default function MultipleChoices({ choices, selectedMultipleChoiceIndex, handleClick }: { choices: MultipleChoiceQuizChoice[], selectedMultipleChoiceIndex: number, handleClick: (multipleChoiceIndex: number) => void }) {

    const playWordAudio = (audioUrl: string) => {
        AudioServiceInstance.play(audioUrl);
    }
    return (
        <div className="multiple-choices">
            {choices.map((choice, index) => {
                return (
                    <div
                        className={`multiple-choice ${index === selectedMultipleChoiceIndex ? 'selected' : ''}`}
                        key={index}
                        onClick={() => {
                            playWordAudio(choice.audioUrl);
                            handleClick(index);
                        }}
                    >
                        <div className="choice-text text-sm">{choice.displayString}</div>
                    </div>
                );
            })}
        </div >
    );

}