import { Dialog } from "business_logic/script/scriptModels";
import { useCreateScriptStore } from "business_logic/script/scriptStore";
import { AudioSettingSliders } from "presentation/components/audioSettingSliders/AudioSettingSliders";
import CustomTable, { Column } from "presentation/components/customTable/customTable";
import IsError from "presentation/components/feedbacks/Error";
import IsLoading from "presentation/components/feedbacks/Loading";
import { useEffect } from "react";
import useCreateContent from "./hooks/useCreateContent";

const CreateScriptStep7 = ({ handleDataChange }) => {

  const createScriptStore = useCreateScriptStore();
  const { createDialogAudio } = useCreateContent(createScriptStore);
  const { series, episode, dialogs, upsertDialog, scriptingStep, addCompletedStep, isStepCompleted, audioSettings, setAudioSettings } = createScriptStore;

  useEffect(() => {
    if (!isStepCompleted()) {
      createDialogAudio.mutateAsync();
      addCompletedStep(scriptingStep);
    }
  }, [createDialogAudio]);

  const columns: Column<Dialog>[] = [
    { header: "턴", field: "dialogTurn", width: "2.5vw", editable: false },
    { header: "캐릭터", field: "characterName", width: "8vw", editable: false },
    { header: "대사", field: "targetScript", width: "32vw", editable: false },
    { header: "퀴즈단어", field: `quizWord`, width: "8vw", editable: false },
    { header: "음성", renderType: 'playAudioButton', width: "4vw" },
    { header: "마스크", renderType: 'playMaskAudioButton', width: "4vw" },
    { header: "재생성", renderType: 'createAudioButton', width: "4vw" },
  ];

  if (createDialogAudio.isLoading) return <IsLoading />;
  if (createDialogAudio.isError) return <IsError />;
  if (isStepCompleted() || createDialogAudio.isSuccess)
    return (
      <div className="justify-between max-height">
        <AudioSettingSliders audioSettings={audioSettings} setAudioSettings={setAudioSettings} />
        <CustomTable<Dialog>
          data={dialogs}
          columns={columns}
          rowHeight="64px"
          handleTextChange={handleDataChange}
          customProps={{ seriesId: series.id, episodeId: episode.id, audioSettings: audioSettings, updateItem: upsertDialog }}
        />
      </div>
    );

};

export default CreateScriptStep7;
