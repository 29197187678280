import InquiryApiInstance from "business_logic/inquiry/InquiryApi";
import { UserInquiry } from "business_logic/inquiry/inquiryModels";
import IsError from "presentation/components/feedbacks/Error";
import IsLoading from "presentation/components/feedbacks/Loading";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ActiveInquiryTable from "./tabs/ActiveInquiryTable";
import InactiveInquiryTable from "./tabs/InactiveInquiryTable";

export default function InquiryPage() {
  const [pendingInquiries, setPendingInquiries] = useState<UserInquiry[]>([]);
  const [repliedInquiries, setRepliedInquiries] = useState<UserInquiry[]>([]);

  const fetchInquiries = useMutation("fetchInquiries", async () => {
    let pendingInquiries = await InquiryApiInstance.getInquiries({ status: "pending" });
    let repliedInquiries = await InquiryApiInstance.getInquiries({ status: "replied" });
    return { pendingInquiries, repliedInquiries };
  },
    {
      onSuccess: (data) => {
        setPendingInquiries(data.pendingInquiries);
        setRepliedInquiries(data.repliedInquiries);
      },
      onError: (error) => {
        alert(error);
      },
    });

  // Fetch Inquiries upon component mount
  useEffect(() => {
    fetchInquiries.mutate();
  }, [fetchInquiries.mutate]);

  if (fetchInquiries.isLoading) return <IsLoading />;
  if (fetchInquiries.isError) return <IsError />;
  if (fetchInquiries.isSuccess)
    return (
      <div className="page page-inquiry">
        <div className="page-header flex-col">
          <h1 className="h1">유저 문의</h1>
        </div>
        <hr />
        <section className="section-tabs">
          <Tabs>
            <TabList>
              <Tab>{`답변 대기 - ${pendingInquiries?.length}`}</Tab>
              <Tab>답변 완료 </Tab>
            </TabList>
            <TabPanel>
              <ActiveInquiryTable inquiries={pendingInquiries} setInquiries={setPendingInquiries} />
            </TabPanel>
            <TabPanel>
              <InactiveInquiryTable inquiries={repliedInquiries} setInquiries={setRepliedInquiries} />
            </TabPanel>
          </Tabs>
        </section>
      </div>
    );
}
