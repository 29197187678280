import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import { Episode } from "business_logic/episode/episodeModels";
import { useQuery } from "react-query";
import useEditContent from "../useEditContent";
import EpisodeItem from "./EpisodeItem";
import DevLogger from "services/Logger";

export default function EpisodeTab() {

    const { seriesSelected, episodeList, setEpisodeList } = useEditContent();
    const fetchEpisodes = useQuery(["activeSeriesCharacters", seriesSelected?.id],
        async () => {
            return await EpisodeApiInstance.getEpisodesBySeries({ seriesId: seriesSelected.id });
        },
        {
            refetchInterval: 60000,
            onSuccess: (data: Episode[]) => {

                const sortedEpisodes = data.sort((a, b) => a.index - b.index);
                setEpisodeList(sortedEpisodes);
                DevLogger.log(data);

            }
        }
    );

    if (fetchEpisodes.isSuccess)
        return (
            <div className="grid grid-cols-1 w-full gap-4">
                {episodeList.map((episode: Episode) => (
                    <div className="w-full">
                        <EpisodeItem key={episode.id} episode={episode} />
                    </div>
                ))}
            </div>
        )
}