import ContentPane from "./components/ContentPane";
import SeriesDrawer from "./components/SeriesDrawer";

export default function EditContentPage() {
    return (
        <div className="grid grid-cols-[1fr,3fr] h-screen">
            <SeriesDrawer />
            <ContentPane />
        </div>
    );
}