import { UserInquiry } from "business_logic/inquiry/inquiryModels";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface useInquiryTableLogicInterface {
  inquiries: UserInquiry[];
  setInquiries: (inquiries: UserInquiry[]) => void;
}

export function useInquiryTableLogic({ inquiries, setInquiries }: useInquiryTableLogicInterface) {
  const navigate = useNavigate();

  const handleChange = useCallback(
    (field: string, newValue: any, inquiriesId: string) => {
      const updatedInquiry = inquiries.map((s) => (s.id === inquiriesId ? { ...s, [field]: newValue } : s));
      setInquiries(updatedInquiry);
    },
    [inquiries, setInquiries]
  );

  const replyInquiry = useCallback(
    async (rowData: any) => {
      const inquiriesId = rowData.id;
      const targetInquiry = inquiries.find((s) => s.id === inquiriesId);
      if (targetInquiry) {
        navigate("/inquiry/" + inquiriesId);
      }
    },
    [inquiries, navigate]
  );

  return { handleChange, replyInquiry };
}
