import { create } from "zustand";
import { Series, SeriesCharacter } from "business_logic/series/seriesModels";
import { Episode } from "business_logic/episode/episodeModels";
import SeriesApiInstance from "business_logic/series/SeriesApi";
import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import { AudioSettings, Dialog, DialogTurn, DialogWordLinkDetails, WordDefinition } from "business_logic/script/scriptModels";
import ScriptApiInstance from "business_logic/script/ScriptApi";

type EditScriptStoreState = {
    series: Series | null;
    setSeries: (series: Series) => void;
    fetchSeriesById: (seriesId: string) => void;

    episode: Episode | null;
    fetchEpisode: (episodeId: string) => void;
    setEpisode: (episode: Episode) => void;

    characters: SeriesCharacter[];
    setCharacters: (characters: SeriesCharacter[]) => void;

    dialogs: Dialog[];
    setDialogs: (dialogs: Dialog[]) => void;
    upsertDialog: (dialog: any) => void;

    dialogTurns: DialogTurn[];
    setDialogTurns: (dialogTurns: DialogTurn[]) => void;
    fetchDialogTurns: () => void;

    audioSettings: {};
    setAudioSettings: (audioSettings: AudioSettings) => void;

    fetchWordLinks?: () => void;
    setCurrentWordLink?: (dialogTurn: number, wordStartIndex: number, wordDefinition: WordDefinition) => void;
    setWordLinks?: (dialogTurn: number, wordLinkDetails: DialogWordLinkDetails[]) => void;
    getCurrentWordLink?: (dialogTurn: number, wordStartIndex: number) => WordDefinition;
    deleteWordLink?: (dialogTurn: number, wordStartIndex: number) => void;

    resetEditScriptStore: () => void;

}

export const useEditScriptStore = create<EditScriptStoreState>((set, get) => ({

    series: null,
    setSeries: (series: Series) => set({ series: series }),
    fetchSeriesById: async (seriesId: string) => {
        const series = await SeriesApiInstance.getSeriesById(seriesId);
        set({ series });
    },

    episode: null,
    setEpisode: (episode: Episode) => set({ episode }),
    fetchEpisode: async (episodeId: string) => {
        const data = await EpisodeApiInstance.getEpisode(episodeId);
        console.log(data);
        set({ episode: data });
        const { episode } = get();
        console.log(episode)
    },

    characters: [],
    setCharacters: (characters: SeriesCharacter[]) => set({ characters }),

    dialogs: [],
    setDialogs: (dialogs: Dialog[]) => set({ dialogs: dialogs.sort((a, b) => a.dialogTurn - b.dialogTurn) }),
    resetDialogs: () => set({ dialogs: [] }),
    upsertDialog: (dialog: any) =>
        set((state) => {
            const existingDialogIndex = state.dialogs!.findIndex((d) => d.dialogTurn === dialog.dialogTurn);
            let updatedDialogs;
            if (existingDialogIndex >= 0) {
                const updatedDialogs = state.dialogs!.slice();
                updatedDialogs[existingDialogIndex] = { ...updatedDialogs[existingDialogIndex], ...dialog };
                return { dialogs: updatedDialogs };
            } else {
                updatedDialogs = [...state.dialogs!, dialog];
            }
            // Sort dialogs by dialogTurn
            updatedDialogs.sort((a, b) => a.dialogTurn - b.dialogTurn);

            return { dialogs: updatedDialogs };
        }),

    dialogTurns: [],
    setDialogTurns: (dialogTurns: DialogTurn[]) => set({ dialogTurns: dialogTurns }),
    fetchDialogTurns: async () => {
        const { episode, setDialogs, setDialogTurns } = get();
        const dialogTurns = await ScriptApiInstance.fetchDialogTurns(episode!.id);
        const dialogs: Dialog[] = dialogTurns.map((dialogTurn: DialogTurn) => Dialog.fromDialogTurn(dialogTurn));

        setDialogTurns(dialogTurns)
        setDialogs(dialogs.sort((a, b) => a.dialogTurn - b.dialogTurn));
    },

    audioSettings: { voiceStability: 0.5, voiceSimilarity: 0.75, voiceStyle: 0 },
    setAudioSettings: (audioSettings: AudioSettings) => set({ audioSettings }),

    fetchWordLinks: async () => {
        const { episode } = get();
        const wordLinks = await ScriptApiInstance.fetchDialogWordLink(episode!.id);
        wordLinks.forEach((item: any) => {
            const dialogTurn = item.dialogTurn;
            get().setWordLinks!(dialogTurn, item.wordLinkDetails);
        });
    },
    setWordLinks: (dialogTurn: number, wordLinkDetails: DialogWordLinkDetails[]) =>
        set(
            (state): EditScriptStoreState => ({
                ...state,
                dialogs: state.dialogs!.map((dialog): Dialog => {
                    if (dialog.dialogTurn === dialogTurn) {
                        return new Dialog({
                            ...dialog,
                            wordLinkDetails: wordLinkDetails,
                        } as Dialog);
                    }
                    return dialog;
                }),
            })
        ),
    setCurrentWordLink: (dialogTurn: number, wordStartIndex: number, wordDefinition: WordDefinition) =>
        set(
            (state): EditScriptStoreState => ({
                ...state,
                dialogs: state.dialogs!.map((dialog): Dialog => {
                    if (dialog.dialogTurn === dialogTurn) {
                        return new Dialog({
                            ...dialog,
                            wordLinkDetails: dialog.wordLinkDetails!.map((wordLinkDetail) => {
                                if (wordLinkDetail.startIndex === wordStartIndex) {
                                    return { ...wordLinkDetail, current: wordDefinition } as DialogWordLinkDetails;
                                }
                                return wordLinkDetail as DialogWordLinkDetails;
                            }),
                        } as Dialog);
                    }
                    return dialog;
                }),
            })
        ),
    getCurrentWordLink: (dialogTurn: number, wordStartIndex: number) => {
        const { dialogs } = get();
        const dialog = dialogs!.find((dialog) => dialog.dialogTurn === dialogTurn);
        return dialog?.wordLinkDetails?.find((wordLinkDetail) => wordLinkDetail.startIndex === wordStartIndex)?.current;
    },
    deleteWordLink: (dialogTurn: number, wordStartIndex: number) => {
        set(
            (state): EditScriptStoreState => ({
                ...state,
                dialogs: state.dialogs!.map((dialog): Dialog => {
                    if (dialog.dialogTurn === dialogTurn) {
                        return {
                            ...dialog,
                            wordLinkDetails: dialog.wordLinkDetails!.map((wordLinkDetail) => {
                                if (wordLinkDetail.startIndex === wordStartIndex) {
                                    return { ...wordLinkDetail, current: null };
                                }
                                return wordLinkDetail;
                            }),
                        } as Dialog;
                    }
                    return dialog;
                }),
            })
        );
    },

    resetEditScriptStore: () => {
        set({
            series: null,
            episode: null,
            characters: [],
            dialogs: [],
            dialogTurns: [],
            audioSettings: { voiceStability: 0.5, voiceSimilarity: 0.75, voiceStyle: 0 },
        });
    }



}))

export default useEditScriptStore;