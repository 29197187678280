import { faLightbulb, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DialogTurn, MultipleChoiceQuizHighlight, MultipleChoiceQuizMask } from "business_logic/script/scriptModels";
import { useState } from "react";
import "../episodePlays.scss";
import AudioServiceInstance from "services/AudioService";


export default function DialogBox({ dialogTurn }: { dialogTurn: DialogTurn }) {
    const [hintVisible, setHintVisible] = useState(true);
    const [audioPlaying, _] = useState(false);
    const playDialogAudio = () => {
        AudioServiceInstance.play(dialogTurn.maskedAudioUrl)
    }
    const targetScriptWithUnderlineAndHighlight = () => {
        let wordList = []
        let lastIndex = 0
        const sortedWordLinks = dialogTurn.wordLinks.sort((a, b) => a.startIndex - b.startIndex)
        const wordMask = dialogTurn.multipleChoiceQuiz !== null && dialogTurn.multipleChoiceQuiz?.masks[0] as MultipleChoiceQuizMask;
        sortedWordLinks.forEach((wordLink, index) => {
            wordList.push(dialogTurn.targetScript.slice(lastIndex, wordLink.startIndex));
            wordList.push(<span key={index} className={`target-script-underline ${wordMask && wordLink.startIndex == wordMask.maskStartIndex && wordLink.endIndex == wordMask.maskEndIndex ? "target-script-highlight" : ""}`}>{dialogTurn.targetScript.slice(wordLink.startIndex, wordLink.endIndex)}</span>);
            lastIndex = wordLink.endIndex;
        }
        );
        wordList.push(dialogTurn.targetScript.slice(lastIndex));
        return wordList;
    }

    const baseScriptWithHighlight = () => {
        if (dialogTurn.multipleChoiceQuiz === null) {
            return dialogTurn.baseScript;
        }
        let wordList = []
        const highlightedTranslation = dialogTurn.multipleChoiceQuiz.highlights[0] as MultipleChoiceQuizHighlight;
        wordList.push(dialogTurn.baseScript.slice(0, highlightedTranslation.highlightStartIndex));
        wordList.push(<span key={0} className="base-script-highlight text-xs">{dialogTurn.baseScript.slice(highlightedTranslation.highlightStartIndex, highlightedTranslation.highlightEndIndex)}</span>);
        wordList.push(dialogTurn.baseScript.slice(highlightedTranslation.highlightEndIndex));
        return wordList;
    }

    return (
        <div className="dialog-box">
            <div className="tags flex-row justify-between">
                {dialogTurn.characterName !== "narration" && <div className="character-tag text-center">{dialogTurn.characterName}</div>}
                {/* <div className="word-tag">"Test"</div> */}
            </div>
            <div className="dialog-section pt-8 pb-4 px-4">
                <div className="text-xs md:text-base">{targetScriptWithUnderlineAndHighlight()}</div>
                {hintVisible && <div className="text-xs mt-4 font-light">{baseScriptWithHighlight()}</div>}
                <div className="buttons flex-row justify-between">
                    <div className={`audio-play-button ${audioPlaying && "active"}`} onClick={() => playDialogAudio()}>
                        <FontAwesomeIcon icon={faPlay} />
                    </div>
                    <div className={`hint-button ${hintVisible && "active"}`} onClick={() => setHintVisible(!hintVisible)}>
                        <FontAwesomeIcon icon={faLightbulb} />
                    </div>
                </div>
            </div>
        </div>
    );
}