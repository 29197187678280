import ScriptApiInstance from "business_logic/script/ScriptApi";
import { EpisodeScriptInfo } from "business_logic/script/scriptModels";
import { Series } from "business_logic/series/seriesModels";
import { GenericAgGridTable } from "presentation/components/agGridTable/AgGridTable";
import IsError from "presentation/components/feedbacks/Error";
import IsLoading from "presentation/components/feedbacks/Loading";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useScriptTableLogic } from "./useScriptTableLogic";
import DateFormatter from "services/DateFormatter";

export default function InactiveScriptTable({ series }: { series: Series }) {
  const [scripts, setScripts] = useState<EpisodeScriptInfo[]>([]);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    "getSeriesScripts",
    async () => {
      return await ScriptApiInstance.fetchScriptSummary(series.id!, "inactive");
    },
    {
      onSuccess: (data) => {
        setScripts(data);
      },
    }
  );

  // Fetch Episode upon component mount
  useEffect(() => {
    mutate();
  }, [mutate]);

  // Table Resources
  const { handleChange, restoreScript, hardDeleteScript } = useScriptTableLogic({ scripts, setScripts, refetchScripts: mutate });
  const columns = [
    { field: "id", headerName: "아이디", cellClass: "non-editable-cell", editable: false },
    { field: "index", headerName: "회차", width: 100, cellClass: "non-editable-cell", editable: false, sortable: true, sort: "asc" },
    { field: "title", headerName: "에피소드 명", width: 200, cellClass: "non-editable-cell", editable: false },
    { field: "dialogTurns", headerName: "대사 수", width: 100, cellClass: "non-editable-cell", editable: false },
    { field: "quizCount", headerName: "퀴즈 수", width: 100, cellClass: "non-editable-cell", editable: false },
    { field: "status", headerName: "출시상태", cellClass: "non-editable-cell", editable: false },
    { field: "createdAt", headerName: "생성일", cellClass: "non-editable-cell", editable: false, valueFormatter: (params: any) => DateFormatter.formatShort(params.value) },
  ];

  if (isLoading) return <IsLoading />;
  if (isError) return <IsError />;
  if (isSuccess) return <GenericAgGridTable data={scripts} columns={columns} onCellValueChanged={handleChange} onRestore={restoreScript} onHardDelete={hardDeleteScript} />;
}
