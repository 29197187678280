import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface WorkInProgressOverlayProps {
  isProcessing: boolean;
  message?: string;
}

const WorkInProgressOverlay: React.FC<WorkInProgressOverlayProps> = ({ isProcessing, message = "처리 중. 페이지를 닫지 마세요" }) => {
  if (!isProcessing) return null;
  return (
    <div className="work-in-progress-overlay">
      <div className="flex-col container">
        <div className="icon-loading-spinner margin-auto-all">
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </div>
        <div className="text">{message}</div>
      </div>
    </div>
  );
};

export default WorkInProgressOverlay;
