import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { LucideIcon } from "lucide-react";

export type GraphData = {
    date: string,
    value: number
}

export const useDashboard = () => {

    const getThisWeekData = (T: any[], fieldName: string): any => {
        if (T.length === 0) return '-';
        return T[T.length - 1][fieldName];
    }

    const getLastWeekData = (T: any[], fieldName: string): any => {
        if (T.length === 0) return '-';
        return T[T.length - 2][fieldName];
    }

    const getRecentDelta = (T: any[], fieldName: string): any => {
        if (T.length < 2) return '-';
        const currentValue = T[T.length - 1][fieldName];
        const previousValue = T[T.length - 2][fieldName];
        return ((currentValue - previousValue));
    }

    const parseRetentionRate = (rate: number): string => {
        return (rate * 100).toFixed(1) + '%';
    }

    const getKstDate = (date: string): any => {
        const kstTimezone = 'Asia/Seoul';
        return format(toZonedTime(date, kstTimezone), 'M.d');
    }

    const getRetentionRateDelta = (today: number, comparison: number): any => {
        return ((today - comparison) / comparison) * 100 || null;
    }

    return { getThisWeekData, getRecentDelta, getKstDate, parseRetentionRate, getRetentionRateDelta, getLastWeekData }

}