import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import OverviewTab from "./overviewTab/OverviewTab";
import RetentionTab from "./retentionTab/RetentionTab";
import EngagementTab from "./engagementTab/EngagementTab";

export default function DashboardPage() {

    return (
        <div className="flex-col p-8 gap-4">
            <div className="text-4xl font-black text-black">Dashboard</div>
            <Tabs className="flex-col gap-4" defaultValue="overview">
                <header>
                    <TabsList>
                        <TabsTrigger value="overview">Overview</TabsTrigger>
                        <TabsTrigger value="retention">Retention</TabsTrigger>
                        <TabsTrigger value="engagement">Engagement</TabsTrigger>
                    </TabsList>
                </header>
                <section className="w-full overflow-y-scroll scrollbar-hide">
                    <TabsContent key="seriesTab" value="overview">
                        <OverviewTab />
                    </TabsContent>
                    <TabsContent key="retentionTab" value="retention">
                        <RetentionTab />
                    </TabsContent>
                    <TabsContent key="engagementTab" value="engagement">
                        <EngagementTab />
                    </TabsContent>
                </section>
            </Tabs>
        </div >
    )
}

