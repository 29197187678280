import { Dialog, ImageDirection } from "business_logic/script/scriptModels";
import DevLogger from "services/Logger";

class DialogParser {
  static parseCharacterName(line: string): string {
    return line.includes(":") ? line.split(":")[0].trim().toLowerCase() : "narration";
  }

  static parseTargetScript(line: string): string {
    // Remove texts within square and round brackets.
    let dialog = line.includes(":") ? line.split(":")[1] : line;
    const parsedDialog = dialog.includes("?") || dialog.includes("!") || dialog.includes(".") ? dialog.replace(/[{}]/g, "").trim() : dialog.replace(/[{}]/g, "").trim() + ".";
    return parsedDialog.charAt(0).toUpperCase() + parsedDialog.slice(1);
  }

  static parseDialogTurn(targetScripts: string[]): number {
    return (targetScripts ?? []).length;
  }

  static parseQuizWord(line: string): string {
    return (
      line
        .match(/{([^}]*)}/)?.[1]
        ?.trim()
        .toLowerCase() || ""
    );
  }

  static parseDialogType(quizWord: string): string {
    return quizWord !== "" ? "quiz" : "";
  }

  static parseBackgroundImage(): string {
    return "";
  }

  static parseCharacterExpression(): string {
    return "";
  }

  static parseCharacterClothes(): string {
    return "";
  }

  static parseImageDirection(index: number, currentCharacterName: string, parsedData: Partial<Dialog>[]): ImageDirection {
    if (index === 0) {
      return ImageDirection.Left;
    } else {
      DevLogger.log();
      const lastDirection = parsedData[index - 1].imageDirection || ImageDirection.Left;
      const changedDirection = lastDirection === ImageDirection.Left ? ImageDirection.Right : ImageDirection.Left;
      const previousCharacter = parsedData[index - 1].characterName;

      if (currentCharacterName === "narration") {
        return lastDirection;
      } else {
        if (currentCharacterName === previousCharacter) {
          return lastDirection;
        } else {
          return changedDirection;
        }
      }
    }
  }
}

export default DialogParser;
