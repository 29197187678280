import { useCreateScriptStore } from "business_logic/script/scriptStore";
import { useCallback, useMemo, useState } from "react";
import NavigationBar from "./components/NavigationBar";
import useValidateScript from "./hooks/useValidateScript";
import CreateScriptStep1 from "./Step1";
import CreateScriptStep2 from "./Step2";
import CreateScriptStep3 from "./Step3";
import CreateScriptStep4 from "./Step4";
import CreateScriptStep5 from "./Step5";
import CreateScriptStep6 from "./Step6";
import CreateScriptStep7 from "./Step7";

import { handleDialogChange } from "presentation/components/customTable/components/handleChange";
import { useToast } from "@/components/ui/use-toast";
import { useAlertDialog } from "hooks/useAlertDialog";
import WorkInProgressOverlay from "presentation/components/workInProgressOverlay/WorkInProgressOverlay";
import "styles/global.scss";
import ProgressAlertDialog from "./components/ProgressAlertDialog";
import useCreateContent from "./hooks/useCreateContent";


export default function CreateScriptPage() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [, setIsWaitingForAutomation] = useState(false);
  const createScriptStore = useCreateScriptStore();
  const {
    scriptingStep,
    series,
    episode,
    dialogs,
    nextPage,
    previousPage,
    setDialogs,
    resetScript } = useCreateScriptStore();
  const { toast } = useToast();
  const { isOpen, setClose } = useAlertDialog();
  const { validateCreateScriptStep } = useValidateScript();
  const { createWordImages, insertScript } = useCreateContent(createScriptStore);

  const stepTitle = useMemo(() => [
    "시리즈, 에피소드 선택",
    "스크립트 입력",
    "대사 검토",
    "번역 검토",
    "퀴즈 선택지 검토",
    "퀴즈 해설 검토",
    "대사 음성 확인"
  ], []);

  function handleCreateScriptChange({ field, index, event }) {
    handleDialogChange({ dialogs, setDialogs, field, index, event });
  };

  const renderPageComponent = () => {
    switch (scriptingStep) {
      case 1:
        return <CreateScriptStep1 />;
      case 2:
        return <CreateScriptStep2 />;
      case 3:
        return <CreateScriptStep3 handleDataChange={handleCreateScriptChange} />;
      case 4:
        return <CreateScriptStep4 handleDataChange={handleCreateScriptChange} />;
      case 5:
        return <CreateScriptStep5 handleDataChange={handleCreateScriptChange} />;
      case 6:
        return <CreateScriptStep6 handleDataChange={handleCreateScriptChange} />;
      case 7:
        return <CreateScriptStep7 handleDataChange={handleCreateScriptChange} />;
      default:
        return <div>No more pages</div>;
    }
  };

  const handleClickNext = useCallback(async () => {
    const isValid = await validateCreateScriptStep(createScriptStore)
    if (isValid) {
      nextPage();
      // if (scriptingStep === 3) {
      //   setOpen();
      //   setIsWaitingForAutomation(true);
      // } else {
      //   nextPage();
      // }
    }
  }, [createScriptStore, validateCreateScriptStep]);

  const handleClickPrevious = useCallback(() => {
    previousPage();
  }, [createScriptStore]);


  const handleSave = useCallback(async () => {
    setIsProcessing(true);
    try {
      await insertScript.mutateAsync();
      toast({
        title: "Success",
        description: "Script created successfully"
      });
      await createWordImages.mutateAsync();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive"
      });
    } finally {
      setIsProcessing(false);
      resetScript();
    }
  }, [setIsProcessing, createWordImages, resetScript]);

  return (
    <div className="page create-script">
      <div className="top-col">
        <div className="top-bar flex-row justify-between align-center">
          <h1 className="h1">
            {scriptingStep}단계 - {stepTitle[scriptingStep - 1]}
          </h1>
        </div>
      </div>
      <hr />
      <WorkInProgressOverlay isProcessing={isProcessing} />
      <div>{renderPageComponent()}</div>
      <NavigationBar
        createScriptStore={{ ...createScriptStore, currentScriptingStep: scriptingStep }}
        lastScriptStep={7}
        handleClickNext={handleClickNext}
        handleClickPrevious={handleClickPrevious}
        handleClickSubmit={handleSave} />
      <ProgressAlertDialog
        open={isOpen}
        onClose={() => {
          setClose();
          setIsWaitingForAutomation(false);
          nextPage();
        }}
        onOpenChange={setClose}
      />
    </div>
  );
}
