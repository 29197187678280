import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts"
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle
} from "@/components/ui/card"
import {
    ChartContainer,
    ChartLegend,
    ChartLegendContent
} from "@/components/ui/chart"
import { useMemo } from "react"
import DevLogger from "services/Logger"

export const description = "A multiple line chart"

// const chartData = [
//     { month: "January", desktop: 186, mobile: 80 },
//     { month: "February", desktop: 305, mobile: 200 },
//     { month: "March", desktop: 237, mobile: 120 },
//     { month: "April", desktop: 73, mobile: 190 },
//     { month: "May", desktop: 209, mobile: 130 },
//     { month: "June", desktop: 214, mobile: 140 },
// ]


export function EngagementGraph({ chartData }) {

    const chartLines = useMemo(() => {
        if (chartData.length === 0) return [];
        const firstDataPoint = chartData[0];
        const keys = Object.keys(firstDataPoint).filter(key => key !== 'index');
        return keys.map((key, index) => (
            <Line
                key={key}
                dataKey={key}
                type="monotone"
                stroke={`hsl(var(--chart-${index + 1}))`}
                strokeWidth={2}
                dot={false}
            />
        ));
    }, [chartData])


    const chartConfig = useMemo(() => {
        if (chartData.length === 0) return {};
        const firstDataPoint = chartData[0];
        const keys = Object.keys(firstDataPoint).filter(key => key !== 'date');
        DevLogger.log(chartData);
        return keys.reduce((config, key, index) => {
            config[key] = {
                label: key,
                color: `hsl(var(--chart-${index + 1}))`,
            };
            return config;
        }, {});
    }, [chartData]);

    return (
        <Card>
            <CardHeader>
                <CardTitle>Series Engagement</CardTitle>
                <CardDescription>Percentage of users completing subsequent episodes</CardDescription>
            </CardHeader>
            <CardContent>
                <ChartContainer config={chartConfig} className="aspect-auto w-full h-[65vh]">
                    <LineChart
                        accessibilityLayer
                        data={chartData}
                        margin={{
                            left: 12,
                            right: 12,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="index"
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            fontSize={12}
                            tickFormatter={(value) => { return `Ep.${value}` }}
                        />
                        <YAxis
                            tickLine={false}
                            axisLine={false}
                            tickMargin={8}
                            fontSize={12}
                            tickFormatter={(value) => { return `${value * 100}%` }} />
                        <Tooltip
                            formatter={(value: any) => (value * 100).toFixed(2) + "%"}
                        />
                        {chartLines}
                        <ChartLegend content={<ChartLegendContent />} className="pt-12 font-sm" />
                    </LineChart>

                </ChartContainer>
            </CardContent>
        </Card>
    )
}
