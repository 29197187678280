import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();
  const handleSignIn = () => {
    navigate("/signin");
  };
  return (
    <div className="page-not-found max-height max-width flex-col justify-center text-center">
      <h1 className="h1">404 Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <p className="flex-row justify-center cursor-pointer text-underline" onClick={handleSignIn}>
        로그인하기
      </p>
    </div>
  );
}
