import BaseApi from "business_logic/base/BaseApi";
import { CreateSeriesCharacterRequest, PatchSeriesCharacterRequest, SeriesCharacter } from "business_logic/series/seriesModels";
import { DataStatus } from "business_logic/base/baseModels";
import DevLogger from "services/Logger";

class CharacterApi extends BaseApi {
  private static instance: CharacterApi;
  private constructor() {
    super();
  }
  public static getInstance() {
    if (!CharacterApi.instance) {
      CharacterApi.instance = new CharacterApi();
    }
    return CharacterApi.instance;
  }

  async createSeriesCharacter(req: CreateSeriesCharacterRequest) {
    try {
      const response = await this.axiosInstance.post("/series/characters", req);
      return response;
    } catch (error: any) {
      throw Error(error.response.data.detail);
    }
  }

  async getSeriesCharacters(seriesId: string, status: DataStatus = "all"): Promise<SeriesCharacter[]> {
    try {
      const response = await this.axiosInstance.get(`/series/${seriesId}/characters`, {
        params: {
          status: status,
        },
      });
      return response.data as SeriesCharacter[];
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async updateSeriesCharacter({ characterId, req }: { characterId: string; req: PatchSeriesCharacterRequest }) {
    try {
      DevLogger.log(req);
      const response = await this.axiosInstance.patch(`/series/characters/${characterId}`, req);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async deleteSeriesCharacter({ character, hardDelete = false }: { character: SeriesCharacter; hardDelete?: boolean }) {
    try {
      const response = await this.axiosInstance.delete(`/series/characters/${character.id}`, {
        params: {
          hard_delete: hardDelete,
        },
      });
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async restoreSeriesCharacter(character: SeriesCharacter) {
    try {
      const response = await this.axiosInstance.patch(`/series/characters/${character.id}/restore`);
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }
}

const CharacterApiInstance = CharacterApi.getInstance();
export default CharacterApiInstance;
