import { IDBase, TimeStampBase } from "business_logic/base/baseModels";
import { Episode, EpisodeStatusEnum } from "business_logic/episode/episodeModels";
import StringParser from "services/StringParser";

export enum ImageDirection {
  Left = "L",
  Right = "R",
}

// INSERT SCRIPT MODELS

// Step 3 : Dialog Translation

export interface CreateDialogTranslationRequest {
  dialogTurn: number;
  targetScript: string;
  quizWord?: string;
  characterName?: string;

}

export interface CreateDialogTranslationResponse {
  dialogTurn: number;
  baseScript: string;
  targetScript?: string;
  highlightedTranslation?: string;
}

// Step 4 : Get Quiz Word Info 

export interface GetQuizWordInfoRequest {
  dialogTurn: number;
  quizWord: string;
}

export interface GetQuizWordInfoResponse {
  dialogTurn: number;
  quizWord?: string;
  quizWordInfo?: WordInfo;
  quizWordDefinition?: WordDefinition;
}

// Step 5 : Multiple Choice Quiz

export interface CreateMultipleChoicesRequest {
  dialogTurn: number;
  targetScript: string;
  characterName?: string;
  quizWord: string;
}

export interface CreateMultipleChoicesResponse {
  dialogTurn: string;
  multipleChoices: string[];
}

// Step 6 : Quiz Explanation

export interface CreateQuizExplanationRequest {
  dialogTurn: number;
  dialog: string;
  characterName: string;
  quizWord: string;
  multipleChoices: string[];
}

export interface CreateQuizExplanationResponse {
  dialogTurn: number;
  quizExplanationEn: string;
  quizExplanationKo: string;
}

// Step 6-2 : Translation Choice

export interface CreateTranslationQuizChoicesRequest {
  dialogTurn: number;
  targetScript: string;
}

export interface CreateTranslationQuizChoicesResponse {
  dialogTurn: number;
  translationQuizAnswer: string;
  translationQuizChoices: string[];
}

// Step 7 : Dialog Audio Request

export interface AudioSettings {
  voiceStability?: number;
  voiceSimilarity?: number;
  voiceStyle?: number;
  voiceUseSpeakerBoost?: boolean;
}
export interface CreateDialogAudioRequest {
  dialogTurn: number;
  targetScript: string;
  quizWord: string;
  characterName: string;
  audioSettings?: AudioSettings;
}

export interface CreateDialogAudioResponse {
  dialogTurn: number;
  audioUrl: string;
  maskedAudioUrl?: string;
}

// Step Final : Insert Script

export interface CreateEpisodeScriptRequest {
  seriesId: string;
  episodeId: string;
  dialogs: Dialog[];
}

export interface WordTag extends IDBase, TimeStampBase {
  tagHeader: string;
  tagValue: string;
  highlightColor?: string;
}

export interface WordDefinition extends IDBase, TimeStampBase {
  pos?: string;
  definition?: string;
  imageUrl?: string;
  exampleSentences?: WordExampleSentence[];
}

export interface FeatureWordInfo extends IDBase, TimeStampBase {
  wordNumber?: number;
  occurrence?: number;
  wordDifficulty?: number;
  wordFrequency?: number;
  wordInfoId?: string;
  wordDefinitionId?: string;

  wordInfo?: WordInfo;
  wordDefinition?: WordDefinition;
}

export interface WordExampleSentence extends IDBase, TimeStampBase {
  sentence: string;
  translation: string;
  audioUrl?: string;
}

export interface WordLexicalRelation extends IDBase, TimeStampBase {
  relationType: string;
  relatedWord: string;
  definition: string;
}

export interface WordInsight extends IDBase, TimeStampBase {
  insightType: string;
  value: string;
  explanation: string;
}

export interface WordInfo extends IDBase, TimeStampBase {
  word: string;
  audioUrl?: string;
  pronunciation?: string;
  definitions: WordDefinition[];
  tags: WordTag[];
  lexicalRelations: WordLexicalRelation[];
  insights: WordInsight[];
}

export interface TranslationChoiceQuiz extends IDBase, TimeStampBase {
  dialogTurnId: string;
  choices?: TranslationChoiceQuizChoices[];
}

export interface TranslationChoiceQuizChoices extends IDBase, TimeStampBase {
  translationChoiceQuizId: string;
  displayString: string;
  isCorrect: boolean;
  audioUrl?: string;
}

export interface MultipleChoiceQuizChoice extends IDBase, TimeStampBase {
  displayString: string;
  wordInfo?: WordInfo;
  audioUrl?: string;
  isCorrect: boolean;
  featureWordInfo?: FeatureWordInfo;
}

export interface MultipleChoiceQuizHighlight extends IDBase, TimeStampBase {
  highlightWord?: string;
  highlightStartIndex?: number;
  highlightEndIndex?: number;
}

export interface MultipleChoiceQuizMask extends IDBase, TimeStampBase {
  maskStartIndex: number;
  maskEndIndex: number;
}

export interface MultipleChoiceQuiz extends IDBase, TimeStampBase {
  explanation?: string;
  displayExplanation?: string;
  masks: MultipleChoiceQuizMask[];
  highlights: MultipleChoiceQuizHighlight[];
  choices: MultipleChoiceQuizChoice[];
}

export interface WordLink extends IDBase, TimeStampBase {
  dialogTurnId?: string;
  wordInfoId?: string;
  wordDefinitionId?: string;
  startIndex?: number;
  endIndex?: number;
  saved?: boolean;
  visible?: boolean;

  wordInfo?: WordInfo;
  wordDefinition?: WordDefinition;
}



export interface DialogTurn extends IDBase, TimeStampBase {
  dialogType: string;
  index: number;
  targetScript: string;
  baseScript: string;
  backgroundImageUrl?: string;
  characterInternalId?: string;
  characterName?: string;
  characterImageUrl?: string;
  audioUrl?: string;
  maskedAudioUrl?: string;
  soundEffectUrl?: string;
  invertImage?: boolean;
  wordLinks?: WordLink[];

  episode?: Episode;
  multipleChoiceQuiz?: MultipleChoiceQuiz;
  translationChoiceQuiz?: TranslationChoiceQuiz;
  isWordImageExists?: boolean;
}

// Word Links


export interface WordLinkData {
  dialogTurnId: string;
  dialogTurn: DialogTurn;
  wordLink: WordLink;
}

export interface DialogWordLinkDetails {
  id?: string;
  word?: string;
  wordInfoId?: string;
  wordDefintionId?: string;
  startIndex?: number;
  endIndex?: number;
  current?: WordDefinition | null;
  candidates?: WordDefinition[];
  visible?: boolean;
}


export interface DialogWordLinks {
  dialogTurnId: string;
  baseScript: string;
  wordLinks: DialogWordLinkDetails[];
}

export class Dialog {
  id?: string;
  dialogTurn?: number;
  dialogType?: string;
  baseScript?: string | "";
  targetScript?: string | "";
  isQuiz: boolean;
  highlightedTranslation?: string;
  backgroundImageUrl?: string | "";
  backgroundImage?: string;
  objectImageUrl?: string;
  objectImage?: string;
  characterName?: string | "";
  characterExpression?: string | "";
  characterClothes?: string | "";
  characterImage?: string;
  invertImage?: boolean;
  imageDirection?: ImageDirection;
  soundEffect?: string;

  multipleChoiceQuiz?: boolean;
  quizWord?: string;
  quizWordId?: string;
  quizWordInfo: WordInfo;
  quizWordBase: string;
  quizWordDefinition?: WordDefinition;
  toeicWord?: string;
  isWordImageExists: boolean;
  quizExplanationEn?: string;
  quizExplanationKo?: string;
  multipleChoices?: string[];
  multipleChoicesId?: string[];

  translationQuiz?: boolean;
  translationQuizAnswer?: string;
  translationQuizChoices?: string[];

  audioSettings?: AudioSettings;
  audioUrl?: string | "";
  maskedAudioUrl?: string | "";
  wordLinkDetails?: DialogWordLinkDetails[];

  // Don't populate null or undefined fields
  toJSON() {
    const obj: any = {};
    for (const key in this) {
      if (this[key] !== null && this[key] !== undefined) {
        obj[key] = this[key];
      }
    }
    return obj;
  }

  // Method to translate DialogTurn to Dialog
  static fromDialogTurn(data: DialogTurn): Dialog {
    const dialog = new Dialog();
    dialog.id = data.id;
    dialog.dialogTurn = data.index;
    dialog.dialogType = data.dialogType;
    dialog.baseScript = data.baseScript;
    dialog.targetScript = data.targetScript;
    dialog.isQuiz = !!data.multipleChoiceQuiz;
    dialog.highlightedTranslation = dialog.getHighlightedTranslation(data);
    dialog.backgroundImageUrl = data.backgroundImageUrl;
    dialog.backgroundImage = dialog.getBackgroundImageUrlIdentifier(data);
    [dialog.characterName, dialog.characterExpression, dialog.characterClothes] = dialog.getCharacterExpressionClothes(data.dialogType, data.characterImageUrl!);
    dialog.objectImage = dialog.getObjectImageUrlIdentifier(data);
    dialog.invertImage = data.invertImage;
    dialog.imageDirection = data.invertImage! ? ImageDirection.Right : ImageDirection.Left; // Right if true
    dialog.soundEffect = data.soundEffectUrl;
    dialog.quizWord = dialog.getQuizWord(data.multipleChoiceQuiz);
    dialog.quizWordId = data.multipleChoiceQuiz?.choices.find((choice) => choice.isCorrect)?.id || null;
    dialog.toeicWord = data.multipleChoiceQuiz ? dialog.getToeicWord(data.multipleChoiceQuiz) : null;
    dialog.quizExplanationEn = data.multipleChoiceQuiz?.explanation || null;
    dialog.quizExplanationKo = data.multipleChoiceQuiz?.displayExplanation || null;
    dialog.multipleChoices = dialog.getMultipleChoices(data.multipleChoiceQuiz!);
    dialog.multipleChoicesId = dialog.getMutipleChoicesId(data.multipleChoiceQuiz!);

    dialog.translationQuiz = data.translationChoiceQuiz ? true : false;
    dialog.translationQuizAnswer = data.translationChoiceQuiz?.choices.find((choice) => choice.isCorrect)?.displayString || "";
    dialog.translationQuizChoices = data.translationChoiceQuiz?.choices.filter((choice) => !choice.isCorrect).map((choice) => choice.displayString) || [];

    dialog.isWordImageExists = data.isWordImageExists;
    dialog.audioSettings = {};
    dialog.audioUrl = data.audioUrl;
    dialog.maskedAudioUrl = data.maskedAudioUrl;
    dialog.wordLinkDetails = data.wordLinks;
    return dialog;
  }

  // Method to translate Dialog to DialogTurn
  toDialogTurn(): DialogTurn {
    return {
      index: this.dialogTurn!,
      dialogType: this.dialogType!,
      baseScript: this.baseScript || undefined,
      targetScript: this.targetScript || undefined,
      backgroundImageUrl: this.backgroundImageUrl!,
      characterInternalId: undefined,
      characterName: this.characterName || undefined,
      characterImageUrl: undefined,
      audioUrl: this.audioUrl || undefined,
      maskedAudioUrl: this.maskedAudioUrl || undefined,
      soundEffectUrl: this.soundEffect || undefined,
      invertImage: this.invertImage || undefined,
    };
  }

  constructor(data?: Partial<Dialog>) {
    Object.assign(this, data);
  }

  private getBackgroundImageUrlIdentifier(data): string {
    return StringParser.extractFilename(data.backgroundImageUrl);
  }

  private getObjectImageUrlIdentifier(data): string {
    if (data.dialogType === "narration") {
      if (data.characterImageUrl) {
        return StringParser.extractFilename(data.characterImageUrl);
      }
      return "";
    }
  }

  private getCharacterExpressionClothes(dialogType: string, characterImageUrl: string): string[] {
    // Check if characterImageUrl is valid
    if (dialogType === "narration") return ["narration", "", ""];

    // Match the URL pattern
    const filename = characterImageUrl.split("/").pop()?.split(".")[0] ?? "";

    // Split the filename by underscores
    const parts = filename.split("_");

    // Initialize default values
    let name = "",
      expression = "",
      clothes = "";

    // Extract information based on parts
    if (parts.length >= 3) {
      name = parts[0] || "";
      expression = parts[1] || "";
      clothes = parts[2] || "";
    } else if (parts.length === 2) {
      name = parts[0] || "";
      expression = parts[1] || "";
    } else if (parts.length === 1 && parts[0] !== "") {
      name = parts[0] || "";
    }

    return [name, expression, clothes];
  }

  private getMultipleChoices(multipleChoiceQuiz: MultipleChoiceQuiz): string[] | null {
    if (multipleChoiceQuiz && multipleChoiceQuiz.choices.length === 4) {
      return multipleChoiceQuiz.choices.filter((choice) => !choice.isCorrect).map((choice) => choice.displayString);
    }
    return null;
  }

  private getMutipleChoicesId(multipleChoiceQuiz: MultipleChoiceQuiz): string[] | null {
    if (multipleChoiceQuiz && multipleChoiceQuiz.choices.length === 4) {
      return multipleChoiceQuiz.choices.filter((choice) => !choice.isCorrect).map((choice) => choice.id);
    }
    return null;
  }

  private getTranslationChoiceQuizChoices(translationChoiceQuiz: TranslationChoiceQuiz): string[] | null {
    if (translationChoiceQuiz && translationChoiceQuiz.choices.length > 0) {
      return translationChoiceQuiz.choices.map((choice) => choice.displayString);
    }
    return null
  }

  private getHighlightedTranslation(dialogTurn: DialogTurn): string | null {
    if (dialogTurn.multipleChoiceQuiz) {
      return dialogTurn.baseScript.substring(dialogTurn.multipleChoiceQuiz.highlights[0].highlightStartIndex!, dialogTurn.multipleChoiceQuiz.highlights[0].highlightEndIndex!);
    }
    return null;
  }

  private getQuizWord(multipleChoiceQuiz?: MultipleChoiceQuiz): string | null {
    if (multipleChoiceQuiz && multipleChoiceQuiz.choices.length > 0) {
      const correctChoice = multipleChoiceQuiz.choices.find((choice) => choice.isCorrect);
      return correctChoice ? correctChoice.displayString : null;
    }
    return null;
  }

  private getToeicWord(multipleChoiceQuiz?: MultipleChoiceQuiz): string | null {
    if (multipleChoiceQuiz && multipleChoiceQuiz.choices.length === 4) {
      const correctChoice = multipleChoiceQuiz.choices.find((choice) => choice.isCorrect);
      if (correctChoice && correctChoice.featureWordInfo?.wordInfo?.word) {
        return correctChoice.featureWordInfo.wordInfo.word;
      }
    }
    return null;
  }
}

// Patch Dialog Word Link

export interface PatchDialogWordLinkItem {
  id: string | null;
  startIndex: number | null;
  definition?: string | null;
  wordDefinitionId: string | null;
  visible?: boolean | null;
}

export interface PatchDialogWordLinkRequest {
  dialogTurnId: string;
  baseScript?: string;
  wordLinkItems?: PatchDialogWordLinkItem[];
}

// Get Script

export interface EpisodeScriptInfo extends IDBase, TimeStampBase {
  index?: number;
  title?: string;
  quizCount?: number;
  dialogTurns?: DialogTurn[];
  isFree?: boolean;
  status?: string;
}

// Script validation

export interface AssetValidation {
  asset: string;
  exists: boolean;
}

export interface ScriptAssetValidation {
  episodeId: string;
  backgroundImages?: AssetValidation[];
  characterImages?: AssetValidation[];
  dialogAudio?: AssetValidation[];
  maskedDialogAudio?: AssetValidation[];
  characterProfileImages?: AssetValidation[];
  episodePreviewAssets?: AssetValidation[];
}

export interface DialogTurnValidation extends IDBase, TimeStampBase {
  index: number;
  dialogTurnId: string; // Assuming UUID4 is represented as a string
  isTargetScriptValid: boolean;
  isCharacterImageValid: boolean;
  isBackgroundImageValid: boolean;
  isAudioValid: boolean;
  isMaskedAudioValid: boolean;
  isWordImageValid: boolean;
}

export interface EpisodeValidation extends IDBase, TimeStampBase {
  episodeIndex: number;
  isEpisodeTitleValid: boolean;
  isEpisodeDescriptionValid: boolean;
  isEpisodeIntroValid: boolean;
  isEpisodePreviewImageValid: boolean;
  isEpisodePreviewBgmValid: boolean;
  isEpisodeThumbnailValid: boolean;
}

export interface CharacterValidation extends IDBase, TimeStampBase {
  name: string;
  characterInternalId: string;
  isCharacterProfileImageValid: boolean;
}

export interface SeriesValidation {
  isSeriesTitleValid: boolean;
  isSeriesDescriptionValid: boolean;
  isSeriesPosterValid: boolean;
}


export interface ScriptValidation extends IDBase, TimeStampBase {
  episodeId: string;
  episodeStatus: EpisodeStatusEnum;
  seriesValidation: SeriesValidation[];
  episodeValidation: EpisodeValidation[];
  dialogValidations: DialogTurnValidation[];
  characterValidations: CharacterValidation[];
}
