import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "../validateScript.scss";

interface ValidationTableProps {
  title: string;
  headers: string[];
  data: any[];
}

const ValidationTable: React.FC<ValidationTableProps> = ({ title, headers, data }) => {
  const headerToColumnName = {
    index: "턴",
    episodeIndex: "회차",
    name: "이름",
    isTargetScriptValid: "대사",
    isCharacterImageValid: "캐릭터 이미지",
    isBackgroundImageValid: "배경 이미지",
    isAudioValid: "대사 음성",
    isMaskedAudioValid: "마스킹 음성",
    isEpisodeTitleValid: "에피소드 제목",
    isEpisodeDescriptionValid: "에피소드 설명",
    isEpisodeIntroValid: "프리뷰 설명",
    isEpisodePreviewImageValid: "프리뷰 배경",
    isEpisodePreviewBgmValid: "BGM",
    isEpisodeThumbnailValid: "썸네일",
    isCharacterProfileImageValid: "프로필 이미지",
    isWordImageValid: "퀴즈 이미지",
    isSeriesTitleValid: "제목",
    isSeriesDescriptionValid: "설명",
    isSeriesPosterValid: "포스터 이미지",
  };

  const textToCheck = (text: string) => {
    if (text === "true") return <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green" }} />;
    else if (text === "false") return <FontAwesomeIcon icon={faCircleXmark} style={{ color: "red" }} />;
    else return text;
  };

  return (
    <div className="validation-table">
      <div>{title}</div>
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{headerToColumnName[header]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            .slice()
            .sort((a, b) => {
              if (a.hasOwnProperty("index") && b.hasOwnProperty("index")) {
                return a.index - b.index;
              } else {
                return 0;
              }
            })
            .map((item, idx) => (
              <tr key={idx}>
                {headers.map((header, index) => (
                  <td key={index}>{item.hasOwnProperty(header) ? textToCheck(item[header].toString()) : ""}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ValidationTable;
