import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowRotateRight, faCirclePlay, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScriptApiInstance from "business_logic/script/ScriptApi";
import { CreateDialogAudioRequest, CreateDialogTranslationRequest, CreateMultipleChoicesRequest, CreateQuizExplanationRequest, CreateTranslationQuizChoicesRequest, Dialog } from "business_logic/script/scriptModels";
import { useState } from "react";
import AudioServiceInstance from "services/AudioService";
import DevLogger from "services/Logger";


const BaseButton = ({ onClick, label, height, item, icon, disabled = false }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            await onClick(item);
        } catch (error) {
            alert('An error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button
            className="custom-table-button"
            style={{ height }}
            onClick={handleClick}
            disabled={isLoading || disabled}
        >
            <FontAwesomeIcon
                icon={isLoading ? (faSpinner as IconProp) : (icon as IconProp)}
                spin={isLoading}
                style={{ color: "white", height: "100%" }}
            />
            {label && label}
        </button>
    );
};



export const AudioCreateButton = ({ item, height, seriesId, episodeId, audioSettings, updateItem, disabled = false }) => {

    const createAudio = async () => {
        const createDialogAudioRequest: CreateDialogAudioRequest = {
            dialogTurn: item.dialogTurn,
            characterName: item.characterName,
            quizWord: item.quizWord,
            targetScript: item.targetScript,
            audioSettings: audioSettings
        }
        const response = await ScriptApiInstance.createDialogAudio({ seriesId, episodeId, payload: [createDialogAudioRequest] });
        try {
            response.forEach((audioUrl: any) => {
                updateItem(audioUrl as Dialog)
            });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <BaseButton
            height={height}
            label={""}
            icon={faArrowRotateRight}
            item={item}
            disabled={disabled}
            onClick={async () => {
                await createAudio();
            }}
        />
    );
}

export const AudioPlayButton = ({ item, height, maskAudio = false, disabled = false }) => {

    const playAudio = () => {
        const audioUrl = maskAudio && item.maskedAudioUrl
            ? item.maskedAudioUrl
            : item.audioUrl;
        AudioServiceInstance.play(audioUrl)
    }

    return (
        <BaseButton
            height={height}
            label=""
            icon={faCirclePlay}
            item={item}
            disabled={disabled}
            onClick={async () => {
                playAudio();
            }}
        />
    );
}

export const CreateQuizExplanationButton = ({ item, updateItem, height, disabled = false }) => {

    const createQuizExplanation = async () => {
        const payload: CreateQuizExplanationRequest[] = [
            {
                dialogTurn: item.dialogTurn,
                dialog: item.targetScript,
                characterName: item.characterName,
                quizWord: item.quizWord,
                multipleChoices: item.multipleChoices
            }]
        const response = await ScriptApiInstance.createQuizExplanation(payload);
        DevLogger.log("Create Quiz Explanation Response", response);

        response.forEach((quizExplanation: any) => {
            updateItem(quizExplanation as Dialog)
        });
    }
    return (
        <BaseButton
            height={height}
            label=""
            icon={faArrowRotateRight}
            item={item}
            disabled={disabled}
            onClick={async () => { await createQuizExplanation() }}
        />
    );
}

export const CreateDialogTranslationButton = ({ item, updateItem, height, disabled = false, translationSpeechStyle = "honorific" }) => {

    const createDialogTranslation = async () => {
        const payload: CreateDialogTranslationRequest[] = [
            {
                dialogTurn: item.dialogTurn,
                targetScript: item.targetScript,
                characterName: item.characterName,
                quizWord: item.quizWord,
            }]
        DevLogger.log("Create Dialog Translation", payload);
        const response = await ScriptApiInstance.createDialogTranslation(payload, translationSpeechStyle);
        DevLogger.log("Create Dialog Translation Response", response);

        response.forEach((translation: any) => {
            updateItem(translation as Dialog)
        });
    }
    return (
        <BaseButton
            height={height}
            label=""
            icon={faArrowRotateRight}
            item={item}
            disabled={disabled}
            onClick={async () => { await createDialogTranslation() }}
        />
    );
}

export const CreateMultipleChoicesButton = ({ item, updateItem, height, disabled = false }) => {

    const createMultipleChoices = async () => {
        const payload: CreateMultipleChoicesRequest[] = [
            {
                dialogTurn: item.dialogTurn,
                targetScript: item.targetScript,
                characterName: item.characterName,
                quizWord: item.quizWord,
            }]
        const response = await ScriptApiInstance.createMultipleChoice(payload);
        DevLogger.log("Create Multiple Choices", response);

        response.forEach((multipleChoices: any) => {
            updateItem(multipleChoices as Dialog)
        });
    }
    return (
        <BaseButton
            height={height}
            label=""
            icon={faArrowRotateRight}
            item={item}
            disabled={disabled}
            onClick={async () => { await createMultipleChoices() }}
        />
    );
}

export const CreateTranslationQuizChoicesButton = ({ item, updateItem, height, disabled = false }) => {

    const createTranslationQuizChoices = async () => {
        const payload: CreateTranslationQuizChoicesRequest[] = [
            {
                dialogTurn: item.dialogTurn,
                targetScript: item.targetScript,
            }]
        const response = await ScriptApiInstance.createTranslationQuizChoices(payload)
        DevLogger.log("Create Multiple Choices", response);
        response.forEach((choices: any) => {
            updateItem(choices as Dialog)
        });
    }
    return (
        <BaseButton
            height={height}
            label=""
            icon={faArrowRotateRight}
            item={item}
            disabled={disabled}
            onClick={async () => { await createTranslationQuizChoices() }}
        />
    );
}