import { Dialog } from "business_logic/script/scriptModels";
import { useCreateScriptStore } from "business_logic/script/scriptStore";
import CustomTable, { Column } from "presentation/components/customTable/customTable";
import IsError from "presentation/components/feedbacks/Error";
import IsLoading from "presentation/components/feedbacks/Loading";
import { useEffect } from "react";
import useCreateContent from "./hooks/useCreateContent";

const CreateScriptStep5 = ({ handleDataChange }) => {
  const createScriptStore = useCreateScriptStore();
  const { createMultipleChoice } = useCreateContent(createScriptStore);
  const { dialogs, upsertDialog, scriptingStep, addCompletedStep, isStepCompleted } = createScriptStore;

  useEffect(() => {
    if (!isStepCompleted()) {
      createMultipleChoice.mutateAsync();
      addCompletedStep(scriptingStep);
    }
  }, [createMultipleChoice]);

  const columns: Column<Dialog>[] = [
    { header: "턴", field: "dialogTurn", width: "2.5vw", editable: false },
    { header: "캐릭터", field: "characterName", width: "8vw", editable: false },
    { header: "대사", field: "targetScript", width: "32vw", editable: false },
    { header: "정답선택지", field: `quizWord`, width: "8vw", editable: false },
    { header: "오답선택지 1", field: `multipleChoices-0`, width: "8vw", editable: (dialog: Dialog) => !!dialog.quizWord },
    { header: "오답선택지 2", field: `multipleChoices-1`, width: "8vw", editable: (dialog: Dialog) => !!dialog.quizWord },
    { header: "오답선택지 3", field: `multipleChoices-2`, width: "8vw", editable: (dialog: Dialog) => !!dialog.quizWord },
    { header: "재생성", renderType: 'createMultipleChoicesButton', width: '4vw', editable: (dialog: Dialog) => !!dialog.quizWord }

  ];

  if (createMultipleChoice.isLoading) return <IsLoading />;
  if (createMultipleChoice.isError) return <IsError />;
  if (isStepCompleted() || createMultipleChoice.isSuccess) return (
    <CustomTable<Dialog>
      data={dialogs}
      columns={columns}
      rowHeight="64px"
      handleTextChange={handleDataChange}
      customProps={{ upsertDialog }}
    />
  );
};

export default CreateScriptStep5;
