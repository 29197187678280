import { Dialog } from "business_logic/script/scriptModels";
import { useCreateScriptStore } from "business_logic/script/scriptStore";
import CustomTable, { Column } from "presentation/components/customTable/customTable";
import IsError from "presentation/components/feedbacks/Error";
import IsLoading from "presentation/components/feedbacks/Loading";
import { useEffect } from "react";
import useCreateContent from "./hooks/useCreateContent";

export default function CreateScriptStep4({ handleDataChange }) {
  const createScriptStore = useCreateScriptStore();
  const { createDialogTranslation } = useCreateContent(createScriptStore);
  const { dialogs, upsertDialog, translationSpeechStyle, scriptingStep, addCompletedStep, isStepCompleted } = createScriptStore;

  useEffect(() => {
    if (!isStepCompleted()) {
      createDialogTranslation.mutateAsync();
      addCompletedStep(scriptingStep);
    }
  }, [createDialogTranslation]);

  const columns: Column<Dialog>[] = [
    { header: "턴", field: "dialogTurn", width: "2.5vw", editable: false },
    { header: "캐릭터", field: "characterName", width: "8vw", editable: true },
    { header: "대사", field: "targetScript", width: "28vw", editable: true },
    { header: "대사 번역", field: `baseScript`, width: "28vw" },
    { header: "퀴즈단어", field: `quizWord`, width: "8vw", editable: true },
    { header: "퀴즈단어 번역", field: `highlightedTranslation`, width: "8vw", editable: true },
    { header: "재생성", renderType: 'createDialogTranslationButton', width: '4vw' }
  ];

  if (createDialogTranslation.isLoading) return <IsLoading />;
  if (createDialogTranslation.isError) return <IsError />;
  if (isStepCompleted() || createDialogTranslation.isSuccess) return (
    <CustomTable<Dialog>
      data={dialogs}
      columns={columns}
      rowHeight="64px"
      handleTextChange={handleDataChange}
      customProps={{ upsertDialog, translationSpeechStyle: translationSpeechStyle }}
    />
  )
};
