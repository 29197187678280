import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import "./dropzone.scss";
import "tailwindcss/tailwind.css";
import DevLogger from "services/Logger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";

function validateImagePixels(file: File) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function (this: HTMLImageElement) {
      const pixelCount = this.width * this.height;
      DevLogger.log(file.name, "pixelCount", pixelCount);
      resolve(pixelCount < 2096704);
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
}

interface FileUploadDropzoneProps {
  title?: string;
  label?: string;
  files: File[];
  upscale?: boolean;
  acceptedFileTypes?: string[];
  maxFiles?: number;
  checkImagePixels?: boolean;
  onFilesChange: (files: File[]) => void;
  validateFileName?: (fileName: string) => boolean;
}

const FileUploadDropzone: React.FC<FileUploadDropzoneProps> = ({
  title,
  label,
  files,
  upscale,
  onFilesChange,
  validateFileName,
  acceptedFileTypes = ["image/*", "audio/*"],
  maxFiles = 50,
  checkImagePixels = false,
}) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      let validFiles = [];
      let errorFiles = [];

      for (const file of acceptedFiles) {
        if (checkImagePixels) {
          const isValid = await validateImagePixels(file);
          isValid ? validFiles.push(file) : errorFiles.push(file);
        } else {
          validFiles.push(file);
        }
      }

      const newFiles = [...files, ...validFiles].slice(0, maxFiles);
      onFilesChange(newFiles);

      if (errorFiles.length > 0) {
        const errorMessage = `이미지가 너무 커서 업스케일 할 수 없습니다 : ${errorFiles.map((file) => file.name).join(", ")}`;
        alert(errorMessage);
      }
    },
    [files, maxFiles, checkImagePixels, onFilesChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {}),
    maxFiles: maxFiles - files.length,
  });

  const removeFile = (event: React.MouseEvent, indexToRemove: number) => {
    event.stopPropagation();
    const newFiles = files.filter((_, index) => index !== indexToRemove);
    onFilesChange(newFiles);
  };

  const displayHintText = () => {
    if (files.length === 0) {
      return isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>;
    }
  };

  const AudioIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
        clipRule="evenodd"
      />
    </svg>
  );

  const fileListItem = (file: File, index: number) => {
    return (
      <div key={file.name} className="file-list-item-container max-width">
        <div className="file-preview">
          {file.type.startsWith("image/") ? (
            <img src={URL.createObjectURL(file)} alt={file.name} className="file-preview-image" />
          ) : file.type.startsWith("audio/") ? (
            <AudioIcon />
          ) : (
            <div className="text">No preview</div>
          )}
        </div>
        <div className="file-name">{file.name}</div>
        <div className="file-size">{(file.size / 1024).toFixed(2)} KB</div>
        <button onClick={(event) => removeFile(event, index)} className="file-delete-button">
          삭제하기
        </button>
      </div>
    );
  };

  const upscaleTag = () => {
    return (
      <div className="upscale-tag flex-row align-center gap-8">
        <p>업스케일</p>
        <FontAwesomeIcon icon={upscale ? faCheck : faX} />
      </div>
    )
  }

  return (
    <div className="component-dropzone flex-col max-width align-start">
      <div className="flex-row justify-between max-width">
        {title && <div>{title}</div>}
        {/* {upscaleTag()} */}
      </div>
      {label && <span className="dropzone-label">{label}</span>}

      <div {...getRootProps()} className={`dropzone-container ${isDragActive ? "active" : ""}`}>
        <input {...getInputProps()} />
        {displayHintText()}
        <div className="file-list">{files.length > 0 && files.map((file, index) => fileListItem(file, index))}</div>
      </div>
    </div>
  );
};

export default FileUploadDropzone;
