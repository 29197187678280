import { LucideIcon } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";

export type StatSummaryCardProps = {
    label: string;
    value: string;
    icon?: LucideIcon;
    infoText: string | JSX.Element;
    pointColor?: string;
};

export default function StatSummaryCard({ label, value, icon: Icon, infoText }: StatSummaryCardProps) {
    return (
        <Card>
            <CardContent className="flex flex-col gap-4 p-6">
                <div className="flex flex-col">
                    <header className="flex justify-between items-center mb-2">
                        <div className="text-sm font-bold overflow-hidden text-ellipsis whitespace-nowrap">{label}</div>
                        {Icon && <Icon className="color-black" />}
                    </header>
                    <section className="flex flex-col gap-1">
                        <div className="text-3xl font-bold">{value}</div>
                        <div className="text-sm font-light text-slate-500 overflow-hidden text-ellipsis whitespace-nowrap">{infoText}</div>
                    </section>
                </div>
            </CardContent>
        </Card>
    );
}