import BaseApi from "business_logic/base/BaseApi";
import { UserInquiry, UserInquiryMessage, UserInquiryStatus } from "./inquiryModels";

class InquiryApi extends BaseApi {
  private static instance: InquiryApi;
  private constructor() {
    super();
  }
  public static getInstance() {
    if (!InquiryApi.instance) {
      InquiryApi.instance = new InquiryApi();
    }
    return InquiryApi.instance;
  }

  async getInquiries({ status = "pending" }: { status: UserInquiryStatus }): Promise<UserInquiry[]> {
    try {
      const response = await this.axiosInstance.get("/inquiries", {
        params: {
          status: status,
        },
      });
      return response.data as UserInquiry[];
    } catch (error: any) {
      throw Error(error.response.data.detail);
    }
  }

  async getInquiryMessages(inquiryId: string): Promise<UserInquiryMessage[]> {
    try {
      const response = await this.axiosInstance.get(`/inquiries/${inquiryId}/messages`);
      return response.data as UserInquiryMessage[];
    } catch (error: any) {
      throw Error(error.response.data.detail);
    }
  }

  async createInquiryMessage(inquiryMessage: UserInquiryMessage): Promise<void> {
    try {
      await this.axiosInstance.post("/inquiries/messages", inquiryMessage);
    } catch (error: any) {
      alert(error.response.data.detail);
      throw Error(error.response.data.detail);
    }
  }

  async deleteInquiryMessage(inquiryMessageId: string): Promise<void> {
    try {
      await this.axiosInstance.delete(`/inquiries/messages/${inquiryMessageId}`);
    } catch (error: any) {
      alert(error.response.data.detail);
      throw Error(error.response.data.detail);
    }
  }
}

const InquiryApiInstance = InquiryApi.getInstance();
export default InquiryApiInstance;
