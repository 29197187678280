import SeriesInfo from "./SeriesInfo";
import SeriesTags from "./SeriesTags";

export default function SeriesTab() {
    return (
        <div className="grid grid-cols-2 w-full gap-4">
            <SeriesInfo />
            <SeriesTags />
        </div>
    )
}
