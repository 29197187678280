import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { Label } from "@radix-ui/react-label";
import SeriesApiInstance from "business_logic/series/SeriesApi";
import { SeriesTag } from "business_logic/series/seriesModels";
import { useCallback } from "react";
import useEditContent from "../useEditContent";
import DevLogger from "services/Logger";

export default function SeriesTags() {

    const { seriesSelected, fetchSeries, seriesTags, setSeriesTags } = useEditContent();

    const addSeriesTag = useCallback(() => {
        const updatedTags = [...seriesTags, { seriesId: seriesSelected.id, tagHeader: "", tagValue: "" }];
        setSeriesTags(updatedTags);
    }, [seriesSelected, seriesTags]);

    const deleteSeriesTag = useCallback((index: number) => {
        const updatedTags = seriesTags.filter((_, i) => i !== index);
        setSeriesTags(updatedTags);
        DevLogger.log(seriesTags);
    }, [seriesTags]);

    const saveSeriesTags = useCallback(async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        try {
            const tags: SeriesTag[] = seriesTags.map((tag, index) => {
                return {
                    seriesId: seriesSelected.id,
                    id: tag.id || undefined,
                    tagHeader: formData.get(`tagHeader-${index}`) as string,
                    tagValue: formData.get(`tagValue-${index}`) as string
                }
            });

            if (tags.some(tag => tag.tagHeader === "" || tag.tagValue === "")) {
                toast({
                    title: "Error",
                    description: "Tag header and value cannot be empty",
                    variant: "warning"
                })
                return;
            }
            await SeriesApiInstance.saveSeriesTags(seriesSelected.id, tags);
            toast({
                title: "Success",
                description: "Series tags have been updated successfully",
            })
            fetchSeries();
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update series tags",
                variant: "destructive"
            })
        }
    }, [seriesTags, seriesSelected]);

    return (
        <Card>
            <CardHeader>
                <CardTitle className="items-center"> Series Tags</CardTitle>
            </CardHeader>
            <form onSubmit={saveSeriesTags}>
                <CardContent>
                    <div className="grid grid-rows gap-4">
                        {seriesTags.map((tag: SeriesTag, index) =>
                            <div key={`${tag.id}-${index}`} className="grid-cols-5 grid items-end gap-4">
                                <div className="col-span-2">
                                    <Label htmlFor={tag.tagHeader} className="text-right"> Tag Header </Label>
                                    <Input name={`tagHeader-${index}`} defaultValue={tag.tagHeader} />
                                </div>
                                <div className="col-span-2">
                                    <Label htmlFor={tag.tagValue} className="text-right"> Tag Value </Label>
                                    <Input name={`tagValue-${index}`} defaultValue={tag.tagValue} />
                                </div>
                                <Button className="col-span-1" variant="delete" type="button" onClick={() => deleteSeriesTag(index)}>X</Button>
                            </div>
                        )}
                    </div>
                    <Button className="mt-4 w-full" variant="default" type="button" onClick={addSeriesTag}>Add Tag</Button>
                </CardContent>
                <CardFooter className="justify-end gap-4">
                    <Button variant="outline" type="submit">Save</Button>
                </CardFooter>
            </form>
        </Card>
    )
}