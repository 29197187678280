import React from "react";
import { Link } from "react-router-dom";

type SidebarMenuProps = {
  type: string;
  menuName: string;
  text: string;
  icon: React.ReactElement;
  onClick: (menuName: string) => void;
  selectedMenuName: string;
  setSelectedMenuName: (menuName: string) => void;
  to;
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({ type, menuName, text, icon, onClick, selectedMenuName, setSelectedMenuName, to }) => {

  const handleClick = (e: React.MouseEvent) => {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      setSelectedMenuName(menuName);
      onClick(menuName);
    }
  };

  return (
    <Link
      to={to}
      className={`menu-item ${selectedMenuName === menuName ? "active" : ""}`}
      onClick={handleClick}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="menu-content flex-row align-center">
        {icon}
        <h5 className="text">{text}</h5>
      </div>
    </Link>
  );
};

export default SidebarMenu;
