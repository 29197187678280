import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "styles/global.scss";
import "styles/layouts.scss";
import "../components.scss";

export default function Loading() {
  return (
    <div className="component-is-loading">
      <div className="icon-loading-spinner margin-auto-all">
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
      </div>
    </div>
  );
}
