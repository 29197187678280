import UploadApiInstance from "business_logic/uploadAsset/UploadAssetApi";
import SaveButton from "presentation/components/buttons/SaveButton";
import FileUploadDropzone from "presentation/components/dropzone/Dropzone";
import WorkInProgressOverlay from "presentation/components/workInProgressOverlay/WorkInProgressOverlay";
import { useState } from "react";

export default function UploadWordImageTab() {
  const [wordImages, setWordImages] = useState<File[]>([]);
  const acceptedFileTypes = ["image/*"];
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      wordImages.length > 0 && (await UploadApiInstance.uploadWordImages(wordImages));
      setWordImages([]);
      alert("업로드 완료");
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateFileName = (fileName: string): boolean => {
    const pattern = /^[\w]+_[\w]+\.(png|webp|jpg|jpeg)$/i;
    return pattern.test(fileName);
  };

  return (
    <div className="upload-word-images-tab max-width">
      <WorkInProgressOverlay isProcessing={isLoading} />
      <div className="upload-containers flex-row">
        <FileUploadDropzone title="토익단어 이미지 업로드" files={wordImages} onFilesChange={setWordImages} validateFileName={validateFileName} acceptedFileTypes={acceptedFileTypes} />
      </div>
      <SaveButton handleClick={handleUpload} />
    </div>
  );
}
