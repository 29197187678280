import icon_app from "assets/images/icon_app.png";
import { useAuth } from "contexts/AuthContext";
import "presentation/components/sidebar/sidebar.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "styles/global.scss";
import SidebarMenu from "./components/sidebarMenu";
import getSidebarMenuItems from "./components/sidebarMenuItems";
import { IoIosLogOut } from "react-icons/io";

export default function Sidebar() {
  const navigate = useNavigate();
  const signOut = useAuth().signOut;
  const [selectedMenuName, setSelectedMenuName] = useState("");

  const handleSignOut = () => {
    signOut();
    alert("로그아웃 되었습니다.");
    navigate("/signIn");
  };

  // Call the function to get the array of menu items
  const sidebarMenuItems = getSidebarMenuItems(navigate);

  return (
    <div className="sidebar">
      <div className="sidebar-layout"></div>
      <nav className="navigation"></nav>
      <div className="menu-items flex-column">
        <div className="logo-section flex-row justify-left margin-left-16">
          <img className="logo" src={icon_app} alt="logo"></img>
          <div className="logo-text">Vocatale</div>
        </div>

        <div className="menu-section">
          {sidebarMenuItems.map((item: any) => (
            <div className="mb-1">
              <SidebarMenu
                to={item.to}
                type={item.type}
                key={item.menuName}
                menuName={item.menuName}
                text={item.text}
                icon={item.icon}
                onClick={item.onClick}
                selectedMenuName={selectedMenuName}
                setSelectedMenuName={setSelectedMenuName}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="menu-item logout" onClick={handleSignOut}>
        <div className="menu-content flex-row align-center">
          <IoIosLogOut style={{ color: "#c1c1c1", fontSize: "24" }} />
          <h5 className="text">로그아웃</h5>
        </div>
      </div>
    </div >
  );
}
