import SeriesApiInstance from "business_logic/series/SeriesApi";
import { Series } from "business_logic/series/seriesModels";
import { useEffect } from "react";
import { useMutation } from "react-query";
import "styles/buttons.scss";
import IsLoading from "../feedbacks/Loading";
import { Button } from "@/components/ui/button";

interface Props {
  selectedSeries: Series;
  setSelectedSeries: (selectedSeries: Series) => void;
}

export default function SelectSeriesButtons({ selectedSeries, setSelectedSeries }: Props) {
  const {
    mutate: getSeries,
    data,
    isLoading,
    isError,
  } = useMutation(async () => {
    return await SeriesApiInstance.getSeries("active");
  });

  useEffect(() => {
    getSeries();
  }, [getSeries]);

  function handleSeriesSelect(series: Series) {
    setSelectedSeries(series);
  }

  if (isLoading) return <IsLoading />;
  if (isError) return <div>Error fetching data</div>;
  if (data)
    return (
      <div className="flex flex-wrap gap-2 mb-4 mt-4">
        {data.map((series) => (
          <Button variant="outline" className={`${selectedSeries?.id === series.id ? 'bg-black text-white' : 'bg-white text-black hover:bg-gray-100'}`} key={series.id} onClick={() => handleSeriesSelect(series)}>
            {series.title}
          </Button>
        ))}
      </div>
    );
}
