import React from "react";
import { useAuth } from "contexts/AuthContext";
import { PrivateRouter, PublicRouter } from "routers/routers";
import "styles/global.scss";
import { Toaster } from "@/components/ui/toaster"

const App: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return (
    <>
      <div className="app">{isAuthenticated ? <PrivateRouter /> : <PublicRouter />}</div>
      <Toaster />
    </>
  );
};

export default App;
