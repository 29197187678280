import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDialog } from "hooks/useDialog";
import { Plus } from "lucide-react";
import { useCallback, useState } from "react";
import useEditContent from "../useEditContent";
import CharacterTab from "./CharacterTab";
import EpisodeTab from "./EpisodeTab";
import SeriesTab from "./SeriesTab";
import CreateCharacterDialog from "presentation/dialogs/CreateCharacterDialog";
import CreateSeriesDialog from "presentation/dialogs/CreateSeriesDialog";
import CreateEpisodeDialog from "presentation/dialogs/CreateEpisodeDialog";

export default function ContentPane() {
    const { seriesSelected, fetchEpisodes, fetchSeries, fetchCharacters } = useEditContent();
    const [tabSelected, setTabSelected] = useState("seriesInfo");
    const { dialogs, openDialog, closeDialog } = useDialog();

    const handleClick = useCallback(() => {
        openDialog(tabSelected);
    }, [openDialog, tabSelected]);

    return (
        <div className="relative h-full overflow-hidden border-r border-gray-200">
            <Tabs defaultValue="seriesInfo" className="h-full">
                <section className="absolute top-0 left-0 right-0 flex justify-between items-center h-16 px-4 bg-white z-10 border-b border-gray-200">
                    <TabsList>
                        <TabsTrigger onClick={() => setTabSelected("seriesInfo")} value="seriesInfo">Series Info</TabsTrigger>
                        <TabsTrigger onClick={() => setTabSelected("episode")} value="episode">Episodes</TabsTrigger>
                        <TabsTrigger onClick={() => setTabSelected("character")} value="character">Characters</TabsTrigger>
                    </TabsList>
                    <Button className="gap-2 px-2 pr-4" onClick={handleClick}>
                        <Plus size={20} />
                        Add {tabSelected === 'seriesInfo' ? 'Series' : tabSelected === 'episode' ? 'Episode' : 'Character'}
                    </Button>
                </section>

                {seriesSelected &&
                    <section key={seriesSelected.id} className="mt-16 h-[calc(100%-4rem)] w-full overflow-y-scroll scrollbar-hide p-4 pt-2">
                        <TabsContent key="seriesTab" value="seriesInfo"> <SeriesTab /></TabsContent>
                        <TabsContent key="episodeTab" value="episode"><EpisodeTab /></TabsContent>
                        <TabsContent key="characterTab" value="character"><CharacterTab /></TabsContent>
                    </section>}
            </Tabs>

            {seriesSelected &&
                <div key={seriesSelected.id} >
                    <CreateSeriesDialog
                        key={`${seriesSelected.id}`}
                        open={dialogs["seriesInfo"] || false}
                        onClose={() => closeDialog("seriesInfo")}
                        onSuccess={fetchSeries}
                    />
                    <CreateEpisodeDialog
                        key={`${seriesSelected}-create-episode-dialog`}
                        providedSeries={seriesSelected}
                        open={dialogs["episode"] || false}
                        onClose={() => closeDialog("episode")}
                        onSuccess={fetchEpisodes}
                    />
                    <CreateCharacterDialog
                        key={`${seriesSelected}-create-character-dialog`}
                        providedSeries={seriesSelected}
                        open={dialogs["character"] || false}
                        onClose={() => closeDialog("character")}
                        onSuccess={fetchCharacters}
                    />
                </div>
            }
        </div>
    );
}