import DevLogger from "services/Logger";
import { PatchWordRequest, WordInfoRow } from "./wordModels";

class WordUtils {
  static parseWordInfoToWordInfoRow(wordInfo: []): WordInfoRow[] {
    const wordInfoRows: WordInfoRow[] = [];

    wordInfo.map((word: any, index) => {
      DevLogger.log(word);
      word.definitions.map((definition: any) => {
        const exampleSentence = definition.exampleSentences[0];
        const wordInfoRow: WordInfoRow = {
          // WordInfo Properties
          id: word.id,
          word: word.word,
          audioUrl: word.audioUrl,
          pronunciation: word.pronunciation,
          // WordDefinition Properties
          definitionId: definition.id,
          pos: definition.pos,
          definition: definition.definition,
          createdAt: definition.createdAt,
          // WordExampleSentence Properties
          sentenceId: exampleSentence.id,
          sentence: exampleSentence.sentence,
          translation: exampleSentence.translation,
          sentenceAudioUrl: exampleSentence.audioUrl,
        };
        wordInfoRows.push(wordInfoRow);
      });
    });
    DevLogger.log(wordInfoRows);
    return wordInfoRows;
  }

  static parseWordInfoRowToWordInfo(wordInfoRow: WordInfoRow): any {
    const wordInfo: PatchWordRequest = {
      word: wordInfoRow.word,
      definitions: [
        {
          pos: wordInfoRow.pos,
          definition: wordInfoRow.definition,
          exampleSentences: [
            {
              sentence: wordInfoRow.sentence,
              translation: wordInfoRow.translation,
            },
          ],
        },
      ],
    };
    return wordInfo;
  }
}

export default WordUtils;
