import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import CharacterApiInstance from "business_logic/character/CharacterApi";
import { CreateSeriesCharacterRequest, SeriesCharacter } from "business_logic/series/seriesModels";
import { Series } from "business_logic/series/seriesModels";

import { Loader2 } from "lucide-react";
import SelectSeriesButtons from "presentation/components/dropdowns/SeriesSelectButtons";
import { useState } from "react";
import { useMutation } from "react-query";
import "styles/buttons.scss";
import "styles/global.scss";
import "styles/modals.scss";

interface Props {
  providedSeries?: Series;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export default function CreateCharacterDialog({ providedSeries, open, onClose, onSuccess = () => { } }: Props) {
  const { toast } = useToast();
  const [isProcessing, setIsProcessing] = useState(false);
  const [series, setSeries] = useState<Series>(providedSeries ? providedSeries : null!);

  const createSeriesCharacter = useMutation(
    async (request: CreateSeriesCharacterRequest) => {
      await CharacterApiInstance.createSeriesCharacter(request);
    },
    {
      onSuccess: () => {
        toast({
          title: "Success",
          description: "캐릭터가 추가됐어요."
        })
        onSuccess();
        onClose();
      },
      onError: (error: any) => {
        toast({
          title: "Failure",
          description: error.message,
          variant: "destructive"
        })
      },
      onSettled: () => {
        setIsProcessing(false);
      }
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsProcessing(true);

    const formData = new FormData(event.target);
    const seriesCharacter: CreateSeriesCharacterRequest = {
      seriesId: providedSeries?.id as string,
      name: formData.get("name") as string,
      internalId: formData.get("internalId") as string,
      voiceId: formData.get("voiceId") as string,
      description: formData.get("description") as string,
    }
    createSeriesCharacter.mutate({ ...seriesCharacter });
  };

  return (
    <Dialog open={open} onOpenChange={onClose} defaultOpen={open} >
      <DialogContent className="sm:max-w-[600px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Create new series character</DialogTitle>
            <DialogDescription>
              Select Series.
            </DialogDescription>
          </DialogHeader>
          <SelectSeriesButtons selectedSeries={series} setSelectedSeries={setSeries} />
          {series &&
            <>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-4 align-center gap-4">
                  <Label htmlFor="name" className="text-right"> Title </Label>
                  <Input name="name" className="col-span-3" required placeholder="in Korean" />
                </div>
                <div className="grid grid-cols-4 align-center gap-4">
                  <Label htmlFor="internalId" className="text-right">Internal ID</Label>
                  <Input name="internalId" className="col-span-3" required placeholder="eg: ember, bongsoons, lib" />
                </div>
                <div className="grid grid-cols-4 align-center gap-4">
                  <Label htmlFor="voiceId" className="text-right">Elevenlabs ID</Label>
                  <Input name="voiceId" className="col-span-3" required />
                </div>
                <div className="grid grid-cols-4 align-center gap-4">
                  <Label htmlFor="description" className="text-right">Description</Label>
                  <Input name="description" className="col-span-3" required />
                </div>
              </div>

              <DialogFooter>
                <Button type="submit" disabled={isProcessing}>
                  {isProcessing ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Please wait
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </DialogFooter>
            </>}
        </form>
      </DialogContent>

    </Dialog>
  );
}
