import { AgGridReact } from "ag-grid-react";
import { useCallback, useState } from "react";

export interface GenericAgGridTableProps<T> {
  data: T[];
  columns: any[];
  rowHeight?: number;
  defaultColDef?: {};
  onCellValueChanged: (field: string, newValue: string, id: string) => void;
  onUpdate?: (item: T) => Promise<void>;
  onSoftDelete?: (item: T) => Promise<void>;
  onRestore?: (item: T) => Promise<void>;
  onPublish?: (item: T) => Promise<void>;
  onValidate?: (item: T) => Promise<void>;
  onHardDelete?: (item: T) => Promise<void>;
  onReply?: (item: T) => Promise<void>;
}

export function GenericAgGridTable<T extends { id: string; deletedAt?: string; status?: string }>({
  data,
  columns,
  rowHeight = 70,
  defaultColDef = { sortable: true },
  onCellValueChanged,
  onUpdate,
  onSoftDelete,
  onRestore,
  onHardDelete,
  onValidate,
  onPublish,
  onReply,
}: GenericAgGridTableProps<T>) {
  const [colDefs] = useState([
    ...columns,
    ...(onUpdate
      ? [
          {
            field: "modify",
            headerName: "수정",
            editable: false,
            cellClass: "non-editable-cell",
            width: 80,
            cellRenderer: (params: any) => {
              if (!params.data.deletedAt) {
                return (
                  <button className="ag-grid update-button" onClick={() => onUpdate(params.data)}>
                    수정
                  </button>
                );
              }
              return null;
            },
          },
        ]
      : []),
    ...(onSoftDelete
      ? [
          {
            field: "delete",
            headerName: "삭제",
            editable: false,
            cellClass: "non-editable-cell",
            width: 80,
            cellRenderer: (params: any) =>
              !params.data.deletedAt ? (
                <button className="ag-grid delete-button" onClick={() => onSoftDelete(params.data)}>
                  삭제
                </button>
              ) : null,
          },
        ]
      : []),
    ...(onRestore
      ? [
          {
            field: "restore",
            headerName: "복구",
            editable: false,
            cellClass: "non-editable-cell",
            width: 80,
            cellRenderer: (params: any) =>
              params.data.deletedAt ? (
                <button className="ag-grid restore-button" onClick={() => onRestore(params.data)}>
                  복구
                </button>
              ) : null,
          },
        ]
      : []),
    ...(onHardDelete
      ? [
          {
            field: "delete",
            headerName: "최종 삭제",
            editable: false,
            cellClass: "non-editable-cell",
            width: 80,
            cellRenderer: (params: any) =>
              params.data.deletedAt ? (
                <button className="ag-grid hard-delete-button" onClick={() => onHardDelete(params.data)}>
                  최종 삭제
                </button>
              ) : null,
          },
        ]
      : []),
    ...(onReply
      ? [
          {
            field: "reply",
            headerName: "답변",
            editable: false,
            cellClass: "non-editable-cell",
            width: 80,
            cellRenderer: (params: any) =>
              !params.data.deletedAt ? (
                <button className="ag-grid reply-button" onClick={() => onReply(params.data)}>
                  답변
                </button>
              ) : null,
          },
        ]
      : []),
    ...(onValidate
      ? [
          {
            field: "validate",
            headerName: "검증",
            editable: false,
            cellClass: "non-editable-cell",
            width: 80,
            cellRenderer: (params: any) =>
              !params.data.deletedAt ? (
                <button className="ag-grid validate-button" onClick={() => onValidate(params.data)}>
                  검증
                </button>
              ) : null,
          },
        ]
      : []),
    ...(onPublish
      ? [
          {
            field: "publish",
            headerName: "출시",
            editable: false,
            cellClass: "non-editable-cell",
            width: 80,
            cellRenderer: (params: any) =>
              !params.data.deletedAt ? (
                <button className="ag-grid publish-button" onClick={() => onPublish(params.data)}>
                  {params.data.status === "draft" ? "출시" : "해제"}
                </button>
              ) : null,
          },
        ]
      : []),
  ]);

  const handleCellValueChanged = useCallback(
    (e: any) => {
      onCellValueChanged(e.colDef.field, e.newValue, e.data.id);
    },
    [onCellValueChanged]
  );

  return (
    <div className="table-ag-grid max-width max-height overflow-x overflow-y margin-bottom-16 ag-theme-quartz">
      <AgGridReact rowData={data} columnDefs={colDefs} defaultColDef={defaultColDef} rowHeight={rowHeight} domLayout="autoHeight" onCellValueChanged={handleCellValueChanged} />
    </div>
  );
}
