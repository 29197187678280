import BaseApi from "business_logic/base/BaseApi";
import { Episode, EpisodeStatusEnum, PatchEpisodeRequest, SyncFeatureWordInfoRequest } from "business_logic/episode/episodeModels";
import StringParser from "services/StringParser";
import { CreateEpisodeRequest } from "./episodeModels";
import { DataStatus } from "business_logic/base/baseModels";
import { FeatureWordInfo } from "business_logic/script/scriptModels";
import DevLogger from "services/Logger";

class EpisodeApi extends BaseApi {
  private static instance: EpisodeApi;
  private constructor() {
    super();
  }
  public static getInstance() {
    if (!EpisodeApi.instance) {
      EpisodeApi.instance = new EpisodeApi();
    }
    return EpisodeApi.instance;
  }

  async createEpisode(newEpisodeRequest: CreateEpisodeRequest) {
    try {
      DevLogger.log("EpisodeApi - createEpisode", newEpisodeRequest);
      const response = await this.axiosInstance.post("/episodes", newEpisodeRequest);
      return response;
    } catch (error: any) {
      throw Error(error.response.data.detail);
    }
  }

  async getEpisodesBySeries({ seriesId, status = "active", emptyEpisodesOnly = false }: { seriesId: string; status?: DataStatus; emptyEpisodesOnly?: boolean }): Promise<Episode[]> {
    try {
      const response = await this.axiosInstance.get(`/episodes/by-series/${seriesId}`, {
        params: { status: status, empty_episodes_only: emptyEpisodesOnly },
      });
      const episodeInfoList: Episode[] = response.data.map((episode: any) => {
        return {
          ...episode,
          startBackgroundImage: StringParser.extractFilename(episode.startBackgroundImageUrl),
          listThumbnailImage: StringParser.extractFilename(episode.listThumbnailImageUrl),
          introBgm: StringParser.extractFilename(episode.introBgmUrl),
        };
      });
      return episodeInfoList;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async getEpisode(episodeId: string): Promise<Episode> {
    try {
      const response = await this.axiosInstance.get(`/episodes/${episodeId}`);
      return response.data as Episode;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async updateEpisode({ episodeId, episode }: { episodeId: string, episode: PatchEpisodeRequest }) {
    try {
      const payload: PatchEpisodeRequest = {
        index: episode.index,
        title: episode.title,
        description: episode.description,
        intro: episode.intro,
        startBackgroundImage: episode.startBackgroundImage,
        introBgm: episode.introBgm,
        isFree: episode.isFree,
      };
      DevLogger.log("EpisodeApi - updateEpisode", payload);
      const response = await this.axiosInstance.patch(`/episodes/${episodeId}`, payload);
      return response;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async deleteEpisode({ episode, hardDelete = false }: { episode: Episode; hardDelete?: boolean }) {
    try {
      const response = await this.axiosInstance.delete(`/episodes/${episode.id}`, {
        params: { hard_delete: hardDelete },
      });
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async restoreEpisode(episode: Episode) {
    try {
      const response = await this.axiosInstance.patch(`/episodes/${episode.id}/restore`);
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async updateEpisodeStatus(episodeId: string, newStatus: EpisodeStatusEnum) {
    try {
      const response = await this.axiosInstance.patch(`/episodes/${episodeId}/state`, { newStatus });
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async getEpisodeFeatureWords(episodeId: string): Promise<FeatureWordInfo[]> {
    try {
      const response = await this.axiosInstance.get(`/episodes/${episodeId}/feature-words`);
      DevLogger.log(response);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }

  async syncFeatureWordInfo(request: SyncFeatureWordInfoRequest) {
    try {
      DevLogger.log(request);
      const response = await this.axiosInstance.post(`/episodes/feature-word-info/sync`, request);

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.detail);
    }
  }
}

const EpisodeApiInstance = EpisodeApi.getInstance();
export default EpisodeApiInstance;
