import { IDBase, TimeStampBase } from "business_logic/base/baseModels";

export interface CreateSeriesRequestDTO {
  seriesTitle: string;
  seriesCode: string;
  seriesDescription: string;
  learningLang: string[];
}

export interface Series extends IDBase, TimeStampBase {
  title: string;
  internalId?: string;
  description: string;
  posterImageUrl?: string;
  posterImage?: string;
  dataSheetId?: string;
  tags?: SeriesTags[];
  episodes?: [];
}

export interface SeriesTags {
  tagHeader: string;
  tagValue: string;
}

export interface SeriesCharacter {
  name: string;
  internalId: string;
  voiceId: string;
  description: string;
  imageUrl: string;
  voiceStability?: Number;
  voiceSimilarityBoost?: Number;
  voiceStyle?: Number;
  voiceUseSpeakerBoost?: Boolean;
}

export interface CreateSeriesRequest {
  series: Series;
  tags?: SeriesTags[];
}

export type UploadLocation = "upload" | "characters" | "characterProfile" | "backgrounds" | "episodeThumbnail" | "seriesPoster";

export const uploadLocationMapping: Record<UploadLocation, string> = {
  "upload": "upload",
  "characters": "characters",
  "characterProfile": "character_profile",
  "backgrounds": "backgrounds",
  "episodeThumbnail": "episode_thumbnail",
  "seriesPoster": "series_poster"
};