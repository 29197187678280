import BaseApi from "business_logic/base/BaseApi";
import { DataStatus } from "business_logic/base/baseModels";
import DevLogger from "services/Logger";
import { CreateSeriesRequest, Series, SeriesTag } from "./seriesModels";

class SeriesApi extends BaseApi {
  private static instance: SeriesApi;
  private constructor() {
    super();
  }
  public static getInstance() {
    if (!SeriesApi.instance) {
      SeriesApi.instance = new SeriesApi();
    }
    return SeriesApi.instance;
  }

  async createSeries(series: CreateSeriesRequest) {
    DevLogger.log("SeriesApi - Payload : ", series);
    try {
      const response = await this.axiosInstance.put("/series", series);
      return await response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async getSeries(status: DataStatus = "active"): Promise<Series[]> {
    try {
      const response = await this.axiosInstance.get("/series", {
        params: { status: status },
      });
      return response.data as Series[];
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async getSeriesById(seriesId: string): Promise<Series> {
    try {
      const response = await this.axiosInstance.get(`/series/${seriesId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async updateSeries(seriesId, series: Partial<Series>): Promise<Series> {
    try {
      const payload: Series = {
        title: series.title,
        description: series.description,
      };
      const response = await this.axiosInstance.patch(`/series/${seriesId}`, payload);
      return await response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async deleteSeries({ series, hardDelete = false }: { series: Series; hardDelete?: boolean }): Promise<void> {
    try {
      await this.axiosInstance.delete(`/series/${series.id}`, {
        params: { hard_delete: hardDelete },
      });
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async restoreSeries(series: Series) {
    try {
      const response = await this.axiosInstance.patch(`/series/${series.id}/restore`);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async saveSeriesTags(seriesId, tags: SeriesTag[]) {
    try {
      const response = await this.axiosInstance.put(`/series/${seriesId}/tags/save`, tags);
      return response.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}

const SeriesApiInstance = SeriesApi.getInstance();
export default SeriesApiInstance;
