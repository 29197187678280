import CharacterApiInstance from "business_logic/character/CharacterApi";
import EpisodeApiInstance from "business_logic/episode/EpisodeApi";
import { Episode } from "business_logic/episode/episodeModels";
import SeriesApiInstance from "business_logic/series/SeriesApi";
import { Series, SeriesCharacter, SeriesTag } from "business_logic/series/seriesModels";
import { create } from "zustand";


type EditContentState = {
    seriesList: Series[];
    setSeriesList: (series: Series[]) => void;
    fetchSeries: () => void;

    seriesSelected: Series | null;
    setSeriesSelected: (series: Series) => void;

    episodeList: Episode[];
    setEpisodeList: (episodes: Episode[]) => void;
    fetchEpisodes: () => void;

    episode: Episode | null;
    setEpisode: (episode: Episode) => void;
    fetchEpisode: (episodeId: string) => void;

    characters: SeriesCharacter[];
    setCharacters: (characters: SeriesCharacter[]) => void;
    fetchCharacters: () => void;

    seriesTags: SeriesTag[] | [];
    setSeriesTags: (tags: SeriesTag[]) => void;

}

export const useEditContent = create<EditContentState>((set, get) => ({
    seriesList: [],
    setSeriesList: (series: Series[]) => set({ seriesList: series }),

    seriesSelected: null,
    setSeriesSelected: (series: Series) => set({ seriesSelected: series, seriesTags: series.tags }),

    episodeList: [],
    setEpisodeList: (episodes: Episode[]) => set({ episodeList: episodes }),

    episode: null,
    setEpisode: (episode: Episode) => set({ episode }),

    characters: [],
    setCharacters: (characters: SeriesCharacter[]) => set({ characters }),

    fetchSeries: async () => {
        const series = await SeriesApiInstance.getSeries("active");
        set({ seriesList: series });
    },

    fetchEpisodes: async () => {
        const { seriesSelected } = get();
        const episodes = await EpisodeApiInstance.getEpisodesBySeries({ seriesId: seriesSelected.id });
        set({ episodeList: episodes });
    },

    fetchEpisode: async (episodeId) => {
        const episode = await EpisodeApiInstance.getEpisode(episodeId);
        set({ episode: episode });
    },

    fetchCharacters: async () => {
        const { seriesSelected } = get();
        const characters = await CharacterApiInstance.getSeriesCharacters(seriesSelected.id);
        set({ characters: characters });
    },

    seriesTags: [],
    setSeriesTags: (tags: SeriesTag[]) => {
        set({ seriesTags: tags });
    }

}))

export default useEditContent;