import { toast } from "@/components/ui/use-toast";
import AnalyticsApiInstance from "business_logic/analytics/AnalyticsApi";
import { User, UserAttendance, UserAttendanceWithUserInfo, UserLog, UserReport } from "business_logic/analytics/analyticsModels";
import DevLogger from "services/Logger";
import { create } from "zustand"


interface UseAnalyicsStoreState {
    user: User | null;

    userReport: UserReport | null;

    userAttendanceSessionLogs: UserLog[] | null;

    date: Date;
    setDate: (date: Date) => void;

    attendances: UserAttendanceWithUserInfo[] | null;
    setAttendances: (attendances: UserAttendanceWithUserInfo[]) => void;

    attendanceSessionSelected: UserAttendanceWithUserInfo | null;
    setAttendanceSessionSelected: (attendanceSessionSelected: UserAttendanceWithUserInfo) => void;

    fetchAttendancesByDate: (date: Date) => void;
    fetchAttendancesByUser: (userId: string) => void;
    fetchAttendanceSessionLogs: () => void;
    fetchUserReport: () => void;


}

export const useAnalyticsStore = create<UseAnalyicsStoreState>((set, get) => ({
    user: null,
    userReport: null,
    userAttendanceSessionLogs: null,

    date: new Date(),
    setDate: (date: Date) => set({ date: date }),

    attendances: null,
    setAttendances: (attendances: UserAttendanceWithUserInfo[]) => set({ attendances }),

    attendanceSessionSelected: null,
    setAttendanceSessionSelected: (attendanceSessionSelected: UserAttendanceWithUserInfo) => set({ attendanceSessionSelected, user: attendanceSessionSelected.user }),

    fetchAttendancesByDate: async (date?: Date) => {
        const attendances = await AnalyticsApiInstance.fetchAttendancesByDate(date);
        DevLogger.log('Attendances', attendances);
        set({ attendances });
    },

    fetchAttendancesByUser: async (userSearch: string) => {
        if (!userSearch) {
            return;
        }
        const attendances = await AnalyticsApiInstance.fetchAttendancesByUser(userSearch);
        DevLogger.log('Attendances', attendances);
        set({ attendances });
    },

    fetchAttendanceSessionLogs: async () => {
        const { attendanceSessionSelected } = get();
        const userId = attendanceSessionSelected.user.id;
        const attendanceDate = new Date(attendanceSessionSelected.createdAt);

        const rawLogs = await AnalyticsApiInstance.fetchAttendanceSessionLogs(userId, attendanceDate);
        const userAttendanceSessionLogs = rawLogs.map(log => UserLog.fromJson(log));

        DevLogger.log('UserAttendanceSessionLogs', userAttendanceSessionLogs);
        set({ userAttendanceSessionLogs });
        DevLogger.log('UserAttendanceSessionLogs-after', userAttendanceSessionLogs);
    },

    fetchUserReport: async () => {
        const { attendanceSessionSelected } = get();
        const userReport = await AnalyticsApiInstance.fetchUserReport(attendanceSessionSelected.user.id);
        DevLogger.log('UserReport', userReport);
        set({ userReport });
    }

}))

export default useAnalyticsStore;