
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    ChartContainer,
    ChartLegend,
    ChartLegendContent
} from "@/components/ui/chart"
import { useMemo } from "react"
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts"
import DevLogger from "services/Logger"

export function RetentionGraph({ data }) {

    const chartData = useMemo(() => {
        if (!data) return [];

        return Object.entries(data).map(([date, values]) => ({
            date,
            ...(typeof values === 'object' ? values : {})
        })).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }, [data]);

    const chartConfig = useMemo(() => {
        if (chartData.length === 0) return {};
        const firstDataPoint = chartData[0];
        const keys = Object.keys(firstDataPoint).filter(key => key !== 'date');
        const colors = ['hsl(var(--chart-1))', 'hsl(var(--chart-2))', 'hsl(var(--chart-3))', 'hsl(var(--chart-4))'];
        DevLogger.log(chartData);
        return keys.reduce((config, key, index) => {
            config[key] = {
                label: key,
                color: colors[index % colors.length],
            };
            return config;
        }, {});
    }, [chartData]);

    return (

        <div className="w-full">
            <Card>
                <CardHeader className="flex items-center gap-2 space-y-0 border-b py-5 sm:flex-row">
                    <div className="grid flex-1 gap-1 text-center sm:text-left">
                        <CardTitle>Retention Rates</CardTitle>
                        <CardDescription>
                            Showing cumulative retention for past 12 weeks
                        </CardDescription>
                    </div>
                </CardHeader>
                <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">

                    <ChartContainer
                        config={chartConfig}
                        className="aspect-auto w-full h-[45vh]"
                    >
                        <LineChart accessibilityLayer
                            data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                                minTickGap={32}
                                fontSize={12}
                                tickFormatter={(value) => {
                                    const date = new Date(value)
                                    return date.toLocaleDateString("en-US", {
                                        month: "short",
                                        day: "numeric",
                                    })
                                }}
                            />
                            <YAxis
                                tickLine={false}
                                axisLine={false}
                                tickMargin={8}
                                minTickGap={16}
                                fontSize={12}
                                tickFormatter={(value) => {
                                    return (value * 100).toFixed(0) + "%"
                                }
                                }
                            />
                            <Tooltip
                                formatter={(value: any) => (value * 100).toFixed(2) + "%"}
                                labelFormatter={(value) => new Date(value).toLocaleDateString("en-US", {
                                    month: "short",
                                    day: "numeric",
                                })}
                            />
                            {Object.keys(chartConfig).map((key) => (
                                <Line
                                    key={key}
                                    type="monotone"
                                    dataKey={key}
                                    stroke={chartConfig[key].color}
                                    isAnimationActive={false}
                                />
                            ))}
                            <ChartLegend content={<ChartLegendContent />} />
                        </LineChart>
                    </ChartContainer>
                </CardContent>
            </Card>
        </div>
    )
}
